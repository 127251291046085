.cp_emslogo {
    height: 7.291666666666667vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
input:-internal-autofill-selected {
    box-shadow: 0 0 0 1000px #F3F7F9 inset; /* Replace 'white' with your desired color */
    text-shadow: none;
  }
.cp_emslogo img {
    /* height: 5.2083333vw;
    width: 13.28vw; */
    height: 6.21875vw;
}

.cpword__form {

    height: 30.36458333333333vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #383B3C;
}

.cpword__main a {
    text-decoration: none;
}

.cpword__sub {
    /* position: absolute;
    left:34.7395833vw;
    top:7.1875vw; */
    /* height: 7.18vw; */
    width: 30.57291666666667vw;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.cpword__text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9375vw;
    line-height: 1.145833333333333vw;
    color: #263659;

}
.password_already_used_parent {
    position: relative;
    height:2vw;
    width: 100%;
}
.password_already_used{
    position: absolute;
    color: red;
    top: 0vw;
    white-space: nowrap;
    font-size: 0.9375vw;
    font-weight: 500;
    font-style: 'Montserrat';
}

.cpword__sub input {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145833333333333vw;
    color: #8B93A5;
    border-radius: 6px;
    background: #F3F7F9;
    margin-bottom: 1.25vw;
    height: 2.65625vw;
    width: 27.760vw;
    border: none;
    padding-left: 0.9278350515vw;
}

::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145833333333333vw;
    margin-left: 0.9375vw;
    color: #8B93A5;
}

.cpword__text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.041666666666667vw;
    line-height: 1.25vw;
    text-align: left;
    width: 90%;
    margin-bottom: 5px;
    color: #263659;
}

.cpword__validation_text {
    color: red;
    font-size: 10px;
    margin-top: -6px;
    margin-left: 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    width: 50%;
    text-align: left;
    font-size: 0.677083vw;
    line-height: 0.8333333333333333vw;
    margin-bottom: 10px;
}

.indicator {
    height: 10px;
    width: 90%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 10px 0; */
}

.indicator span {
    display: inline-block;
    width: 6.666666666666667vw;
    height: 0.3125vw;
    background: lightgray;
    border-radius: 5px;
}
.indicator div.strength{
    display: block;
    margin-bottom:18px;
}
.indicator div.strengthNoshow{
    display: none;
}
.indicator span.veryweak {
    /* margin: 0 4px; */
    background: #EE5555;
    ;
}

.indicator span.weak {
    /* margin: 0 4px; */
    background: #E5C553;
}

.indicator span.medium {
    /* margin: 0 4px; */
    background: #65CB4B;
}

.indicator span.strong {
    /* margin: 0 4px; */
    background: #C2C2C2;
}

.cpword__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8854166666666667vw;
    margin-top: 2.0833333vw;
    margin-bottom: 1.041666666666667vw;
}

.cpword__heading h3 {
    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-size: 1.25vw;
    font-weight: 500;
    color: #000000;
    line-height: 1.510416666666667vw;
    color: #000000;
    text-align: center;
    margin: 0;
}

.cpword__hrline {
    width: 7.7vw;
    height: 0;
    border: 1px solid #A5A5A5;
    /* transform: rotate(180deg); */
}

.createpword__button {
    height: 2.65625vw;
    width: 27.76041666666667vw;
    border: none;
    border-radius: 8px;
    background: #1C477A;
    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #FFFFFF;
    margin-bottom: 1.25vw;

    font-style: normal;
    /* identical to box height */
}

.log_into {
    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-weight: 500;
    font-size: 1.0416666vw;
    line-height: 1.25vw;
    text-align: center;
    position: absolute;
    color: #FFFFFF;
    position: absolute;
    left: 44.6875vw;
    /* width:10.9895833vw; */
}

.cp_emptyspace {
    height: 14.11458333333333vw;
}

.copyright {
    height: 4.47916667vw;
    background: #1B2837;
    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-weight: 400;
    font-size: 0.7291666666666667vw;
    line-height: 0.8854166666666667vw;
    color: #FFFFFF;
    margin-top: 11.45833333vw;

    display: flex;
    justify-content: center;
    align-items: center;
}
/* @media (max-width: 600px) { */

@media (max-width: 767px) {
    .cp_emslogo {
        height: 21.66666666666667vw;
        display: none;
    }

    .cp_emslogo img {
        height: 17.38888888888889vw !important;
    }
    .password_already_used{
        font-size: 2.5vw !important;
    }
    .bac_arrow_main_div{
        height:0vw !important;
    }
    .cpword__form {
        height:100vh !important;
        align-items: baseline;
        background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%);
 
    }
.cpword__hrline{
    display: none !important;
}
    .cpword__sub {
        margin-top: 23vw !important;
        justify-content: flex-start;
        width: 86.11111111111111vw !important;
        /* height: 88.88888888888889vw !important; */
        background: none !important;
        box-shadow: none !important;
    }
    .cpword__heading{
        margin-top: 5.555555555555556vw !important;
        margin-bottom: 4.166666666666667vw !important;
        gap: 4vw;
    }
    .cpword__heading h3 {
        line-height: 6.666666666666667vw;
        color: #0047B2;
text-align: center;
font-size: 5.94vw !important;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
 .cpword__text{
    display: none !important;
 }
 .leftarrow_img_createpasswordimg{
    width:2.777777vw !important;
    height:5vw !important;
    margin-left:6.944444vw;
    position: relative;
    top:15.555vw;
  }
    .cpword__sub input {
        width: 75vw !important;
        height: 11.11111111111111vw !important;
        font-weight: 500;
        font-size: 4.888888888888889vw !important;
        line-height: 4.722222222222222vw !important;
        border-radius: 5px;
        border: 1px solid #0047B2;
        background: #FFF;
        margin-top: 5.5vw;
    }

    .createpword__button {
        line-height: 4.722222222222222vw;
        margin-top: 4.166666666666667vw !important;
        width: 44.1666vw !important;
height: 12.5vw !important;
background-color:#056B38;
font-size:5.1111111vw !important;
font-weight: 600;
border-radius: 6px !important;
color: #FFF;
margin-top:5.333333vw !important
    }

    .copyright {
        height: 21.38888888888889vw;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 3.888888888888889vw !important;
        line-height: 4.722222222222222vw;
        color: #FFFFFF;
    }

    .cpword__hrline {
        width: 11.38888888888889vw;
    }

    .cpword__text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        margin-left: 2vw;
        font-size: 4.444444444444444vw !important;
        line-height: 5.555555555555556vw;
        color: #263659;
        margin-bottom:2.777777777777778vw ;
    }
    .indicator{
        height: 7vw;
        margin-left:3vw;
    }

    .indicator span {
        width: 18.05555555555556vw;
        height: 1.666666666666667vw;
    }
    .cp_emptyspace {
        height: 0px;
    }
    .cpword__validation_text{
        font-size: 3.333333333333333vw !important;
        line-height: 4.166666666666667vw;
        margin-left: 12vw !important;
        margin-top:1vw;
        /* width: 50vw; */
    }
    .cpword__validation_text{
        width: 100%;
    }
    .copyright{
        display: none !important;
    }
    .forgot_password_input_value::placeholder{
        font-size: 3vw!important;
    }
 
  
    
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .cp_emslogo {
        height: 21.66666666666667vw;
        display: none;
    }

    .cp_emslogo img {
        height: 1.38888888888889vw !important;
    }
    .cpword__main{
        height:0vw;
    }
    .password_already_used{
        font-size: 2.5vw;
    }
    .bac_arrow_main_div{
        height:0vw !important;
    }
    .cpword__form {
        /* height: 169.1666666666667vw; */
        height:100vh !important;
        align-items: baseline;
        /* background: none !important; */
        background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%);
        /* margin-top:-6vw; */
 
    }
.cpword__hrline{
    display: none !important;
}
    .cpword__sub {
        margin-top: 10.55555555555556vw;
        justify-content: flex-start;
        width: 86.11111111111111vw;
        /* height: 88.88888888888889vw; */
        background: none !important;
        box-shadow: none !important;
        /* background-color: red !important; */
    }
    .cpword__heading{
        margin-top: 5.555555555555556vw;
        margin-bottom: 4.166666666666667vw;
        gap: 4vw;
    }
    .cpword__heading h3 {
        /* font-weight: 500;
        font-size: 5.555555555555556vw; */
        /* line-height: 6.666666666666667vw; */
        color: #0047B2;

text-align: center;
font-size: 4.94vw;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
 .cpword__text{
    display: none !important;
 }
 .leftarrow_img_createpasswordimg{
    width:2.777777vw;
    height:5vw;
    margin-left:6.944444vw;
    position: relative;
    top:15.555vw;
  }
    .cpword__sub input {
        width: 75vw;
        height: 11.11111111111111vw;
        font-weight: 500;
        font-size: 3.888888888888889vw;
        line-height: 4.722222222222222vw;
        border-radius: 5px;
        border: 1px solid #0047B2;
        background: #FFF;
        margin-top: 2vw;
    }
    .cpword__validation_text {
        width: 86%;

    }
    .createpword__button {
        line-height: 4.722222222222222vw;
        margin-top: 4.166666666666667vw !important;
        width: 44.1666vw;
height: 12.5vw;
background-color:#056B38;
font-size:5.1111111vw;
font-weight: 600;
border-radius: 6px !important;
color: #FFF;
margin-top:2.333333vw !important
    }

    .copyright {
        /* width: 360px; */
        height: 21.38888888888889vw;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 3.888888888888889vw;
        line-height: 4.722222222222222vw;
        color: #FFFFFF;
    }

    .cpword__hrline {
        width: 11.38888888888889vw;
    }

    .cpword__text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        margin-left: 2vw;
        font-size: 4.444444444444444vw;
        line-height: 5.555555555555556vw;
        color: #263659;
        margin-bottom:2.777777777777778vw ;
    }
    .indicator{
        height: 7vw;
    }

    .indicator span {
        width: 18.05555555555556vw;
        height: 1.666666666666667vw;
    }
    .cp_emptyspace {
        height: 0px;
    }
    .cpword__validation_text{
        font-size: 3.333333333333333vw;
        line-height: 4.166666666666667vw;
        /* margin-left: 3vw; */
    }
    .copyright{
        display: none !important;
    }
    .forgot_password_input_value::placeholder{
        font-size: 4vw!important;
    }
   
}

@media screen and (max-width: 1180) and (max-height:820) {
    .cp_emslogo {
        height: 0.66666666666667vw;
        display: none;
    }

    .cp_emslogo img {
        height: 1.38888888888889vw !important;
    }
    .password_already_used{
        font-size: 2.5vw;
    }
    .bac_arrow_main_div{
        height:0vw !important;
    }
    .cpword__form {
        /* height: 169.1666666666667vw; */
        height:100vh !important;
        align-items: baseline;
        /* background: none !important; */
        background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%);
        /* margin-top:-6vw; */
 
    }
.cpword__hrline{
    display: none !important;
}
    .cpword__sub {
        margin-top: 3.55555555555556vw;
        justify-content: flex-start;
        width: 86.11111111111111vw;
        /* height: 88.88888888888889vw; */
        background: none !important;
        box-shadow: none !important;
    }
    .cpword__heading{
        margin-top: 5.555555555555556vw;
        margin-bottom: 4.166666666666667vw;
        gap: 4vw;
    }
    .cpword__heading h3 {
        line-height: 6.666666666666667vw;
        color: #0047B2;
text-align: center;
font-size: 2.94vw;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
 .cpword__text{
    display: none !important;
 }
 .leftarrow_img_createpasswordimg{
    width:2vw;
    height:2.2vw;
    margin-left:6.944444vw;
    position: relative;
    top:5.555vw;
  }
    .cpword__sub input {
        width: 45vw;
        height: 6.11111111111111vw;
        font-weight: 500;
        font-size: 2.888888888888889vw;
        line-height: 4.722222222222222vw;
        border-radius: 5px;
        border: 1px solid #0047B2;
        background: #FFF;
        margin-top: 2.5vw;
    }


    .createpword__button {
        width: 24.1666vw;
height: 6.5vw;
background-color:#056B38;
font-size:3.1111111vw;
font-weight: 600;
border-radius: 6px !important;
color: #FFF;
margin-top:4.333333vw !important
    }

    .copyright {
        /* width: 360px; */
        height: 21.38888888888889vw;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 3.888888888888889vw;
        line-height: 4.722222222222222vw;
        color: #FFFFFF;
    }

    .cpword__hrline {
        width: 11.38888888888889vw;
    }

    .cpword__text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        margin-left: 2vw;
        font-size: 4.444444444444444vw;
        line-height: 5.555555555555556vw;
        color: #263659;
        margin-bottom:2.777777777777778vw ;
    }
    .indicator{
        height: 7vw;
    }

    .indicator span {
        width: 18.05555555555556vw;
        height: 1.666666666666667vw;
    }
    .cp_emptyspace {
        height: 0px;
    }
    .cpword__validation_text{
        font-size: 2.333333333333333vw;
        /* line-height: 4.166666666666667vw; */
        /* margin-left:34vw; */
    }
    .copyright{
        display: none !important;
    }
    /* .forgot_password_input_value::placeholder{
        font-size: 2vw!important;
    } */
   
}
