.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid lightgray;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
}

/* CSS for the fade-in animation */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

#estatus_leftarrow {
  width: 1.8326206475vw; /*19.478px; */
  height: 18.968px;
  flex-shrink: 0;
  margin-right: 10px;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textContainer {
  display: "flex";
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

.e_campaigns_main_div {
  .schedule_division {
    margin: 0.6vw 0vw 0.4vw -0.8vw;
    display: flex;
    align-items: center;
    gap: 0.7vw;
    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 0.9375vw;
      line-height: 1.145vw;
      color: #263659;
    }
  }
  .formdivs_inputfields {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #f3f7f9;
    width: 98.8%;
    height: 2.65625vw;
    margin-left: -0.8vw;
    border-radius: 0.3vw;
    label {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 0.9375vw !important;
      line-height: 1.145vw;
      color: #8b93a5 !important;
      margin-right: 0.8vw;
    }

    .form_input {
      width: 9.916666666666667vw;
      background-color: #fff !important;
      border-radius: 4px;
      border: none;
      background: url("../Assets/e-campaign-calander.png");
      background-repeat: no-repeat;
      padding-left: 2vw;
      background-size: 1.2vw 1.2vw !important;
      background-position: left 0.2vw center !important;
      font-size: 0.8333333333333334vw;
      color: #8b93a5 !important;
    }
    .form_timeinput {
      width: 6.666666666666667vw;
      border: none;
      font-size: 0.8333333333333334vw;
      color: #8b93a5 !important;
    }
  }
  .Searchbar_inputfield {
    /* background: #f3f7f9;
      border-radius: 6px;
      border: 1px solid #ece7e7;
      height: 2.29vw;
      width: 29%;
      font-size: 0.9375vw !important;
      color: #8b93a5 !important;
      position: absolute;
      left: 38vw;
  
      bottom: 0.45vw;
      padding-left: 0.6vw; */
  }
  .Searchclick_button {
    /* width: 3.9583333333333335vw;
      height: 1.5625vw;
      border: none;
      background-color: #1c477a;
      color: #ffffff;
      font-size: 0.8333333333333334vw;
      font-family: "Montserrat";
      border-radius: 0.3vw;
      position: absolute;
      left: 57.5vw;
      bottom: 0.8vw; */
  }
  .e_campaigns_text_ems {
    font-size: 1.4583333333333333vw;
    font-family: "Montserrat";
    font-weight: 800;
    position: relative;
    top: 1.19vw;
    right: 0.1vw;
    font-weight: 400;
    line-height: 1.77vw;
    color: #263659;
  }
  .socialImages {
    position: absolute;
    right: 5.9%;
    width: 14.458vw;
    height: 2.2912vh;
    top: 0.9vw;
    cursor: pointer;
  }
  .whatsapp {
    position: relative;
    right: 0.9375vw;
    width: 2.9166vw;
    height: 2.29vw;
  }
  .call {
    position: relative;
    right: 0.9375vw;
    width: 2.9166vw;
    height: 2.29vw;
  }
  .sms_modal {
    position: relative;
    right: 0.9375vw;
    width: 2.9166vw;
    height: 2.29vw;
  }
  .e-campaign {
    position: relative;
    right: 1.4vw;
    height: 2.29vw;
  }
  .e_campaigns_text_maindiv {
    height: 3.8541666666666665vw;
    width: 96%;
    position: relative;
    border-bottom: 0.2vw solid #1e1e1e;
    margin-top: 3.854vw;
    margin-left: 1.45%;
  }
  .voters_data_filter_dropdownE {
    /* position: relative; */
    top: 3.7vw !important;
    width: 26.7vw;
    height: 14.5vw;
  }

  .e-campaigns_filter_todate {
    position: relative;
    left: 6.8vw;
  }
  .applybtn_in_e-campaigns_data_dropdowns {
    height: 2.0833333333333335vw;
    width: 30%;
    position: absolute;
    bottom: 7%;
    right: 2%;
    font-size: 1.0416666666666667vw;
    font-family: "Montserrat";
    font-weight: 501;
    color: #ffffff;
    background-color: #04a559;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2vw;
    cursor: pointer;
    border: none;
  }
  #scroll_table_body {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    color: #263659;
    line-height: 0.88541vw;
    font-size: 0.729166vw;
  }

  .scroll_ecampaigns {
    margin: 14px 0px 0px 0px;
    margin-left: 1.45%;
    width: 96%;
    overflow: "";
  }
  .tableFixHead_ecampaigns_status {
    overflow: auto !important;
    height: 610px !important;
    /* height:31.77vw!important; */
    border: 1px solid lightgray !important;
  }
  .tableFixHead_ecampaigns_status th {
    position: sticky !important;
    top: -0.5px !important;
  }
  .thead_checkbox {
    width: 2.6vw !important;
    background: #e8ecf1;
  }
  #checkbox1 {
    width: 0.954vw;
    height: 0.954vw;
    position: absolute;
    top: 0.83vw !important;
    border: 2px solid #cbcbcb;
  }
  #body_checkbox {
    width: 0.954vw;
    height: 0.954vw;
    border: 2px solid #cbcbcb;
  }
  .thead_1_,
  .thead_2_,
  .thead_3_,
  .thead_4_,
  .thead_5_,
  .thead_6_ {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.729166vw;
    color: #4d7093;
  }
  .thead_1_ {
    background: #e8ecf1;
    width: 13.593vw !important;
  }
  .thead_2_ {
    background: #e8ecf1;
    width: 12.9687vw;
  }
  .thead_3_ {
    background: #e8ecf1;
    width: 12.8645vw;
  }
  .thead_4_ {
    background: #e8ecf1;
    width: 11.6145vw;
  }
  .thead_5_ {
    background: #e8ecf1;
    width: 10.9375vw;
  }
  .thead_6_ {
    background: #e8ecf1;
    width: 16.4583vw;
  }
  .table_data_2,
  .table_data_3,
  .table_data_4,
  .table_data_5,
  .table_data_6,
  .table_data_7 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.72916vw;
    color: #263659;
    line-height: 0.8854vw;
    height: 2.552vw;
  }

  .total_pages {
    margin-left: 0.48%;
    height: 1.927vw;
    background: #88a7c6;
    border-radius: 2px;
  }
  .prev_button_ecampaignsdata {
    background-color: #88a7c6;
    width: 2.7083333333333335vw;
    height: 1.9270833333333333vw;
    border: 0.052083333333333336vw solid #ccd0d6;
    border-radius: 0.10416666666666667vw;
    color: white;
    font-size: 0.7291666666666666vw;
    text-align: center;
    font-family: "Montserrat";
    outline: #88a7c6 !important;
    margin-left: 87.8%;
    cursor: pointer;
    font-weight: 800;
  }
  .current_page_number_ecampaignsdata {
    background-color: #88a7c6;
    width: 1.3541666666vw;
    height: 1.9270833333333333vw;
    border: 0.052083333333333336vw solid #ccd0d6;
    border-radius: 0.10416666666666667vw;
    color: white;
    font-size: 0.7291666666666666vw;
    text-align: center;
    font-family: "Montserrat";
    outline: #88a7c6 !important;
    margin-left: 1%;
    cursor: pointer;
    font-weight: 800;
  }
  #e-campaigns_currentPage span {
    position: relative;
    top: 5px;
  }
  .next_button_ecampaignsdata {
    background-color: #88a7c6;
    width: 2.7083333333333335vw;
    height: 1.9270833333333333vw;
    border: 0.052083333333333336vw solid #ccd0d6;
    border-radius: 0.10416666666666667vw;
    color: white;
    font-size: 0.7291666666666666vw;
    text-align: center;
    font-family: "Montserrat";
    outline: #88a7c6 !important;
    margin-left: 1%;
    cursor: pointer;
    font-weight: 800;
  }
  .viewstatus_modal-header {
    display: flex;
    justify-content: space-between;
    padding: 1vw;
    border: 1px solid #000000;
    border-left: none;
    border-top: none;
    border-right: none;
  }
  .viewstatus_modal-content {
    margin: auto;
    margin-top: 7vw;
    width: max-content;
    pointer-events: auto;
    background-color: #fff;

    /*       bottom: -2vw !important; */
  }
  .modal-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 1.145vw;
    line-height: 1.4vw;
    color: #263659;
  }
  .close {
    font-size: 1.1458333333333333vw;
    color: #0f2540 !important;
    position: relative;
    bottom: 3px;
    margin-left: 0 !important;
  }
  #label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #263659;
    margin-bottom: 0.3vw;
    padding-left: 0;
  }
  .select {
    height: 2vw;
    background: #f3f7f9;
    border: none !important;
    outline: none !important;
    margin-top: 0.365vw;
  }
  select {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #8b93a5;
    padding-left: 0.9375vw;
  }
  .select-toggle {
    position: relative;
    display: inline-block;
    height: 2.65vw;
    margin-top: 0.4vw;
    width: 95%;
    background: #f3f7f9;
    border: none;
    border-radius: 6px;
    option {
      min-width: 100px !important;
    }
  }
  select option {
    font-size: 1.3vw;
  }

  .select-toggle::after {
    position: absolute;
    top: 1vw;
    right: 1.5vw;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #023f55;
    content: "";
    pointer-events: none;
  }
  .select-toggle select {
    padding-left: 0;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
  }
  input[type="mobile"] {
    background: #f3f7f9;
    border-radius: 6px;
    border: none;
    width: 95% !important;
    height: 8vw;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }
  #enter_message,
  input[type="mobile"] {
    padding-bottom: 60px;
    padding-left: 10px;
    transition: 0.2s ease-in;
  }
  input[type="mobile"]::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw !important;
    line-height: 1.145vw;
    color: #8b93a5 !important;
    position: absolute;
    top: 0.7vw;
    left: 0vw;
  }
  #enter_message {
    background: #f3f7f9;
    border-radius: 6px;
    border: none;
    width: 95%;
    height: 8vw;
    font-size: 0.9375vw !important;
    color: #8b93a5 !important;
  }
  #enter_message::placeholder,
  .textAreaMobile::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw !important;
    line-height: 1.145vw;
    color: #8b93a5 !important;
  }
  .error {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    font-size: 0.78125vw;
    line-height: 0.9375vw;
    color: #263659;
    position: relative;
    left: 500px;
    top: 13px;
  }
  .side_section {
    position: relative;
    padding: 0;
  }
  #buttons_maindiv {
    display: flex;
    flex-direction: row;
    flex-wrap: initial;
  }
  #close_side span {
    display: flex;
    background: #d9d9d9;
    justify-content: center;
    align-items: center;
    font-size: 1.3vw;
    width: 1.95vw;
    color: #0f2540;
    border-radius: 6px;
    margin: 0;
    margin-left: 0.3vw;
  }
  #upload {
    background: #d9d9d9;
    border-radius: 6px;
    position: absolute;
    width: 1.989vw;
    height: 1.97vw;
    margin-top: 50px;
    margin-left: 8px;
  }
  #upload img {
    position: relative;
    top: 0px;
    right: 2px;
    height: 15px;
    width: 15px;
  }
  #iframe {
    border-radius: 6px;
    padding: 0;
  }
  #iframe img {
    width: 1.95vw;
    margin-left: 0.3vw;
    margin-top: 0.5vw;
  }
  #myModel {
    justify-content: center;
    display: flex;
  }
  #label_schedule {
    position: absolute;
    right: 4.1vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #263659;
  }
  #check {
    position: absolute;
    top: 0.04vw;
    right: 6.9vw;
    height: 0.9375vw;
    width: 0.9375vw;
    border: 2px solid #cbcbcb;
  }
  #date_block {
    background: #f3f7f9;
    height: 3vw;
    max-width: 95% !important;
  }
  .textAreaMobile {
    background: #f3f7f9;
    border-radius: 6px;
    border: none;
    height: 8vw;
    width: 95%;
  }
  .calender {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #8b93a5;
    padding-top: 13px;
    margin-left: 3px;
  }
  .calender_to {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #8b93a5;
    padding-left: 65px;
    padding-top: 13px;
  }
  #appt {
    margin-top: 5px;
    border: none;
    position: relative;
    right: 32px;
    width: 120px;
  }

  #label_allow {
    position: absolute;
    left: 0.1vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #263659;
  }
  #checkbox {
    position: absolute;
    top: 0.09vw;
    right: 16vw;
    height: 0.9375vw;
    width: 0.9375vw;
    border: 2px solid #cbcbcb;
  }
  .submitdiv_main {
    display: flex;
    justify-content: center;
  }
  .submit {
    padding: 10px 30px;
    justify-content: center;
    left: 22.15vw;
    background: #1c477a;
    opacity: 0.5;
    border-radius: 4px;
    margin-top: 2vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.145vw;
    color: #ffffff;
    border: none;
  }
  .e_campaigns_hr_line_maindiv {
    width: 96%;
    margin-left: 1.45%;
    margin-top: 1vw;
    hr {
      margin: 0;
      margin-top: 0.2vw;
    }
    .e_campaigns_filter_img {
      /* height:10%!important;
        width:10%!important; */
    }
  }

  @media screen and (max-width: 767px) {
  
    .schedule_division {
      margin: 6.6vw 0vw 2.4vw -1.6vw !important;  
      display: flex !important;
      align-items: center !important;
      gap: 0.7vw !important;
      span {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 3.888vw !important;
      }
    }
    .formdivs_inputfields {
      display: flex !important;
      flex-direction: column !important;
      width: 101.8% !important;
      height: 37.5vw !important;
      padding-left: 4vw !important;
      align-items: baseline !important;

      label {
        font-size: 3.888vw !important;
        line-height: 1.145vw;
        color: #8b93a5 !important;
        margin-right: 0.8vw !important;
      }

      .form_input {
        width: 33.333333333333336vw !important;
        height: 9.166666666666666vw !important;
        padding-left: 7vw !important;
        background-size: 5.2vw 5.2vw !important;
        background-position: left 1.2vw center !important;
        font-size: 3.888vw !important;
      }
      .form_timeinput {
        width: 28.333333333333336vw !important;
        height: 9.166666666666666vw !important;
        border: none !important;
        font-size: 3.888vw !important;
      }
    }
    .Searchbar_inputfield {
      height: 10.2777vw !important;
      width: 87% !important;
      left: 6vw !important;
      top: 2.6vw !important;
      font-size: 3.3vw !important;
      padding-left: 2vw !important;
    }
    .Searchclick_button {
      border-radius: 1vw !important;
      width: 16.958333333333332vw !important;
      height: 7vw !important;
      left: 73.5vw !important;
      top: 4.4vw !important;
      font-size: 3vw !important;
      font-family: "Montserrat" !important;
    }
    #iframe {
      margin-left: 0 !important;
    }
    .submitdiv_main {
      margin-top: 6vw !important;
    }
    .viewstatus_modal-dialog {

      margin: 0 !important;
    }

    .textAreaMobile::placeholder {
      font-size: 3.888vw !important;
    }
    .textAreaMobile {
      background: #f3f7f9 !important;
      border-radius: 6px !important;
      border: none !important;
      height: 40.333333vw !important;
      width: 88% !important;
    }
    .e_campaigns_hr_line_maindiv {
      width: 100% !important;
      margin-top: 3vw !important;
      margin-left: 0 !important;
    }
    .e_campaigns_text_maindiv {
      height: 0vw !important;
      width: 100% !important;
      position: absolute !important;
      top: 28.555vw !important;
      border: 2px solid #263659 !important;
      margin-left: 0% !important;
    }
    .e_campaigns_text_ems {
      position: relative !important;
      top: -10.33vw !important;
      left: 5.5vw !important;
      font-family: "Montserrat" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 5vw !important;
      color: #263659;
      line-height: 6.1111vw;
    }
    .filterES {
      position: absolute;
      left: 92.2%;
      width: 10.458333333333333vw;
      height: 20.2916666666666665vw;
      top: 0.7291666666666666vw;
      cursor: pointer;
      padding: 0;
      margin: 0;
      border: none;
    }
    .e_campaigns_filter_img {
      width: 100%;
      height: 100%;
    }
    .tableFixHead_ecampaigns_status {
      /* margin-top: 200px !important; */
    }
    .tableFixHead_ecampaigns_status {
      /* position: relative;
      top: 200px !important;
      overflow: auto!important;
      height: 610px !important;
      border: 1px solid lightgray!important; */
    }
    .tableFixHead_ecampaigns_status th {
      position: sticky !important;
      top: -0.5px !important;
    }
    .scroll_ecampaigns {
      /* position: relative; */
      /* top:100px; */
      /* margin: 14px 0px 0px 0px; */
      /* margin-left: 1.45%;
      width: 96%;
      margin-top:100px!important;
      overflow: ""; */
    }
    #e-campaigns_table {
      margin-top: 300px !important;
    }
    .ecampaings_status_table {
      position: relative;
      top: 300px;
    }
    .tableFixHead_ecampaigns_status {
      /* position: relative;
      top:200px!important; */
      position: absolute;
      top: 200px !important;
      overflow: auto !important;
      height: 610px !important;
      border: 1px solid lightgray !important;
    }
    .tableFixHead_ecampaigns_status {
      /* position: relative!important;
         top:500px!important; */
    }
    .tableFixHead_ecampaigns_status th {
      /* position: sticky!important;
      top: -0.5px!important; */
    }
    .socialImages {
      position: absolute !important;
      top: 2.77vw !important;
      left: 6.7vw !important;
    }
    .whatsapp {
      width: 13.88vw;
      height: 10.555vw;
      top: 12vw;
    }
    .call {
      width: 13.88vw;
      height: 10.555vw;
      position: relative;
      left: 15.877vw;
      top: 1.456vw;
    }
    .sms_modal {
      width: 13.88vw;
      height: 10.555vw;
      position: relative;
      left: 32.777vw;
      bottom: 9.2vw;
    }
    .e-campaign {
      width: 35vw;
      height: 10.2777vw;
      position: relative;
      left: 50.67vw;
      bottom: 19.8vw;
    }
    /* .tableFixHead_ecampaigns_status {
        overflow: auto!important;
        height: 610px !important;
        border: 1px solid lightgray!important;
      }
      .tableFixHead_ecampaigns_status  th {
        position: sticky!important;
        top: -0.5px!important;
      } */
    .tableFixHead_ecampaigns_status {
      /* position: relative;
        top:300px!important; */
    }
    .tableFixHead_ecampaigns_status {
      /* position: relative;
        top:100px;
        overflow: auto!important;
        height: 610px !important;
        border: 1px solid lightgray!important;
        top: 100px; */
    }
    #scrollbar_ecampaigns {
      /* position: relative;
        top:200px; */
    }
    .tableFixHead_ecampaigns_status {
      /* overflow: auto;
        height: 105vw;
        width: 86.11vw !important;
        margin-top: 3vw !important;
        margin-left: 5vw !important; */
    }
    .tableFixHead_ecampaigns_status th {
      /* position: sticky!important;
        height: 10vw!important;
        top: -5px!important;
        vertical-align: middle!important;
        font-size: 3.88vw!important; */
    }
    .thead_checkbox {
      /* background: #d5e5f2!important;
        border-radius: 3px 0px 0px 0px; */
    }
    #e-campaigns_table {
      margin-top: 500px !important;
    }
    .e_campaigns_main_div .thead_1_ {
      min-width: 8.5vw !important;
      background: #d5e5f2;
    }
    .thead_1_,
    .thead_2_,
    .thead_3_,
    .thead_4_,
    .thead_5_,
    .thead_6_ {
      font-family: "Montserrat" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 3.333vw !important;
      line-height: 4.1666vw !important;
      color: #4d7093 !important;
    }
    .thead_1_ {
      min-width: 36.5vw !important;
      background: #d5e5f2 !important;
    }
    .thead_2_ {
      min-width: 64vw !important;
      background: #e8ecf1 !important;
    }
    .thead_3_ {
      min-width: 45.83333vw !important;
      background: #e8ecf1 !important;
    }
    .thead_4_ {
      min-width: 45.83333vw !important;
      background: #e8ecf1 !important;
    }
    .thead_5_ {
      min-width: 45.83333vw !important;
      background: #e8ecf1 !important;
    }
    .thead_6_ {
      min-width: 45.83333vw !important;
      background: #e8ecf1 !important;
    }
    ::-webkit-scrollbar {
      width: 2px !important;
    }
    table,
    th,
    td {
      border: 1px solid #ccd0d6 !important;
      border-collapse: collapse !important;
    }
    .table_data_2,
    .table_data_3,
    .table_data_4,
    .table_data_5,
    .table_data_6,
    .table_data_7 {
      font-family: "Montserrat" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 3.88vw !important;
      line-height: 4.722vw !important;
      color: #263659 !important;
      height: 10.277777777777779vw !important;
      vertical-align: middle !important;
    }
    #checkbox1 {
      width: 4vw !important;
      height: 4vw !important;
      position: absolute !important;
      top: 2vw !important;
    }
    #body_checkbox {
      width: 4vw !important;
      height: 4vw !important;
    }
    .table_data_1 {
      background: #d5e5f2 !important;
      border-radius: 3px 0px 0px 0px;
    }
    .table_data_2 {
      background: #d5e5f2;
      border-radius: 3px 0px 0px 0px;
    }

    input[type="checkbox"] {
      width: 4vw !important;
      height: 4vw !important;
      border: 2px solid #cbcbcb;
    }

    .total_pages {
      width: auto !important;
      margin-left: 6.3vw;
      height: 9.722vw !important;
      margin-top: 10px;
      background: #88a7c6;
      border-radius: 2px;
    }
    .prev_button_ecampaignsdata {
      width: 13.611vw !important;
      height: 9.722vw !important;
      font-family: "Montserrat";
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 3.333vw !important;
      line-height: 4.166vw !important;
      position: absolute !important;
      right: 30.2vw !important;
      color: #ffffff;
      background: #88a7c6;
      border-radius: 2px;
      border: none;
      margin-top: 10px;
    }
    .current_page_number_ecampaignsdata {
      width: 6.611vw !important;
      height: 9.722vw !important;
      font-family: "Montserrat" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 3.333vw !important;
      line-height: 4.166vw !important;
      color: #ffffff !important;
      background: #88a7c6 !important;
      border-radius: 2px !important;
      position: relative !important;
      left: 29vw !important;
      border: none !important;
      margin-top: 10px !important;
    }
    .current_page_number_ecampaignsdata span {
      position: relative !important;
      top: 2.5vw !important;
      left: 0px !important;
      margin-top: 10px !important;
    }
    .next_button_ecampaignsdata {
      width: 13.611vw !important;
      height: 9.722vw !important;
      font-family: "Montserrat";
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 3.333vw !important;
      line-height: 4.166vw !important;
      color: #ffffff !important;
      background: #88a7c6 !important;
      border-radius: 2px !important;
      position: relative !important;
      left: 30.5vw !important;
      border: none;
      margin-top: 10px !important;
    }

    .viewstatus_modal-header {
      height: 4vw !important;
      border: 1px solid #000000 !important;
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
    }
    .viewstatus_modal-content {
      margin-bottom: 7vw !important;
      margin-left: 3.3vw !important;
      position: relative !important;
      display: flex !important;
      flex-direction: column !important;
      width: 91.66vw !important;
      max-height: 284vw !important;
      pointer-events: auto !important;
      background-color: #fff !important;
      border: #ffffff !important;
      outline: 0;
      left: 0.9vw !important;
      margin-top: 29vw !important;
      border-radius: 6px !important;
    }
    .viewstatus_modal-header {
      height: 15.2777vw !important;
      border: 1px solid #000000 !important;
      border-left: none;
      border-top: none;
      border-right: none;
      display: flex;
    }
    .modal-title {
      font-family: "Montserrat";
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 5vw !important;
      line-height: 6.111vw !important;
      color: #263659 !important;
      position: absolute !important;
      top: 5.7vw !important;
      left: 25vw !important;
    }
    .close {
      font-size: 5vw !important;
      color: #0f2540;
    }
    #label {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 4.444vw;
      line-height: 5.55vw;
      color: #263659;
      position: relative;
    }
    select option {
      font-size: 3vw;
      line-height: 4vw;
      overflow: hidden;
      width: auto;
    }

    select {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 4.444vw;
      line-height: 5.5555vw;
      color: #8b93a5;
      padding-left: 2vw;
      padding-top: 0vw;
    }
    .select-toggle {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 11.11vw;
      background: #f3f7f9;
      border: none;
      margin-top: 10px;
    }
    .select-toggle::after {
      position: absolute;
      top: 5vw !important;
      right: 3vw;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #023f55;
      content: "";
      pointer-events: none;
    }
    .select-toggle select {
      height: 10vw;
      appearance: none;
      -webkit-appearance: none;
      background: transparent;
      cursor: pointer;
    }
    #enter_message,
    input[type="mobile"] {
      padding-bottom: 30vw !important;
      padding-left: 10px;
      transition: 0.2s ease-in;
    }
    input[type="mobile"] {
      width: 74vw !important;
      height: 45vw;
      border: none;
      position: relative;
      top: 1.5vw;
    }
    input[type="mobile"]::placeholder {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 3.888vw !important;
      line-height: 4.7222vw;
      color: #8b93a5 !important;
      position: absolute;
      top: 12px;
      left: 14px;
    }
    #close_side {
      background: #d9d9d9;
      color: #0f2540;
      border-radius: 6px;
    }
    #close_side span {
      font-size: 8.333333333333334vw !important;
      width: 9vw;
      height: 9vw;
    }
    #upload {
      width: 9vw;
      height: 9vw;
      margin-top: 64px;
      background: #d9d9d9;
      border-radius: 6px;
    }
    #upload img {
      width: 4.5vw;
      height: 4.5vw;
    }
    #iframe img {
      width: 9vw;
      height: 9vw;
    }
    .msg {
      position: relative;
      top: 1.4vw;
    }
    #enter_message {
      height: 45vw;
      border: none;
      position: relative;
      top: 3vw;
      width: 100%;
      font-size: 3.888vw !important;
      text-overflow: ellipsis;
    }
    #enter_message::placeholder {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 3.888vw !important;
      line-height: 4.7222vw;
      color: #8b93a5 !important;
      position: absolute;
      top: 12px;
      left: 14px;
    }
    .error {
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 400;
      font-size: 3.611111vw;
      line-height: 4.4444vw;
      color: #263659;
      position: relative;
      left: 500px;
      top: 13px;
    }
    #label_schedule {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 4.444vw;
      line-height: 5.55vw;
      color: #263659;
      position: relative;
      right: 15px;
    }
    #check {
      position: absolute;
      right: 54.8vw;
      width: 4vw;
      height: 4vw;
      top: -5vw;
    }
    #date_block {
      height: 265px !important;
      min-width: 83.5vw !important;
      background: #f3f7f9;
      border-radius: 6px;
      position: relative;
      top: 0vw;
    }
    .calender {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 4.44vw;
      line-height: 5.55vw;
      color: #8b93a5;
      position: relative;
      left: 4vw !important;
      top: 20px;
    }
    #date1_bg {
      height: 11.11vw;
      width: 0px !important;
      top: -30px;
      position: relative;
      left: 25vw !important;
    }
    #date {
      position: relative;
      height: 10.9vw;
      margin-left: 15px !important;
      width: 170px !important;
      top: -0.6px;
      font-size: 4vw;
    }
    .calender_to {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 4.44vw;
      line-height: 5.55vw;
      color: #8b93a5;
      position: relative;
      top: -15px;
      right: 0.5vw !important;
    }
    #date2_bg {
      height: 10.9vw;
      width: 0px;
      top: -65px;
      position: relative;
      left: 15.15vw !important;
    }
    #dates {
      height: 40px;
      position: relative;
      height: 10.9vw;
      margin-left: 15px;
      width: 170px !important;
      top: -0.6px;
      font-size: 4vw;
    }
    #time_block {
      margin-left: 75px !important;
      margin-top: -40px;
    }
    #appt {
      border: none;
      position: relative;
      left: 50px;
      width: 200px !important;
      height: 50px;
      top: -16px;
    }
    #label_allow {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 4.44444vw;
      line-height: 5.555vw;
      color: #263659;
      position: absolute;
      left: -7px !important;
    }
    #checkbox {
      width: 4.58vw;
      height: 4.58vw;
      position: relative;
      top: 0.5vw;
      left: 297px !important;
    }
    .submit {
      width: 46.1111vw;
      height: 11.111vw;
      font-size: 4.444vw;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .prev_button_ecampaignsdata {
      margin-left: 81.8% !important;
    }
    .e_campaigns_text_ems {
      font-size: 1.8vw;
    }
    .e_campaigns_text_maindiv {
      height: 4.3vw;
    }
    .scroll_ecampaigns {
      margin: 14px 0px 0px 14px;
    }
    .prev_button_ecampaignsdata,
    .next_button_ecampaignsdata {
      height: 3.5vw !important;
      width: 5vw !important;
      font-size: 1.3vw !important;
    }
    .current_page_number_ecampaignsdata {
      height: 3.5vw !important;
      width: 2vw !important;
      font-size: 1.3vw !important;
    }
    .buttons_voterdata_next_prev_main_div {
      margin-left: 53% !important;
    }
    .table_fixed_status {
      position: relative;
      /* top:30vw!important; */
      height: 800px !important;
      background-color: red;
    }
  }
}

.voters_data_filter_dropdownE {
  height: 16vw !important;
  width: 30%;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  z-index: 50;
  bottom: -10vw;
  border-radius: 0.20833333333333334vw;
  box-shadow: 0vw 0.20833333333333334vw 0.5208333333333334vw;
  animation: mandaldrop 0.2s linear;
}

.filterES {
  position: absolute;
  left: 92.2%;
  width: 6.458333333333333vw;
  height: 2.2916666666666665vw;
  top: 0.7291666666666666vw;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
}
.e_campaigns_filter_img {
  width: 100%;
  height: 100%;
}
.noDataContainer {
  min-width: none;
  height: 50px;
  position: absolute;
  padding: 20px;
  font-size: medium;
}

.ecampaigns_data {
  /* position: relative; */
  /* top:10px; */
  margin: 10px;
}
.date_picker_topmanin_div {
  display: flex;
  justify-content: space-around;
  padding: 5px !important;
}
.date_picker_topmanin_div span {
  padding: 0px 10px 0px 10px !important;
}
.selection_criteria_title_div {
  background-color: #f7f7f7;
  color: black;
  padding: 12px;
  border: 1px solid grey;
  font-weight: 800;
}
.canclebt_in_ecampaings_data_dropdowns {
  /* background-color: red; */
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  border: 1px solid grey;
  box-shadow: 1px 1px grey;
  /* top:-7vw; */
  /* display: none; */
  color: black;
  font-weight: 800;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: grey !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
@media screen and (max-width: 767px) {
  .viewstatus_modal-content {
    margin: auto;
    margin-top: 7vw;
    width: 90% !important;
    height: 75vh !important;
  }
  .tableFixHead_ecampaigns_status {
    position: relative;
    top: 30vw !important;
  }
  .thead_1_,
  .thead_2_,
  .thead_3_,
  .thead_4_,
  .thead_5_,
  .thead_6_ {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.3333vw !important;
    color: #4d7093;
  }
  .filterES {
    position: absolute;
    left: 71.3%;
    width: 24.4444444vw;
    height: 9.72vw;
    top: -9vw;
    cursor: pointer;
    border: none;
  }
  .voters_data_filter_dropdownE {
    width: 92vw !important;
    height: 54vw !important;
    margin-right: 5vw !important;
    /* background-color: red; */
  }
  .selection_criteria_title_div {
    background-color: #f7f7f7;
    color: black;
    height: 15vw;
    border: 1px solid grey;
    font-weight: 800 !important;
    font-size: 5vw !important;
    /* background-color: red; */
  }
  .date_pciker_block {
    /* background-color: yellow; */
    width: 98%;
    height: 30vw;
    margin-left: 1vw;
  }
  .ecampaigns_data span {
    position: absolute !important;
    width: 90%;
    height: 12vw !important;
    /* background-color:green; */
    .e-campaigns_filter_fromdate {
      /* background-color: red; */
      width: 28vw;
      position: relative;
      top: 1vw;
      font-size: 4.5vw;
      left: -4vw;
      /* right:5vw; */
      font-weight: 500;

      /* left:-6vw!important; */
    }
    .e-campaigns_filter_todate {
      position: relative;
      top: 1vw;
      left: 40vw !important;
      font-size: 4.5vw;
      font-weight: 500;
    }
  }

  .date_picker_topmanin_div {
    /* background-color: orange!important; */
    width: 100%;
    height: 13vw;
    /* position: relative; */
    position: absolute;
    right: 0vw;
    top: 26vw;
    /* top:26vw; */
  }
  #datePicker,
  #datetoPicker {
    margin: 0;
    padding: 0;
    width: 40vw;
    height: auto;
    position: relative;
    /* top:1vw; */
  }
  .e-campaigns_filter_hrtag {
    /* display: none; */
    /* margin-top:6vw; */
    background-color: grey;
    position: relative;
    top: -10vw;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: grey !important;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .e-campaigns_filter_foot_buttons {
    position: absolute;
    /* top:-7.5vw; */
    width: 100%;
    /* background-color: red!important; */
    height: 10vw !important;
    bottom: 2.5% !important;
  }
  .canclebt_in_ecampaings_data_dropdowns {
    /* background-color: red; */
    margin: 0;
    padding: 0;
    position: relative;
    border: none;
    border: 1px solid grey;
    box-shadow: 1px 1px grey;
    /* top:-7vw; */
    /* display: none; */
  }
  .applybtn_in_e-campaigns_data_dropdowns {
    padding: 0;
    margin: 0;
    display: none;
    height: 10.5833333333333335vw !important;
    /* width: 80%!important; */
    position: absolute;
    width: 30%;
    bottom: 0% !important;

    font-size: 3.3816666666666667vw !important;
    font-family: "Montserrat";
    font-weight: 501;
    color: #ffffff;
    background-color: #04a559;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2vw;
    cursor: pointer;
    border: none;
    padding: 0;
  }

  #scroll_table_body {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    color: #263659;
    line-height: 0.88541vw;
    font-size: 0.729166vw;
  }
  .ecampaigns_data {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .selection_criteria_title_div {
    background-color: #f7f7f7;
    color: black;
    /* padding: 12px; */
    border: 1px solid grey;
    font-weight: 800;
  }
  .ecampaigns_data {
    display: flex;
    flex-direction: row;
  }

  .voters_data_filter_dropdownE {
    width: 35vw !important;
    height: 21vw !important;
    /* background-color: green; */
  }
  .ecampiangsdate_ {
    position: absolute;
    height: 3vw;
    /* background-color: red; */
    position: absolute;
    width: 95%;
    .e-campaigns_filter_fromdate {
      position: relative;
      right: 0.5vw;
    }
    .e-campaigns_filter_todate {
      position: relative;
      display: flex;
    }
  }

  .date_picker_topmanin_div {
    display: flex;
    justify-content: space-around;
    position: absolute;
    margin-top: 5vw;
    margin-left: 0vw;
    width: 99%;
    /* background-color: #000000; */
    #datePicker,
    #datetoPicker {
      position: relative;
      width: 15.5vw;
      padding: 0;
      margin: 0;
    }
  }

  .e-campaigns_filter_hrtag {
    background-color: grey !important;
    /* background-color: red!important; */
    position: relative;
    top: 8vw !important;
    height: 0.2vw;
  }

  .e-campaigns_filter_foot_buttons {
    position: absolute;
    /* top:-7.5vw; */
    top: 15.8vw;
    height: 5vw;
    width: 100%;
    /* background-color: red!important; */
    /* height:10vw!important;
  bottom: 1.5%!important; */
  }
  .canclebt_in_voters_data_dropdowns_button {
    height: 3.0833333333333335vw !important;
    width: 20% !important;
    font-size: 1.5816666666666667vw !important;
    /* position: absolute; */
    /* bottom: 3%!important; */
    position: relative;
    top: 1.1vw;
  }
  .applybtn_in_e-campaigns_data_dropdowns {
    height: 3.0833333333333335vw !important;
    width: 30% !important;
    /* position: absolute; */
    position: relative;
    top: 1vw;
    /* bottom: 3%!important; */
    right: 2%;
    font-size: 1.5816666666666667vw !important;
    font-family: "Montserrat";
    font-weight: 501;
    color: #ffffff;
    background-color: #04a559;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2vw;
    cursor: pointer;
    border: none;
  }
}
