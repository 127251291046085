* {
  scroll-behavior: smooth;
}

.dashboard_main_div {
  position: "relative";
  margin-top: 4vw;
}

.navholder_main_div {
  position: "absolute";
  left: "0%";
  width: "100%";
  animation: dashboard 1.5s linear;
}

.dropdowns_dashboard_colors {
  background-color: #f3f7f9;

  border-radius: 0.3vw;
}

.admin_dashboard_total_votersflex_div,
.admin_dashboard_dropdoens_main_div {
  width: 96.1%;
  margin-left: 0.9vw;
}

@keyframes dashboard {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.topnav_holder_div {
  width: 100%;
}

.EMSAdmin_main_div {
  height: fit-content !important;
  /* min-height: 100vw !important; */
  overflow: scroll;
}

.Admin_dashboard_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #263659;
  font-size: 1.4583333333333333vw;
  margin-top: 1.1979166666666667vw;
}

.EmsAdmin_line_div {
  width: 94.5%;
  height: 0.1vw;
  background-color: #263659;
  margin-left: 1.7vw !important;
  margin-top: 0.8854166666666666vw;
}

.admin_dashboards_total_voters_box {
  width: 23.8%;
  height: 6.927083333333333vw;
  border-radius: 0.20833333333333334vw;
  margin-left: 1%;
  margin-top: 1.25vw;
  position: relative;
}

.Admin_dashboard_total_voters_text {
  font-size: 1.0416666666666667vw;
  margin-top: 1.0416666666666667vw;
  margin-left: 1.0416666666666667vw;
  font-weight: 500;
}

.admin_total_count_number {
  margin-left: 1.0416666666666667vw;
  margin-top: 0.78125vw;
  font-size: 1.6666666666666667vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
}

.ems_total_voter_boxes_underline_div {
  width: 94.5%;
  height: 0.1vw;
  background-color: #c5c9d0;
  margin-left: 1.7vw !important;
  margin-top: 1.1458333333333333vw;
}

.search_clearfilters_parent {
  position: relative;
  height: 2.8125vw;
  display: flex;
  align-items: center;
}

.search_appears_admin_dashboard {
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  font-weight: 1000;
  color: #263659;
  margin-left: 1.8229166666666667vw;
}

.clear_filter_admin_dashboard {
  position: absolute;
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  font-weight: 1000;
  color: #263659;
  right: 5.8229166666666667vw;
  border: none;
  background: none;
}

#constituency_posn {
  align-items: center;
  display: flex;
  position: absolute;
  top: 4.2916666667vw;
  right: 3vw;
  width: 19.8%;
}

.admin_dropdow_constituency {
  position: relative;
  top: 0px;
  right: 0vw;
  height: 2.65625vw;
  /* width: 23.8%; */
  background-color: #f3f7f9;
  margin-left: 1%;
  margin-top: 0.8333333333333334vw;
  border-radius: 0.3vw;
  border: 0.052083333333333336vw solid #d8d8d8;
}

.admin_dropdow_constituency {
  width: 100%;
  background-color: #f3f7f9;
  cursor: pointer;
}

.admin_dropdow_town_mandal {
  height: 2.65625vw;
  width: 23.8%;
  background-color: #f3f7f9;
  margin-left: 1%;
  margin-top: 0.8333333333333334vw;
  border-radius: 0.3vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  cursor: pointer;
  position: relative;
}

.ems_total_dropdown_underline_div {
  width: 94.5%;
  height: 0.1vw;
  background-color: #c5c9d0;
  margin-left: 1.7vw;
  margin-top: 0.8333333333333334vw;
}

.admindashboard_dropdown_box_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  color: #8b93a5;
  font-size: 1.0416666666666667vw;
  margin-left: 0.78125vw;
  width: 55%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.admin_dashboard_bargraph_main_div_boxs {
  margin-top: 0.6770833333vw;
  margin-bottom: 0.6770833333vw;
}
.mandalmore,
.wardmore,
.pollingstationmore,
.constituencymore {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  color: #8b93a5;
  font-size: 1.0416666666666667vw;
  position: absolute;
  left: 65%;
}

.admindashboard_dropdown_arrow {
  height: 0.6291666666666666vw;
  width: 0.7291666666666666vw;
  position: absolute;
  left: 90%;
}

.admin_dashboard_bargraph_main_div {
  height: 13.802083333333334vw;
  width: 30%;
  background-color: #ffffff;
  border-radius: 0.3125vw;
  margin-left: 1.9vw;
  box-shadow: 0px 0.20833333333333334vw 0.5208333333333334vw rgba(0, 0, 0, 0.15);
  position: relative;
}

.admindashboard_important_text_div {
  width: 100%;
  height: 2.8645833333333335vw;
  margin-top: 0.3vw;
  position: relative;
}

.important_people_text {
  margin-left: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  font-size: 0.9375vw;
}

.maxmizebtn_admin_dashboard {
  height: 0.6942708333333333vw;
  width: 0.6942708333333333vw;
  position: absolute;
  right: 4.8%;
  cursor: pointer;
  display: none;
}

.name_designation_mobile_div {
  height: 2.1354166666666665vw;
  width: 100%;
  background-color: #d7dfe8;
}

.important_people_name_text {
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  margin-left: 1.8791666666666667vw;
}

.important_people_designation_text {
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  margin-left: 5.15625vw;
}

.important_people_mobile_text {
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  margin-left: 2.99995vw;
}

.important_people_info_scrolldiv {
  width: 100%;
  height: 8.502083333333334vw;
  overflow-y: scroll;
}

.important_people_info_scrolldiv::-webkit-scrollbar {
  width: 0.2vw;
}

.important_people_info_scrolldiv::-webkit-scrollbar-thumb {
  background: #959595;
  width: 0.3vw;
  border-radius: 1vw;
}

.importantpeople_info_text_details_div {
  height: 2.5vw;
  width: 9vw;
  margin-right: 0vw;
  font-size: 0.8333333333333334vw;
  margin-left: 0.54vw;
  overflow: hidden;
}
#imp_poeple_first_name {
  margin-left: 1.4vw;
  font-size: 0.8vw;
}

#imp_poeple_designation {
  margin-left: 0.5vw;
}
.importantpeople_info_text_details_div span {
  font-size: 0.8333333333333334vw;
  font-weight: 500;
  font-family: "Montserrat";
}

.call_logo_admin_dashboard {
  width: 0.8333333333333334vw;
  height: 0.8333333333333334vw;
  margin-left: 0.3515625vw;
}

.call_logo_button_admin_dashboard {
  padding: 0;
  background: none;
  border: none;
  font-weight: 500;
  font-family: "Montserrat";
}

.admin_dashboard_text_info_detail_border_div {
  border-bottom: 0.1vw solid #e4e4e4;
  border-top: 0.1vw solid #e4e4e4;
}

.importantpeople_underline {
  height: 0.1vw;
  width: 6.145833333333333vw;
  background-color: #acacac;
  margin-left: 1.8229166666666667vw;
}

.important_people_name_text_title {
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  margin-left: 4%;
}

.important_people_designation_text_status {
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  margin-left: 42.4%;
}

.totalvoterslogo_admin_dashboard {
  height: 3.040625vw;
  width: 4.427083333333333vw;
  position: absolute;
  top: 1.9791666666666667vw;
  right: 10%;
}

.othersvoterslogo_admin_dashboard {
  position: absolute;
  top: 1.9791666666666667vw;
  right: 10%;
  height: 3.040625vw;
  width: 2.5833333333333335vw;
}

.admin_dashboard_ward_village_issues_info_main_div {
  height: 2.5vw;
  width: 100%;
}

.admin_dashboard_ward_village_issues_info_sub_div {
  width: 50%;
  height: 100%;
  text-align: left;
  font-size: 0.8333333333333334vw;
  font-weight: 500;
  font-family: "Montserrat";
  border-bottom: 0.1vw solid #e4e4e4;
}

.admin_dashboard_ward_village_issues_info_text {
  margin-left: 1vw;
}

.admin_village_ward_isseues_info_scroll_div {
  height: 8.2vw;
  width: 100%;
  overflow-y: scroll;
}

.admin_village_ward_isseues_info_scroll_div::-webkit-scrollbar {
  width: 0.2vw;
}

.admin_village_ward_isseues_info_scroll_div::-webkit-scrollbar-thumb {
  background: #959595;
  width: 0.3vw;
  border-radius: 1vw;
}

.importantpeople_todo_underline {
  width: 3vw;
  height: 0.1vw;
  background-color: #acacac;
  margin-left: 1.5vw;
}

.inValidtodo {
  font-family: Montserrat;
  font-size: 0.8333333333333334vw;
  color: red;
  margin-left: 1.3vw;
  display: none;
}

.addyour_new_todo {
  width: 83.2%;
  height: 2.0833333333333335vw;
  background-color: #f3f7f9;
  margin-left: 1.1888888888888888vw;
  border: 0.06944444444444445vw solid #d8d8d8;
  font-size: 1.1111111111111112vw;
  border-radius: 0.2vw;
  flex-direction: column;
}

input.addyour_new_todo_input::placeholder {
  font-size: 1vw !important;
}

.addyour_new_todo_input {
  width: 95%;
  border: none;
  background-color: #f3f7f9;
  font-family: "Montserrat";
  font-size: 1vw;
  font-weight: 500;
  height: 1vw;
}

.addyour_new_todo_input:focus {
  outline: none;
}

.add_todos_btn {
  height: 2.0833333333333335vw;
  width: 2.1875vw;
  background-color: #528bb8;
  margin-left: 0.37638888888888888vw;
  color: white;
  border-radius: 0.2vw;
  border: none;
}

.todolist_div {
  margin-top: 0.5vw;
}

.call_to_contractor {
  width: 93%;
  min-height: 2.0833333333333335vw;
  font-size: 0.8333333333333334vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  border-radius: 0.5vw;
  margin-left: 1.0416666666666667vw;
  margin-top: 0.5208333333333334vw;
  position: relative;
  cursor: pointer;
  word-break: break-all;
}

.call_to_contractor span {
  margin-left: 2%;
}

.call_to_contractor:hover .delete_option_todolist {
  visibility: visible;
}

.delete_option_todolist {
  height: 100%;
  width: 2.1875vw;
  background-color: red;
  position: absolute;
  right: 0%;
  visibility: hidden;
  border-radius: 0.2vw;
}

.delete_option_totolist_img {
  width: 0.8333333333333334vw;
  height: 0.9375vw;
}

.constituency_d {
  position: absolute;
  width: 100%;
  top: 3vw;
  left: 0%;
  height: 12vw;
  background-color: #ffffff;
  box-shadow: 0.1vw 0.1vw 0.5vw black;
  border-radius: 0.5vw;
  overflow-y: scroll;
  z-index: 50;
  animation: constituencydisplay 0.4s linear;
}

@keyframes constituencydisplay {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.rotete_pollygon_dashboard {
  height: 0.7vw;
  position: absolute;
  right: 5%;
  transition: 0.5s ease-out;
  width: 1vw;
  top: 30%;
}
.constituency_d::-webkit-scrollbar {
  display: none;
}

.mandaltown_data {
  position: absolute;
  width: 100%;
  top: 3vw;
  left: 0%;
  height: 12vw;
  background-color: #ffffff;
  box-shadow: 0.1vw 0.1vw 0.5vw black;
  border-radius: 0.5vw;
  overflow-y: scroll;
  z-index: 50;
  animation: mandaldisplay 0.4s linear;
}

@keyframes mandaldisplay {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ward_village_data {
  position: absolute;
  width: 100%;
  top: 3vw;
  left: 0%;
  height: 12vw;
  background-color: #ffffff;
  box-shadow: 0.1vw 0.1vw 0.5vw black;
  border-radius: 0.4vw;
  overflow-y: scroll;
  z-index: 50;
  animation: mandaldisplay 0.4s linear;
}

.polling_station_data {
  position: absolute;
  width: 100%;
  top: 3vw;
  left: 0%;
  height: 12vw;
  background-color: #ffffff;
  box-shadow: 0.1vw 0.1vw 0.5vw black;
  border-radius: 0.5vw;
  overflow-y: scroll;
  z-index: 50;
  animation: mandaldisplay 0.4s linear;
}

.polling_station_data_name {
  width: 75%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.constituency_data {
  height: 3vw;
  width: 100%;
  border-bottom: 0.1vw solid #d9d8d8;
  text-align: left;
  font-size: 1vw;
  font-family: "Montserrat";
  font-weight: 500;
}

.constituency_data span {
  margin-left: 1vw;
}

.checkbox_constituency {
  position: absolute;
  height: 1vw;
  right: 10%;
}

.mandal_town_data {
  height: 3vw;
  width: 100%;
  border-bottom: 0.1vw solid #d9d8d8;
  text-align: left;
  font-size: 1vw;
  font-family: "Montserrat";
  font-weight: 500;
  position: relative;
  border-bottom: 0.1vw solid #d9d8d8 !important;
}

.mandal_town_data span {
  margin-left: 1vw;
}

.poll_town_data_value {
  height: 3vw;
  width: 100%;
  font-size: 1vw;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: left;
  border-bottom: 0.1vw solid #d9d8d8 !important;
}

.poll_town_data_value span {
  margin-left: 1vw;
}

span {
  cursor: pointer;
}

.add_todos_plus {
  color: white;
  font-size: 1.5vw;
  margin-top: -0.3vw;
  font-weight: 500;
  height: 1.8vw;
}

.todo_sroll_div {
  height: 8vw;
  width: 100%;
  overflow-y: scroll;
  font-weight: 500;
  font-family: "Montserrat";
  margin-top: 0.5vw;
  border-top: 0.1vw solid #d7d7d7;
}

.todo_sroll_div::-webkit-scrollbar {
  width: 0.2vw;
}

.check::after {
  content: "\2713";
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  margin-right: 5px;
  position: absolute;
  right: 10px;
}

.checkbox_mandal {
  position: absolute;
  height: 1vw;
  right: 10%;
}

.caste_vote_favour {
  position: absolute;
  width: 100%;
  top: 3vw;
  left: 0%;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0.1vw 0.1vw 0.5vw #000;
  border-radius: 0.5vw;
  z-index: 50;
  border: none;
  animation: mandaldisplay 0.4s linear;
}

.call_to_contractor span {
  color: #263659;
  width: 20vw;
}

.graphs_holder_maindiv_dashboard {
  position: relative;
}

.voters_bargraph_viewreport {
  position: fixed;
  background-color: #ffffff;
  width: 70%;
  left: 15%;
  height: 35vw;
  top: 5vw;
  opacity: 1 !important;
}

.voters_bargraph_viewreport .dx-visibility-change-handler {
  width: 92% !important;
  height: 87%;
}

.voters_bargraph_viewreport .dx-visibility-change-handler svg {
  height: 100% !important;
  margin: 0%;
  width: 100% !important;
}

.voters_bargraph_viewreport_parent_div {
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.64;
  overflow-y: none;
  top: 0;
  left: 0;
  position: fixed;
}

.bargraph_voters_maxmize_main_div {
  width: 100%;
  height: 100vh;
  position: relative;
  position: fixed;
  animation: popgraph 0.5s linear;
}

@keyframes popgraph {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.close_charts {
  position: absolute;
  height: 0.6942708333333333vw !important;
  width: 0.6942708333333333vw !important;
  top: 0.588541666666667vw;
  right: 2.0588541666666667vw;
  z-index: 100;
  cursor: pointer;
  background: none !important;
  border: none !important;
}
.close_charts img {
  height: 0.6942708333333333vw !important;
  width: 0.6942708333333333vw !important;
}
.voters_bargraph_viewreport .maxmizebtn_charts {
  display: none;
}

.voters_bargraph_viewreport .castedetails_main_div {
  margin-top: 10vw;
}

.voters_Areagraph_viewreport {
  position: fixed;
  background-color: #ffffff;
  width: 70%;
  left: 15%;
  height: 35vw;
  top: 5vw;
  opacity: 1 !important;
}

.voters_Areagraph_viewreport .dx-visibility-change-handler {
  width: 100% !important;
  height: 60%;
}

.voters_Areagraph_viewreport .dx-visibility-change-handler svg {
  height: 100% !important;
  margin: 0%;
  width: 100% !important;
}

.voters_Areagraph_viewreport .dougnut_graph_center_text {
  font-size: 2.5vw;
  left: 24vw;
}

.voters_Areagraph_viewreport .maxmizebtn_charts {
  display: none;
}

.voters_Areagraph_viewreport .castedetails_main_div {
  overflow-y: scroll !important;
  height: 30vw;
  width: 10vw;
}
.dashboard_constituency_to_topset {
  position: absolute;
  top: 5vw;
  right: 3%;
}
.dashboard_filter_filter_constiency_checkbox {
}



@media screen and (max-width: 767px) {
  .voters_Areagraph_viewreport .dx-visibility-change-handler {
    width: 60% !important;
    height: 60%;
 
  }
  .graphs_holder_maindiv_dashboard {
  
  }
  .caste_voter_favour_dashboard{
    background-color: gray;
    height:20vw;
  }

  .dash_underline1{
    width: 100vw;
    border:1px solid #ABABAB;
height: 15.0555vw;
margin-top:30px;
border-left: none;
border-right: none;
  }

  .admin_dashboard_total_votersflex_div{
    margin-top:10vw !important;
  }
  .call_logo_button_admin_dashboard {
    font-size: 3.3333333333333335vw;
  }
  .rotete_pollygon_dashboard {
    height: 3vw;
    width: 4vw;
  }
  .dashboard_constituency_to_topset {
    position: relative;
    top: 0%;
    right: 0%;
  }

  .dropdowns_dashboard_colors {
    background-color: #f3f7f9;
    border-radius: 1.6666666666666667vw;
  }

  .EmsAdmin_line_div {
    margin-left: 0px !important;
    height: 0.4vw;
    width: 100% !important;
  }

  .dashboard_main_div {
    margin-top: 20vw;
  }

  .voters_Areagraph_viewreport .dougnut_graph_center_text {
    font-size: 3.5vw;
    left: 27.5vw;
    margin-top: 14vw !important;
  }

  .inValidtodo {
    margin-left: 4.3vw;
    display: none;

  }

  .EMSAdmin_main_div {
    height: fit-content;
    width: 100% !important;
    margin-left: 0% !important;
    position: relative;
    left: 0%;
    overflow: none !important;
  }

  .navholder_main_div {
    width: 100% !important;
    position: relative;
    margin-left: 0%;
    padding: 0%;
  }

  .topnav_holder_div {
    width: 100%;
  }

  .admin_dashboard_total_votersflex_div {
    width: 100%;
  }

  .Admin_dashboard_text {
    font-size: 6vw;
    color: #0047B2;

text-align: center;
font-size: 5.55vw;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top:2vw;
  }

  .admin_dashboards_total_voters_box {
    width: 88%;
    margin-left: 6%;
    height: 25.555555555555557vw;
    background-size: 18.61111111111111vw 22.727777777777774vw !important;
    margin-top: 3.3333333333333335vw;
    border-radius: 1.1111111111111112vw;
  }

  .Admin_dashboard_total_voters_text {
    font-size: 4.44444444444444vw;
    margin-left: 4.444444444444445vw;
    color: #fff;
    margin-top: 2.7vw;
    font-family: "Open Sans";
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .admin_total_count_number {
    margin-top: 2.2222222222222223vw;
    margin-left: 4.444444444444445vw;
    color: #fff;
    font-family: "Open Sans";
    font-family: "Montserrat";
    font-size: 6.88vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .admin-bashboard_male_voters_box {
    height: 11vw;
  }
  .dashboard_malevoter {
    flex-direction: column;
    position: relative;
  }
  .male_total_count_number {
    position: absolute;
    top: 0vw;
    right: 10%;
    color: #fff;
    text-align: right;
    font-family: "Open Sans";
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .totalvoterslogo_admin_dashboard {
    height: 10.833333333333334vw;
    width: 16.11111111111111vw;
    margin-top: 6.666666666666667vw;
  }

  .othersvoterslogo_admin_dashboard {
    height: 10.833333333333334vw;
    width: 10.11111111111111vw;
    margin-top: 6.666666666666667vw;
  }

  .ems_total_voter_boxes_underline_div {
    margin-left: 0% !important;
    width: 100%;
    margin-top: 3.3333333333333335vw;
  }

  .admin_dashboard_dropdoens_main_div {
    width: 100% !important;
  }

  #constituency_posn {
    position: relative;
    width: 100% !important;
    right: 0 !important;
    height: 11.944444444444445vw;
    margin-bottom: 5.222222222222222vw;
    border-radius: 1.6666666666666667vw;
    right: unset;
  }

  .admin_dropdow_town_mandal,
  .admin_dropdow_constituency {
    position: relative;
    width: 88%;
    margin-left: 6%;
    height: 11.944444444444445vw;
    margin-top: 2.2222222222222223vw;
    border-radius: 1.6666666666666667vw;
  }

  .admindashboard_dropdown_box_text {
    font-size: 3.888888888888889vw;
    margin-left: 3.78125vw;
    margin-top: 1.1979166666666667vw;
  }

  .admindashboard_dropdown_arrow {
    height: 2.888889vw !important;
    width: 3.888889vw !important;
  }

  .ems_total_dropdown_underline_div {
    margin-top: 3.3333333333333335vw;
    margin-left: 0%;
    width: 100%;
  }

  .clear_filter_admin_dashboard {
    font-size: 3.3333333333333335vw;
    margin-top: 0;
  }

  .underline_admin_caste_chart_div {
    width: 9vw !important;
    margin-left: 3.333333vw;
  }

  .search_clearfilters_parent {
    margin-top: 2.5333333333333335vw;
  }

  .search_appears_admin_dashboard {
    font-size: 3.3333333333333335vw;
    margin-left: 6.09375vw;
  }

  .search_appears_admin_dashboard {
    font-size: 3.3333333333333335vw;
    margin-left: 6.09375vw;
  }

  .search_appears_admin_dashboard {
    font-size: 3.3333333333333335vw;
    margin-left: 6.09375vw;
  }
  .admin_dashboard_bargraph_main_div_1 {
    width: 88%;
    height:100vw !important;
    margin-left: 6%;
    border-radius: 1.6666666666666667vw;
    margin-top: 3.888888888888889vw;
    box-shadow: 0px 1.1111111111111112vw 2.7777777777777777vw
      rgba(0, 0, 0, 0.15);
    border: 1px solid #d8d8d8;
    position: relative;
    animation: fade 1s
  }
  .admin_dashboard_bargraph_main_div_1 span {
    animation: fade 1s;
  }

  .btn-container {
    position: relative;
    width: 80%;
  }
  .btn {
    background-color: #f0f0f0 !important;
    color: #000000;
    border: none;
    border-radius: 50% !important;
    cursor: pointer;
    margin: 4vw;

  }
  .btn:hover {
    filter: brightness(1.5);
    background-color: #ffffff;
    border-radius: 50% !important;
    cursor: pointer;
    flex-shrink: 0;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  }
  #prev {
    margin-left: 40% !important;
    font-size: 4vw;
    color: #000000;
    font-weight: 600;
    border-radius: 50% !important;

  }
  #next {
    position: absolute;
    left: 54vw;
    font-size: 4vw;
    color: #000000;
    font-weight: 600;
    border-radius: 50% !important;

  }
  .admin_dashboard_bargraph_main_div {
    width: 88%;
    height: 53.611111111111114vw;
    margin-left: 6%;
    border-radius: 1.6666666666666667vw;
    margin-top: 3.888888888888889vw;
    box-shadow: 0px 1.1111111111111112vw 2.7777777777777777vw
      rgba(0, 0, 0, 0.15);
  }
  .admin-todo_block {
    margin-bottom: 40vw;
  }
  .important_people_text_1 {
    font-size: 5vw;
    margin-left: 20vw;
    margin-top: 6.2222222222222223vw;
    color: #444;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .important_people_text {
    font-size: 5vw;
    margin-left: 0vw;
    margin-top: 4.5vw;
    color: #444;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .name_designation_mobile_div {
    height: 8.333333333333334vw;
    margin-top: 9.444444444444445vw;
    background-color: #b3c8e8;
  }

  .important_people_name_text {
    font-size: 3.88vw;
    color: #36588c;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .important_people_designation_text {
    font-size: 3.88vw;
    margin-left: 13vw;
    color: #36588c;

    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .important_people_mobile_text {
    font-size: 3.88vw;
    margin-left: 12vw;
    color: #36588c;

    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .important_people_info_scrolldiv {
    height: 29vw;
  }

  .importantpeople_info_text_details_div {
    width: 100%;
    height: 12vw;
    font-size: 3.3333333333333335vw !important;
    margin-left: -1vw !important;
  }
  #imp_poeple_first_name {
    width: 20vw !important;
    font-size: 3.88vw;
    text-align: left;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  .importantpeople_info_text_details_div span {
    font-size: 3.88888vw;
    width: 24.1vw !important;
    text-align: left;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .call_logo_admin_dashboard {
    height: 3vw;
    width: 3vw;
  }

  .importantpeople_underline {
    width: 30vw;
    height: 0.2vw;
    margin-left: 5vw;
  }

  .important_people_name_text_title {
    font-size: 3.88vw;
    margin-left: 8vw !important;
    color: #36588c;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .important_people_designation_text_status {
    font-size: 3.88vw;
    color: #36588c;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .admin_dashboard_ward_village_issues_info_text {
    font-size: 3.888888vw;
    margin-left: 4vw;
  }

  .admin_dashboard_ward_village_issues_info_sub_div {
    width: 100%;
    min-height: 7vw !important;
  }
  .addyour_new_todo_input  {
    margin-bottom: 0px !important;
}
  .admin_village_ward_isseues_info_scroll_div {
    height: 17vw;
  }

  .admin_dashboard_ward_village_issues_info_main_div {
    height: 10vw;
  }

  .admin_village_ward_isseues_info_scroll_div {
    height: 28vw;
  }

  .important_people_name_text_title {
    margin-left: 2vw;
  }

  .important_people_designation_text_status {
    margin-left: 36vw;
  }

  .charts_viewport_text {
    font-size: 3.3333333333333335vw !important;
  }

  .addyour_new_todo {
    margin-top: 10.555555555555555vw;
    height: 8.61111111111111vw;
    width: 65.27777777777777vw;
    margin-left: 4.3333333333333335vw;
  }

  .add_todos_btn {
    height: 8.61111111111111vw;
    width: 9.166666666666666vw;
    border-radius: 0.5555555555555556vw;
  }

  .addyour_new_todo {
    font-size: 3.888888888888889vw;
  }

  .maxmizebtn_admin_dashboard {
    height: 3.702777777777778vw;
    width: 3.702777777777778vw;
    margin-right: 1vw !important;
    margin-top: 6vw !important;
  }

  .maxmizebtn_charts {
    height: 2.7777777777777777vw;
    width: 2.7777777777777777vw;
  }

  .maxmizebtn_charts img {
    height: 3.702777777777778vw !important;
    width: 3.702777777777778vw !important;
  }

  .add_todos_btn {
    margin-top: 10vw;
    margin-left: 2.988888888888889vw;
    border-radius: 1vw !important;
    display: flex;
    align-items: center;
    outline: none !important;
    border: none !important;
  }

  .add_todos_plus {
    font-size: 6.3vw;
    margin-bottom: 1vw;
    margin: 1vw;
    height: auto;
    outline: none !important;
    border: none !important;
  }

  .importantpeople_todo_underline {
    width: 20vw;
    margin-left: 3vw;
  }

  .addyour_new_todo_input {
    height: 75%;
    font-size: 3.888888888888889vw !important;
  }

  input.addyour_new_todo_input::placeholder {
    font-size: 3.888888888888889vw !important;
  }

  .todo_sroll_div {
    height: 28vw;
    margin-top: 1vw;
  }
#Admin_dashboard_total_voters_tex_{
margin-top: 4vw !important;
}
  .call_to_contractor {
    min-height: 10vw;
    width: 88%;
    font-size: 3.61111111vw;
    margin-left: 4.388888888888889vw;
    margin-top: 4vw !important;
  }

  .call_to_contractor span {
    color: #263659;
    width: 63.5vw;
    width: 84%;
  }

  .delete_option_todolist {
    height: 100%;
    width: 10vw;
    height: 10vw !important;
    border-radius: 1vw;
  }

  .delete_option_totolist_img {
    height: 3vw;
    min-height: 3vw;
    width: 3vw;
  }

  .maxmizebtn_charts {
    height: 3.702777777777778vw !important;
    width: 3.702777777777778vw !important;
    margin-right: 3.2vw !important;
    margin-top: 2vw !important;
  }

  .inValidtodo {
    font-size: 2.833333vw;
    color: red;
    margin-left: 3.5vw;
    display: none;
  }

  .castedetails_main_div {
    margin-top: 7vw !important;
    width: 12vw;
  }

  .castecolors_caste_div {
    font-size: 1.9vw !important;
    width: 8vw !important;
  }

  .voters_Areagraph_viewreport .castedetails_main_div {
    height: 37vw;
    width: 14vw;
    width: 85vw;
  }

  .close_charts {
    height: 2.694271vw;
    width: 2.694271vw;
    top:3.5vw !important;
    right: 4.058854vw;
  }

  .castedetails_main_div {
    margin-right: 0vw;
    margin-top: 14vw !important;
  }

  .mandalmore,
  .constituencymore {
    font-size: 3.5vw;
  }

  .wardmore {
    font-size: 3.5vw;
  }

  .pollingstationmore {
    font-size: 3.5vw;
  }

  .ward_village_data {
    height: 40vw;
    width: 100%;
    left: 0%;
    margin-top: 10vw;
    border-radius: 1vw;
  }

  .mandal_town_data,
  .constituency_data {
    height: 9.444444444444445vw !important;
  }

  .mandal_town_data span,
  .constituency_data span {
    font-size: 3.888888888888889vw;
  }

  .checkbox_mandal,
  .checkbox_constituency {
    height: 4.075vw !important;
    width: 4.075vw !important;
  }

  .mandaltown_data,
  .constituency_d {
    height: 30vw !important;
    margin-top: 10vw;
    border-radius: 1vw;
  }

  .polling_station_data {
    height: 30vw !important;
    margin-top: 10vw;
    border-radius: 1vw;
    z-index: 1140;
  }

  .poll_town_data_value {
    height: 9.444444444444445vw !important;
  }

  .poll_town_data_value span {
    font-size: 3.888888888888889vw;
  }

  .caste_vote_favour {
    margin-top: 10vw;
    border-radius: 1vw;
  }

  .navholder_main_select_div {
    position: fixed !important;
    z-index: 1040;
    height: 100vh !important;
    width: 71.6666666667% !important;
    top: 0vw !important;
    margin-left: 0px !important;
  }

  #constituencyMla_name {
  }

  .delete_option_todolist {
    visibility: visible !important;
    background-color: #de4c4c;
  }

  .close_charts {
    height: 2.694271vw !important;
    width: 2.694271vw !important;
  }
  .close_charts img {
    height: 2.694271vw !important;
    width: 2.694271vw !important;
    margin-top: -2.5vw !important;
  }
  .to_main_div_{
    overflow: hidden;
    height: 26vw !important;
  }
  .importantpeople_info_text_details_div {
    width: 100%;
    height: 12vw;
    font-size: 7.333333vw !important;
}
.iphen_dashboard{
   text-align: center;
   align-items: center;
   display: flex !important;
   justify-content: center !important;
}

}

@media (min-width: 768) and (max-width: 1024) {
  .Admin_dashboard_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    color: #263659;
    font-size: 1.4583333333333333vw;
    margin-top: 2.1979166666666667vw;
    margin-left: 1.7vw;
  }

  .EmsAdmin_line_div {
    width: 94.5%;
    height: 0.1vw;
    background-color: #263659;
    margin-left: 1.7vw !important;
    margin-top: 0.8854166666666666vw;
  }
  .admin_dropdow_constituency {
    width: 100%;
    cursor: pointer;
    margin-top: 1vw;
  }
  .admin_dashboards_total_voters_box {
    height: 9vw;
  }
  .Admin_dashboard_total_voters_text {
    font-size: 2vw;
  }
  .totalvoterslogo_admin_dashboard {
    height: 4.040625vw;
    width: 5.427083333333333vw;
    position: absolute;
    top: 2.9791666666666667vw;
    right: 10%;
  }
  .othersvoterslogo_admin_dashboard {
    height: 4.040625vw;
    width: 4.427083333333333vw;
  }

  .search_clearfilters_parent {
    position: relative;
    height: 5.8125vw;
    display: flex;
    align-items: center;
  }
  .admin_dashboard_bargraph_main_div {
  
  }
  .admin_dropdow_town_mandal {
    height: 4.2vw;
  }
  .admindashboard_dropdown_arrow {
    height: 1vw !important;
    width: 1.5vw !important;
  }
  .boxs_wrap_for_tabs {
    height: 57vh;
  }
  .admin_dashboard_bargraph_main_div {
  }
  .admin_dashboard_total_votersflex_div,
  .admin_dashboard_dropdoens_main_div {
    height: 22.5vw;
  }
  .admin_dashboard_total_votersflex_div,
  .admin_dashboard_dropdoens_main_div {
    flex-wrap: wrap !important;
    width: 95%;
    margin: 1vw;
  }

  .admin_dashboards_total_voters_box {
    flex-basis: 49%;
  }

  .admin_dashboard_dropdoens_main_div {
    flex-wrap: wrap !important;
    height: 11vw !important;
  }

  .admin_dropdow_town_mandal {
    flex-basis: 49%;
  }

  .graphs_holder_maindiv_dashboard {
    flex-wrap: wrap !important;
    height: 35vw;
  }

  .admin_dashboard_bargraph_main_div {
    flex-basis: 46%;
    margin-left: 2.2%;
  }
  #admin_imp_people {
  }
  #ward_village_dashboard {
  }

  .admin_dashboard_bargraph_main_div_boxs {
    flex-wrap: wrap;
  }

  .boxs_wrap_for_tabs {
    flex-wrap: wrap;
  }

  #todo_list_for_tab {
    margin-top: -31.8vw;
    margin-left: 43.7vw;
  }

  #todo_list_for_tab_full {
    margin-top: -30vw;
    margin-left: 50.4vw;
  }

  .clear_filter_admin_dashboard {
    right: 3.6vw;
  }

  #constituency_posn {
    right: 3.5vw;
  }
  .important_people_designation_text {
    position: relative;
    left: 1vw;
  }
  .important_people_mobile_text {
    position: relative;
    left: 2vw;
  }
  .navholder_main_select_div {
  }
}


@media screen and (max-width: 767px) {
.ems_title_dashbaord{
  color: #0047B2;
text-align: center;
font-size: 5.55vw;
font-style: normal;
font-weight: 700;
line-height: normal;
position: relative;
display: none;
}
  .constituency_wardvillageissues{
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10) !important;
  }
.ward_village_filter_contstiency_text{
  color: #1C477A !important;
font-size: 5.55vw !important;
font-weight: 600 !important;
}
.dropdown_wardvillages_data_div{
  background: #FFF !important;
}
#dropdown_wardvillages_data_div_drop{
  /* height:42vw !important; */
  /* background-color: red !important; */
}
.dropdown_wardvillages_data_div {
  height: 22vw!important; 
  /* Default height when no data */
  /* background-color: red !important; */

}

.dropdown_wardvillages_data_div.with-data {
  height: 40vw!important;
  background-color: #FFFFFF !important; 
   box-shadow: 0.1vw 0.1vw 0.5vw black !important;
   /* Height when there is data */
   /* height: 20vw!important; */
  /* background-color: green !important; */

}
.ward_village_filter_constiency_api_data{
  color: #444;
font-size: 4.44vw !important;
font-weight: 600;
margin-top: 3vw;
}

#ward_village_filter_constiency_checkbox {
  height: 5.075vw !important;
  width: 6vw !important;;
  margin-left: 10vw !important;
}

#lablesof-wardvillage_dropdown_data_{
  height: 11vw!important;
  border: none !important;
  border-radius: 1px !important;

}
.searchbar_wardvillagesissue_filters{
background: #E4F0FF !important;
border: none !important;

}

.searchbar_wardvillagesissue_filters::placeholder{
  color: #AEAEAE;
font-size: 4.44vw;
font-weight: 600;
color: #457BBC;
font-style: italic;
}
#ward_village_filter_constiency_checkbox_{
  margin-left: 10vw !important; 
}
#dropdown_wardvillages_data_div_vill{
  border: none !important;
}
.lablesof-wardvillage_dropdown_data_fex{
  border: none!important;
}
.lablesof-wardvillage_dropdown_data {
  border: none;
}
#labels_border{
  height: auto !important;
}
.wardvillages_dropdowns_data_scroller_div {
  /* height: 73% !important; */
  /* height: 60%!important; */

  overflow-y: scroll;
  width: 100%;
  /* margin-top: 0.7%; */
  /* background-color: #FFFFFF !important; */
  /* box-shadow: 0px 1.1111111111111112vw 2.7777777777777777vw rgba(0, 0, 0, 0.15); */
}
#wardvillages_dropdowns_data_scroller_div{
  height: auto;
}
#ward_village_filter_constiency_checkbox{
  margin-top:3vw!important
}

}

@media screen and (max-width: 767px) {
  .btn-container{
    display: block !important;
  }
  #admin_dashboard_bargraph_main_div_charts{
    display: none !important;
  }
  #admin-dashboard_total_voters_female_others{
    margin-top:-1vw !important;
  }
  .navholder_main_select_div{
   background-color: #0047B2;
   width: 0vw !important;
   height:0vh !important;
  }
  .ward_village_filter_contstiency_text_more {
    color: #8B93A5;
    font-family: "Montserrat";
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    margin-left:2vw;
  }
  #constituency_wardvillageissues_caste_voter_favour_{
    height:18.5vw!important;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10) !important;
  }
  #todo_list_for_tab{
    min-height:50vw!important;
  }
}

@media  (min-width:768px) and (max-width: 1024px) {

  .EMSAdmin_main_div{
    margin-top:7vw !important;
    margin-bottom: 4vw !important;
  }
  .voters_bargraph_viewreport .dx-visibility-change-handler {
    width: 78% !important;
    height: 65% !important;
    margin-top:3vw;
}
#wardvillages_dropdowns_data_scroller_div{
  height: auto;
}
.close_charts img {
  height: 1.594271vw !important;
  width: 1.594271vw !important;
}
.navholder_main_select_div{
  width: 0vw !important;
}
.constituency_wardvillageissues {
  border-radius: 0.3vw !important;
  width:46vw !important;
  margin-left:1vw !important;
  height:5vw !important;
}
.ward_village_filter_contstiency_text_more {
  color: #8B93A5;
  font-family: "Montserrat";
  font-size: 2.3vw !important;
  font-style: normal;
  font-weight: 600;
}

#constituency_wardvillageissues_active_login{
  height:4vw !important;
  width:33.9vw !important;

}
#ward_village_filter_contstiency_text_constituncy_name{
  font-size: 1.7vw !important;
}
.ward_village_filter_contstiency_text {
  color: #8B93A5;
  font-family: "Montserrat";
  font-size: 2vw !important;
  width: 19vw !important;
  color: #1C477A !important;
    font-weight: 600 !important;
}
.constituency_wardvillageissues1{
  position: relative;
    bottom: 3vw;
    right: 1.5vw;
}
#constituency_wardvillageissues_active_login{
}

.ems_title_dashbaord{
  display: none !important;
} 
.admin_dashboards_total_voters_box {
  width: 48.4%;
  height: 9.927083333333333vw;
  border-radius: 0.20833333333333334vw;
  margin-left: 1%;
  margin-top: 1.25vw;
  position: relative;
}
.Admin_dashboard_total_voters_text {
  font-size: 2.44444444444444vw;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
}
.admin_total_count_number {
  font-size: 2.222222222222222vw;
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.totalvoterslogo_admin_dashboard {
  height: 6.040625vw;
  width: 7.27083vw;
  position: absolute;
  top: 1.9791666666666667vw;
  right: 10%;
}

.dropdown_wardvillages_data_div {
  border-radius: 0.5vw;
  z-index: 50;
  animation: mandaldisplay 0.4s linear;
  border: none;
  width: 46vw !important;
  height: 17vw !important;
  margin-left: 1vw !important;
  position: absolute;
  z-index: 50;
  margin-top: 0.5vw;
  animation: dropdown 0.7s linear;
}

.dropdown_wardvillages_data_div {
  /* Define common styles here */
}

.dropdown_wardvillages_data_div {
  height: 12vw !important; /* Default height when no data */
  /* background-color: red !important; */
}

.dropdown_wardvillages_data_div.with-data {
  height: 17vw !important; /* Height when there is data */
  /* background-color: green !important; */
}
#labels_border{
  height: auto !important;
}
.lablesof-wardvillage_dropdown_data {
  width: 100%;
  height: 5vw !important;
  border: 0.1vw solid #CCD0D6;
  align-items: center;
  padding: 0;
  margin: 0;
  text-align: left;
  background-color: #FFFFFF;
  border-radius: 0.22vw !important;
}
.admindashboard_important_text_div {
  width: 100%;
  height: 4.864583vw;
  margin-top: 0.3vw;
  position: relative;
}
.ward_village_filter_constiency_api_data {
}
.ward_village_filter_constiency_checkbox{
  height:2vw !important;
  width: 2vw !important;
}
.admin_dashboard_bargraph_main_div_1{
  display: none !important;
}
.btn-container{
  display: none;
}
.admin_dashboard_total_votersflex_div{

}
.rotete_pollygon_dashboard {
  height: 1.7vw !important;
  width: 1.7vw !important;
}
.searchbar_wardvillagesissue_filters {
  width: 96%;
  height: 4.7vw !important;
  background: #E4F0FF !important;
    border: none !important;
    font-style: italic;
    color: #36588c !important;    ;
}
.searchbar_wardvillagesissue_filters::placeholder{
  font-size: 2vw !important;
}
.admin_dashboard_bargraph_main_div {
  height: 21.802083vw !important;
  width: 46% !important;
  background-color: #ffffff !important;
  border-radius: 0.3125vw !important;
  margin-left: 2vw !important;
  position: relative;
}
.admin_dashboard_bargraph_main_div_charts_voters_favoutr_compoent_div_tag{
   margin-top:2vw !important;
}
#admin_imp_people{
    margin-top:2vw !important
}
#ward_village_dashboard{
}
.search_clearfilters_parent {
  position: relative;
  height: 4.8125vw;
  display: flex;
  align-items: center;
}
.search_appears_admin_dashboard {
  font-size: 1.833333vw;
  font-family: "Montserrat";
  font-weight: 1000;
  color: #263659;
  margin-left: 2.7229166666666667vw;
}
.clear_filter_admin_dashboard {
  position: absolute;
  font-size: 1.833333vw;
  font-family: "Montserrat";
  font-weight: 1000;
  color: #263659;
  right: 5.822916666666667vw;
  border: none;
  background: none;
}

.important_people_text_1{
  font-size: 2vw !important;
  font-weight: 700;
  margin-left:1vw !important;
}
.name_designation_mobile_div {
  height: 4.135417vw;
  width: 100%;
  background-color: #b3c8e8;
}
.important_people_name_text {
  font-size: 1.833333vw !important;
  color:#36588c !important;

}
.important_people_designation_text {
  font-size: 2vw !important;
  color:#36588c !important;

}
.important_people_mobile_text{
  font-size: 1.8333333333333334vw !important;
  margin-left:5vw !important;
  color:#36588c !important;

}
.close_charts {
  position: absolute;
  height: 1.294271vw !important;
  width: 1.294271vw !important;
  top: 1.588541666666667vw;
  right: 3.058854166666667vw;
}
#imp_poeple_first_name {
  margin-left: 1.4vw;
  font-size: 1.8vw;
}
.admin_dashboard_text_info_detail_border_div {
  border-bottom: 0.1vw solid #e4e4e4;
  border-top: 0.1vw solid #e4e4e4;
  height: 4vw;
}
.importantpeople_info_text_details_div {
  width: 14vw;
  font-size: 2.8333333333333334vw;
  overflow: hidden;
}
.importantpeople_info_text_details_div span {
  font-size: 2vw !important;
  font-weight: 500;
  font-family: "Montserrat";
}
.call_logo_admin_dashboard {
  width: 1.833333vw;
  height: 1.833333vw;
  margin-left: 0.3515625vw;
}
.important_people_info_scrolldiv {
  width: 100%;
  height: 10.502083vw;
  overflow-y: scroll;
}
#constituency_wardvillageissues_caste_voter_favour_{
  height:10.5vw!important;
}
.wardvillages_dropdowns_data_scroller_div {
  overflow-y: scroll;
  width: 100%;
  margin-top: 0.7%;
}
#ward_village_filter_constiency_checkbox{
  margin-top:2vw!important;
  margin-left: 5vw!important;

}
.important_people_name_text_title {
  font-size: 1.833333vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  margin-left: 4%;
  color:#36588c !important;

}
.important_people_designation_text_status {
  font-size: 1.833333vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  margin-left: 42.4%;
  color:#36588c !important;

}
.admin_dashboard_bargraph_main_div_boxs {
  margin-top: 1.677083vw;
  margin-bottom: 0.6770833333vw;
}
.admin_village_ward_isseues_info_scroll_div {
  height: 11.3vw;
  width: 100%;
  overflow-y: scroll;
}

.admin_dashboard_ward_village_issues_info_main_div {
  height: 4.5vw;
  width: 100%;
}

.admin_dashboard_ward_village_issues_info_sub_div {
  width: 50%;
  height: 100%;
  text-align: left;
  font-size: 2vw !important;
  font-weight: 500;
  font-family: "Montserrat";
  border-bottom: 0.1vw solid #e4e4e4;
}

.important_people_text {
  margin-left: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 1000;
  font-size: 2vw !important;
}
.addyour_new_todo {
  width: 83.2%;
  height: 3.53333vw;
  background-color: #f3f7f9;
  margin-left: 1.1888888888888889vw;
  border: 0.06944444444444445vw solid #d8d8d8;
  border-radius: 0.2vw;
  flex-direction: column;
}
.add_todos_btn {
  height: 3.5833333333333335vw;
  width: 3.583333vw;
  background-color: #528bb8;
  margin-left: 0.3763888888888889vw;
  color: white;
  border-radius: 0.2vw !important;
  border: none !important;
  outline: none !important;
}
.add_todos_plus {
  color: white;
  font-size: 2.5vw;
  margin-top: -2.3vw;
  font-weight: 500;
  border: none !important;
  outline: none!important;
  height: 1.8vw;
}
.todo_sroll_div {
  height: 12vw;
}
.call_to_contractor {
  width: 93% !important;
  min-height: 4.083333vw !important;
  font-size: 1.833333vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  border-radius: 0.5vw !important;
}
.call_to_contractor span {
  color: #263659;
  width: 89% !important;
}

.delete_option_todolist {
  height: 100%;
  height: 3vw;
  width: 3.2875vw;
  background-color: #de4c4c;
  position: absolute;
  right: 0%;
  visibility: visible;
  border-radius: 0.2vw;
}
.delete_option_totolist_img {
  width: 1.533333333333334vw;
  height: 1.5375vw;
  visibility: visible;
}

#todo_list_for_tab{
  min-height:21vw !important;
  margin-bottom: 10vw!important;
}

.addyour_new_todo_input {
  width: 95%;
  height:75%;
  border: none;
  font-family: "Montserrat";
  font-size: 2vw!important;
  font-weight: 500;
  padding: 0 !important;
  margin:0 !important;
  background: none;
}
.addyour_new_todo_input::placeholder {
  font-size:1.5vw!important; 
}
#addyour_new_todo_input::placeholder{
  font-size: 10px;;
}
.voters_bargraph_viewreport {
  position: fixed;
  background-color: #ffffff;
  width: 70%;
  left: 15%;
  height: 35vw;
  top: 20vw !important;
  opacity: 1 !important;
}
.voters_Areagraph_viewreport .castedetails_main_div {
  margin-top: 2vw;
  overflow-y: scroll !important;
  height: 26.9vw;
  width: 15vw;
  right:2vw;
}
.voters_Areagraph_viewreport .dougnut_graph_center_text {
  font-size: 2vw;
  left: 10vw;
}
.importantpeople_info_text_details_div span {
  font-size: 3.88888vw;
  width: 24.1vw !important;
  text-align: left;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.EMSAdmin_main_div{
}
.inValidtodo {
  font-family: "Montserrat";
  font-size: 1.333333333333334vw;
  color: red;
  margin-left: 0.3vw;
}
.to_main_div_{
  overflow: hidden;
  height: 13vw !important;
}
#addyour_new_todo_input::placeholder{
  font-size: 2vw!important;
}
.iphen_dashboard{
  display: flex !important;
  justify-content: center !important;
}

}






@media  (min-width:1140) and (max-height: 958){
   #constituency_wardvillageissues_active_login{
    /* background-color: gray; */
    position: relative;
    bottom:4vw;
    right:5vw;
    width: 20vw !important;
  } 
   #admin_dashboard_total_voters__{
    /* background-color: green; */
    margin-top:4.2vw
  }
  #admin_dashboards_total_voters_box_{
    width: 23vw;
  } 
  #admin-dashboard_total_voters_female_others{
    /* background-color: red; */
    width: 48vw !important;
    margin-left:49.3vw;
    position: relative;
    bottom: 8.2vw;
    /* display: none !important; */
  }
  .ems_total_voter_boxes_underline_div {
    width: 94.5%;
    height: 0.1vw;
    background-color: #c5c9d0;
    margin-left: 1.7vw !important;
    margin-top: -6.854167vw;
}
  .constituency_wardvillageissues{
    width: 22.8vw !important;
    border-radius: 6px !important;
    height:3vw !important;
    margin-top:.5vw;

  }
  #war_villages_polling_caste_favour{
    /* background-color: red; */
    position: relative;
    bottom:4vw;
    left: 48vw !important;;
  }
  .admin_dashboard_bargraph_main_div_1{
    /* display: none !important; */
  }
  .ems_total_dropdown_underline_div {
    width: 94.5%;
    height: 0.1vw;
    background-color: #c5c9d0;
    margin-left: 1.7vw;
    margin-top: -2.866667vw;
}
.btn-container,.admin_dashboard_bargraph_main_div_1{
    display: none !important;
  }
  .admin_dashboard_bargraph_main_div_charts_voters_favoutr_compoent_div_tag{
    /* display: none; */
    position: relative;
    left:63.5vw !important;
    bottom:13.9vw;
  }
  #admin_imp_people{
    position: absolute;
    left:0vw;
    margin-top:.9vw
  }
  #ward_village_dashboard{
    /* background-color: red; */
    position: absolute;
    left:32vw;
    bottom:19vw;
    /* margin-top:1vw */

  }
  .admin-todo_block{
    /* background-color: red; */
    position: absolute;
    left:63.5vw;
    bottom:19vw;
    /* margin-top:5vw!important; */

  }

}

@media  (min-width:1368) and (max-height: 912){
  #ward_village_dashboard{
    /* background-color: red; */
    position: absolute;
    left:32vw;
    bottom:16vw !important;
    /* margin-top:1vw */

  }
  .admin-todo_block{
    /* background-color: red; */
    position: absolute;
    left:63.5vw;
    bottom:16vw !important;
    /* margin-top:5vw!important; */

  }
}

@media  (min-width:1366) and (max-height: 1024){
  #constituency_wardvillageissues_active_login{
   /* background-color: gray; */
   position: relative;
   bottom:4vw;
   right:5vw;
   width: 20vw !important;
 } 
  #admin_dashboard_total_voters__{
   /* background-color: green; */
   margin-top:4.2vw
 }
 #admin_dashboards_total_voters_box_{
   width: 23vw;
 } 
 #admin-dashboard_total_voters_female_others{
   /* background-color: red; */
   width: 48vw !important;
   margin-left:49.3vw;
   position: relative;
   bottom: 8.2vw;
   /* display: none !important; */
 }
 .ems_total_voter_boxes_underline_div {
   width: 94.5%;
   height: 0.1vw;
   background-color: #c5c9d0;
   margin-left: 1.7vw !important;
   margin-top: -6.854167vw;
}
 .constituency_wardvillageissues{
   width: 22.8vw !important;
   border-radius: 6px !important;
   height:3vw !important;
   margin-top:.5vw;

 }
 #war_villages_polling_caste_favour{
   /* background-color: red; */
   position: relative;
   bottom:4vw;
   left: 48vw !important;;
 }
 .admin_dashboard_bargraph_main_div_1{
   /* display: none !important; */
 }
 .ems_total_dropdown_underline_div {
   width: 94.5%;
   height: 0.1vw;
   background-color: #c5c9d0;
   margin-left: 1.7vw;
   margin-top: -2.866667vw;
}
.btn-container,.admin_dashboard_bargraph_main_div_1{
   display: none !important;
 }
 .admin_dashboard_bargraph_main_div_charts_voters_favoutr_compoent_div_tag{
   /* display: none; */
   position: relative;
   left:63.5vw !important;
   bottom:13.9vw;
 }
 #admin_imp_people{
   position: absolute;
   left:0vw;
   margin-top:.9vw
 }
 #ward_village_dashboard{
   /* background-color: red; */
   position: absolute;
   left:32vw;
   bottom:24.5vw;
   /* margin-top:1vw */

 }
 .admin-todo_block{
   /* background-color: red; */
   position: absolute;
   left:63.5vw;
   bottom:24.5vw;
   /* margin-top:5vw!important; */

 }

}

@media  (min-width:1280) and (max-height: 800) {
  #ward_village_dashboard{
    position: absolute;
    left:32vw;
    bottom:12.3vw !important;

  }
  .admin-todo_block{
    /* background-color: red; */
    position: absolute;
    left:63.5vw;
    bottom:12.3vw !important;
    /* margin-top:5vw!important; */

  }
}


