.dropdowns_assignvoters_colors {
    width: 20vw;
    height: 2.6565vw;
    background-color: #F3F7F9;
    border: none;
    border-radius: 5px;
    margin-top: 0.4vw;
}
.assignvoter_label_div{
    margin-top: 0.5166vw;
    margin-left: 0.89vw;
    width: 42vw;
}
.assignvoter_label_div {
    .row{
        row-gap: 1.5vw;
    }
}
.information_bar{
    background: #E8ECF1;
    width: 45.677vw;
    height: 2.135vw;
    margin-left: 6px;
    margin-top:0.3vw ; 
    color: #263659;
    font-family: Montserrat;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 500;
    display: flex;
    padding-left: 1vw;
    align-items: center;
    line-height: normal;
}
.assignvoter_dropdown_arrow {
    height: 0.6291666666666667vw;
    width: 0.7291666666666666vw;
    position: absolute;
    left: 18vw;
}
.assignvoter_totalvoter{
    color: #263659;
    font-family: Montserrat;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 1vw;
    margin-top: 1.3vw;
}
.voterid_field{
    color: #263659;
    font-family: Montserrat;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    padding-left: 1.7vw;
    position: absolute;
    top: 6.767vw;
    // left: 0.2vw;
}
.assign_voter_buttons{
    padding:0.9375vw;
    display: flex;
    justify-content: end;
    /* position: relative; */
    margin-right:20px;
}
.assignvoter_pop_up_maindiv{
    overflow: scroll;
    background: #FFFFFF;
    width: 46.354vw;
    height: 27.2vw;
    position: absolute;
    top:11vw;
    left:25vw;

}
@media screen and (max-width:767px){
    .information_bar {
        width: 82vw;
        height: 9.65vw;
        margin-left: 3vw;
        font-family: Montserrat;
    font-size: 4.444vw;
    }
    .assignvoter_label_div {
        margin-top: 15.5166vw;
    }
    .voterid_field {
        position: absolute;
        top: 26.767vw;
        left: 1.2vw;
    }
    .vid_assignvoter{
        margin-top: 7vw;
    }
    .assignvoter_dropdown_arrow {
        height: 2.629167vw;
        width: 3.729167vw;
        position: absolute;
        left: 75vw;
    }
    .assignvoter_totalvoter{
        color: #263659;
    font-family: Montserrat;
    font-size: 4.444vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2vw;
    margin-left: 4vw;
    }
    .dropdowns_assignvoters_colors{
        width: 83.333vw;
        height: 11.111vw;
        background-color: #F3F7F9;
        border: none;
        border-radius: 5px;
    }
    .lables_assignvoter{
        color: #263659;
    font-family: Montserrat;
    font-size: 4.444vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2vw;
    margin-left: 2vw;
    }
    .assignvoter_pop_up_maindiv{
        background: #FFFFFF;
        width: 91.66vw;
        height: 144vw;
        position: absolute;
        top: 6vw;
        left:4.166vw;
    }
    .voter_label_div{
        /* margin-top:5.633vw; */
        width: 60vw;
        margin-left:0.041vw;
    }
    .assign_voter_buttons{
        /* margin-top: 5.555vw; */
        width:90vw;
        position: relative;
        left: 3vw;
        padding: 4vw;
        justify-content: space-around;
        /* margin-bottom: 5.55vw; */
      }
}

@media screen and (max-width: 1024px) and (min-width: 768px){
    .voter_label_div{
        margin-top:0vw;        
    } 
}