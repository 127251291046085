
.forgot-password_main_div_mobile{
  background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%) !important;
  height:max-content !important;
  min-height: 100vh!important;

}
.fp_emslogo {
  height: 7.2916666667vw;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
input:-internal-autofill-selected {
  box-shadow: 0 0 0 1000px #f3f7f9 inset; /* Replace 'white' with your desired color */
  text-shadow: none;
}
.fp_emslogo img {
  /* width: 14.84375vw;
    height: 5.625vw; */
  height: 6.21875vw;
}


.fpword__main {
  /* height: 25.88541666666667vw; */
  min-height:40vw!important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

.fpword__bottom_text {
  text-decoration: none;
}

.fpword__sub {
  /* height: 12.76041666666667vw; */
  width: 30.57291666666667vw;
  /* background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
  border-radius: 12px;

  margin-bottom: 1.125vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fpword__sub_statusMsg {
  font-size: 1.241666666666667vw;
  color: red !important;
  margin-bottom: 1vw;
  margin-top: 0vw;
  color:black;
}

.fpword__sub_statusError {
  color: red;
}

.fpword__sub_statusSuccess {
  color: green;
}

.fpword__sub input {
  margin-bottom: 1.25vw;
  height: 2.95625vw;
  width: 27.76041666666667vw;
  border: none;
  /* width: 75vw; */
  /* height: 11.11111111111111vw; */
  /* margin-bottom: 2.555555555555556vw; */
  border-radius: 5px;
  border: 1px solid #0047B2;
  background: #FFF;
outline: none;
}

#resetPassword {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.9375vw !important;
  line-height: 1.145833333333333vw;
  color: #8b93a5;
}

#resetPassword::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.2vw !important;
  line-height: 1.145833333333333vw;
  color: #8b93a5;
}

.fpword__text,
.fpword_bottom_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.041666666666667vw;
  line-height: 1.25vw;
  text-align: center;
  /* margin-top: 3.125vw; */
  color: #ffffff;
  cursor: pointer;
  /* margin-top: 8.333333333333333vw; */
  display: none;
}
.error_forgot{
  color:red;
  font-size: 1.2vw;
}
.fpword__heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8854166666666667vw;
  /* margin-top: 3.125vw; */
  margin-bottom: 1.041666666666667vw;
}

.fpword__heading h3 {
  font-family: "Montserrat";
  font-style: normal;
  /* font-weight: 500; */
  font-weight: bold;
  font-size: 2.09375vw;
  line-height: 1.510416666666667vw;
  color: #0047b2;
  text-align: center;
  margin: 0;
}

.fpword__hrline {
  width: 7.7vw;
  height: 0;
  border: 1px solid #a5a5a5;
  display: none;
  /* transform: rotate(180deg); */
}

.resetpword__button {
  height: 3.4vw;
  width: 13.76041666666667vw;
  border: none;
  border-radius: 4px !important;
  background: #056B38;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.41666666666667vw;
  line-height: 1.25vw;
  color: #ffffff;
  margin-bottom: 1.25vw;
}

.copyright {
  height: 4.47916667vw;
  background: #1b2837;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7291666666666667vw;
  line-height: 0.8854166666666667vw;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.fp_emptyspace {
  height: 14.11458333333333vw;
}
.leftarrow_img_signupimg{
  height:2vw;
  width: 1.3vw;
  margin-left:5vw;
  margin-top:5vw;
}
@media (max-width: 767px) {

/* @media (max-width: 600px) { */
  .forgot-password_main_div_mobile{
    background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%) !important;
    /* height:100vh !important; */
    /* height:max-content !important; */
    /* height:auto !important;  */

  }
  #resetPassword {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.888888888888889vw !important;
    line-height: 4.722222222222222vw !important;
    color: #8b93a5;
  }
  .leftarrow_img_signupimg{
    width:2.777777vw;
    height:5vw;
    margin-left:6.944444vw;
    position: relative;
    top:15.555vw;
  }
  #resetPassword::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 4.444vw !important;
    line-height: 4.722222222222222vw !important;
    /* color: #8b93a5; */
    color: #AEAEAE;


  }

  .fp_emslogo {
    /* height: 21.66666666666667vw; */
    height:0vw !important;
  }

  .fpword__sub_statusMsg {
    font-size: 3.041667vw;
    margin-bottom: 2vw;
    margin-top: -3vw;
  }

  .fp_emslogo img {
    /* height: 12.5vw;
        width: 35.55555555555556vw; */
    height: 22.38888888888889vw !important;
    display: none !important;
  }

  .fp_emslogo_title_text{
    display: none;
  }
  .fpword__main {
    /* height: 169.1666666666667vw; */
    /* height:auto; */
    justify-content: flex-start;
    /* background-color: red; */
  }

  .fpword__sub {
    width: 86.11111111111111vw;
    /* height:52vw; */
    /* min-height:80vw; */
    box-shadow: none !important;
    background: none !important;
    margin-bottom: 1.125vw;
    border: none!important;
    outline: none !important;
    /* margin-top:10vh; */
    margin-top: 20vh;
  }
  .forgot_password_place{
    border: none!important;
    outline: none !important;
  }

  .fpword__sub input {
    width: 75vw;
    height: 11.11111111111111vw;
    margin-bottom: 7.555555555555556vw;
    border-radius: 5px;
border: 1px solid #0047B2;
background: #FFF;
  }

  .fpword__heading {
    margin-bottom: 6.444444444444444vw;
  }

  .fpword__heading h3 {
    font-weight: 500;
    font-size: 5.9444vw;
    line-height: 6.666666666666667vw;
    color: #0047b2;

    text-align: center;
    /* font-family: Proxima Nova; */
    /* font-size: 25px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .fpword__text {
    width: 79.44444444444444vw;
    font-weight: 500;
    font-size: 4.444444444444444vw;
    line-height: 5.555555555555556vw;
    margin-top: 8vw;
    display: none !important;
  }

  .fpword__hrline {
    /* width: 13.05555555555556vw;
        background-color: red !important; */
    width: 0;
    height: 0;
  }

  .resetpword__button {
    height:12.5vw;
    font-size: 4.16666666666667vw;
    line-height: 4.722222222222222vw;
    border-radius: 6px !important;
background: #056B38;
width: 47.55vw;
font-size: 5.11vw;
font-style: normal;
font-weight: 600;
  }
  /* .forgot_password_place::placeholder {
    font-size: 3vw;
   
  } */
  .fpword_bottom_text {
    font-weight: 400;
    font-size: 5.555555555555556vw;
    line-height: 6.666666666666667vw;
    color: #ffff;
    /* display: none; */
  }

  .fp_emptyspace {
    height: 0px;
  }
}



@media screen and (max-width: 1024px) and (min-width: 768px) {

  .forgot-password_main_div_mobile{
    background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%) !important;
    /* height:100vh !important; */
    /* height:auto !important;  */

  }
  #resetPassword {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.888888888888889vw !important;
    line-height: 4.722222222222222vw !important;
    color: #8b93a5;
  }
  .leftarrow_img_signupimg{
    width:2vw;
    height:3vw;
    margin-left:6.944444vw;
    position: relative;
    top:5.555vw;
  }
  #resetPassword::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 2.74vw !important;
    line-height: 4.722222222222222vw !important;
    /* color: #8b93a5; */
    color: #AEAEAE;


  }

  .fp_emslogo {
    /* height: 21.66666666666667vw; */
    height:12vw !important;
  }

  .fpword__sub_statusMsg {
    font-size: 3.041667vw;
    margin-bottom: 2vw;
    margin-top: -3vw;
  }

  .fp_emslogo img {
    /* height: 12.5vw;
        width: 35.55555555555556vw; */
    height: 22.38888888888889vw !important;
    display: none !important;
  }

  .fp_emslogo_title_text{
    display: none;
  }
  .fpword__main {
    /* height: 169.1666666666667vw; */
    height:auto;
    justify-content: flex-start;
    /* background-color: red; */
  }

  .fpword__sub {
    width: 86.11111111111111vw;
    /* height: 49.16666666666667vw; */
    /* height:52vw; */
    box-shadow: none !important;
    /* width: 30.57291666666667vw; */
    background: none !important;
    margin-bottom: 1.125vw;
    margin-top:25vw;
    /* background-color: red !important; */
  }

  .fpword__sub input {
    width: 57vw;
    height: 9.11111111111111vw;
    margin-bottom: 7.555555555555556vw;
    border-radius: 5px;
border: 1px solid #0047B2;
background: #FFF;
  }
.leftarrow_block{
  /* background-color: red; */
  height:0vw;
}
  .fpword__heading {
    margin-bottom: 4.444444444444444vw;
  }

  .fpword__heading h3 {
    font-weight: 500;
    font-size: 3.9444vw;
    line-height: 6.666666666666667vw;
    color: #0047b2;

    text-align: center;
    /* font-family: Proxima Nova; */
    /* font-size: 25px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .fpword__text {
    width: 79.44444444444444vw;
    font-weight: 500;
    font-size: 4.444444444444444vw;
    line-height: 5.555555555555556vw;
    margin-top: 8vw;
    display: none !important;
  }

  .fpword__hrline {
    /* width: 13.05555555555556vw;
        background-color: red !important; */
    width: 0;
    height: 0;
  }

  .resetpword__button {
    height:10vw;
    font-size: 4.16666666666667vw;
    line-height: 4.722222222222222vw;
    border-radius: 6px !important;
background: #056B38;
width: 40vw;
font-size: 3.11vw;
font-style: normal;
font-weight: 600;
  }
  /* .forgot_password_place::placeholder {
    font-size: 3vw;
   
  } */
  .fpword_bottom_text {
    font-weight: 400;
    font-size: 5.555555555555556vw;
    line-height: 6.666666666666667vw;
    color: #ffff;
    display: none;
  }

  .fp_emptyspace {
    height: 0px;
  }

}

@media screen and (max-width: 1180) and (max-height:820) {
  .forgot-password_main_div_mobile{
    background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%) !important;
    height:100vh !important;
  }
  #resetPassword {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.888888888888889vw !important;
    line-height: 4.722222222222222vw !important;
    color: #8b93a5;
  }
  .leftarrow_img_signupimg{
    width:2vw;
    height:2.2vw;
    margin-left:6.944444vw;
    position: relative;
    top:7.55vw;
  }
  #resetPassword::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 2.444vw!important;
    /* line-height: 4.722222222222222vw !important; */
    color: #AEAEAE;
  }

  .fp_emslogo {
    height:0vw !important;
    background-color: green;
  }

  .fpword__sub_statusMsg {
    font-size: 1.541667vw;
    margin-bottom: 2vw;
    margin-top: -3vw;
  }

  .fp_emslogo img {
    /* height: 12.5vw;
        width: 35.55555555555556vw; */
    height: 22.38888888888889vw !important;
    display: none !important;
  }

  .fp_emslogo_title_text{
    display: none;
  }
  .fpword__main {
    /* height: 169.1666666666667vw; */
    height:auto;
    justify-content: flex-start;
    /* background-color: red; */
  }

  .fpword__sub {
    width: 86.11111111111111vw;
    height:52vw;
    box-shadow: none !important;
    background: none !important;
    margin-bottom: 1.125vw;
  }

  .fpword__sub input {
    width: 40vw !important;
    height: 6.11111111111111vw;
    margin-bottom: 7.555555555555556vw;
    border-radius: 5px;
border: 1px solid #0047B2;
background: #FFF;
  }

  .fpword__heading {
    margin-bottom: 6.444444444444444vw;
  }

  .fpword__heading h3 {
    font-weight: 500;
    font-size: 2.9444vw;
    line-height: 6.666666666666667vw;
    color: #0047b2;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .fpword__text {
    width: 49.44444444444444vw;
    font-weight: 500;
    font-size: 2.444444444444444vw;
    /* line-height: 5.555555555555556vw; */
    margin-top: 8vw;
    display: none !important;
  }

  .fpword__hrline {
    width: 0;
    height: 0;
  }

  .resetpword__button {
    height:7.5vw;
    font-size: 2.16666666666667vw;
    line-height: 4.722222222222222vw;
    border-radius: 6px !important;
background: #056B38;
width: 27.55vw;
font-size: 2.11vw;
font-style: normal;
font-weight: 600;
  }

  .fpword_bottom_text {
    font-weight: 400;
    font-size: 2.555555555555556vw;
    line-height: 6.666666666666667vw;
    color: #ffff;
    display: none;
  }

  .fp_emptyspace {
    height: 0px;
  }

}