.role-view {
    // margin: 0 10px;
    margin-top: 4vw;
    .title {
        font-size: 1.4583333333333333vw;
        font-weight: 400;

        hr {
            border-width: 2px;
            border-color: #000;
        }
    }

    .table-main {
        max-height: 80vh;
        overflow: auto;
    }
    .form-error {
        display: block !important;
    }
    .create-button {
        font-size: 14px;
    }

    .page {
        margin: 5px 0;
    }

    .session {
        margin-top: 5px;
    }

    .title-section {
        margin-bottom: 15px;
        .title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #263659;
            margin-bottom: 5px;
        }
        .sub-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #263659;
        }
    }

    .ems-text {
        color: #263659;
        font-size: 18px;
        font-weight: 500;
    }
    
    .data-section {
        margin-bottom: 10px;
    }

    .import-button {
        background: #1C477A;
        border-radius: 0;
    }

    .label {
        font-size: 18px;
        font-weight: 500;
    }

    .btn-outline-ems {
        --bs-btn-color: #1C477A;
        --bs-btn-border-color: #1C477A;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #1C477A;
        --bs-btn-hover-border-color: #1C477A;
        --bs-btn-focus-shadow-rgb: 13,110,253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #1C477A;
        --bs-btn-active-border-color: #1C477A;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #1C477A;
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #1C477A;
        --bs-gradient: none;
        // color: #1C477A;
    }

    .btn-primary-ems {
        --bs-btn-color: #fff;
        --bs-btn-bg: #1C477A;
        --bs-btn-border-color: #1C477A;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #0b5ed7;
        --bs-btn-hover-border-color: #1C477A;
        --bs-btn-focus-shadow-rgb: 49,132,253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #1C477A;
        --bs-btn-active-border-color: #1C477A;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #0d6efd;
        --bs-btn-disabled-border-color: #0d6efd;
        color: #fff;
    }
    .navbar-fixed-bottom {
        position: fixed;
        bottom: 0px;
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
    }

    .card-navbar-bottom {
        background-color: #fff;
        border: none;

        .card-body {
            padding: 10px 55px;
        }
    }

    .no-border-radius {
        // border-radius: 1px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
}