.bargraphtwo_main_div {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.bargraphtwo_main_div svg {
  height: 10vw !important;
  width: 21vw !important;
  position: relative;
  margin-top: 2vw;
  margin-left: 0.5vw;
  /* background-color: red; */
}
.dxc-arg-elements {
  display: none;
}
.dxc-arg-line path {
  display: none !important;
}
.dxc-val-elements {
  display: none;
}
.dxc-val-strips {
  display: none !important;
}
.dxc-axes-group {
  display: none;
}
.voters_favourtext_main_div {
  margin-top: 0.5416666666666667vw;
  margin-left: 1.0416666666666667vw;
  position: absolute;
  top: 0vw;
  /* background-color: red; */
}
.voters_favourtext_main_div span {
  font-size: 0.9375vw;
  font-family: "Montserrat";
  font-weight: 1000;
}
.ems_admin_dashboara_votersfavoursto_chart_text_underline {
  width: 6.145833333333333vw;
  height: 0.1vw;
  background-color: #acacac;
  margin-left: 0.7vw;
}

.charts_viewport_text{
  bottom: 3.2%;
}
.bargraphtwo_main_div text {
  font-size: 1vw !important;
  margin-left: 0%;
  font-family: 'Montserrat' !important;
  font-weight: 800 !important;
}
@media screen and (max-width:767px) {
  .voters_favourtext_main_div span { 
    /* margin-left:33vw; */
    margin-left: 0vw;
  }
  .bargraphtwo_main_div svg{
    height:35vw !important;
    width: 75vw !important;
    margin-top: 3vw;
    margin-left: -3vw !important;
  }
  .ems_admin_dashboara_votersfavoursto_chart_text_underline{
    width: 87.3vw;
    margin-left: -.9vw;
    margin-top:2vw;

  }
  .bargraphtwo_main_div text {
    font-size: 3.5vw !important;
    margin-left: 0%;
    font-family: 'Montserrat' !important;
    font-weight: 800 !important;
  }
  
}

@media  (min-width:768px) and (max-width: 1024px) {
.bargraphtwo_main_div svg {
  height: 16vw !important;
  width: 32vw!important;
    position: relative;
    margin-top: 2vw;
    margin-left: 0.5vw;
    /* background-color: red; */
}
.voters_favourtext_main_div span {
  font-size: 1.9375vw;
  font-family: "Montserrat";
  font-weight: 1000;
}
.ems_admin_dashboara_votersfavoursto_chart_text_underline {
  width: 6.145833333333333vw;
  height: 0.1vw;
  background-color: #acacac;
  margin-left: 0.7vw;
  display: none !important;
}
}

@media  (min-width:1140px) and (max-height: 958px){
  .bargraphtwo_main_div svg {
    height: 10vw !important;
    width: 24vw !important;
    position: relative;
    margin-top: 2vw;
    margin-left: -.5vw;
}
}