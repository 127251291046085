.main_div_wardvillages_issues_page {
  /* height: 100vh; */
  height:auto;
  width: 100%;
  margin-top: 4vw;
}
.ward_villages__dashboard-main_div {
  height: 3.84vw;
  width: 95%;
  margin-left: 2% !important;
  border-bottom: 0.10416666666666667vw solid black;
}
#ward_villages_title {
  color: #263659;
  font-family: Montserrat;
  font-size: 1.45833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.flex-md-row {
  /* flex-direction: row !important; */
}
.ward_village_module_main_div {
  flex-wrap: wrap !important;
  width: 95%;
  margin-left: 1vw;
  /* margin: 1vw; */
}
.ward_village_admin_dropdow_town_mandal {
  height: 2.65625vw;
  width: 25.52vw;
  background-color: #f3f7f9;
  margin-left: 1%;
  margin-top: 0.8333333333333334vw;
  border-radius: 0.3vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  cursor: pointer;
  position: relative;
}
.ward_village_dropdowns_dashboard_colors {
  background-color: #f3f7f9;
}
.admindashboard_dropdown_box_text_mandaltown {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  color: #8b93a5;
  font-size: 1.0416666666666667vw;
  margin-left: 0.78125vw;
  width: 55%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.ward_village_dropdown_arrow {
  height: 0.6291666666666666vw;
  width: 0.7291666666666666vw;
  position: absolute;
  left: 90%;
}
.ward_village_underlines_holder {
  margin-top: 0.7811vw;
}
.ward_village_underline_one {
  background-color: #ccd0d6;
  width: 95%;
  height: 0.1vw;
  margin-left: 1.8vw;
}
.ward_village_note_main_div {
  margin-left: 1%;
}
.ward_village_issues_main_child {
  height: 39.94vw !important;
  /* height: 407px; */
  /* width: 25.52vw; */
  width: 31.3%;
  /* background-color: red; */
  /* background-color: #f3f7f9; */
  margin-left: 1%;
  margin-top: 0.8333333333333334vw;
  border-radius: 0.3vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  cursor: pointer;
  position: relative;
}
.ward_village_child_div {
  /* display: flex;
    justify-content: space-between; */
}
.ward_village_text_div {
  margin-top: 1vw;
  margin-bottom: 1vw;
}
.important_people_title {
  color: #263659;
  font-family: Montserrat;
  font-size: 0.9275vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1vw !important;
}
.ward_village_importantpeople_underline {
  height: 1px;
  width: 6.145833333333333vw;
  background-color: #acacac;
  left: 2vw;
  position: relative;
  margin-top: 0.25vw;
}
.ward_village_importpeople_add {
  width: 4vw;
  height: 1.77vw;
  margin-top: 0vw;
  display: flex;
  position: absolute;
  right: 1vw;
}
.ward_village_name_designation_mobile_div {
  height: 2.1354166666666665vw;
  width: 100%;
  background-color: #d7dfe8;
}
.important_people_name_text_title_name {
  font-size: 0.9333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  margin-left: 1.041666666666667vw !important;
  color: #263659;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.important_people_name_text_name {
  margin-left: 1.4vw;
}
.ward_villageissue_important_people_name_text {
  font-size: 0.9333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  margin-left: 1.5791666666666667vw !important;
  color: #263659;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ward_village_todo_important_people_name_todo_tilte {
  font-size: 0.9333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  margin-left: 1.5791666666666667vw !important;
  color: #263659;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.important_people_name_text_name,
.ward_important_people_name_text_title,
.ward_important_people_designation_text_status {
  color: #6c7c9e;
  font-size: 0.833vw;
  color: #6c7c9e;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ward_important_people_name_text_title {
  margin-left: 2vw;
}
.ward_important_people_designation_text_status {
  /* margin-left:10vw; */
  position: absolute;
  right: 9.6vw;
}
.ward_village_important_people_designation_text {
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  margin-left: 4.8vw !important;
  color: #6c7c9e;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ward_village_important_people_mobile_text {
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  /* margin-left: 3.489833vw !important; */
  position: absolute;
  right: 7vw;
  color: #6c7c9e;
}
.name_designations_mobile_div {
  background-color: #d7dfe8;
  height: 2.1354166666666665vw;
}
#ward_village_imp_poeple_first_name {
  margin-left: 1vw !important;
  color: #263659;
  font-family: Montserrat;
  font-size: 0.8333vw;
  font-weight: 500;
  width: 70%;
  height: 1.2vw;
  overflow: hidden;
}
#ward_village_imp_poeple_designation {
  font-size: 0.8333vw;
  color: #263659;
  font-weight: 500;
  align-items: center;
  width:60%;
  overflow: hidden;
  position: relative;
  left: 0;
}
#ward_village_imp_poeple_mobile {
  font-size: 0.8333vw;
  color: #263659;
  font-weight: 500;
  /* margin-left:-1.5vw !important; */
  width: 19%;
  overflow: hidden;
  position: absolute;
  right: 5.3vw;
}
.ward_village_text_info_detail_border_div {
  border-bottom: 1px solid #e4e4e4 !important;
  height: 4vw;
  position: relative !important;
}
.call_logo_ward_village {
  height: 1vw;
  width: 0.9vw;
  position: absolute;
  right: 3.5vw;
  /* margin-left:0.5vw !important; */
}

.ward_village_importantpeople_info_text_details_div {
  width: 100%;
}
.usermanagement_menu_icon {
  height: 1.6vw;
  width: 1.6vw;
  position: absolute;
  right: 1vw;
}

.ward_village_data_view_delete_maindiv {
  display: flex !important;
  flex-direction: column;
  border-radius: 0.10416666666666667vw;
  position: absolute;
  z-index: 1;
  top: 2.9vw;
  right: 0.3vw;
  height:3.08125vw;
  width: 4.479166666666667vw;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
}
#ward_view {
  font-size: 1vw;
  color: #263659;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.1vw;
  text-align: center;
}
#ward_delete {
  font-size: 1vw;
  color: #eb7070;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.1vw;
  text-align: center;
}
#ward_villagissue_child_title_sub1 {
  font-size: 0.9333333333333334vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  margin-left: 1.041666666666667vw !important;
  color: #263659;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ward_village_important_people_info_scrolldiv {
  /* height: 60vh; */
  height:34vw;
  overflow-y: scroll;
  /* background-color: red; */
}
.ward_villageisuuse_text_info_detail_border_div {
  height: 4vw;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
}
.ward_village_imp_poeple_child_title1 {
  color: #263659;
  font-family: "Montserrat";
  font-size: 0.888vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 1.63vw;
  width: 85%;
}
.ward_village_imp_poeple_child_title2 {
  color: #263659;
  font-family: "Montserrat";
  font-size: 0.888vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5vw;
  position: absolute;
  right: 8vw;
}
.ward_village_issues_menu_icon {
  width: 1.66vw;
  height: 1.66vw;
  /* margin-left:4vw; */
  position: absolute;
  right: 1.8vw;
  margin-top: 1.3vw;
}

.ward_village_important_people_info_scrolldiv_2 {
  height:34vw;
  overflow-y: scroll;
}

.ward_village_todo_importantpeople_underline {
  width: 3.489vw;
  height: 0.05vw;
  background: #acacac;
  margin-left: 1.9vw;
  margin-top: 0.29vw;
}

.ward_village_todoaddyour_new_todo {
  background-color: #f3f7f9;
  /* width: 20.875vw; */
  width: 81%;
  height: 2.2vw;
  margin-left: 1vw;
  margin-top: 1.2vw;
  border-radius: 5px;
  border: 1px solid #d8d8d8 !important;
}
.ward_village_add_todos_btn {
  width: 2.1875vw;
  height: 2vw;
  border-radius: 2px;
  margin-top: 1vw;
  background: #528bb8;
  border: none;
  margin-left: 0.5vw;
}
.ward_village_todo_add_todos_plus {
  color: #ffffff;
}
.ward_village_todo_underline_div {
  border: 1px solid #d7d7d7;
  /* width: 25.5vw; */
  width: 100%;
  height: 1px;
  margin-top: 10px;
}
.ward_village_todo_child_main_div {
  height: 57.3vh;
  overflow-y: scroll;
}
.ward_village_call_todo_items_list {
  /* width: 23.5vw !important; */
  width: 92%;
  height: 2.083333vw;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-left: 1vw;
  margin-top: 0.52vw;
}

.ward_village_itmes_lists {
  color: #263659;
  font-family: "Montserrat";
  font-size: 0.8333vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5%;
}

.ward_village_filter_contstiency_text_more{
  color: #8B93A5;
  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .wardvillage_issues_filters_main_div {
    flex-direction: column;
  }
  .constituency_wardvillageissues {
    width: 86.111vw !important;
    height: 11.9444vw !important;
    margin-top: 3.888vw !important;
    margin-left: 6.944vw !important;
  }
  .ward_village_filter_contstiency_text {
    color: #8b93a5;
    font-family: "Montserrat";
    font-size: 3.888vw !important;
    color: #8b93a5;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    margin-left:4.444vw !important;
    text-align: left;
    height: 45% !important;
    width: 40vw !important;
    width: 63%!important;

  }
  .ward_village_filter_contstiency_text_more{
    font-size: 3.888vw ;
  }

  .ward_village_dropdown_arrow {
    height:3vw !important;
    width: 3.888888888888889vw !important;
    
  }

  .dropdown_wardvillages_data_div {
    background-color: #ffffff;
    box-shadow: 0.1vw 0.1vw 0.5vw black;
    border-radius: 0.5vw;
    z-index: 50;
    animation: mandaldisplay 0.4s linear;
    border: none;
    width: 85.5vw!important;
    /* height: 35vw !important; */
    margin-left: 7vw !important;
    position: absolute;
    z-index: 50;
    margin-top: 0.5vw;
    animation: dropdown 0.7s linear;
    background-color: #FFFFFF !important;
    box-shadow: 0px 1.1111111111111112vw 2.7777777777777777vw rgba(0, 0, 0, 0.15);
}
.dropdown_wardvillages_data_div {
  /* height: 20vw!important;  */
  /* Default height when no data */
  /* background-color: red !important; */
  /* background-color: #FFFFFF !important; */
  /* box-shadow: 0px 1.1111111111111112vw 2.7777777777777777vw rgba(0, 0, 0, 0.15) !important; */
  /* box-shadow: 0.1vw 0.1vw 0.5vw black !important;
  border-radius: 0.5vw;
  z-index: 50; */
}

.dropdown_wardvillages_data_div.with-data {
  /* height: 35vw !important; */
  /* Height when there is data */
  /* background-color: green !important; */
}
.searchbar_wardvillagesissue_filters {
  width: 96%;
  height: 8.3vw !important;
  margin-left: 2%;
  margin-top: 2%;
  border-radius: 0.2vw;
  border: 0.1vw solid #D8D8D8;
  background-color: #f8f9f9;
  font-size: 3vw;
}
.lablesof-wardvillage_dropdown_data {
  width: 100%;
  height: 14vw !important;
  height: 60% !important;
  border: 0.1vw solid #CCD0D6 !important;
  align-items: center;
  /* background-color: red !important; */
  border-radius: 0.3vw!important;
}
.ward_village_filter_constiency_checkbox {
  height:4.075vw !important;
  width: 4.075vw !important;
  margin-left: 2vw !important;

}
.ward_village_filter_constiency_api_data {
  margin-left: 3vw !important;
  font-size: 3.8888vw !important;
  width: 70%;
  overflow: hidden;
  /* height: 100% !important; */
  height: auto !important;
  /* margin-bottom: 5vw !important; */
  /* white-space: nowrap !important;
    text-overflow: ellipsis !important; */
  /* background-color: green !important; */
}

  .main_div_wardvillages_issues_page {
    height: auto !important;
    margin-top: 29vw;
  }
  .ward_villages__dashboard-main_div {
    height: 3.84vw;
    width: 95%;
    margin-left: 2% !important;
    border-bottom: 0.10416666666666667vw solid black;
  }
  #ward_villages_title {
    color: #263659;
    font-family: Montserrat;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 8vw;
    margin-left: 6vw;
  }

  .flex-md-row {
    flex-direction: column !important;
  }
  .ward_village_admin_dropdow_town_mandal {
    height: 11.94444vw;
    width: 86.11vw;
    margin-left: 6vw;
    margin-top: 4vw;
  }
  .ward_village_underlines_holder {
    margin-top: 2vw;
  }
  .ward_village_note_main_div {
    margin-top: 2vw;
    margin-left: 6%;
  }
  .ward_village_issues_main_child {
    height: 89.4444vw !important;
    width: 86vw !important;
    border-radius: 6px !important;
    margin-left: 2%;
    margin-top: 0.8333333333333334vw;
    border: 0.052083333333333336vw solid #d8d8d8;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .ward_village_text_div {
    margin-top: 2.22vw !important;
    /* margin-top:1vw; */
    margin-bottom: 1vw;
  }
  .important_people_name_text_title_name {
    font-size: 3.88vw !important;
  }
  .ward_village_importantpeople_underline {
    width: 26.94444vw;
    height: 1px;
  }
  .ward_village_importpeople_add {
    width: 16.66vw;
    height: 7.222vw;
  }

  .ward_village_name_designation_mobile_div {
    height: 8.333vw;
    width: 100%;
    background-color: #d7dfe8;
    margin-top: 3vw;
  }
  .ward_village_important_people_mobile_text {
    font-size: 0.8333333333333334vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    /* margin-left: 3.489833vw !important; */
    position: absolute;
    right: 15vw;
  }
  .important_people_name_text_name,
  /* .important_people_designation_text, */
  .ward_village_important_people_designation_text,
  .ward_village_important_people_mobile_text,
  /* .important_people_mobile_text, */
  .ward_important_people_name_text_title,
  .ward_important_people_designation_text_status {
    font-size: 3.88vw;
    color: #6c7c9e;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #ward_villagissue_child_title_sub1 {
    font-size: 3.888888888888889vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    margin-left: 1.041666666666667vw !important;
    color: #263659;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
  .ward_important_people_name_text_title {
    margin-left: 5vw;
  }
  /* .ward_important_people_designation_text_status {
    margin-left: 30vw !important;
  } */
  .ward_important_people_designation_text_status {
    position: absolute;
    right: 26vw;
}
.ward_villageisuuse_text_info_detail_border_div {
  height: 8vw;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
}
.ward_village_important_people_info_scrolldiv_2 {
  height: 71vw;
  overflow-y: scroll;
  
}
.ward_village_imp_poeple_child_title1 {
  color: #263659;
  font-family: "Montserrat";
  font-size: 3.333vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5.4vw;
  width: 90%;
  overflow-y: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
.ward_village_imp_poeple_child_title2 {
  color: #263659;
  font-family: "Montserrat";
  font-size: 3.333vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5vw;
  position: absolute;
  left: 41vw;
}
.ward_village_issues_menu_icon {
  width: 5vw;
  height: 5vw;
  position: absolute;
  right: 3vw;
  margin-top: 1.3vw;
}
  .ward_village_important_people_info_scrolldiv {
    height: 70vw;
    overflow-y: scroll;
  }
  #ward_village_ward_modules {
    margin-top: 2vw;
  }
  .important_people_name_text_name {
    margin-left: 3vw !important;
  }
  .important_people_designation_text {
    /* margin-left:7vw !important; */
  }
  .important_people_mobile_text {
    /* margin-left:3vw!important; */
  }
  .name_designations_mobile_div {
    background-color: #d7dfe8;
    width: 85.6vw;
    height: 8.33vw;
    margin-top: 2vw;
  }
  .important_people_name_todo_tilte {
    margin-left: 6vw !important;
  }
  #ward_village_imp_poeple_first_name {
    margin-left: 2.5vw !important;
    font-weight: 500;
    color: #263659;
    font-family: "Montserrat";
    font-size: 3.33vw;
    /* background-color: red; */
    width: 5em;
     height: 1.2em;
      overflow-y: hidden;
      text-overflow: ellipsis;
       white-space: nowrap;
       

  }
  .ward_village_important_people_designation_text {
    margin-left: 9vw !important;
  }
  #ward_village_imp_poeple_designation {
    position: relative;
    right: 2.5vw;
    color: #263659;
    font-size: 3.33vw;
    width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  #ward_village_imp_poeple_mobile {
    position: absolute;
    right: 10vw;
    width: 25%;
    overflow-y: hidden;
    text-overflow: ellipsis;
     white-space: nowrap;
     color: #263659;
    font-size: 3.33vw;
    /* background-color: red; */
  }
  .call_logo_ward_village {
    height: 4vw;
    width: 4vw;
    position: absolute;
    right: 6vw;
  }
  .usermanagement_menu_icon {
    height: 6vw !important;
    width: 6vw !important;
    position: absolute;
    right: 1vw;
}
.ward_village_data_view_delete_maindiv {
  display: flex !important;
  flex-direction: column;
  border-radius: 0.10416666666666667vw;
  position: absolute;
  z-index: 1;
  top: 6.5vw;
  right: 1vw;
  height: 13.5vw;
  width: 20.88888888888889vw;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
}
#ward_view {
  font-size: 3.888888888888889vw;
  color: #263659;
  padding:0.8vw;
}
#ward_delete {
  font-size: 3.888888888888889vw;
  padding:0.8vw;

}
  .ward_village_importantpeople_info_text_details_div {
    width: 100%;
    height: 7.5vw;
  }


  .ward_village_text_info_detail_border_div {
    border-bottom: 1px solid #e4e4e4 !important;
    height: 8.5vw !important;
    /* position: relative !important; */
  }

  .ward_village_todo_important_people_name_todo_tilte {
    font-size: 3.933333vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    margin-left: 1.5791666666666666vw !important;
}

.ward_village_todoaddyour_new_todo {
  background-color: #f3f7f9;
  width: 81%;
  height: 8.2vw;
  margin-left: 1vw;
  margin-top: 1.2vw;
  border-radius: 5px;
  border: 1px solid #d8d8d8 !important;
}
.ward_village_add_todos_btn {
  width: 7.1875vw;
  height: 8vw;
  border-radius: 2px;
  margin-top: 1vw;
  background: #528bb8;
  border: none;
  margin-left: 1.5vw;
}
.ward_village_call_todo_items_list {
  width: 92%;
  height: 8.083333vw;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-left: 1vw;
  margin-top: 1.52vw;
}
.ward_village_itmes_lists {
  color: #263659;
  font-family: "Montserrat";
  font-size: 3.8333vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5%;
}

}




@media (min-width: 768px) and (max-width: 1024px) {
  .main_div_wardvillages_issues_page {
    height: 94vh !important;
  }
  .ward_village_module_main_div {
    flex-wrap: wrap !important;
    width: 95%;
    margin: 1vw;
  }
}
