.otpmainBlock {

  .otpmainBlock_popup{
    position: absolute;
    color: red;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding-top: 30px;
  }
  .alert-hidden{
    opacity: 0;
    transition: all 1500ms linear 2s;
  }
  .otpmainBlock_popup p{
    // position: absolute;
    width: 24vw; 
    text-align: center;
    background: #67a7f4;
    /* border: 2px solid lightblue; */
    padding: 10px;
    border-radius: 6px;
    color: white;
  }

  .otplogo_block {
    height: 7.291666666666667vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .otplogo_block img {
    height: 6.21875vw !important;
  }

  .otpbodyBlock {
    background-color: #383b3c;
    width: 100%;
    // height: 25.88541666666667vw;
    display: flex;
    align-items: center;

    .signup__sub {
      width: 100%;
      // height: 27.291666666666667vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      .otpbodyBlock_verifyotp {
        height: 7.1875vw;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .otpbodyBlock_verifyotp p {
        font-family: Montserrat;
        font-style: Medium;
        font-size: 1.041666666666667vw;
        line-height: 1.25vw;
        color: #ffffff;
        margin-bottom: 0px !important;
      }

      .otpbody_box {
        width: 30.57291666666667vw;
        height: 16.2vw;
        border: 1px solid white;
        border-radius: 0.5208333333333333vw;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 1.5625vw;
        padding-right: 1.25vw;
        margin-bottom: 0.5208333333333333vw;

        .otpbody_box_otpVerification {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 2.083333333333333vw;
          margin-bottom: 1vw;
          gap: 1.197916666666667vw;
        }

        .otpbody_box_otpVerificationhrline {
          width: 7.395833333333333vw;
          height: 0;
          border: 1px solid #a5a5a5;
          //   margin-top: 2.083333333333333vw;
          // width:7.395833333333333vw;
          // margin-top: 2.8125vw
        }

        .otpbody_box_enterotp {
          width: 27.76041666666667vw;
          height: 2.65625vw;
          font-family: Montserrat;
          font-size: 0.9375vw;
          font-weight: 500;
          line-height: 3.145833333333333vw;
          color: #8B93A5;
          background-color: #F3F7F9;
          border: none;
          margin-bottom: 1vw;
          padding-left: 0.9375vw;
          border-radius: 0.5208333333333333vw;
          outline: none !important;
        }

        .otpbody_box_enterotp::placeholder{
          font-family: Montserrat;
          // font-size: 0.9375vw !important;
          color: #8B93A5;
        }

        .otpbody_box_otpVerification h3 {
          font-family: Montserrat;
          font-size: 1.25vw;
          font-weight: 500;
          line-height: 1.510416666666667vw;
          color: #000000;
          margin-bottom: 0px;

        }

        .otpbody_box_submit {
          width: 27.76041666666667vw;
          height: 2.65625vw;
          font-family: Montserrat;
          font-size: 0.9375vw;
          font-weight: 500;
          line-height: 2.145833333333333vw;
          background-color: #1C477A;
          border: none;
          color: white;
          margin-bottom: 0.5208333333333333vw;
          border-radius: 0.5208333333333333vw;
        }
        .otpbody_box_wrongotp{
          font-size:0.824742268vw;
          color:red;
          margin-bottom: 0.5vw;
        }
        .otpbody_box_sucessfullotp{
          font-size:0.824742268vw;
          color:green;
          text-align: center;
        }

        .otpbody_box_resendotp {
          width: 100%;
          display: flex;
          justify-content: end;
          margin-bottom: 0px;
          // align-items: center;
        }

        .otpbody_box_resendotp p {
          font-family: Montserrat;
          font-size: 0.7291666666666667vw;
          font-weight: 500;
          line-height: 0.8854166666666667vw;
          color: #E82A36;
          text-align: right;
          cursor: pointer;
          margin-bottom: 0px;
          // border-bottom: 1px solid #E82A36;
        }
      }

      .otpbodyBlock_login {
        height: 5.260416666666667vw;
        display: flex;
        justify-content: center;
        padding-top: 1.25vw;
      }
      a:link{
        text-decoration: none !important;
      }
      .otpbodyBlock_login p {
        font-family: 'Montserrat';
        font-style: Medium;
        font-size: 1.041666666666667vw;
        line-height: 1.25vw;
        color: #ffffff;
        text-decoration: none !important;
      }
    }


  }

  .otp_Emptyblock {
    width: 100%;
    height: 18.5938vw !important;
    background-color: white;
  }

  .otp_footerBlock {
    width: 100%;
    height: 4.479166666666667vw;
    background-color: #1B2837;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .otp_footerBlock p {
    font-family: Montserrat;
    font-size: 0.7291666666666667vw;
    font-weight: 400;
    line-height: 0.8854166666666667vw;
    color: #FFFFFF;
    margin-bottom: 0px !important;
  }

  @media (max-width: 600px) {
    .otpbody_box_wrongotp{
      font-size:2.824742268vw !important;
      margin-bottom: 4px;
      margin-left: -14px !important;
    margin-top: 2vw;
    }
    .otpbody_box_sucessfullotp{
      font-size:3.124742268vw !important;
      margin-bottom: 4px;
      font-weight: 600;
    }
    .otpbody_box {
      margin-top: 7vw;
      
    }
    .otplogo_block {
      height: 21.94444444444444vw;

    }

    .otplogo_block img {
      height: 17.38888888888889vw !important;
    }

    .otpbodyBlock {
      // height: 160.1666666666667vw;
      width: 100%;
      height: 100vh !important;
      // height:0vw !important;
      // height:auto;
      // background-color: #383b3c;
      // background-color: green !important;
      background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%);


      .signup__sub {
        // height: 169.1666666666667vw;
        width: 100%;
        // background-color: red;
      }

      
      .otpbody_box {
        height: 61.77777777777778vw !important;
        width: 86.11111111111111vw !important;
        border-radius: 1.5208333333333333vw;
        background: none !important;
        border: none !important;

        .otp_heading {
          font-family: 'Montserrat';
          font-size: 6.94444vw !important;
          line-height: 6.666666666666667vw !important;
          text-align: center;
          color: #0047B2 !important;
          font-weight: 700;

        }

        .otpbody_box_otpVerification {
          gap: 2.197916666666667vw !important;
          margin-bottom: 5.555555555555556vw !important;
          // .Otp_otpbody_box_wrongotp {
          //   font-size: 2.824742vw !important;
          //   margin-bottom: 7px !important;
          // }
        }

        .otpbody_box_otpVerificationhrline {
          width: 12.395833333333333vw !important;
          display: none !important;
        }

        .otpbody_box_enterotp {
          width: 75vw !important;
          height: 11.11111111111111vw !important;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 3.888888888888889vw !important;
          line-height: 4.722222222222222vw !important;
          color: #8B93A5;
          margin-bottom: 4.555555555555556vw !important;
          padding-left: 2.2vw !important;
          background-color: none !important;
        }

        .otpbody_box_enterotp::placeholder {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 3.888888888888889vw !important;
          color: #8B93A5;
          margin-top: 2vw;

        }

        .otpbody_box_submit {
          width: 40vw !important;
          height: 11.11vw !important;
          font-family: 'Montserrat' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 5.11vw !important;
          line-height: 4.722222222222222vw !important;
          text-align: center;
          color: #FFF;
          background-color: #056B38 !important;
          border-radius: 6px !important;
        }

      }

      .otpbodyBlock_verifyotp {
        padding-top: 8.333333333333333vw !important;
        padding-bottom: 5.555555555555556vw !important;

        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 5.555555555555556vw !important;
          line-height: 6.666666666666667vw !important;
          text-align: center;
          color: #FFFFFF;

        }
      }

    }

    .otp_Emptyblock {
      height: 0px !important;

    }

    .name_alert {
      font-size: 4.120833vw !important;
      margin-top: -13px !important;
      margin-left: 8vw !important;
      font-weight: 500;
    }

    .otpbodyBlock_login {
      // padding-top: 8.333333333333333vw !important;
      padding-bottom: 5.555555555555556vw !important;

      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 5.555555555555556vw !important;
        line-height: 6.666666666666667vw !important;
        text-align: center;
        color: #FFFFFF;
      }
    }
.otpbody_box_resendotp{
  margin-top:2vw !important;
  // background-color: red;
}
    .otpbody_box_resendotp p {
      margin-right: 4vw;
      padding-top: 3vw;
      cursor: pointer;
      margin-bottom: 0px;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 3.888888888888889vw !important;
      line-height: 4.722222222222222vw !important;
      // text-decoration-line: underline;
      // color: #E82A36;
      color:#666666 !important;
      text-decoration: none !important;
    }
    u{
      text-decoration: none !important;
    }

    .otp_footerBlock {
      height: 21.38888888888889vw !important;
      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 3.888888888888889vw !important;
        line-height: 4.722222222222222vw !important;
        color: #FFFFFF;
      }
    }

  }



  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .otpbody_box_wrongotp{
      font-size:2.824742268vw !important;
      margin-bottom: 4px;
      margin-left: -14px !important;
    margin-top: 2vw;
    }
    .otpbody_box_sucessfullotp{
      font-size:2.824742268vw !important;
      margin-bottom: 4px;
    }
    .otpbody_box {
      margin-top: 7vw;
      
    }
    .otplogo_block {
      height: 21.94444444444444vw;

    }

    .otplogo_block img {
      height: 17.38888888888889vw !important;
    }

    .otpbodyBlock {
      // height: 160.1666666666667vw;
      width: 100%;
      height: 100vh !important;
      // height:0vw !important;
      // height:auto;
      // background-color: #383b3c;
      // background-color: green !important;
      background: linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%);


      .signup__sub {
        // height: 169.1666666666667vw;
        width: 100%;
        // background-color: red;
      }

      
      .otpbody_box {
        height: 61.77777777777778vw !important;
        width: 86.11111111111111vw !important;
        border-radius: 1.5208333333333333vw;
        background: none !important;
        border: none !important;

        .otp_heading {
          font-family: 'Montserrat';
          font-size: 6.94444vw !important;
          line-height: 6.666666666666667vw !important;
          text-align: center;
          color: #0047B2 !important;
          font-weight: 700;

        }

        .otpbody_box_otpVerification {
          gap: 2.197916666666667vw !important;
          margin-bottom: 5.555555555555556vw !important;
          // .Otp_otpbody_box_wrongotp {
          //   font-size: 2.824742vw !important;
          //   margin-bottom: 7px !important;
          // }
        }

        .otpbody_box_otpVerificationhrline {
          width: 12.395833333333333vw !important;
          display: none !important;
        }

        .otpbody_box_enterotp {
          width: 75vw !important;
          height: 11.11111111111111vw !important;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 3.888888888888889vw !important;
          line-height: 4.722222222222222vw !important;
          color: #8B93A5;
          margin-bottom: 4.555555555555556vw !important;
          padding-left: 2.2vw !important;
          background-color: none !important;
        }

        .otpbody_box_enterotp::placeholder {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 3.888888888888889vw !important;
          color: #8B93A5;
          margin-top: 2vw;

        }

        .otpbody_box_submit {
          width: 40vw !important;
          height: 11.11vw !important;
          font-family: 'Montserrat' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 5.11vw !important;
          line-height: 4.722222222222222vw !important;
          text-align: center;
          color: #FFF;
          background-color: #056B38 !important;
          border-radius: 6px !important;
        }

      }

      .otpbodyBlock_verifyotp {
        padding-top: 8.333333333333333vw !important;
        padding-bottom: 5.555555555555556vw !important;

        p {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 5.555555555555556vw !important;
          line-height: 6.666666666666667vw !important;
          text-align: center;
          color: #FFFFFF;

        }
      }

    }

    .otp_Emptyblock {
      height: 0px !important;

    }

    .name_alert {
      font-size: 3.520833vw !important;
      margin-top: -13px !important;
      margin-left: 8vw !important;
    }
    // .wrongotp {

    // }
    // .sucessfullotp{
      
    // }

    .otpbodyBlock_login {
      // padding-top: 8.333333333333333vw !important;
      padding-bottom: 5.555555555555556vw !important;

      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 5.555555555555556vw !important;
        line-height: 6.666666666666667vw !important;
        text-align: center;
        color: #FFFFFF;
      }
    }
.otpbody_box_resendotp{
  margin-top:2vw !important;
  // background-color: red;
}
    .otpbody_box_resendotp p {
      margin-right: 4vw;
      padding-top: 3vw;
      cursor: pointer;
      margin-bottom: 0px;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 3.888888888888889vw !important;
      line-height: 4.722222222222222vw !important;
      // text-decoration-line: underline;
      // color: #E82A36;
      color:#666666 !important;
      text-decoration: none !important;
    }
    u{
      text-decoration: none !important;
    }

    .otp_footerBlock {
      height: 21.38888888888889vw !important;
      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 3.888888888888889vw !important;
        line-height: 4.722222222222222vw !important;
        color: #FFFFFF;
      }
    }

  }


  @media screen and (max-width: 1180px) and (max-height:820px) {
    .otpbodyBlock {
      width: 100%;
      height: 100vh;
      background: linear-gradient(153deg, rgba(217, 217, 217, 0) 0%, #93C7F7 80.21%); 
    }
    .otpbody_box {
      width: 50.57291666666667vw!important;
      height: 26.2vw!important;
      border: none!important;
      background: none !important;
    }
    .otpbody_box_otpVerificationhrline{
      display: none;
    }
    .otp_heading{
      font-size: 2vw!important;
      color: #0047B2 !important
    }
    .otpbody_box_enterotp{
      height:4vw!important;
      margin-top:2vw!important;
    }
    .otpbody_box_enterotp::placeholder{
      font-size: 1.7vw!important;
    }
    .otpbody_box_submit{
      background-color: #056B38 !important;
      font-size: 1.7vw!important;
      height:5vw!important;
      width: 15vw!important;
    }

  

     .otpbody_box_resendotp{
      margin-top:2vw !important;
    }
        .otpbody_box_resendotp p {
          font-size: 3.888888888888889vw!important;
          color:#666666 !important;
        }
        u{
          text-decoration: none !important;
        }
    
        .otp_footerBlock {
          height: 21.38888888888889vw !important;
          p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            color: #FFFFFF;
          }}



  }
 

  
}