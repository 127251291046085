.assignvoters_filter_contstiency_text{
    color: #8B93A5;
    font-family: "Montserrat";
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left:0.78vw;
}
.lables_assignvoter{
    color: #263659;
    font-family: Montserrat;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    padding-left: 1.7vw;
}
.constituency_assignvoters{
    width: 20vw;
    height: 2.65vw;
    margin-top: 0.6vw;
    background-color: #F3F7F9;
   position: relative;
   display: flex;
   align-items: center;
   border:1px solid #D8D8D8;
   border-radius: 0.2604166666666667vw;
}
.dropdown_assignvoter_data_div{
    background-color: #ffffff;
    box-shadow: 0.1vw 0.1vw 0.5vw black;
    border-radius: 0.5vw;
    overflow-y: scroll;
    z-index: 50;
    animation: mandaldisplay 0.4s linear;
    border:none;
    width: 20vw;
    height: 10vw;
    overflow: scroll;
    margin-left: 0.5vw;
    position: absolute;
    z-index: 50;
    margin-top: 0.5vw;
}

@media screen and (max-width:767px){
    .constituency_assignvoters {
        width: 82vw;
        height: 9.65vw;
        
    }
    .assignvoters_filter_contstiency_text{
        font-size: 4.444vw;
        font-style: normal;
        font-weight: 500;
    }
    .lables_assignvoter{
        color: #263659;
    font-family: Montserrat;
    font-size: 4.444vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2vw;
    margin-left: 2vw;
    }
}