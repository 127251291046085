
.admin_dropdow_town_mandal_ecampaign {
  height: 2.65625vw;
  width: 23.8%;
  background-color: #f3f7f9;
  margin-left: 1%;
  margin-top: 0.8333333333333334vw;
  border-radius: 0.3vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  cursor: pointer;
  position: relative;
}


.admin_more_filters_ecampaign {
  height: 2.65625vw;
  width: 23.8%;
  background: #f3f7f9;
  vertical-align: super;
  border-radius: 0.2vw;
  margin-left: 1%;
  margin-top: 0.8333333333333334vw;
  border: 0.1vw solid rgb(216, 216, 216);
  cursor: pointer;
  position: relative;
}
#filterCollapse {
  width: 98% !important;
  margin-left: 0.5%;
  background-color: "red" !important;
}

.filter_btn {
  font-size: 0.9375vw !important;
  font-family: "Montserrat";
  width: 100%;
  margin-left: 2%;
  text-align: left;
  cursor: pointer;
  color:#3f9ab5;
}

.admin_filter_dropdown_main {
  height: 2.65625vw;
  border-radius: 0.3vw;
  // width: 25%;
}

.admin_dashboard_dropdoens_main_div_ecampaign {
  // width: 96.78%;
  width: 96.6%;
  margin-left: 0.5vw;
}

.admin_dropdow_constituency_ecampaign {
  // position: relative;
  // top: 0px;
  // right: 0vw;
  height: 2.65625vw;
  // width: 100%;
  // background-color: #f3f7f9;
  // border-radius: 0.3vw;
  // border: 0.052083333333333336vw solid #d8d8d8;
}

.admindashboard_dropdown_box_text_ecampaign {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  color: #8b93a5;
  font-size: 1.0416666666666667vw;
  margin-left: 0.78125vw;
  width: 55%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.filter-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  color: #989191;
}

.input-range__slider {
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
}

.input-range__slider:active {
  transform: scale(1.3);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: 0.5rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #3f51b5;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .admindashboard_dropdown_box_text_ecampaign {
    font-size: 3.888888888888889vw;
  }
  .filter_btn {
    font-size: 3.888888888888889vw !important;
    cursor: pointer;
    color:#3f51b5;
  }
  .collapse {
    height: 72vw;
    width: 70vw;
  }
  .collapse .row {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0% !important;
    padding: 0% !important;
  }
  .filter-title {
    font-size: 2.9vw;
    margin-bottom: 5vw !important;
  }
  .mt-3 {
    margin-top: 0vw !important;
  }
  .css-1r4vtzz {
    height: 12vw !important;
  }
  .admin_filter_dropdown_main {
    margin-top: 12vw;
    width: 100%;
  }
  .admin_dropdow_constituency_ecampaign {
    // position: relative;
    // width: 100%;
    height: 11.944444444444445vw;
    border-radius: 1.6666666666666667vw;
  }

  .admin_dropdow_town_mandal_ecampaign,
  .admin_dropdow_constituency {
    position: relative;
    width: 88%;
    margin-left: 5.5%;
    height: 11.944444444444445vw;
    margin-top: 2.2222222222222223vw;
    border-radius: 1.6666666666666667vw;
  }

  .admin_more_filters_ecampaign {
    position: relative;
    width: 88%;
    margin-left: 5.5%;
    height: 11.944444444444445vw;
    margin-top: 2.2222222222222223vw;
    border-radius: 1.6666666666666667vw;
  }

  .admin_dashboard_dropdoens_main_div_ecampaign {
    width: 100% !important;
    margin-top: 58.333333vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .admin_dropdow_town_mandal_ecampaign {
    flex-basis: 49%;
  }

  .admin_more_filters_ecampaign {
    flex-basis: 49%;
  }

  .admin_dashboard_dropdoens_main_div_ecampaign {
    flex-wrap: wrap !important;
    width: 96.8%;
    margin-left: 0.6vw;
  }
}
