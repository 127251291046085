.loadingscreenI2connect {
  height: 100vh;
  width: 100%;
  background-color: black;
  position: fixed;
  opacity: 0.85;
  z-index: 1040;
  top: 0%;
  left: 0%;
  right: 0%;

  .Loading_ring {
    height: 25vw !important;
    width: 25vw !important;
  }
  .parent_Loading_holder {
    height: 30vw !important;
    width: 30vw !important;
  }

  .parent_Loading_holder {
    height: 12vw;
    width: 12vw;
    margin-left: 0vw !important;
    position: relative;
  }
  .loading_logo {
    height: 40%;
    width: 50%;
  }
  .Loading_ring {
    height: 10vw;
    width: 10vw;
    position: absolute;
    /* left: 0%; */
    pointer-events: none;
    animation: loadingring 2s linear infinite;
  }
  @keyframes loadingring {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
}
