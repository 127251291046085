.import-data {
    // height: 48.5333333333vw;
    margin-top: 5.3vw;

    .row>*{
        padding-left: 0 !important;
    }
    .row{
        width: 97.1%;
        margin-left: 0.9vw;
        
    }

    .form-control {
        color: #263659;
        font-size: 0.9375vw;
        font-weight: 500;
    }

    ._2Jtxm._35Ago ._2kL0S {
        color: #1C477A;
        // margin-top: 0.8vw;
        // margin-bottom: 0.7vw;
    }

    // ._2Jtxm{
    //     margin-top: 0.8vw;
    // }
    // ._35Ago{
    //     margin-bottom: 0.7vw;
    // }
    .title {
        font-size: 1.4583333333333333vw;
        font-weight: 400;
        font-family: 'Montserrat';
        font-style: normal;
        color: #263659;

        hr {
            border-width: 0.10416666666666667vw;
            border-color: #263659 !important;
            opacity: 1;
        }
    }

    .page {
        margin: 0.2604166666666667vw 0;
    }

    .session {
        margin-top: 1.09375vw;

        .group_logo_importdata {
            height: 2.1354166666666665vw;
            width: 3.109375vw;
        }
    }

    .download_sample_impdata {
        font-size: 0.9375vw;
        font-weight: 400;
        font-family: 'Montserrat';
        font-style: normal;
        color: #263659;
        text-decoration: underline !important;
    }

    .title-section {
        margin-bottom: 0.78125vw;
        margin-left: 1.1vw;

        .title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 1.0416666666666667vw;
            line-height: 1.25vw;
            color: #263659;
            margin-bottom: 0.2604166666666667vw;
        }

        .sub-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 0.78125vw;
            line-height: 0.9375vw;
            color: #263659;
        }
    }
    .hide{
        display: none;
    }
    .ems-text {
        color: #263659;
        font-size: 0.9375vw;
        font-weight: 500;
    }

    .data-section {
        margin-bottom: 0.5208333333333334vw;
        .form-check-inline {
            margin-left: 0.4vw;
            .form-check-label {
                margin-top: 0px;
            }
        }
    }

    .import-button {
        background: #1C477A;
        border-radius: 0.20833333333333334vw;
        font-weight: 500;
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 1.0416666666666667vw;
        width: fit-content;
    }

    .label {
        font-size: 0.9375vw;
        font-weight: 500;
        font-family: 'Montserrat';
        font-style: normal;
        color: #263659;
        margin-left: 1.1vw;
    }

    .import_data_line {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9375vw;
        color: #263659;
        margin-top: 0.7291666666666666vw;
        margin-left: 4.010416666666667vw;
    }

    .btn-outline-ems {
        --bs-btn-color: #1C477A;
        --bs-btn-border-color: #1C477A;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #1C477A;
        --bs-btn-hover-border-color: #1C477A;
        --bs-btn-focus-shadow-rgb: 13, 110, 253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #1C477A;
        --bs-btn-active-border-color: #1C477A;
        --bs-btn-active-shadow: inset 0 0.15625vw 0.2604166666666667vw rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #1C477A;
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #1C477A;
        --bs-gradient: none;
        // color: #1C477A;
    }

    .btn-primary-ems {
        --bs-btn-color: #fff;
        --bs-btn-bg: #1C477A;
        --bs-btn-border-color: #1C477A;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #0b5ed7;
        --bs-btn-hover-border-color: #1C477A;
        --bs-btn-focus-shadow-rgb: 49, 132, 253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #1C477A;
        --bs-btn-active-border-color: #1C477A;
        --bs-btn-active-shadow: inset 0 0.15625vw 0.2604166666666667vw rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #0d6efd;
        --bs-btn-disabled-border-color: #0d6efd;
        color: #fff;
    }

    .navbar-fixed-bottom {
        position: fixed;
        bottom: 0px;
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
        margin-left: 0;
        // border-top: 0.1vw solid;

        .card {
            // background-color: none !important;
            --bs-card-bg: none;
        }
    }

    // .navbar-fixed-bottom {
    //     position: fixed;
    //     bottom: 0px;
    //     width: -moz-available;
    //     width: -webkit-fill-available;
    //     width: fill-available;
    // }

    .card-navbar-bottom {
        // background-color: #fff;
        border: none;
        z-index: 999;

        .card-body {
            padding: 0.5208333333333334vw 2.8645833333333335vw;
            // margin-top: 40px;
            --bs-card-bg: none;
        }
    }

    .no-border-radius {
        // border-radius: 1px;
        padding-left: 1.0416666666666667vw;
        padding-right: 1.0416666666666667vw;
        font-size: 0.9375vw;
        font-weight: 500;
        white-space: nowrap;
    }

    .import-footer-main {
        background: #ffff;
        border-top: 0.1vw solid #1E1E1E;
        margin-left: calc(var(--bs-gutter-x) * -0.5);
        padding: 0.2604166666666667vw 0.10416666666666667vw;

        .import-footer {
            // margin-left: -2.8645833333333335vw;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 1vw;
            color: #263659;

            .import-footer-endfooterimg {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 0.9vw;
                color: #989191;
                margin-left: 1vw;
                // margin-top: 0.7vw;
            }
        }
    }


}


@media (max-width:430px) {}

@media (min-width: 481px) and (max-width: 767px) {
    .view-data-component {
        .tablemain_div {
            height: calc(100vh - 259px);
        }

        .pagination {
            gap: 0.2604166666666667vw;
            z-index: 10000 !important;
            position: absolute;
            width: 55%;
        }
    }

    .mapping-field-component {
        .table-main {
            height: calc(100vh - 320px);
        }
    }

    .import-upload-component {
        .pdf-upload-main {
            margin-bottom: 2.6041666666666665vw;
            overflow: auto;
            height: calc(100vh - 361px);
        }
    }

    .import-batch-component {
        .table-main {
            height: calc(100vh - 200px);
            overflow: auto;
        }
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .view-data-component {
        .tablemain_div {
            height: calc(100vh - 259px);
        }

        .pagination {
            gap: 0.2604166666666667vw;
            z-index: 10000 !important;
            position: absolute;
            width: 60%;
        }
    }

    .mapping-field-component {
        .table-main {
            height: calc(100vh - 356px);
        }
    }

    .import-upload-component {
        .pdf-upload-main {
            margin-bottom: 2.6041666666666665vw;
            overflow: auto;
            height: calc(100vh - 361px);
        }
    }

    .import-batch-component {
        .table-main {
            height: calc(100vh - 199px);
            overflow: auto;
        }
    }

}


@media (min-width: 1025px) and (max-width: 1280px) {

    .view-data-component {
        .tablemain_div {
            height: calc(100vh - 252px);
        }

        .pagination {
            gap: 6%;
            z-index: 10000 !important;
            position: absolute;
            width: 60%;
        }
    }

    .mapping-field-component {
        .table-main {
            height: calc(100vh - 320px);
        }
    }

    .import-upload-component {
        .pdf-upload-main {
            margin-bottom: 2.6041666666666665vw;
            overflow: auto;
            height: calc(100vh - 361px);
        }
    }

    .import-batch-component {
        .table-main {
            height: calc(100vh - 199px);
            overflow: auto;
        }
    }
}

@media (min-width: 1281px) {
    .view-data-component {
        .tablemain_div {
            height: calc(100vh - 360px);
            width: 98%;
        }

        .pagination {
            gap: 10%;
            // z-index: 10000 !important;
            position: absolute;
            width: 60%;
        }
    }

    .mapping-field-component {
        .table-main {
            height: calc(100vh - 356px);
            padding: 0 !important;
            border: 1px solid lightgray;
            margin-left: 0.3vw;
            margin-bottom: 3vw;
        }
    }

    .import-upload-component {
        .pdf-upload-main {
            margin-bottom: 2.6041666666666665vw;
            overflow: auto;
            height: calc(100vh - 361px);
        }
    }

    .import-batch-component {
        .table-main {
            height: calc(100vh - 270px);
            overflow: auto;
        }
    }

}