.bargraphone_main_div{
   position: relative;
  height: 100%;
  display: flex;
  align-items: center;

}
.bargraphone_main_div svg{
  height: 10vw !important;
  width: 21vw!important;
  position: relative;
  margin-top: 1vw; 
  margin-left: 0.5vw;  
}
  .dxc-arg-elements{
    display: none;
  }
  .dxc-arg-line path{
    display: none !important;
  }
  .dxc-val-elements{
    display: none;
  }
.dxc-val-strips{
  display: none !important;
}
.dxc-axes-group{
  display: none;
}

.ems_admin_dashboara_voters_chart_text_underline{
  width: 2vw;
  height: 0.1vw;
  background-color:#ACACAC;
  margin-left: 0.5vw;
}
.maxmizebtn_charts{
  position: absolute;
    height: 1vw;
    width: 0.6942708333333333vw;
    top: 1.0588541666666667vw;
    right: 1.0588541666666667vw;
    border: none;
    background: none;
}
.maxmizebtn_charts img{
    height: 0.6942708333333333vw;
    width: 0.6942708333333333vw;
    position: absolute;
    top: 1.5px;
    left: 2.1px;
}

.bargraphone_main_div text {
  font-size: 1vw !important;
  margin-left: 0%;
  font-family: 'Montserrat' !important;
  font-weight: 800 !important;
}
/* rect {
  width: 30px;
  height: 120px;
} */
.dxc-markers{
  height: 3vw;
}

@media screen and (max-width:767px) {
  .bargraphone_main_div{
    /* background-color: red !important; */
  }
  .bargraphone_main_div svg{
    height:35vw !important;
    width: 75vw !important;
    margin-top: 3vw;
    margin-left: -5vw !important;
    /* background-color: red !important; */
  }
  .voters_favourtext_main_div span{
    /* margin-top: 0.0555555555555554vw; */
    /* margin-left: 3.3333333333333335vw; */
    text-align: center;
    font-size: 5vw !important;
    margin-top: .0555555555555554vw;
    color: #444444;
    color: #444;
text-align: center;
font-style: normal;
font-weight: 700;
line-height: normal;
/* margin-left:10vw !important; */

  }
  .ems_admin_dashboara_voters_chart_text_underline{
    /* width: 87.5vw; */
    /* width: 87.4vw; */
    margin-left: -1vw;
    margin-top:2vw;
    /* margin-left: 4.3333333333333335vw; */
  }
  .underline_1{
    width: 87vw !important;
    /* background-color: red; */
    /* width: 2vw; */
  height: 0.1vw;
  background-color:#ACACAC;
  margin-left: 0.5vw;
  margin-left: -1vw;
  margin-top:2vw;
  }
  .castecolo_div{
    height:2.7777777777777777vw !important;
    width: 2.7777777777777777vw !important;
  }
  .castecolors_caste_div{
    font-size: 1.9vw !important;

  }
  
  .bargraphone_main_div text {
    font-size: 3.5vw !important;
    margin-left: -3% !important;
    font-family: 'Montserrat' !important;
    font-weight: 800 !important;
  }

  .maxmizebtn_charts img{
    height: 0.6942708333333333vw;
    width: 0.6942708333333333vw;
    position: absolute;
    top:9vw;
    /* top: 30px; */
    /* left: 2.1px; */
}

}


/* new */

.dxc-series-group .dxc-series .rect{
  color:yellow;
  height:100 !important

}

@media  (min-width:768px) and (max-width: 1024px) {
  .bargraphone_main_div svg {
    height: 17vw !important;
    width: 30vw!important;
    position: relative;
    margin-top: 1vw;
    margin-left: 0.5vw;
    /* background-color: red; */
}
.ems_admin_dashboara_voters_chart_text_underline {
  width: 2vw;
  height: 0.1vw;
  background-color: #ACACAC;
  margin-left: 0.5vw;
  display: none !important;
}
.maxmizebtn_charts img {
  height: 1.694271vw;
  width: 1.694271vw;
  position: absolute;
  top: 1.5px;
  left: 0px;
}
}