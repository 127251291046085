/* Map.css */

.map-container {
    display: flex;
    height: 100vh;
  }
  
  .user-table-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    /* margin-top: 20px; */
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr {
    cursor: pointer;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  