@page {
    size: 80mm 150mm; /* Adjust the size to match your thermal printer's paper size */
    margin: 0;
  }
  body {
    font-size: 8pt;
    margin: 0;
  }
  

  /* Apply styles to the main container */
.thermal-print-content {
    font-family: Arial, sans-serif;
    font-size: 10pt;
    width: 80mm; /* Adjust to match your printer's paper width */
    margin: 0 auto;
    padding: 10px;
    text-align: center;
  }
  
  /* Style for the Voter Information section */
  .voter-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  /* Style for individual voter info elements */
  .sl-no, .voter-name, .age, .voter-id, .part-number {
    flex: 2;
    margin-top: 5px;
    color: gray;
    font-weight: 400;
  }
  
  /* Style for the Divider */
  .divider {
    border: 1px solid #000;
    margin: 10px 0;
  }
  
  /* Style for the Booth Information section */
  .booth-info {
    margin-bottom: 10px;
  }
  
  /* Style for individual booth info elements */
  .booth-number, .voting-center {
    text-align: left;
  }
  
  /* Style for the Voting Information section */
  .voting-info {
    margin-bottom: 10px;
  }
  
  /* Style for individual voting info elements */
  .voting-date, .voting-time {
    text-align: left;
  }
  

  .thank-you-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
/* YourComponentStyles.css */

.a4-page {
  page-break-after: always;
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  padding: 10mm; /* Adjust as needed */
}

.thermal-print-content {
  display: flex;
  border: 2px dotted black;
  padding: 10px;
  width: 100%;
  font-size: 8px !important;
  margin-bottom: 10px; /* Adjust as needed */
}

.image-container {
  flex: 1;
  margin-top: 15px;
}

.image-container img {
  max-width: 90%;
}

.data-container {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-left: 1px dotted gray;
}

/* Add styles for other elements inside .data-container as needed */
