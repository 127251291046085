
*{
  font-family: 'Montserrat';
}

.changepassowrd_main_div {
background: rgba(19, 19, 19, 0.5);
height: 100vh;
width: 100%;
position: fixed;
top: 0%;
left: 0%;
z-index: 2040;

.changepassword_sub_div {
  height: 24.53125vw;
  width: 22.864583333333332vw;
  background-color: #ffffff;
  border-radius: 0.5vw;
}
.changepassword_heading_div {
  height: 3.75vw;
  width: 100%;
  border-radius: 0.5vw 0.5vw 0vw 0vw;
  border-bottom: 0.1vw solid #000000;
}
.changepassword_text {
  font-size: 1.1458333333333333vw;
  font-weight: 501;
  color: #263659;
  margin-right: 3vw;
}
.labels_in_change_password {
  font-size: 0.9375vw;
  margin-top: 0.9375vw;
  margin-left: 1.5625vw;
}
.inputs_change_password {
  width: 19.739583333333332vw;
  margin-left: 1.5625vw;
  height: 2.65625vw;
  background-color: #f3f7f9;
  margin-top: 0.3645833333333333vw;
}

.inputs_change_password input {
  width: 90%;
  background-color: #f3f7f9;
  border: none;
  font-size: 1vw;
}
.changepassword_main_div {
  width: 100%;
  height: 4.53125vw;
  margin-top: 0.9375vw;
  border-top: 0.1vw solid #cbcbcb;
}
.cancel_button_changepassword {
  height: 2.1875vw;
  width: 9.53125vw;
  font-size: 0.9375vw;
  border: 0.1vw solid #1c477a;
  background-color: #ffffff;
  margin-top: 0.9375vw;
  margin-left: 1.5625vw;
  border-radius: 0.2vw;
}
.changepassword_save_button {
  height: 2.1875vw;
  width: 9.53125vw;
  font-size: 0.9375vw;
  border: 0.1vw solid #1c477a;
  background-color: #1c477a;
  color: #ffffff;
  margin-top: 0.9375vw;
  margin-left: 1.5625vw;
  border-radius: 0.2vw;
}
.cross_close_bt_main_div{
  height: 1.2vw;
  width: 1.2vw;
  margin-right: -5vw;
  margin-top: -1vw;
}
.labels_in_change_password::after {
content: "*";
color: red;
font-size: 1.2vw;
}
@media (max-width: 768px) {
  .changepassword_sub_div {
    height: 84vw;
    width: 78vw;
  }
  .changepassword_heading_div {
    height: 13vw;
  }
  .changepassword_text {
    font-size: 3.5vw;
    font-weight: 501;
    color: #263659;
  }
  .labels_in_change_password {
    font-size: 3vw;
    margin-top: 2.5vw;
  }
  .inputs_change_password {
    height: 7vw;
    width: 95%;
    margin-top: 2vw;
    border-radius: 0.9vw;
  }
  .inputs_change_password input {
    font-size: 3vw;
  }
  .inputs_change_password input::placeholder {
    color: #8b93a5;
    font-size: 2.8vw !important;
    font-weight: 400 !important;
  }
  .cancel_button_changepassword {
    height: 10vw;
    width: 30vw;
    font-size: 5vw;
    margin-top: 4vw;
    border-radius: 1vw;
  }
  .changepassword_save_button {
    height: 10vw;
    width: 30vw;
    font-size: 5vw;
    margin-top: 4vw;
    margin-left: 14vw;
    border-radius: 1vw;
  }
  .changepassword_text{
    margin-right: 12vw;
  }
  .labels_in_change_password::after {
    content: "*";
    color: red;
    font-size: 3vw;
    }
    .cross_close_bt_main_div{
      height: 3vw;
      width: 3vw;
      margin-right: -15vw;
      margin-top: -1vw;
    }
}
@media screen and (max-width: 1180px) and (max-height:820px) {
  
}
}
