.adduser_background_main_div{
    height:100vh;
    width: 100%;
    background-color: rgba(19, 19, 19, 0.50);
    position: fixed;
    top: 0%;
    right: 0%;
    z-index: 1040;
}
.add_user_pop_up_maindiv{
    overflow: scroll;
    background: #FFFFFF;
    width: 46.354vw;
    height: 29.6vw;
    position: absolute;
    top:11vw;
    left:25vw;

}
.add_user_modal_head{
    padding: 1.15vw;
    border-bottom: 0.10416666666666667vw solid black; 
}
.add-user_popup_modal_body{
    background: #E8ECF1;
    width: 45.677vw;
height: 2.135vw;
margin-left:6px;
margin-top:0.52vw
}
.add_user_modal_head span{
    color: #263659;
font-family: Montserrat;
font-size: 1.1458vw;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
justify-content: center;

}
.add-user_popup_modal_body{
    margin-top: 0.52vw;

}
.add-user_popup_modal_body span{
    color: #263659;
    font-family: Montserrat;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    left:0.88854vw;
    /* top:0.52vw; */
}

.voter_label_div{
    margin-top:1.04166vw;
    margin-left:0.041vw;
    /* width: 21.51vw;
    height:2.65vw; */
}
label {
    color: #263659;
    font-family: Montserrat;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
.voter_id_number_div{
    margin-top:2vw;
}
.voter_id_number_div_input_filed{
    width: 20vw;
height: 2.6565vw;
background-color: #F3F7F9;
border:none;
border-radius: 5px;
margin-top: 0.4vw;
}

 .add_user_underline_block{
    margin-top:1.25vw;
 }
.add_user_underline{
    border: 1px solid #CBCBCB;
}
.add_user_buttons{
    padding:0.9375vw;
    display: flex;
    justify-content: end;
    /* position: relative; */
    margin-right:20px;
}
.add_user_cancel_button{
    color: #1C477A;
    font-family: Montserrat;
    font-size:0.9375vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
padding: 0.52vw 1.041vw;

border-radius: 4px;
border: 1px solid #1C477A;
background: #FFF;
position: relative;
right:20px;

}
.add_user_save_button{
    padding: 0.52vw 1.041vw;
 
    border-radius: 4px;
    background: #1C477A;
    color: #FFF;
font-family: Montserrat;
font-size:0.9375vw;
font-style: normal;
font-weight: 500;
line-height: normal;
border: none;
}
#dropdown-basic{
    margin-top:0vw;
    width: 20vw;
}


@media screen and (max-width:767px){

    .adduser_background_main_div{
        /* height:100vh;
        width: 100%; */
        position: fixed;
        background-color: rgba(19, 19, 19, 0.50);
        margin-top: 15vw;
    }
    .add_user_pop_up_maindiv{
        background: #FFFFFF;
        width: 91.66vw;
        height: 137vw;
        position: absolute;
        top:12vw;
        left:4.166vw;
    }
    .add_user_modal_head{
        padding: 5vw;
    }
    .add_user_modal_head span{
        color: #263659;
    font-family: Montserrat;
    font-size: 5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* display: flex;
    justify-content: center;
     */
    }
    .add-user_popup_modal_body{
        background: #E8ECF1;
        width: 91.66vw;
    height: 11.388vw;
    margin-top:1.11vw;
    margin-left:-0vw;
    }
    .add-user_popup_modal_body{
        margin-top: 1.52vw;
    
    }
    .add-user_popup_modal_body span{
        color: #263659;
        font-family: Montserrat;
        font-size: 5vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: relative;
        left:6.38888vw;
        top:2.6vw;
    }

    .voter_label_div{
        /* margin-top:5.633vw; */
        width: 60vw;
        margin-left:0.041vw;
    }
    .add_user_child_div{
        margin-top:6.633vw;
        margin-left: 2vw;
    }
    .add_user_childs_div{
        margin-top:2.625vw;
        margin-left: 2vw;
    }
    label {
        color: #263659;
        font-family: Montserrat;
        font-size:4.444vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    .voter_id_number_div{
        /* margin-top:2vw; */
    }
    .voter_id_number_div_input_filed{
        width: 83.333vw;
    height:11.111vw;
    background-color: #F3F7F9;
    border:none;
    border-radius: 5px;
    }
    .new_voter_dropdown {
        /* width: 50px !important; 
        background-color: red; */
      }
      .add_user_underline_block{
        margin-top: 8.333vw;
      }
      .add_user_buttons{
        /* margin-top: 5.555vw; */
        width:90vw;
        position: relative;
        left: 3vw;
        padding: 4vw;
        justify-content: space-around;
        /* margin-bottom: 5.55vw; */
      }
      .add_user_cancel_button{
        /* display: none; */
        border-radius: 4px;
        border: 1px solid #1C477A;
        background: #FFF;
        color: #1C477A;
        font-family: Montserrat;
        font-size: 4.4444vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width:40w;
        height:11.11vw;
        padding: 10px 30px;
        

      }
      .add_user_save_button{
        /* display: none; */
        font-family: Montserrat;
        font-size: 4.4444vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width:40w;
        height:11.11vw;
        padding: 10px 42px;

      }

}

@media screen and (max-width: 1024px) and (min-width: 768px){
    .add-user_popup_modal_body span{
        position: relative;
        left:0.88854vw;
        bottom:0.7vw;
    }
    .voter_label_div{
        margin-top:0vw;        
    } 
    .add_user_underline{
        border: 1px solid #CBCBCB;
    }
}