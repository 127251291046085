.addwardvillageissues_main_div {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: #14141480;
  z-index: 1040;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addissues_mainbox_div {
  height:fit-content;
  width: 37vw !important;
  background-color: #ffffff;
  overflow: auto;
}
.dropdown_top_Div_addissues {
  background-color: #f3f7f9;
  width: 15.4625vw;
  height:2.656vw;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-left: 1.7708333333333333vw;

}
.ward_village_add_pop_up_title_name {
  color: #263659;
  font-family: "Montserrat";
  font-size:0.9375vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 2vw;

}
.dropdownflex_div {
  position: relative;
  margin-top: 1.28125vw !important;
}
.add_isues_popup_label_names{
  color: #263659;
  font-family: "Montserrat";
  font-size:0.9375vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 2vw;
  padding-bottom:0.4vw;
}
.add_issues_important_symbol{
  color:red !important;
  font-size: 1vw;
  font-weight: bold;
}
.add_ward_village_issues_add_pop_up_title {
  color: #263659;
  text-align: center;
  font-family: "Montserrat";
  font-size: 1.145833vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  padding: 1.5625vw !important;
  width: 100%;
  border-bottom: 0.1vw solid black;
}
.add_ward_village_issues_add_pop_up_underline {
  background: #000;
  height: 1px;
}
.ward_village_add_dropdown_arrow {
  height: 0.6291666666666667vw;
  width: 0.7291666666666666vw;
  position: relative;
  overflow: hidden;
}
.dropdown_bottom_Div_addissues {
  top: 100%;
  z-index: 51;
  overflow-y: scroll;
  background-color: #ffffff;
  box-shadow: 0.1vw 0.1vw 0.5vw black;
  border-radius: 0.5vw;
  z-index: 50;
  border: none;
  width: 15.5vw;
  height: 10vw;
  margin-left: 2vw;
  position: absolute;
  z-index: 50;
  margin-top: 0.5vw;
}
.ward_village_add_dropdonw_addbutton {
  width: 100%;
  height: 3vw;
  border: 0.1vw solid #ccd0d6;
}
.ward_village_add_dropdown_addbutton_drops_list {
  margin-top: 0.9vw;
}
.ward_village_add_filter_constiency_checkbox {
  margin-left: 0.5vw;
}
.ward_village_add_filter_constiency_api_data {
  margin-left: 1vw;
  font-size: 1vw;
  width: 70%;
  overflow: hidden;
}
.ward_village_add_input_filed_dropdown {
  width: 33vw;
  height: 2.6565vw;
  border: 1px solid #d8d8d8 !important;
  margin-left: 2vw;
  margin-top: 2vw;
  background-color: #f3f7f9 !important;
}
.ward_village_add_input_filed_description_dropdown {
  width: 33vw;
  height: 9.11458vw;
  border: 1px solid #d8d8d8 !important;
  margin-left: 2vw;
  margin-top: 2vw;
  background-color: #f3f7f9 !important;
}
.ward_village_issues_footer {
  border: 1px solid #cbcbcb;
  height: 1px;
  margin-top: 2vw;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: end;
  margin-bottom:4vw !important
  /* margin-right: 40px; */
}

.ward_village_issue_cancel_button {
  color: #1c477a;
  font-family: "Montserrat";
  font-size: 0.9375vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #1c477a;
  background: #fff;
  position: relative;
  right: 20px;
  width: 6vw;
  height: 2.5vw;
  margin-top: 1vw;
  margin-right:40px;
}

.ward_village_issue_save_button {
  border-radius: 4px;
  background: #1c477a;
  color: #fff;
  font-family: "Montserrat";
  font-size: 0.9375vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  width: 6vw;
  height: 2.5vw;
  margin-top: 1vw;
  margin-right:32px;

}

.ward_villages_text_pattern {
  margin-left: 10%;
  position: relative;
  font-size: 1vw;
}
.ward_villages_text_title_pattern {
  margin-left: 6%;
  position: relative;
  top: 1vw !important;
  color: #263659;
  font-family: "Montserrat";
  font-size: 0.9375vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.wards_village_pop_up_error_msg{
    color:red;
    margin-left:12%;
    font-size: 1vw;
}
.wards_village_pop_up_error_msg_title{
    color:red;
    margin-left:6%; 
    font-size: 1vw;
 
}
.wards_village_pop_up_error_msg_description{
    color:red;
    margin-left:6%;
    justify-content: center;
    align-items: center;
    font-size: 1vw;

}

.ward_village_issues_dropdown_arrow{
    height:0.5vw;
    width: 0.8vw;
    position: absolute;
    right: 5%;
}


@media screen and (max-width:767px){
  .addissues_mainbox_div {
    height: 83.33vw;
    width: 91.66vw !important;
    background-color: #ffffff;
    overflow: auto;
  }
  .add_ward_village_issues_add_pop_up_title {
    color: #263659;
    text-align: center;
    font-family: "Montserrat";
    font-size: 5.145833vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    padding: 4.752777777777778vw !important;
    width: 100%;
    border-bottom: 0.1vw solid black;
}

.dropdown_top_Div_addissues {
  background-color: #f3f7f9;
  width: 83.33333333333333vw;
  height:11.11111111111111vw;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-left: 3.33vw !important;
  margin-top: 2.5vw;

}
.add_issues_column{
  flex-direction: column;
}
.add_isues_popup_label_names {
  color: #263659;
  font-family: "Montserrat";
  font-size: 4.444444444444445vw;
  margin-left: 3.33vw !important;
  margin-top:3.3333333333333335vw

}
.ward_villages_text_pattern {
  margin-left: 10%;
  position: relative;
  font-size: 3.5vw;
}
.add_issues_important_symbol {
  color: red !important;
  font-size: 5vw;
}
.ward_village_issues_dropdown_arrow {
  height: 3vw;
  width: 4vw;
  position: absolute;
  right: 10%;
}
.ward_village_add_input_filed_dropdown,.ward_village_add_input_filed_description_dropdown  {
  width: 83.33333333333333vw;
  height:11.11111111111111vw;
  border: 1px solid #d8d8d8 !important;
  margin-left: 3.33vw !important;
  margin-top: 2vw;
  background-color: #f3f7f9 !important;
}
.wards_village_popup_tiledescri{
 margin-top: 1.6vw;
}
.ward_villages_text_title_pattern {
  font-size: 3.88vw;
}
.wards_village_pop_up_error_msg{
  color:red;
  margin-left:6%;
  font-size: 2.7vw;
}
.wards_village_pop_up_error_msg_title{
  color:red;
  margin-left:5%; 
  font-size: 2.7vw;

}
.wards_village_pop_up_error_msg_description{
  color:red;
  margin-left:5%;
  justify-content: center;
  align-items: center;
  font-size: 2.7vw;

}
.ward_village_add_input_filed_description_dropdown::placeholder{
  font-size: 3vw !important;
  position: relative;
  top:4vw !important;
  left:2vw;
}
.ward_village_issues_footer {
  border: 1px solid #cbcbcb;
  height: 1px;
  margin-top: 3vw;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: end;
  margin-bottom:15vw!important
  /* margin-right: 40px; */
}

.ward_village_issue_cancel_button {
  color: #1c477a;
  font-family: "Montserrat";
  font-size: 4.444444444444445vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #1c477a;
  background: #fff;
  /* position: relative;
  right: 20px; */
  width: 35.55555555555556vw;
  height: 11.11111111111111vw;
  margin-top: 2vw;
  margin-right:6vw;
}

.ward_village_issue_save_button {
  border-radius: 4px;
  background: #1c477a;
  color: #fff;
  font-family: "Montserrat";
  font-size: 4.444444444444445vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  width: 35.55555555555556vw;
  height: 11.11111111111111vw;
  margin-top: 2vw;
  margin-right:4vw;

}

.todo_sroll_wardvillages {
  height: 65vw;
  overflow-y: scroll;
  margin-top: 0.5vw;
  width: 90%;
  margin-left: 4.6%;
}


}