.Sidenav_main_div {
  position: fixed;
  height: 100vh;
  background-color: #383b3c;
  width: 13.3vw;
  /* width: 18.8vw; */
  /* overflow: scroll; */
}
.navbar_scroll_div{
overflow-y: scroll;
height: 90vh;
margin-top: 2vw;
}

.modal_popup{
  margin-top: 25vw;
}
.ems_nav_logo_main_div {
  height: 4.166666666666667vw;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itoconnectLogo {
  height: 3.1770833333333335vw;
  /* width: 8.21875vw; */
  /* margin-left: 3.0625vw; */
  /* margin-top: 0.5208333333333334vw; */
}

.navigation_buttons_main_div {
  height: 3.6979166666666665vw;
  width: 100%;
  border-top: 0.1vw solid #7D7D7D;
  border-bottom: 0.1vw solid #7D7D7D;
  cursor: pointer;
  position: relative;
}

.navigation_buttons_main_div_two {
  height: 3.6979166666666665vw;
  width: 100%;
  border-top: 0.1vw solid #7D7D7D;
  border-bottom: 0.1vw solid #7D7D7D;
  cursor: pointer;
  position: relative;
}

.text_inside_navigation_btn_dashboard {
  font-size: 1vw;
  margin-left: 1.125vw;

  color: white;
}

.text_inside_navigation_btn_voterdata {
  font-size: 1vw;
  margin-left: 1.125vw;

  color: white;
}

.text_inside_navigation_btn_usermanagement {
  font-size: 1vw;
  margin-left: 1.125vw;

  color: white;
}

.text_inside_navigation_btn_ecamping {
  font-size: 1vw;
  margin-left: 1.125vw;

  color: white;
}

.sidenav_arrow {
  position: absolute;
  right: 0.6vw;
  color: #92A3B8;
  height: 1.6vw;
  width: 2vw;
  /* top: 6.8vw; */
}

.text_inside_navigation_btn_birthday {
  font-size: 1vw;
  margin-left: 3.125vw;
  color: white;
}
.settings_sub_divs_text {
  font-size: 1vw;
  color: white;
  margin-left: 0.6vw;
}
.settings_sub_divs_atag {
  padding-left: 2vw !important;
}

.text_inside_navigation_btn_settings {
  font-size: 1vw;
  margin-left: 3.125vw;
  color: white;
}

.respnav_closebtn_cross {
  display: none;
}

.resp_nav_logo {
  display: none;
}
.settings_sub_divs{
  height: 3.6979166666666665vw;
   width: 100%;
    text-decoration: "none" ;
    display: flex;
    align-items: center;
    position:relative;
}
::-webkit-scrollbar {
  display: none;
}
.settings_sub_divs_main{
  padding-left: 0 !important;
  }
  
#settings_sub_divs_main{
  /* display: none !important; */
}

.Sidenav_main_div{
  display: none !important;
  width: 10vw !important;
  background-color: red !important;
}
@media screen and (max-width:767px) {
  .importdata_sidnav_arrow{
    top: 4vw;
   }
  .modal_popup{
    margin-top: 55vw;
  }
  .settings_sub_divs_text {
    margin-left: 6vw;
  }
  .sidenav_arrow {
    right: 5.6vw;
    width: 7vw;
    height: 8vw;
  }
  .Sidenav_main_div {
    width: 71.6666666667%;
    height: 100vh;
    z-index: 5000;

  }

  .navigation_buttons_main_div {
    height: 15.277777777777779vw;
    margin-top: 0px;
  }

  .navigation_buttons_main_div_two {
    height: 15.277777777777779vw;
  }
  .settings_sub_divs{
    height: 15.277777777777779vw;

  }

  .text_inside_navigation_btn_dashboard {
    font-size: 5vw;
    margin-left: 6vw;
  }

  .text_inside_navigation_btn_voterdata {
    font-size: 5vw;
    margin-left: 6vw;
  }

  .text_inside_navigation_btn_usermanagement {
    font-size: 5vw;
    margin-left: 6vw;
  }

  .text_inside_navigation_btn_ecamping {
    font-size: 5vw;
    margin-left: 6vw;
  }

  .text_inside_navigation_btn_birthday {
    font-size: 5vw;
    margin-left: 6vw;
  }
.settings_sub_divs_text{
  font-size: 5vw;
}
  .text_inside_navigation_btn_settings {
    font-size: 5vw;
    margin-left: 6vw;
  }

  .ems_nav_logo_main_div {
    height: 19vw;
  }

  .itoconnectLogo {
    display: none;
  }

  .resp_nav_logo {
    display: block;
    height: 17.38888888888889vw;
  }

  .ems_nav_logo_main_div {
    background-color: #383B3C;
  }

  .respnav_closebtn_cross {
    display: block;
    height: 4vw;
    width: 4vw;
    position: absolute;
    right: 10%;
    cursor: pointer;
  }
}

#link_active .active {
  background-color: #1A1A1A;
  height: 100%;
  width: 100%;

}
.Sidenav_main_div {
  /* display: none; */
}

@media (min-width: 768px) and (max-width: 1024px) {

  .Sidenav_main_div {
    z-index: 5000;
    position: fixed;
    height: 100vh;
    background-color: #383b3c;
    width: 18.8vw;
    display: none !important;
    /* width: 18.8vw; */
    /* overflow: scroll; */
  }
}