.ems_login_page_main_div {
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 99.99%); 
    height: max-content !important;
  }
  
      .ems_login_logo_div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .login_emslogo {
        margin-top:7vh;
        height: 14.38888888888889vh!important;
      }
  .itoconnect_logoname span {
    color: #056b38;
    font-family: "Montserrat";
    font-size: 2.8vw!important;
    font-weight: 600;
  }

  .login_page_body_sub_div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ems_login_text {
    font-family: "Montserrat";
    width: fit-content;
    color: #0047b2;
    font-size: 3vw;
    font-weight: 700;
  }

  #href {
    text-decoration: none;
  }
  .login_page_body_main_div {
     min-height:76vh !important;
     margin-top:0vh;
  }
  .login_page_inputfields_{
    margin-top:5vh;

  }
  .login_email_input_div {
     width: 80vw;
     height: 5vw;
     display: flex;
     justify-content: center;
     align-items: center;
  }
  .login_page_input_fields {
    height: 5vw;
    width: 40vw !important;
    font-size: 1.888888888888889vw !important;
    background-color:#FFFFFF!important;
    outline: none;

  }
  .login_inputfileds_pwd{
    margin-top:2vh!important;
  }
  .login_page_input_fields::placeholder {
    font-size: 1.888888888888889vw!important;
  }
  .loginpage_login_button {
    width: 14vw;
    height: 4vw;
    font-size: 2.2vw !important;
    border-radius: 6px!important;
    background: #056b38;
    position: relative;
    left:34vw;
    top:2vw;
    font-weight: 600;
    color: #FFF;
    border: none!important;
  }
 
  .loginpage_forgetpassworddiv {
    font-family: "Montserrat";
    text-decoration: none;
    color: #056b38;
    font-size: 1.88vw;
    font-weight: 600;
  }

  .Donthaveanaccount_loginpage,
  .privacy_policy_text {
    font-size: 1.289vw;
    justify-content: center;
    display: flex;
  }
  .forgetpassword_anchortag_element u {
    text-decoration: none;
  }
  .privacy_policy_div {
    width: 16vw;
    position: relative;
    top: 4vh;
    left:2vw;
  }
  .Donthaveanaccount_loginpage {
    color: #666;
    font-weight: 600;
  }
  .privacy_policy_text {
    color: #056b38;
    font-size: 1.8vw !important;
    font-weight: 700;
    position: relative;
    top:2vw;
    left:1.4vw;

  }
 

  .eye {
    position: absolute;
    right: 33vw;
    margin-top:-.7vw!important;
  }
  .eye-open {
    height: 1.8vw;
    width: 2vw;
  }
  
  .eye-closed {
    height: 2.3vw;
    width: 2vw;

  }
  .forgetpassword_anchortag_element {
    text-decoration: none !important;
  }
  .error,
.errMessage {
  color: red;
  font-size: 1.3vw;
  font-family: "Montserrat";
  font-weight: 600;
}
.remeber_me_main_div{
  position: relative;
  width: 16vw;
  margin-top:1vw;
  background-color: red;
  margin:0vw;
  padding: 0;
  height:0vw !important;
  left:20vw;
}
.remember_me_checkbox{
  width:1.7vw !important;
  height:1.7vw !important;
}
.login_page_rememberme{
  font-size: 1.7vw;
line-height: 4.166666666666667vw;
font-weight: 600;
margin-left: 1.0770833333333334vw;
color: #263659;
position: relative;
bottom: 0.2vw;
}
#error_msg_login{
  color: red;
  font-size: 1.2vw;
  margin-left: 21vw!important;
  font-family: "Montserrat";
  font-weight: 600;
  width: 40vw;
}
.forgetpassword_anchortag_element{
  font-family: "Montserrat";
  text-decoration: none;
  color: #056b38;
  font-size: 1.289vw;
  font-weight: 600;
   text-decoration: none !important;
   position: relative;
   left:48vw;
   top:1vw;
}
  

@media (max-width:767px) {
    .ems_login_page_main_div {
        animation: login 1s linear;
        background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 99.99%);
        height: max-content !important;
      }
      
      .ems_login_logo_div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .login_emslogo {
        height: 12.38888888888889vh!important;
      }
  .itoconnect_logoname span {
    color: #056b38;
    font-family: "Montserrat";
    font-size: 5.2vw!important;
    font-weight: 600;
  }

  .login_page_body_sub_div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ems_login_text {
    font-family: "Montserrat";
    width: fit-content;
    line-height: 6.666vw;
    color: #0047b2;
    font-size: 6.944vw;
    font-weight: 700;
  }
 
  #href {
    text-decoration: none;
  }
  .login_page_body_main_div {
    height: 100vw!important;
     /* margin-top:5vh; */
     margin-top:1vh; 
  }
  .login_page_inputfields_{
    margin-top:5vh;
  }
  .login_email_input_div {
     width: 80vw;
     height: 11vw;
  }
  .login_page_input_fields {
    height: 11.11111111111111vw;
    width: 80vw !important;
    font-size: 3.888888888888889vw !important;
    background-color:#FFFFFF!important;
    outline: none;

  }
  .login_inputfileds_pwd{
    margin-top:2vh!important;
  }
  .login_page_input_fields::placeholder {
    font-size: 3.888888888888889vw !important;
  }
 

  .forgetpassword_anchortag_element{
    font-family: "Montserrat";
    text-decoration: none;
    color: #056b38;
    font-size: 3.889vw;
    font-weight: 600;
     text-decoration: none !important;
     position: relative;
     top:-0.5vw;
     left:44vw !important;
  }
  .loginpage_forgetpassworddiv {
    font-family: "Montserrat";
    text-decoration: none;
    color: #056b38;
    font-size: 3.889vw;
    font-weight: 600;
    margin-left: 0vw;
    position: relative;
    right: 20vw !important;
       text-decoration: none !important;
    background-color: green !important;
  }
  u{
    text-decoration: none !important;
  }

  .loginpage_login_button {
    width: 37vw;
    height: 11.5vw;
    font-size: 5vw !important;
    border-radius: 6px!important;
    background: #056b38;
    font-weight: 600;
    color: #FFF;
    border: none!important;
    position: relative;
    left:21vw;
    top:7vw;

  }
  .forgetpassword_anchortag_element u {
    text-decoration: none;
  }

  .privacy_policy_div {
    width: 49vw;
    display:flex;
   justify-content:center;
   position: relative;
   top:9.5vw;
  }
  .privacy_policy_text {
    color: #056b38;
    font-size: 4.444vw!important;
    position: relative;
    top:10vw;
    font-weight: 700 !important;
    left:0vw !important
  }
  .Donthaveanaccount_loginpage,
  .privacy_policy_text {
    font-size: 2.089vw;
  }
  .Donthaveanaccount_loginpage {
    font-size: 3.889vw;
    justify-content: center;
    display: flex;
    color: #666;
    font-weight: 600;
  }
  .eye {
    position: absolute;
    right: 17vw;
    margin-top:-2.5vw!important;
  }
  .eye-open {
    height: 2.9vw;
    width: 4.5vw;
  }
  
  .eye-closed {
    height: 5.2vw;
    width: 5vw;
  }
 
  .error,
.errMessage {
  color: red;
  font-size: 3vw;
  margin-left: 2vw;
  font-family: "Montserrat";
  font-weight: 600;
}

#error_msg_login{
  color: red;
  font-size: 3.2vw;
  margin-left: 1vw!important;
  font-family: "Montserrat";
  font-weight: 700;
  width: 38vw;
}

.remeber_me_main_div{
  position: relative;
  margin-left:0vw !important;
  width: 37vw!important;
  background-color: red !important;
  position: relative;
  left:0vw;
  width: auto !important;
}
.remember_me_checkbox{
  width:4vw !important;
  height:4vw !important;
}
.login_page_rememberme{
  font-size: 4vw;
line-height: 4.166666666666667vw;
font-weight: 600;
margin-left: 1.6770833333333334vw;
color: #263659;
position: relative;
top:-0.5vw;

}
  
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .ems_login_page_main_div {
        animation: login 1s linear;
        background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 99.99%);
        height: max-content!important;
      }
      
      .ems_login_logo_div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .login_emslogo {
        margin-top:7vh;
        height: 12.38888888888889vh!important;
      }
  .itoconnect_logoname span {
    color: #056b38;
    font-family: "Montserrat";
    font-size: 5.2vw!important;
    font-weight: 600;
  }

  .login_page_body_sub_div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ems_login_text {
    font-family: "Montserrat";
    width: fit-content;
    color: #0047b2;
    font-size: 5.944vw;
    font-weight: 700;
  }

  #href {
    text-decoration: none;
  }
  .login_page_body_main_div {
     min-height:100vw !important;
     margin-top:0vh;
  }
  .login_page_inputfields_{
    margin-top:5vh;

  }
  .login_email_input_div {
     width: 80vw;
     height: 11.11vw;
  }
  .login_page_input_fields {
    height: 11.11vw;
    width: 80vw !important;
    font-size: 3.888888888888889vw !important;
    background-color:#FFFFFF!important;
    outline: none;

  }
  .login_inputfileds_pwd{
    margin-top:2vh!important;
  }
  .login_page_input_fields::placeholder {
    font-size: 3.888888888888889vw!important;
  }
  .loginpage_login_button {
    width: 25vw;
    height: 8vw;
    font-size: 4vw !important;
    border-radius: 6px!important;
    background: #056b38;
    font-weight: 600;
    color: #FFF;
    border: none!important;
    position: relative;
    top:4vw;
    left:27.5vw;
  }
 
  .loginpage_forgetpassworddiv {
    font-family: "Montserrat";
    text-decoration: none;
    color: #056b38;
    font-size: 3vw !important;
    font-weight: 600;
  }
  .forgetpassword_anchortag_element{
    font-family: "Montserrat";
    text-decoration: none;
    color: #056b38 !important;
    font-size: 3.3vw;
    font-weight: 600;
     text-decoration: none !important;
     position: relative;
     left:49.6vw;
     top:-0.5vw;
  }

  .Donthaveanaccount_loginpage,
  .privacy_policy_text {
    font-size: 2.7vw;
    display: flex;
  }
  .forgetpassword_anchortag_element u {
    text-decoration: none;
  }

  .Donthaveanaccount_loginpage {
    color: #666;
    font-weight: 600 !important;
  }
 
  .privacy_policy_div {
    width: 33vw;
    margin-top: 1vh;
    left:1vw;
  } 
  .singup_redirect_loginPage{
    position: relative;
    top:6vw;
    left:1vw;
  }
  .privacy_policy_text {
    color: #056b38;
    font-size: 3.3vw !important;
    font-weight: 700;
    position: relative;
    top:0vw;
    left:0vw;
  }

  .eye {
    position: absolute;
    right: 14vw;
  }
  .eye-open {
    height: 2.7vw;
    width: 4.2vw;
  }
  .eye-closed {
    height: 4.2vw;
    width: 4.4vw;
  }
  .forgetpassword_anchortag_element {
    text-decoration: none !important;
  }

  .error,
.errMessage {
  color: red;
  font-size: 3vw;
  margin-left: 2vw;
  font-family: "Montserrat";
  font-weight: 600;
}
.remeber_me_main_div{
  position: relative;
  width:35vw;
  width: auto !important;
  left:0vw;
}
.remember_me_checkbox{
  width:3.5vw !important;
  height:3.5vw !important;

}
.login_page_rememberme{
   font-size: 3.3vw;
line-height: 4.166666666666667vw;
font-weight: 600;
margin-left: 1.6770833333333334vw;
color: #263659;
position: relative;
bottom: .25vw !important;
}
#error_msg_login{
  color: red;
  font-size: 2vw;
  margin-left: 1vw!important;
  font-family: "Montserrat";
  font-weight: 600;
  width: 40vw;
}
  
}


