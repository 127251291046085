.add_voters_popup_main_div {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(13, 13, 13, 0.5);
  z-index: 1040;
  width: 100vw !important;
}
.asterisk_newvoter {
  color: red;
  font-size: 0.5vw;
  margin-left: 0.4vw;
}

.add_voters_popup_scroll_div {
  position: fixed;
  left: 15%;
  height: 80%;
  width: fit-content;
  overflow-y: scroll;
  overflow-y: none;
  opacity: 1;
  z-index: 2000;
  top: 5vw;
}

.add_voters_popup_scroll_div::-webkit-scrollbar {
  display: none;
}

.add_voters_popup_sub_div span {
  font-weight: 800;
  font-family: "Montserrat";
  font-size: 1.1458333333333333vw;
}

.add_voters_popup_sub_div {
  width: 72.96875vw;
  height: 113.979167vw;
  background-color: white;
  position: relative;
}

.add_voters_popup_sub_div span {
  margin-left: 33.489583333333336vw;
  margin-top: 1.5625vw;
  color: #263659;
}

.popup_newvotrs_underline_div {
  width: 95%;
  height: 0.1vw;
  margin-left: 2.5%;
  margin-top: 1.1458333333333333vw;
  background-color: #000000;
}

.popup_new_voter_informtion_div {
  width: 95%;
  margin-left: 2.5%;
  height: 2.1354166666666665vw;
  background-color: #e8ecf1;
  margin-top: 0.3125vw;
  font-size: 0.9375vw;
  font-family: "Montserrat";
  font-weight: 500;
  position: relative;
}

.popup_new_voter_informtion_div p {
  position: absolute;
  top: 0.5vw;
  left: 0.9375vw;
}

.information_details_main_div_newvoter {
  width: 21.510416666666668vw;
  position: relative;
  height: fit-content;
  margin-left: 2.0708333333333333vw;
}

.voter_info_new_voter_main {
  width: 100%;
  margin-top: 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375vw;
  line-height: 1.1458333333333333vw;
  color: #263659;
  height: 4vw;
}

.new_voter_data_input {
  background-color: #f3f7f9;
  text-align: center;
  height: 2.65625vw;
  border-radius: 0.1vw;
  margin-top: 0.3645833333333333vw;
}

.new_voter_data_input input {
  width: 94%;
  height: 100%;
  border: none;
  background-color: #f3f7f9;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375vw;
  line-height: 1.1458333333333333vw;
  color: #263659;
  font-family: "Montserrat";
}

.new_voter_data_input input:focus {
  outline: none;
}

#dropdown-basic {
  width: 21.4vw;
  height: 2.65625vw;
  background-color: #f3f7f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8b93a5;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375vw;
  line-height: 1.1458333333333333vw;
  border: none;
  color: #8b93a5 !important;
  margin-top: 8px;
  /* overflow: hidden; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new_voter_add_info {
  width: 100%;
  background-color: #aab7c8;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375vw;
  line-height: 1.145833333333333vw;
  color: #263659;
  padding: 9px;
  margin-top: 26px;
}

.buttons_holder_div_addvoters {
  position: absolute;
  bottom: 1%;
  background-color: #e2e4e8;
  height: 4.010416666666667vw;
  left: 1.7708333333333333vw;
  margin-top: 10vw;
  width: 69.27083333333333vw;
}

.add_voters_popup_cancle_btn {
  height: 2.1875vw;
  width: 4.322916666666667vw;
  font-size: 0.9375vw;
  font-weight: 800;
  font-family: "Montserrat";
  border-radius: 0.20833333333333334vw;
  background-color: #ffffff;
  cursor: pointer;
  border: none;
}

.add_voters_popup_savenew_btn {
  width: 7.614583333333333vw;
  height: 2.1875vw;
  background-color: #ffffff;
  font-size: 0.9375vw;
  font-weight: 800;
  font-family: "Montserrat";
  margin-left: 1.0416666666666667vw;
  border-radius: 0.20833333333333334vw;
  cursor: pointer;
  border: none;
}

.add_voters_popup_save_btn {
  width: 3.28125vw;
  height: 2.1875vw;
  margin-left: 1.0416666666666667vw;
  background-color: #1c477a;
  font-size: 0.9375vw;
  color: #ffffff;
  font-weight: 800;
  font-family: "Montserrat";
  border-radius: 0.20833333333333334vw;
  cursor: pointer;
  border: none;
}

.add_picture_main {
  height: 8.958333333333333vw;
  width: 8.958333333333333vw;
  margin: auto;
  margin-top: 45px;
  background: #ffffff;
  border: 1px solid #99a3be;
  border-radius: 1.1111111111111112vw;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
  position: inherit;
}

.add_picture_main img {
  height: 5.972395833333333vw;
  width: 5.972395833333333vw;
}

.add_picture_sub {
  height: 1.614583333333333vw;
  width: 8.758333333333333vw;
  background-color: #e2e4e8;
  border-radius: 0px 0px 1.1111111111111112vw 1.1111111111111112vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8333333333333333vw;
  line-height: 1.041666666666667vw;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admindashboard_dropdown_arrow {
  height: 0.6291666666666666vw;
  width: 0.7291666666666666vw;
  position: absolute;
  left: 90%;
}
.pollingstationName_newvoters {
  /* background-color: #f3f7f9; */
  background-color: #f3f7f9;
  height: 2.8vw;
  text-align: center;
  margin-top: 1vw;
  border-radius: 0.2vw;
  overflow: hidden;
}
.pollingstation_div {
  position: absolute;
  color: black;
  z-index: 2000;
  height: 10vw;
}

.dropdown-menu {
  max-height: 8vw;
  overflow-y: scroll;
}

.Addvoters_popup_scroll_holder_div {
  position: fixed;
  height: fit-content;
  width: fit-content;
  z-index: 3000;
}
input::-webkit-input-placeholder {
  /* width: 94%;
  height: 100%; */
  border: none;
  /* background-color: #f3f7f9; */
  font-size: 1vw !important;
  font-weight: 600;
  font-family: "Montserrat";
  /* padding-left: 0.8vw !important;   */
}
.dropdown_text_newVoters {
  font-size: 0.7291666666666666vw;
  font-family: "Montserrat";
  border-bottom: 0.1vw solid #d9d8d8;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: "textfield";
}

.voteriderror {
  color: red !important;
  font-size: 0.8375vw !important;
  margin-right: 14.3vw;
}
.validationErrorsdropdown {
  color: red !important;
  font-size: 0.8375vw !important;
  margin-right: 6.1vw;
  white-space: nowrap;
}
#housenumber_newvoter {
  margin-right: 10.6vw;
  white-space: nowrap !important;
}
.firstnameerror {
  color: red !important;
  font-size: 0.8375vw !important;
  margin-right: 13.3vw;
}
.middlename_error {
  color: red !important;
  font-size: 0.8375vw !important;
  margin-right: 13.8vw;
}
.lastnameerror {
  color: red !important;
  font-size: 0.8375vw !important;
  margin-right: 13.4vw;
}
.voterAddedpopup_newvoters_page {
  position: fixed;
  height: 12vw !important;
  width: 80vw !important;
  background-color: #f3f7f9 !important;
  color: #1c477a !important;
  font-size: 0.9375vw !important;
  border-radius: 0.7vw !important;
  font-size: 4vw !important;
  left: 10vw;
  z-index: 2001;
  /* top: -5vw; */
  top: 0vw;
  box-shadow: 0.5vw 0.5vw 1vw 0vw black;
  font-weight: 700 !important;
}

@keyframes voteradded {
  0% {
    top: -5vw;
  }
  30% {
    top: 1vw;
  }
  50% {
    top: vw;
  }
  70% {
    top: 1vw;
  }
  100% {
    top: -5vw;
  }
}
.newvoters_text_overflow_elipsis {
  background-color: red;
}

@media (max-width: 767px) {
  .popup_newvotrs_underline_div {
    background: none;
  }
  .input_search {
    width: 83.5833333333332vw !important;
    height: 10.5916666666666665vw !important;
    background-color: #f3f7f9;
    outline: none;
    font-size: 0.7291666666666666vw !important;
    font-family: "Montserrat" !important;
    font-weight: 501 !important;
    color: #4d7093 !important;
    border: 0.052083333333333336vw solid #d8d8d8;
    border-radius: 0.2vw;
    font-size: 4.7291666666666666vw !important;
  }
  #input_search_ {
    font-size: 3.7291666666666666vw !important;
    background: #e4f0ff !important;
    color: #1c477a !important;
    font-weight: 600 !important;
  }
  #input_search_::placeholder {
    font-size: 4.7291666666666666vw !important;
    /* color:red !important; */
    color: #1c477a !important;
    font-weight: 600 !important;
  }

  .btn {
    margin-left: 0 !important;
  }
  .voter_info_new_voter_main {
    height: 15vw;
    /* margin-top: 7.5vw !important; */
  }
  #dropdown-basic {
    background: url("../Assets/downarrow.png") #f3f7f9;
    background-repeat: no-repeat;
    background-position: right 3.5vw center;
    width: 83.33333333333333vw !important;
    height: 11.11111111111111vw !important;
  }
  .voteriderror {
    font-size: 2.811111111111111vw !important;
    /* margin-right: 56.4vw!important; */
    width: 25vw;
    margin-top: -2.7vw;
    font-weight: 600;
  }
  .validationErrorsdropdown {
    font-size: 2.811111111111111vw !important;
    /* margin-right: 30.9vw !important; */
    width: 25vw;
    margin-left: 1vw;
    font-weight: 600;
  }
  #housenumber_newvoter {
    margin-right: 45.9vw !important;
  }
  .firstnameerror {
    font-size: 2.811111111111111vw !important;
    margin-right: 54.9vw !important;
    white-space: nowrap;
    margin-top: -2vw;
  }
  .middlename_error {
    font-size: 2.811111111111111vw !important;
    margin-right: 56.9vw !important;
    width: 30vw;
  }
  .lastnameerror,
  #phone_error,
  #housenumber_newvoter {
    font-size: 2.811111111111111vw !important;
    /* margin-right: 55.3vw  !important; */
    width: 28vw;
    margin-top: -2vw;
  }
  .dropdown-menu {
    max-height: 12vw !important;
  }
  #new_voter_dropdown_gender .dropdown-menu {
    min-height: 23vw !important;
  }
  #new_voter_dropdown_ .dropdown-menu {
    min-height: 10vw !important;
    overflow-y: auto;
  }
  .dropdown_text_newVoters {
    font-size: 5vw;
  }
  .information_details_main_div_newvoter {
    margin-left: 7.6111vw !important;
  }
  .add_voters_popup_scroll_div {
    width: 91.66666666666667vw !important;
    left: 4vw;
    height: 100vh;
    left: 0vw;
    width: 100vw !important;
    top: 0vw !important;
    /* background-color: #DFDFDF; */
  }
  .voter_info_new_voter_main_education {
    margin-bottom: 8vw !important;
  }
  .add_voters_popup_sub_div {
    /* width: 91.66666666666667vw; */
    height: 75vh;
    height: 100vh;
    width: 100vw !important;
  }
  .main_flex_component {
    display: block !important;
    height: 77vh;
    height: 86vh;
    height: 100vh !important;
    overflow: scroll;
    margin-bottom: 16vw !important;
    background: #dfdfdf;
  }
  .popup_new_voter_informtion_div {
    margin-left: 0px;
    margin-bottom: 10vw;
    display: none;
  }
  .popup_new_voter_informtion_div p {
    width: 91.66666666666667vw;
    padding-top: 2.3vw;
    padding-left: 6.388888888888889vw;
    left: 0;
    height: 11.11111111111111vw !important;
    background-color: #e8ecf1;
    font-size: 4.444444444444445vw !important;
    line-height: 5.555555555555555vw !important ;
  }
  .new_voter_data_input {
    width: 83.33333333333333vw !important;
    height: 11.11111111111111vw !important;
    padding-left: 0.8vw;
    margin-top: 2vw;
  }

  .new_voter_add_info,
  .voter_info_new_voter_main,
  .new_voter_data_input input {
    font-size: 4.44444444444445vw !important;
    line-height: 5.555555555555555vw !important ;
    width: 83.33333333333333vw !important;
    color: #444;
    font-weight: 700;
  }
  .voter_info_new_voter_main {
    margin-top: 9vw;
  }
  .add_voters_popup_sub_div span {
    font-size: 4.444444444444445vw !important;
    line-height: 5.555555555555555vw !important ;
    margin-top: 3vw !important;
    margin-bottom: 2vw !important;
    /* background-color: red !important; */
  }
  .add_poling {
    /* background-color: red !important; */
    /* padding: 0vw !important; */
    /* margin-left: 0vw !important;
    width: 70vw !important;
    height: 5vw !important;
    overflow-wrap: normal !important;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden !important; */
    font-size: 2vw !important;
    color: #444444 !important;
  }
  .title_voters_add {
    /* font-size: 4.444444444444445vw !important;
    line-height: 5.555555555555555vw !important ;
    margin-top:5vw!important;
    margin-bottom: 2vw !important; */
  }
  #dropdown-basic,
  input::-webkit-input-placeholder {
    font-size: 3.9333333333333335vw !important;
    /* line-height: 4.466666666666667vw; */
  }
  .dropdown_text_newVoters {
    color: #263659;
    font-weight: 500;
  }
  #dropdown_text_newVoters_ {
    font-size: 4vw;
    /* height: 13vw; */
    font-weight: 600 !important;
    hyphens: auto !important;
    overflow-wrap: normal !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
    word-break: break-all !important;
  }
  .new_voter_popup_drop_div_address_pollingstation::placeholder {
    background-color: red !important;
    color: red !important;
  }
  .custom-dropdown-toggle {
    color: red !important;
    background: green !important;
  }

  .no_data_drop_down {
    color: #263659 !important;
    font-weight: 600 !important;
  }

  .asterisk_newvoter {
    color: red;
    font-size: 2vw;
    margin-left: 1.4vw;
  }
  .add_picture_main {
    width: 47.777778vw;
    height: 47.777778vw;
    margin-left: 18vw;
    /* margin-bottom: 24vw; */
    margin-bottom: 5.55vw;
    margin-top: 4.1666vw;
  }
  .add_picture_main img {
    width: 31.852777777777778vw;
    height: 31.852777777777778vw;
  }
  .add_picture_sub {
    font-size: 4.444444444444445vw !important;
    line-height: 5.555555555555555vw !important ;
    width: 47.666667vw;
    height: 8.61111111111111vw;
    color: #444;

    font-weight: 600;
  }
  .buttons_holder_div_addvoters {
    height: 15.555555555555555vw;
    height: 44vw;
    width: 100%;
    left: 0;
    bottom: 0%;
    /* background-color: red; */
    /* flex-direction: column; */
  }
  .add_voters_popup_cancle_btn {
    width: 24.333333vw;
    height: 11.111111vw;
    background: #ffffff;
    border: 1px solid #1c477a;
    border-radius: 1.1111111111111112vw;
    font-size: 4.444444444444445vw !important;
    line-height: 4.555555555555555vw !important ;
    font-weight: 600 !important;
    color: #1c477a;
    border: 1px solid #fff;
  }

  .buttons_holder_div_addvoters_view_voters {
    height: 16vw !important;
    display: flex;
    justify-content: space-evenly;
  }
  .add_voters_popup_savenew_btn {
    width: 32.166667vw;
    height: 11.111111vw;
    background: #ffffff;
    border: 1px solid #1c477a;
    border-radius: 1.1111111111111112vw !important;
    font-size: 4.444444444444445vw !important;
    line-height: 4.555555555555555vw !important ;
    font-weight: 600 !important;
    color: #1c477a;
    position: relative;
    left: 13vw !important;
    /* margin-bottom: 2vw;; */
    margin-top: 1vw;
    border: 1px solid #fff;
  }
  .add_voters_popup_save_btn {
    width: 21.333333vw;
    height: 11.111111vw;
    background: #1c477a;
    border-radius: 1.1111111111111112vw;
    font-size: 4.444444444444445vw !important;
    line-height: 4.555555555555555vw !important ;
    font-weight: 500 !important;
    color: #fff;
    margin-bottom: 2vw;
    position: relative;
    right: 15.5vw !important;
  }

  #voter_info_new_voter_main_voter_id {
    background-color: red;
  }
  .welcome {
    background-color: green;
  }
}
.dropdown-toggle::after {
  font-size: 1.3vw;
}

.input_search {
  width: 21.320833333333332vw;
  height: 2.4916666666666665vw;
  background-color: #f3f7f9;
  outline: none;
  position: relative;
  top: -8px;
  font-size: 0.7291666666666666vw !important;
  font-family: "Montserrat" !important;
  font-weight: 501 !important;
  color: #4d7093 !important;
  border: 0.052083333333333336vw solid #d8d8d8;
  border-radius: 0.2vw;
}
.input_search::placeholder {
  position: relative;
  left: 13px !important;
}

@media (max-width: 767px) {
  .view_voters_data_popup_main_div {
    position: fixed;
    left: 0%;
    width: fit-content;
    opacity: 1;
    z-index: 2000;
   bottom: 0vw!important;
    background-color: #8b93a5;
    border-radius: 20px 20px 0px 0px;
    background: #fff;
    height: 80vw;
    height: 50%;
    height: fit-content !important;
    /* overflow-y:scroll !important; */
    width: 100vw;
    animation: delay 2s ease-in-out;
  }

  @keyframes delay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .popup_newvotrs_underlines_1 {
    width: 100vw;
    margin-left: 0vw;
    height: 0.1vw;
    border: 1px solid #ccd0d6;
  }
  .view_voters_popup_sub_div_bottom {
    margin: 4.16vw;
    display: flex;
    justify-content: space-between;
    height: 5vw;
  }
  .view_voters_popup_sub_div_bottom span {
    font-size: 4vw;
    color: #263659;
    font-size: 4.4vw;
    font-weight: 600;
  }
  .view_voters_voter_id_display {
    /* background-color: red; */
    width: 30vw !important;
  }
  .pencil_icon_view_voters_ {
    color: #1c477a !important;
    font-size: 5vw;
    margin-left: 25vw;
  }
  .view_voters_close_icon img {
    /* font-size: 5vw;
     color: #76787a; 
     font-weight: 700; */
    /* margin-right:3vw; */
  }
  .cross_img_ {
    height: 5vw;
    width: 5vw;
    margin-right: 4.16vw;
    margin-top: 1vw;
  }
  .popup_view_voters_main_content_div {
    display: flex;
    margin-top: 2vw;
    margin-left: 5.5vw;
    width: 100%;
    /* background-color: red; */
    /* height:16.11vw; */
  }

  .view_voters_first_name {
    color: #606b83;
    font-size: 3.888vw;
    font-weight: 600;
    width: 38vw;
  }
  .view_voters_first_name_name {
    color: #606b83;
    font-size: 3.8vw;
    font-weight: 600;
  }
  .view_voters_last_name {
    color: #606b83;
    font-size: 3.888vw;
    font-weight: 600;
    margin-left: 13vw;
    /* background-color: gainsboro; */
    width: 40vw;
  }
  .view_voters_gender {
    color: #606b83;
    font-size: 3.888vw;
    font-weight: 600;
    margin-left: 13vw;
    /* background-color: gainsboro; */
    width: 37vw;
  }
  #voter_email_div {
    margin-bottom: 3vw !important;
  }
  .view_voters_mobile {
    color: #606b83;
    font-size: 3.888vw;
    font-weight: 600;
    margin-left: 13vw;
    /* background-color: gainsboro; */
    width: 37vw;
  }

  .pencil_icon_mobile_ {
    height: 5vw;
    width: 5vw;
    /* margin-left: 18vw; */
  }
  .delete_icon_mobile_ {
    height: 5vw;
    width: 5vw;
  }
  #first_name_text {
    /* background-color: red; */
    /* height:11vw; */
  }
  .popup_view_voters_text {
    font-size: 4vw;
    color: #263659;
    width: 40vw;
    white-space: wrap !important;
    word-break: break-all;
    /* background-color: red;
    height: 70% !important; */
  }
  .popup_view_voters_text_ {
    font-size: 4vw;
    color: #263659;
    width: 40vw;
    white-space: wrap !important;
  }

  .new_voter_popup_drop_div {
    border-radius: 0% !important;
    color: gray;
  }
  .new_voter_popup_drop_div:hover {
    border-radius: 0% !important;
    /* background-color: red; */
  }
  .new_voter_popup_drop_div.clicked {
    color: #8b93a5;
    background-color: #f3f7f9 !important;
  }
  .new_voter_popup_drop_div:hover {
    /* background: white!important; */
    color: #8b93a5;
    /* background-color: #f3f7f9 !important; */
  }
  .show dropdown {
    background-color: red;
  }
  .new_voter_popup_drop_div:focus {
    background-color: #f3f7f9 !important;
  }
  .new_voter_popup_drop_div.btn.btn-primary {
    background-color: #f3f7f9 !important;
    color: #8b93a5 !important;
    font-weight: 600 !important;
    /* color: red !important; */
  }
  .new_voter_popup_drop_div.btn.btn-primary:active {
    /* color: red; */
    color: #8b93a5 !important;
    font-weight: 600 !important;
    background-color: #f3f7f9 !important;
  }
  .dropdown-menu.show {
    width: 100%;
  }
  .dropdown_text_newVoters:hover {
    background-color: #f3f7f9 !important;
  }
  .word_ {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word !important;
    hyphens: auto !important;
    overflow-wrap: normal !important;
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: wrap; */
    word-break: break-all !important;
  }
  .add_poling {
    /* background-color: red !important; */
    padding: 0vw !important;
    margin-left: 0vw !important;
    width: 70vw !important;
    overflow-wrap: normal !important;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
  }
  .add_voters_title_ {
    display: flex;
  }
  .add_voters_popup_cancle_btn_ {
    /* margin-left:5.5vw; */
    /* font-size: 8.8vw;
 margin-top:1vw;
 color: #DFDFDF; */
    position: absolute;
    right: 9vw !important;
  }
  .close_cross_icon_voters {
    /* margin-left:5.5vw; */
    height: 6vw;
    width: 6vw;
    margin-top: 3vw;
  }
  .title_voters_add {
    /* margin-left:20vw !important; */
    margin-top: 6vw !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
    text-align: center;
  }
  #save_btn_voters {
    margin-top: 3.5vw !important;
    /* background: none !important; */
    color: #fff !important;
    /* color: #1C477A !important; */
    font-weight: 800 !important;
    /* margin-left:8.5vw; */
    border-radius: 1.1vw !important;
  }

  .toggle-text:focus,
  .toggle-text:active {
    color: red !important;
    opacity: 0.5;
  }
  #save_btn_inEdit_voters_display {
    margin-top: 4vw !important;
    position: relative;
    /* left: 0vw !important; */
    right: 4vw !important;
    border-radius: 1.1vw !important;

    /* margin-left: 9.5vw !important; */
  }
  #valiationError_dropdown {
    position: relative;
    bottom: 2vw;
  }
  .new_voter_data_input {
    border: 1px solid #0047b2;
    background: none;
    overflow: ellipse !important;
  }
  #dropdown-basic {
    border-radius: 1px !important;
    border: 1px solid #1c477a;
    color: #8b93a5 !important;
    background-color: #f3f7f9;
  }
  #dropdown-basic:focus {
    /* color:red !important; */
    /* background-color: #f3f7f9; */
    /* background-color: #FFFFFF; */
    /* color: #8B93A5 !important */
    /* background-color: #f3f7f9 !important; */
    color: #8b93a5 !important;
    background-color: #ffffff;
    color: #444444 !important;
    font-weight: 700 !important;
    font-size: 4vw !important;
    /* color:#263659 !important; */
    z-index: 1000 !important;
    opacity: 1 !important;
  }
  #new_voter_dropdown_gender:focus-within #dropdown-basic {
    /* background-color: #f3f7f9 !important;
  color: #8B93A5 !important;
  background-color: #FFFFFF;
  color:#444444 !important;
  font-weight: 700!important;
  font-size: 4vw!important;
  color:#263659 !important;
  z-index: 1000;
  opacity: 1; */
  }
  .new_voter_data_input input {
    /* background:none!important; */
    width: 82.5vw !important;
    background-color: #f3f7f9;
    margin-left: -0.69vw;
  }

  #new_voter_dropdown_votertype .dropdown-menu {
    min-height: 27vw !important;
    /* max-height: 30vw !important; */
    overflow-y: scroll;
  }
  .dropdown_text_newVoters {
    /* min-height: 27vw!important; */
  }
  .no_data_drop_down {
    /* min-height: 10vw !important; */
  }

  #new_voter_dropdown_addres_pollingstation::placeholder {
    width: 10vw !important;
  }
  .full-width-button {
    width: 100%;
  }
  .new_voter_popup_drop_div_address_pollingstation {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 100% !important;
    box-sizing: border-box;

    /* max-width: 160px;  */
  }
  .heelo {
    /* text-align: left !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important; */
  }
  #new_voter_dropdown_pollingstation_name .dropdown-menu {
    min-height: 27vw !important;
  }
  .new_voter_popup_drop_div_address_pollingstation {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  #new_voter_dropdown_state_prov .dropdown-menu {
    min-height: 27vw !important;
  }
  #add {
    display: none !important;
  }
  .new_voter_popup_drop_div_address_pollingstation .selected-text {
    /* white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width:2vw !important; */
  }
  .new_voter_popup_drop_div {
    background-color: red !important;
    font-size: 1vw;
  }
  .dropdown_text_newVoters {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .add_voters_popup_sub_div {
    width: 75.96875vw;
    /* height: 127.979167vw; */
    height: 137.979167vw;
    background-color: white;
    position: relative;
  }
  .add_voters_title_ {
    color: red !important;
    /* display: none; */
  }
  .close_cross_icon_voters {
    height: 2.4vw;
    width: auto;
    /* margin-left:3vw !important; */
    /* position: relative;
  top:2vw; */
    position: absolute;
    right: 2.5vw !important;
    top: 1vw;
    /* margin-top:2vw !important; */
  }
  .add_voters_popup_sub_div span {
    /* font-weight: 800;
  font-family: "Montserrat";
  font-size: 2.145833vw;
  position: relative;
  top: .5vw !important; */
    /* background-color: red; */
  }
  .title_voters_add {
    font-weight: 800;
    font-family: "Montserrat";
    font-size: 2.145833vw !important;
    position: relative;
    top: 0.5vw !important;
  }
  .add_poling {
    /* background-color: red; */
    margin: 0vw !important;
    margin-left: 0vw !important;
    width: 20vw !important;
    overflow-wrap: normal !important;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    font-size: 1.3vw !important;
    color: #263659 !important;
  }
  .voteriderror {
    color: red !important;
    font-size: 1.3vw !important;
    /* margin-right: 14.3vw; */
    width: 14vw !important;
    margin-top: -1vw;
    position: relative;
    right: 1.5vw !important;
    /* background-color: #1C477A; */
  }
  .firstnameerror,
  .lastnameerror {
    color: red !important;
    font-size: 1.3vw !important;
    width: 14vw !important;
    margin-top: -1vw;
    position: relative;
    right: 0.5vw !important;
  }
  #phone_error,
  #housenumber_newvoter {
    margin-top: -1vw;
  }
  .validationErrorsdropdown {
    /* margin-top:-1vw; */
  }
  .validationErrorsdropdown {
    /* margin-top:0.5vw; */
    font-size: 1.2vw !important;
  }
  .add_voters_popup_save_btn {
    width: 8.0125vw;
    height: 3.8vw;
    margin-left: 2.041667vw;
    background-color: #1c477a;
    font-size: 1.5375vw;
    border-radius: 0.2vw !important;
  }
  .popup_new_voter_informtion_div {
    background-color: #b3c8e8;
    font-size: 2vw;
    height: 3.5vw;
  }
  .voter_info_new_voter_main {
    font-size: 1.4vw;
    font-weight: 600;
    margin-top: 2.6vw;
  }
  .new_voter_data_input input {
    font-size: 1.4vw;
    /* color:red */
  }
  .new_voter_data_input::placeholder {
    font-size: 1.7vw !important;
  }
  #new_voter_data_input_::placeholder {
    font-size: 1.2vw !important;
    font-weight: 500;
  }
  .buttons_holder_div_addvoters {
    /* background: none; */
    background-color: #b3c8e8 !important;
    width: 72vw !important;
    display: flex !important;
    justify-content: space-between;
  }
  .new_voter_data_input input::placeholder {
    font-size: 1.1vw !important;
  }
  #dropdown-basic {
    margin-top: 0vw;
    border-radius: 1px !important;
    width: 21.5vw !important;
    border: 1px solid #1c477a;
    font-size: 1.2vw !important;
    height: 3.5vw !important;
  }
  .no_data_drop_down {
    font-size: 1.3vw !important;
    color: #263659 !important;
    font-weight: 600 !important;
  }
  .word_ {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word !important;
    hyphens: auto !important;
    overflow-wrap: normal !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
    word-break: break-all !important;
  }
  .new_voter_data_input input {
    /* background-color: #B3C8E8; */
  }
  .new_voter_data_input {
    /* background: none!important; */
    border: 1px solid #0047b2;
    height: 3.5vw !important;
  }
  .add_voters_popup_savenew_btn {
    background-color: #1c477a;
    color: #fff !important;
    height: 4vw !important;
    font-size: 1.4vw;
    width: 15vw;
    border-radius: 0.4vw !important;
  }
  .dropdown-menu {
    max-height: 8vw;
    overflow-y: scroll;
    /* background: red; */
    width: 21.5vw;
    background-color: #ffffff !important;
    box-shadow: 0px 1.1111111111111112vw 2.7777777777777777vw
      rgba(0, 0, 0, 0.15) !important;
  }
  .dropdown_text_newVoters {
    font-size: 1.3vw;
    font-family: "Montserrat";
    border-bottom: 0.1vw solid #d9d8d8;
    color: #263659 !important;
    font-weight: 600 !important;
    hyphens: auto !important;
    overflow-wrap: normal !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
    word-break: break-all !important;
  }

  .voterAddedpopup_newvoters_page {
    position: fixed;
    height: 8vw !important;
    width: 40vw !important;
    background-color: #f3f7f9 !important;
    color: #1c477a !important;
    border-radius: 0.7vw !important;
    font-size: 2vw !important;
    left: 30vw;
    z-index: 2001;
    /* top: -5vw; */
    top: 0vw;

    box-shadow: 0.5vw 0.5vw 1vw 0vw black;
    font-weight: 700 !important;
  }
  .dropdown-toggle::after {
    font-size: 2vw;
  }

  #input_search_ {
    font-size: 1.291666666666666vw !important;
    background: #e4f0ff !important;
    color: #1c477a !important;
    font-weight: 600 !important;
    /* height: 3vw !important; */
  }
  #input_search_::placeholder {
    font-size: 1.291666666666666vw !important;
    color: #1c477a !important;
  }
  .new_voter_add_info {
    width: 100%;
    background-color: #aab7c8;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.29375vw;
    line-height: 1.145833333333333vw;
    color: #263659;
    padding: 9px;
    margin-top: 26px;
  }
  .add_picture_main {
    height: 10.958333vw;
    width: 13.958333vw;
  }
  .add_picture_sub {
    height: 2.614583vw;
    width: 13.758333vw;
    font-size: 1.3vw !important;
    font-weight: 600;
  }
  .add_picture_main img {
    height: 6.972396vw;
    width: 6.972396vw;
  }
  #new_voter_dropdown_votertype .dropdown-menu {
    /* min-height: 27vw!important;
    overflow-y: scroll;  */
  }
  #dropdown_text_newVoters:focus {
    /* background-color: transparent !important;  */
  }
  .popup_view_voters_text_ {
    font-size: 2vw;
    font-weight: 700;
    color: #263659;
    /* width: 40vw; */
    white-space: wrap !important;
  }
  .dropdown_text_newVoters {
    background-color: transparent !important;
  }
}
