.settingsinfo_mainDiv {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.column_section_main_div {
  width: 96%;
  height: 1.875vw;
  background-color: #ecf0f5;
  border-radius: 0.10416666666666667vw;
  margin-top: 0.2604166666666667vw;
  color: #263659;
  font-size: 0.8333333333333334vw;
  font-weight: 600;
  font-family: "Montserrat";
}
.column_section_main_div span {
  margin-left: 0.625vw;
}
.search_input {
  height: 2.2395833333333335vw;
  width: 96%;
  margin-top: 0.2604166666666667vw;
  border: 0.1vw solid #dddddd;
  border-radius: 0.2vw;
}
.select_all_div {
  height: 2.6041666666666665vw;
  width: 96%;
  border-bottom: 0.1vw solid #ccd0d6;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  border-bottom: 0.1vw solid #ccd0d6;
}
.select_all_div input {
  margin-left: 4%;
}
.select_all_div span {
  margin-left: 4%;
  font-weight: 600;
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
}
.select_all_text {
  margin-left: 4%;
  font-weight: 600;
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
}
.scroll_main-div {
  overflow-y: scroll;
  height: 10vw;
  width: 95%;
}
.selectall_checkbox {
  cursor: pointer;
}
.applybutton_column_section {
  height: 2.0833333333333335vw;
  background-color: #04a559;
  border: 0.1vw solid #bacadd;
  color: #ffffff;
  width: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1vw;
  font-family: "Montserrat";
  font-weight: 501;
  font-size: 0.8333333333333334vw;
  position: absolute;
  right: 2% !important;
  bottom: 2%;
  cursor: pointer;
}
.cancel_column_section {
  height: 2.0833333333333335vw;
  background-color: #ffffff;
  border: 0.1vw solid #bacadd;
  color: #1c477a;
  width: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1vw;
  font-family: "Montserrat";
  font-weight: 501;
  font-size: 0.8333333333333334vw;
  position: absolute;
  left: 2% !important;
  bottom: 2%;
  cursor: pointer;
}
.settings_serach_div {
  margin-top: 0.3645833333333333vw;
  background-color: #ffffff;
  width: 98%;
  border: 0.1vw solid #dddddd;
  height: 2.2395833333333335vw;
}
.settings_search_columns {
  width: 95%;
  height: 100%;
  border: none;
  font-size: 0.9375vw;
}
.settings_search_columns:focus {
  outline: none;
}
input.settings_search_columns::placeholder {
  font-size: 0.9375vw !important;
  background-color: #ffffff !important;
}
@media (max-width: 767px) {
  .selectall_checkbox {
    height: 3.5vw !important;
  }
  .settings_search_columns {
    font-size: 3.188888888888889vw !important;
    background: none !important;
    margin-top: 3vw!important;
    font-weight: 600 !important;
  }
  .settings_serach_div {
    height: 7.166666666666666vw !important;
    border-radius: 0.8vw !important;
    width: 91% !important;
    font-size: 3.888888888888889vw !important;
  }
  input.settings_search_columns::placeholder {
    font-size: 3.888888888888889vw !important;
    background-color: #ffffff !important;
  }

  .settingsoptionMainDiv {
    top: 28vw;
    width: 65.27777777777777vw !important;
    height: 81.1388888888889vw !important;
  }
  .column_section_main_div,
  .select_all_div {
    height: 8.875vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.888888888888889vw;
    line-height: 4.722222222222222vw;
    color: #263659;
    margin-bottom: 0.3vw;
  }
  .select_all_div span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.888888888888889vw;
    line-height: 4.722222222222222vw;
    color: #263659;
  }
  .select_all_text {
    font-weight: 500;
    font-size: 3.888888888888889vw !important;
  }
  .scroll_main-div {
    height: 42vw;
  }
  .select_all_div input {
    margin-left: 4%;
    width: 5.555555555555555vw;
    height: 5.555555555555555vw;
  }
  .cancel_column_section,
  .applybutton_column_section {
    width: 19.166666666666668vw;
    height: 9.277777777777779vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.888888888888889vw;
    line-height: 4.722222222222222vw;
  }
}
@media (max-width: 767px) {

  .settingsinfo_mainDiv {
    height:86vw;
  }

  .settingsoptionMainDiv {
    border-radius: 0px 0px 5px 5px !important;
    background: #FFF !important;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.25) !important;
    }

  .settingsoptionMainDiv {
    width: 84vw !important;
    right: 8.1% !important;
    top: 36.5vw !important;
    height: 100vw !important;
    /* height: 81.1388888888889vw !important; */
  }

  .column_section_main_div {
    border-radius: 5px 5px 0px 0px !important;
    background: #fff !important;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    width: inherit;
    height: 14.16vw;
  }

  .column_section_main_div span {
    color: #1c477a;
    font-size: 5.55vw;
    font-weight: 600;
    margin-left: 18vw;
  }

  .settings_serach_div {
    height: 11.944vw !important;
    margin-top: 2.7vw !important;
  }

  .settings_search_columns::placeholder {
    color: #bdbdbd;
    font-size: 5vw !important;
    font-weight: 600 !important;
  }

  .selectall_checkbox {
    height: 6.11vw !important;
    width: 6.11vw !important;
    /* background-color: #CCD0D6; */
  }

  .select_all_div input {
    margin-left: 6%;
  }

  .select_all_div {
    border: none !important;
    margin-top:1.4vw;
    background: none !important;
  }

  .select_all_text {
    margin-top: 0vw !important;
    color: #444;
    font-size: 4.44vw !important;
    font-weight: 600;
  }

  .scroll_main-div {
    height: 45vw !important;
  }
  .scroll_main-div span {
    color: #444;
    font-size: 4.44vw;
    font-weight: 600;
  }
  #column_section_footer_{
    border-radius: 0px 0px 5px 5px;
background: #FFF;
box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.25);

  }
  .cancel_column_section{
    color: #1C477A;
    font-size: 4.4vw;
    font-weight: 600;
    width: 25vw;
    height:10vw;
    border-radius: 0.2vw !important;


   }
  .applybutton_column_section{
    border-radius: 4px;
background: #1C477A;
font-size: 4.44vw;
width: 25vw;
height:10vw;
border-radius: 0.2vw !important;

  }
  
}

@media screen and (max-width: 1024px) and (min-width: 768px) {

.mandal_dropdown-header,.dropdown_village_subdiv,.pollingstation_dropdown-header{
 border-radius: 0.2vw !important;
}
.applybtn_in_voters_data_dropdowns{
  border-radius: 0.2vw !important;
  border: none;
}

.dropdown-options_maindiv,.dropdown_villadedata ,.dropdown-options_pollingstation{
  max-height: 14.2vw;
}
.dropdown_selected_count {
  font-size: 1.633333vw;
  position: relative;
  left:1vw !important;
}
.dropdown-option{
  border-radius: 0.2vw !important;
}
.dropdown-option span {
  margin-left: 2%;
  font-size: 1.8vw !important;
}
.votersdata_checkboxesdropdown{
  height: 2.4vw !important;
  width: 2.4vw !important;
  background: none !important;
}
.settingsoptionMainDiv {
  width: 37vw !important;
  right: 3.1% !important;
  top: 6vw !important;
  height: 40vw !important;
  border-radius: 0px 0px 5px 5px !important;
    background: #FFF !important;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.25) !important;
}
.column_section_main_div, .select_all_div {
  height: 4.875vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 2.188888888888889vw;
  line-height: 4.722222222222222vw;
  color: #263659;
  margin-bottom: 0.3vw;
  border-radius: 5px 5px 0px 0px !important;
    background: #fff !important;
    /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1); */
    /* width: inherit; */
    /* height: 14.16vw; */
}
.settings_serach_div {
  height: 4.944vw !important;
  /* margin-top: 2.7vw !important; */
}
.settings_search_columns {
  font-size: 2.388888888888889vw!important;
  background: none !important;
  font-weight: 600!important;
}
.settings_search_columns::placeholder{
  /* font-size:3.888888888888889vw!important; */
}
#settings_search_columns::placeholder{
  font-size: 2vw!important;
}
.select_all_div {
  height: 4.944vw !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 3.888888888888889vw;
  line-height: 4.722222222222222vw;
  color: #263659;
  margin-bottom: 0.3vw;
}
.scroll_main-div {
  height: 15.5vw !important;
}
.select_all_text {
  width: 25vw;
  margin-left: 4%;
  font-weight: 600;
  font-size: 2.333333333333334vw;
  font-family: "Montserrat";
}
.select_all_div input {
  margin-left: 4%;
  width: 3vw;
  height: 3vw;
  margin-top:1vw;
}
.select_all_div span {
  margin-left: 4%;
  font-weight: 600;
  font-size: 2.33333vw !important;
  font-family: "Montserrat";
}
.cancel_column_section {
  height: 5.083333vw;
  background-color: #ffffff;
  border: 0.1vw solid #bacadd;
  color: #1c477a;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1vw;
  font-family: "Montserrat";
  font-weight: 501;
  font-size: 1.933333333333334vw;
  position: absolute;
  left: 2% !important;
  border-radius: 0.2vw !important;
  bottom: 2%;
  cursor: pointer;
}
.applybutton_column_section {
  height: 5.0833333333333335vw;
  background-color: #1C477A;
  border: 0.1vw solid #bacadd;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 0.2vw !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1vw;
  font-family: "Montserrat";
  font-weight: 501;
  font-size: 1.933333333333334vw;
  position: absolute;
  right: 2% !important;
  bottom: 2%;
  cursor: pointer;
}
}