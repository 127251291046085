* {
  font-family: "Montserrat";
}

.voters_data_main_div {
  margin-top: 4vw;
  height: fit-content;
  animation: votersdata 1.5s linear;
}
#extra_inputs_for_tab {
  width: 8vw;
}

@keyframes votersdata {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.left_right_btns {
  display: flex;
  position: fixed;
  left: 68%;
  top: 35.3%;
}

.leftarrow_btn,
.rightarrow_btn {
  background: pink;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top_nav_holder_main {
  height: 75%;
  width: 100%;
  position: relative !important;
}

.sidenav_holder_div_voters_data {
  width: fit-content;
  height: 100%;
  display: flex;
}

.top_nav_holder {
  width: 100% !important;
}

.top_headings_in_voters_data_main_div {
  width: fit-content;
}

.top_heading_in_voters_data {
  width: 12.4vw;
  height: 2.8645833333333335vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  background-color: #e8ecf1;
  display: flex;
  align-items: center;
  font-size: 0.7291666666666666vw !important;
  font-family: "Montserrat" !important;
  font-weight: 501 !important;
  color: #4d7093 !important;
}

.search_voter_id {
  width: 25.520833333333332vw;
  height: 2.2916666666666665vw;
  margin-top: 0.625vw;
  background-color: #f3f7f9;
  outline: none;
  font-size: 0.7291666666666666vw !important;
  font-family: "Montserrat" !important;
  font-weight: 501 !important;
  color: #4d7093 !important;
  margin-left: 25%;
  margin-top: 0.78125vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  border-radius: 0.2vw;
}

.search_voter_id input:focus {
  outline: none;
}

.search_voter_id::placeholder {
  font-size: 0.8291666666666666vw !important;
  font-family: "Montserrat" !important;
  font-weight: 501 !important;
  color: #4d7093 !important;
  padding-left: 1.5vw !important;
  background-color: #f3f7f9;
}

.top_heading_in_voters_data span {
  margin-left: 13%;
}

.titleDiv_votersdata {
  width: 12.4vw;
  height: 2.8645833333333335vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  overflow: hidden;
  display: inline-block;
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  margin: 0;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
}

.titleDiv_votersdata span {
  color: #263659;
  margin-left: 13%;
  width: 9vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.voters_data_holder_main_div {
  height: 90% !important;
  width: fit-content;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-left: 0%;
}

.voters_data_holder_main_div::-webkit-scrollbar {
  width: 0.5vw !important;
}

.voters_data_holder_main_div::-webkit-scrollbar-thumb {
  background-color: #959595 !important;
  border-radius: 1vw !important;
}

.votersdata_text_maindiv {
  height: 3.8541666666666665vw;
  width: 96.1%;
  position: relative;
  border-bottom: 0.2vw solid #1e1e1e;
  margin-left: 1%;
}

.votersdata_text_ems {
  font-size: 1.4583333333333333vw;
  font-family: "Montserrat";
  font-weight: 400;
  position: absolute;
  top: 0.9vw;
  left: 0.6%;
  color: #263659;
}

.prev_button_votersdata {
  background-color: #88a7c6;
  width: 2.7083333333333335vw;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  text-align: center;
  font-family: "Montserrat";
  outline: #88a7c6 !important;
  margin-right: 1%;
  cursor: pointer;
  font-weight: 800;
  margin-left: 15.5vw;
}

.next_button_votersdata {
  background-color: #88a7c6;
  width: 2.7083333333333335vw;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-family: "Montserrat";
  text-align: center;
  outline: #88a7c6 !important;
  margin-left: 1%;
  cursor: pointer;
  font-weight: 800;
}

.current_page_number_votersdata {
  background-color: #88a7c6;
  width: fit-content;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-weight: 800;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vw;
}

.total_pages {
  background-color: #88a7c6;
  width: fit-content;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-weight: 800;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  padding: 0.5vw;
  position: absolute;
  left: 1%;
}

.buttons_voterdata_next_prev_main_div {
  margin-left: 52.6% !important;
  margin-top: 1vw;
}

.add_new_voter_btn {
  position: absolute;
  left: 63%;
  height: 2.2916666666666665vw;
  width: 7.8125vw;
  top: 0.78125vw;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
}

.polygon__votersdata {
  height: 0.5208333333333334vw;
  width: 0.5208333333333334vw;
}

.voters_data_view_delete_maindiv {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  border-radius: 0.10416666666666667vw;
  position: absolute;
  z-index: 1;
  top: 2.5vw;
  right: 5vw;
  width: 5vw;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
}

.hrline_view_delete {
  border: 1px solid #ccd0d6;
  margin: 0;
  width: 100%;
}

.delete_btn_voters_data {
  height: 1.5vw;
  display: flex;
  justify-content: center;
  color: #eb7070;
  padding: 0.1vw;
  cursor: pointer;
  font-size: 0.729167vw;
  padding: 0.3vw;
  width: 100%;
}

.excelllogo {
  position: absolute;
  left: 85.1%;
  width: 2.8125vw;
  height: 2.2916666666666665vw;
  top: 0.7291666666666666vw;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
}

.refreshlogo {
  position: absolute;
  left: 73.8%;
  width: 2.8125vw;
  height: 2.2916666666666665vw;
  top: 0.7291666666666666vw;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
}

.filter {
  position: absolute;
  left: 78.5%;
  width: 6.458333333333333vw;
  height: 2.2916666666666665vw;
  top: 0.7291666666666666vw;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
}

.pdflogo {
  position: absolute;
  left: 89.5%;
  width: 2.8125vw;
  height: 2.2916666666666665vw;
  top: 0.7291666666666666vw;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
}

.settingslogo {
  position: absolute;
  left: 93.8%;
  width: 2.8125vw;
  height: 2.2916666666666665vw;
  top: 0.7291666666666666vw;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
}

.voters_data_filter_dropdown {
  height: 16.927083333333332vw;
  width: 25%;
  background-color: #ffffff;
  position: absolute;
  right: 13.8%;
  z-index: 50;
  bottom: -17vw;
  border-radius: 0.20833333333333334vw;
  box-shadow: 0vw 0.20833333333333334vw 0.5208333333333334vw;
  animation: mandaldrop 0.2s linear;
}

.newvoters_img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.excel_img {
  width: 100%;
  height: 100%;
}

.refresh_img {
  height: 100%;
  width: 100%;
}

.settings_btn_img {
  height: 100%;
  width: 100%;
}

@keyframes mandaldrop {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.votersdata_constancy_dropdown_btn {
  background-color: #f3f7f9;
  border-radius: 0.2vw;
  width: 96%;
  margin-top: 0.5208333333333334vw;
  height: 2.65625vw;
  position: relative;
  border: 0.1vw solid #d8d8d8;
}

.votersdata_constancy_dropdown_btn span {
  position: absolute;
  top: 0.58125vw;
  left: 4%;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 800;
  color: #8b93a5;
}

.canclebt_in_voters_data_dropdowns {
  height: 2.0833333333333335vw;
  width: 30%;
  border: 0.052083333333333336vw solid #bacadd;
  position: absolute;
  bottom: 7%;
  left: 2%;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 501;
  color: #1c477a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2vw;
  cursor: pointer;
}

.applybtn_in_voters_data_dropdowns {
  height: 2.0833333333333335vw;
  width: 30%;

  position: absolute;
  bottom: 7%;
  right: 2%;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 501;
  color: #ffffff;
  background-color: #04a559;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2vw;
  cursor: pointer;
}

.voters_data_mandal_dropdow {
  height: 10vw;
  background-color: white;
  width: 96%;
  position: absolute;
  z-index: 53;
  top: 6.8vw;
  border-radius: 0.2vw;
  border: 0.1vw solid white;
  box-shadow: 0vw 0.20833333333333334vw 0.5208333333333334vw rgba(0, 0, 0, 0.15);
  animation: mandaldrop 0.2s linear;
  overflow-y: scroll;
}

.voters_data_village_dropdow {
  height: 10vw;
  background-color: white;
  width: 96%;
  position: absolute;
  z-index: 52;
  top: 9.8vw;
  border-radius: 0.2vw;
  border: 0.1vw solid white;
  box-shadow: 0vw 0.20833333333333334vw 0.5208333333333334vw rgba(0, 0, 0, 0.15);
  animation: mandaldrop 0.2s linear;
  overflow-y: scroll;
}

.voters_data_pollingstation_dropdow {
  height: 10vw;
  background-color: white;
  width: 96%;
  position: absolute;
  z-index: 51;
  top: 12.9vw;
  border-radius: 0.2vw;
  border: 0.1vw solid white;
  box-shadow: 0vw 0.20833333333333334vw 0.5208333333333334vw rgba(0, 0, 0, 0.15);
  animation: mandaldrop 0.2s linear;
  overflow-y: scroll;
}

.votersdata_constituencies_info_div {
  height: 2.3958333333333335vw;
  width: 100%;
  border: 0.1vw solid #d9d8d8;
}

.votersdata_constituencies_info_div span {
  margin-top: 0.625vw;
  margin-left: 0.78125vw;
  font-size: 0.9375vw;
  font-weight: 800;
  font-family: "Montserrat";
}

.checekbox_votersdata_input {
  position: absolute;
  height: 0.9546874999999999vw;
  width: 0.9546874999999999vw;
  right: 10%;
  top: 0.7vw;
}

.voters_data_underline_one {
  background-color: #ccd0d6;
  width: 96.1%;
  height: 0.1vw;
  margin-top: 0.1vw;
  margin-left: 1vw;
}

.dropdown_constituency_Main_div {
  width: 100%;
}

.dropdown_constituency_sub_div {
  background-color: #f3f7f9;
  width: 96%;
  margin-left: 2%;
  height: 2.65625vw;
  border-radius: 0.2vw !important;
  margin-top: 0.5vw;
  border: 0.1vw solid #d8d8d8;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 501;
  color: #8b93a5;
  position: relative;
}

.constancy_dropdown_header {
  height: 100%;
}

.constancy_dropdown_header span {
  margin-left: 2%;
}

.constancy_dropdown-constituencyOptions {
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  max-height: 8vw;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0.5vw;
  z-index: 53;
  animation: dropdown 0.5s linear;
  overflow-y: scroll;
  left: 0%;
}

@keyframes dropdown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mandal_dropdown-header {
  background-color: #f3f7f9;
  width: 96%;
  margin-left: 2%;
  height: 2.65625vw;
  border-radius: 0.2vw;
  border: 0.1vw solid #d8d8d8;
  margin-top: 0.5vw;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 501;
  color: #8b93a5;
  position: relative;
}

.mandal_dropdown-header span {
  margin-left: 2%;
}

.dropdown-options_maindiv {
  background-color: #ffffff;
  max-height: 8vw;
  width: 96%;
  margin-left: 2%;
  position: absolute;
  z-index: 52;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0.5vw;
  animation: dropdown 0.5s linear;
  overflow-y: scroll;
}

.dropdown_village_maindiv {
  height: fit-content;
}

.dropdown_village_subdiv {
  background-color: #f3f7f9;
  width: 96%;
  margin-left: 2%;
  height: 2.65625vw;
  border-radius: 0.2vw;
  border: 0.1vw solid #d8d8d8;
  margin-top: 0.5vw;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 501;
  color: #8b93a5;
  position: relative;
}

.dropdown_village_subdiv span {
  margin-left: 2%;
}

.dropdown_villadedata {
  position: absolute;
  max-height: 8vw;
  background-color: #ffffff;
  width: 96%;
  margin-left: 2%;
  overflow-y: scroll;
  z-index: 51;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0.5vw;
  animation: dropdown 0.5s linear;
}

.pollingstation_dropdown-header {
  background-color: #f3f7f9;
  width: 96%;
  margin-left: 2%;
  height: 2.65625vw;
  border-radius: 0.2vw;
  border: 0.1vw solid #d8d8d8;
  margin-top: 0.5vw;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  color: #8b93a5;
  font-weight: 501;
  position: absolute;
}

.pollingstation_dropdown-header span {
  margin-left: 2%;
}

.dropdown-options_pollingstation {
  position: absolute;
  top: 13vw;
  max-height: 10vw;
  background-color: #ffffff;
  width: 96%;
  margin-left: 2%;
  overflow-y: scroll;
  z-index: 50;
  border-radius: 0.5vw;
  animation: dropdown 0.5s linear;
}

.dropdown-option {
  height: 3.5vw;
  border: 0.1vw solid #d9d8d8;
  position: relative;
  width: 100%;
  background-color: #ffffff;
}

.dropdown-option span {
  margin-left: 2%;
  font-size: 0.9375vw;
  font-family: "Montserrat";
  font-weight: 501;
  color: #263659;
  width: 16vw;
  overflow: hidden;
  text-align: left;
}

.votersdata_checkboxesdropdown {
  position: absolute;
  right: 5%;
  height: 1vw !important;
  width: 1vw;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.dropdown_selected_count {
  font-size: 0.8333333333333334vw;
  font-weight: 501;
  font-family: "Montserrat";
  color: #263659;
  position: absolute;
  right: 20%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 10px;
  cursor: pointer;
}

/* settings div */

.settingsoptionMainDiv {
  height: 21.1875vw;
  width: 15%;
  background-color: #ffffff;
  position: absolute;
  z-index: 50;
  right: 2%;
  top: 4vw;
  border-radius: 0.3vw;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.parent_horizontal_scroll_votersdata {
  height: 68vh !important;
  width: 99.9%;
  margin-left: 0.1%;
  position: relative;
}

.horizontal_scroll_votersdata {
  width: 96.1%;
  height: 98%;
  overflow-x: scroll;
  left: 1%;
  position: absolute;
  margin-top: 0.625vw;
  border: 0.052083333333333336vw solid #ccd0d6;
}

.horizontal_scroll_votersdata::-webkit-scrollbar {
  display: block;
}

.dropdown-toggle {
  background-color: #f3f7f9;
  width: 96%;
  height: 2.65625vw;
  border-radius: 0.2vw;
  border: 0.1vw solid #d8d8d8;
  margin-top: 0.5vw;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  color: #8b93a5;
  font-weight: 501;
  text-align: left;
}

.fields_in_filter_div {
  height: 2vw;
  width: 91%;
  overflow: hidden;
}

.fields_in_filter_div span {
  font-size: 0.9375vw;
  font-weight: 800;
  font-family: "Montserrat";
}

.dropdown-menu {
  width: 100%;
}

.pdfImg {
  height: 100%;
  width: 100%;
  background-color: #c64632;
  border-radius: 0.2vw;
}

.pdfcenter_Logo {
  width: 0.9375vw;
  height: 1.25vw;
}

.search_button_voterid {
  width: 3.9583333333333335vw;
  height: 1.5625vw;
  border: none;
  background-color: #1c477a;
  color: #ffffff;
  font-size: 0.8333333333333334vw;
  font-family: "Montserrat";
  border-radius: 0.3vw;
}

.voterid_search_input {
  height: 100%;
  width: 78%;
  font-size: 1vw !important;
  font-family: "Montserrat";
  border: none;
  background-color: #f3f7f9;
}

.votersdata_underlines_holder {
  margin-top: 0.8vw;
}

.voterid_search_input:focus {
  outline: none;
}

.filter_img {
  width: 100%;
  height: 100%;
}

.constancy_spandiv {
  text-align: left;
  width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mandal_first_name_votersdata {
  text-align: left;
  width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search_option_votersdata_dropdown {
  position: absolute;
  height: 100%;
  left: 0%;
  top: 0%;
  width: 100%;
  border: none;
  background-color: #f3f7f9;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 500;
  border-radius: 0.2vw;
}

.search_option_votersdata_dropdown::placeholder {
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 500;
}

.search_option_votersdata_dropdown:focus {
  outline: none;
}
.nodata_found_message {
  width: 100%;
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  font-weight: 500;
  color: gray;
}
.go_topage_main_div input {
  border: 0.1vw solid black;
  border-radius: 0.3vw;
  height: 2vw;
  width: 80%;
  font-size: 1vw;
  display: flex;
  align-items: center;
}
.go_topage_main_div input::placeholder {
  font-size: 0.9vw !important;
}
.go_topage_main_div button {
  background: #88a7c6;
  border: none;
  margin-left: 4%;
  height: 2vw;
  width: 3vw;
  border-radius: 0.3vw;
  color: #ffffff;
  font-weight: 500;
}
.go_topage_inputfield {
  font-family: "Montserrat";
  border: none !important;
  background-color: #f3f7f9;
}

/* new css for tab */

@media (max-width: 767px) {
  .itoconnect_voters_data_mobile_main_div{
    display: block !important;
  }
  #extra_inputs_for_tab {
    width: 27vw !important;
  }
  .search_option_votersdata_dropdown {
    font-size: 3vw;
  }
  .search_option_votersdata_dropdown::placeholder {
    font-size: 3vw !important;
  }
  .nodata_found_message {
    height: 10vw;
    font-size: 3.0416666666666667vw;
  }
  .pdsmessage_div {
    position: absolute !important;
    left: 20% !important;
    top: -117vw !important;
    z-index: 0 !important;
  }

  .pdsmessage_div span {
    font-size: 2vw !important;
  }

  .delete_popup_voters_data_delete_text_div span {
    font-size: 4.722222222222222vw !important;
    font-weight: 500 !important;
  }

  .delete_voters_dismiss {
    font-size: 4.722222222222222vw !important;
    font-weight: 500 !important;
    left: 10vw !important;
    bottom: 8.5vw !important;
  }

  .titleDiv_votersdata span {
    width: 30vw;
  }

  .delete_voters_continue {
    width: 37.22222222222222vw !important;
    height: 10.555555555555555vw !important;
    font-size: 4.722222222222222vw !important;
    font-weight: 500 !important;
    border-radius: 8.333333333333334vw !important;
    right: 10vw !important;
    bottom: 7.5vw !important;
  }

  .delete_popup_voters_subDiv {
    width: 92.5vw !important;
    height: 43.05555555555556vw !important;
    left: 4vw !important;
    top: 54vw !important;
  }

  .voters_data_view_delete_maindiv span {
    font-size: 3.333333339vw !important;
    padding: 1vw !important;
  }

  .delete_btn_voters_data {
    font-size: 3.333333339vw !important;
  }

  .Loading_ring {
    height: 25vw !important;
    width: 25vw !important;
  }

  .parent_Loading_holder {
    height: 30vw !important;
    width: 30vw !important;
  }

  .voters_data_main_div {
    margin-top:0vw;
  }

  .voterid_search_input {
    font-size: 5vw !important;
    border-radius: 3px;
    border: 1px solid #0047b2;
    background: #fff;
    height: 11.11vw;
    width: 67%;
  }
  .voterid_search_input:focus {
    font-size: 5vw !important;
  }
  .search_voter_id {
    border: none;
    background: none;
  }

  .search_button_voterid {
    width: 16.9583333333333335vw;
    height: 80%;
    height: 11.11vw;
    border: none;
    border-radius: 1vw !important;
    background-color: #1c477a;
    color: #ffffff;
    font-size: 3.5vw;
    font-family: "Montserrat";
    border-radius: 0.3vw;
  }

  .pdfImg {
    height: 100%;
    width: 100%;
    border-radius: 0.8vw;
  }

  .pdfcenter_Logo {
    width: 4.055556vw;
    height: 5.166667vw;
  }

  .search_voter_id {
    width: 86vw;
    left: -18.5vw;
    position: absolute;
    top: 14.68888888888889vw;
    height: 8.88888888888889vw;
    font-size: 3vw !important;
  }

  .search_voter_id::placeholder {
    font-size: 2.5vw !important;
    margin-top: 3vw !important;
  }

  .voters_data_filter_dropdown {
    right: 24.2%;
    top: 28vw;
    height: 54.927083vw !important;
    width: 64.44444444444444vw;
  }

  .canclebt_in_voters_data_dropdowns,
  .applybtn_in_voters_data_dropdowns {
    width: 19.166666666666668vw;
    bottom: 2.5%;
    height: 10.277777777777779vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.888888888888889vw;
    line-height: 4.722222222222222vw;
    border-radius: 0.2vw !important;

  }

  .votersdata_checkboxesdropdown {
    width: 2.888888888888889vw !important;
    height: 2.888888888888889vw !important;
  }

  .dropdown_constituency_sub_div,
  .mandal_dropdown-header,
  .dropdown_village_subdiv,
  .pollingstation_dropdown-header {
    /* width: 61.111111111111114vw !important; */
    width: 79vw !important;
    /* height: 8.61111111111111vw !important; */
    height: 9.61111111111111vw !important;
    margin-top:2vw;
    font-family: "Montserrat" !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 3.888888888888889vw !important;
    line-height: 4.722222222222222vw !important;
    border-radius: 0.2vw !important;

    color: #8b93a5;
  }

  .dropdown-option {
    width: 61.111111111111114vw !important;
    height: 8.61111111111111vw !important;
  }

  .constancy_dropdown-constituencyOptions {
    max-height: 30vw;
  }

  .dropdown-option span,
  .dropdown_selected_count {
    font-family: "Montserrat" !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 3.888888888888889vw !important;
    line-height: 4.722222222222222vw !important;
    color: #8b93a5;
    /* width: 50vw; */
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    left: 37.5vw;
  }

  .dropdown-options_pollingstation {
    top:46.5vw;
    top:68.5vw;
    max-height: 30vw;
    background-color: #ffffff;
    border-radius: 0.20833333333333334vw;
    box-shadow: 0vw 0.20833333333333334vw 0.5208333333333334vw;
 }

  .constancy_spandiv {
    width: 29vw;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mandal_first_name_votersdata {
    width: 32vw;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-options_maindiv {
    max-height: 30vw;
    overflow-y: scroll;
  }

  .dropdown_villadedata {
    max-height: 30vw;
  }

  .constituency_polygon_img {
    width: 3.888888888888889vw !important;
    height: 1.8888888888888888vw !important;
    top: 3vw !important;
    right: 5% !important;
  }

  .votersdata_underlines_holder {
    margin-top: 0%;
    margin-left: 1vw;
    margin-bottom: 3.0555555555555554vw;
    display: flex;
    flex-direction: column;
    gap: 0.7vw;
  }

  .voters_data_view_delete_maindiv {
    width: 16vw !important;
    height: fit-content;
    top: 6.9vw;
    right: 18vw;
  }

  ::-webkit-scrollbar-track {
    background: rgb(237, 236, 236);
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 10px;
  }

  .images_for_responsive {
    position: absolute;
    left: 6.388888888888889vw;
    bottom: 75px;
    width: 86.38888888888889vw;
  }

  .settingsoptionMainDiv {
    top: 39.5vw !important;
  }

  .votersdata_text_ems {
    font-size: 5.55vw;
    line-height: 6.111111111111111vw;
    margin-left: 6.388888888888889vw;
    margin-left:33.5vw;
    color: #0047b2;
    font-weight: 700;
    display: none;
  }

  .add_new_voter_btn {
    width: 31.11111111111111vw;
    height: 9.6vw;
    left: 61.8%;
    margin-top: 0.3vw;
    margin-top:25.3vw;
  }

  #votersdata.total_pages {
    left: 6.388888888888889vw !important;

    min-width: 30vw !important;
    max-width: 40vw !important;
  }

  .buttons_voterdata_next_prev_main_div {
    margin-left: 42% !important;
    margin-top: 10vw;
  }

  .polygon__votersdata {
    height: 2.20833vw;
    width: 3.520833vw;
  }

  .voters_data_underline_one {
    background-color: #ccd0d6;
    width: 88%;
    height: 0.2vw;
    margin-top: 0.1vw;
    margin-left: 5vw;
  }

  .excelllogo {
    width: 11.9444444444444445vw;
    height: 9.722222222222221vw;
    top: 26vw;
    left:20vw;
  }

  .pdflogo {
    width: 11.9444444444444445vw;
    height: 9.722222222222221vw;
    top: 26vw;
    left:33.6vw;
    
  }

  .refreshlogo {
    width: 11.9444444444444445vw;
    height: 9.722222222222221vw;
    top: 28.61111111111111vw;
    top: 26vw;
    left: 6.388888888888889vw;
  }

  .filter {
    width: 24.444444444444443vw;
    height: 11vw;
    left:68vw;
    top:13vw;
  }

  .settingslogo {
    width: 11.9444444444444445vw;
    height: 9.705555555555556vw;
    top: 26vw;
    left:47.3vw;
    position: absolute;
    right: 0;
  }

  .parent_horizontal_scroll_votersdata {
    margin-top: 25%;
    height: 110.83333333333333vw !important;
  }

  .votersdata_text_maindiv {
    height: 12.854167vw;
    width: 100% !important;
    margin-left: 0;
    border: none;
  }

  .titleDiv_votersdata,
  .top_heading_in_voters_data {
    font-size: 3.3333333333333335vw !important;
    line-height: 4.166666666666667vw !important;
    font-weight: 500 !important;
    width: 45.833333333333336vw !important;
    height: 10.277777777777779vw;
  }

  .delete_btn_voters_data {
    height: 8.5vw;
    font-size: 1vw;
  }

  .votersdata_underlines_holder {
    position: relative;
  }

  .sidenav_holder_div_voters_data {
    width: 100% !important;
    position: absolute;
    margin-left: 0%;
    z-index: 2;
    padding: 0%;
  }

  .total_pages {
    width: 9.722222222222221vw !important;
    height: 9.722222222222221vw !important;
    justify-content: center;
    font-family: "Montserrat" !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 3.3333333333333335vw !important;
    line-height: 4.166666666666667vw;
  }
  .prev_button_votersdata {
    margin-left: 16.5vw;
  }
  .prev_button_votersdata,
  .next_button_votersdata {
    width: 13.333333333333334vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.3333333333333335vw;
    line-height: 4.166666666666667vw;
    width: 6vw;
    height: 8vw;
  }

  .current_page_number_votersdata {
    width: 6vw;
    height: 8vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.3333333333333335vw;
    line-height: 4.166666666666667vw;
  }

  #title_div_first_column {
    background-color: #dce8f5;
  }
  .itoconnect_voters_data_mobile_main_div {
    margin-left: 6.5vw;
    width: 86.5vw;
    border-radius: 2px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
 

  }
  .votersid_title_name {
    width: 86.4vw !important;
    width: 100%;
    height: 11.66vw;
    background: #b3c8e8 !important;
    margin-left:-.1vw !important;
  }

  .votersid_title_name span {
    color: #0047b2;
    font-size: 5vw;
    font-weight: 600;
    padding:10px;
    position: relative;
    top:2vw;
    margin-left: 2.4vw !important;

  }

  .voters_data_edit-icon{
    margin-left:10vw !important;
  }
  .parent_horizontal_scroll_votersdata,
  .votersdata_underlines_holder,
  .go_topage_main_div{
    display: none !important;
  }

  #votersdata.total_pages,
  .prev_button_votersdata, 
  .next_button_votersdata,
  .current_page_number_votersdata{
    background-color: #0047B2;
    background: #B3C8E8;
    color: #0047B2;
    font-weight: 700 !important;
    border-radius: 0px!important;


  }
  .voters_data_edit-icon_edit{
    color:#1C477A !important;
    font-size: 5vw;
    margin-left: 12vw;

  }
  .delete_btn_voters_data_delete{
    color:#1C477A;
    font-size: 5vw;
    margin-left: 10vw;
  }
  #itoconnect_voters_id_title {
    margin-top: 2.7vw !important;
  }
  .itoconnect_voters_id_child_div_main {
    height: 124.16vw;
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-width: auto;
    scrollbar-width: inherit !important;
  }

  #title_div_voters_data_first_column {
    background: none !important;
    border: none !important;
    margin-left: 4.16vw;
    margin-top: 2.5vw;
    width: 35vw;
    display: flex;
  }
  .main_div_voters_display_data{
    display: flex;
    flex-direction:row;
  }
  #title_div_voters_data_first_column span {
    color: #046a38;
    font-size: 5vw;
    font-weight: 600;
    height: 7vw;
    width: 40vw !important;    
  }

  .voters_data_first_name_age_caste_main {
    display: flex;
    justify-content: space-between;

  }
  .edit_icon_main_div_voters{
    margin-top: 5vw
  }
  .trash_main_div_voters{
    margin-top: 5vw
  }
.voter_data_child_div_first_name_age_caste{
  margin-top: 2.5vw;
}
  .voters_data_first_name_age_caste_main span {
    color: #263659;
    font-size: 3.888vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 4.16vw;
    /* background-color: gray !important;
    width: 50vw; */
  }
  .voters_display_first_name_{
    text-align: left;
    /* background-color: red !important; */
    /* width: 100%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .voters_display_last_name_{
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .voters_display_gender_{
    text-align: left;
    width: 15vw !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  .voters_display_caste_{
    text-align: left;
    width: 15vw !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
.checkbox_voters_Data{
  width: 10%;
  margin-top: -1vw;
}
.voters_Data_voters_id_dispaay_list_checkbox{
height: 4.05vw !important;
    width: 4.05vw !important;
}
.voters_data_filter_dropdown {
  right: 9.2%;
  top: 39.5vw;
  /* height: 59.927083vw !important; */
  height: 98.927083vw !important;
  width: 82.444444vw;
  /* height: 16rem!important; */
}
.voters_data-custom-input-range{
  background-color: red !important;
  margin-top:10vw!important
}
.input-range {
  height: 1rem;
  position: relative;
  width: 90% !important;
  margin-top: 5vw !important;
  margin-left:3vw !important
}
#ps_Text_div{
  height: 100% !important;
  margin-top: 2vw !important;;
  margin-bottom: 2vw !important;;
 }

#ps_text_checkbox{
  margin-top: 2vw !important;
}
.votersdata_checkboxesdropdown {
  position: absolute;
  right: 0%;
  left: 70vw;
  height: 1vw !important;
  width: 1vw;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

  .dropdown-toggle {
    height: 7.65625vw;
    font-size: 1rem;
  }

  .fields_in_filter_div span {
    font-size: 0.97rem;
    border-bottom: 1px solid lightgrey;
    height: 3vw;
  }

  .horizontal_scroll_votersdata {
    width: 86.38888888888889vw;
    height: 104.72222222222223vw !important;
    left: 6.388888888888889vw;
    border: 1px solid #ccd0d6;
    border-radius: 3px;
    margin-top: 2vw;
  }

  .dropdown-menu {
    width: 96%;
    height: 18vw !important;
  }

  .top_headings_in_voters_data_main_div {
    margin-top: -3px !important;
  }

  .titleDiv_Edit_votersdata {
    width: 45.833333333333336vw !important;
    height: 10.277777777777779vw !important;
    font-size: 3.888888888888889vw !important;
    display: flex;
    align-items: center;
    border: 0.052083333333333336vw solid #ccd0d6;
    color: #263659;
    font-weight: 500;
    position: relative;
    padding-left: 5px;
  }

  .go_topage_main_div {
    width: 51vw;
    position: absolute;
    left: 0%;
    bottom: 4%;
    height: fit-content;
    width: 100%;
    bottom: 7%;
  }
  .go_topage_main_div input {
    border: 0.1vw solid black;
    border-radius: 0.3vw;
    height: 7vw;
    width: 70%;
    font-size: 1vw;
    display: flex;
    align-items: center;
    margin-left: 5.5%;
  }
  .go_topage_main_div input::placeholder {
    font-size: 3vw !important;
  }
  .go_topage_main_div button {
    background: #88a7c6;
    border: none;
    margin-left: 5%;
    height: 7vw;
    width: 13vw;
    border-radius: 0.3vw;
    color: #ffffff;
    font-weight: 500;
  }
  .view_voters_first_name,.view_voters_last_name{
    height: auto !important;
  }
}

.delete_popup_votersdata {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  height: 100vh;
  width: 100%;
  left: 0%;
  top: 0%;
  z-index: 1;
}

.delete_popup_voters_subDiv {
  height: 9.635416666666666vw;
  width: 20.78125vw;
  background-color: #ffffff;
  opacity: 1 !important;
  z-index: 1041;
  position: absolute;
  left: 40vw;
  top: 20vw;
  border-radius: 20px 20px 20px 20px;
background: #FFF;
}

.delete_popup_voters_data_delete_text_div {
  height: 5vw;
  width: 80%;
  margin-left: 11%;
  margin-top: 3.458333vw;
  text-align: left;
}

.delete_popup_voters_data_delete_text_div span {
  font-size: 1.1458333333333333vw;
  color: #1c2943;
  font-weight: 501;
  font-family: "Montserrat";
}

.titleDiv_Edit_votersdata {
  width: 12.4vw;
  height: 2.8645833333333335vw;
  display: flex;
  align-items: center;
  border: 0.052083333333333336vw solid #ccd0d6;
  color: #263659;
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  position: relative;
}

.delete_voters_dismiss {
  font-weight: 700;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  background-color: #ffffff;
  border: none;
  position: absolute;
  left: 2.3vw;
  bottom: 2.7vw;
  color: #1c477a;
}

.delete_voters_continue {
  font-weight: 700;
  font-size: 1.0416666666666667vw;
  font-family: "Montserrat";
  background-color: #e85b5b;
  color: #ffffff;
  border: none;
  position: absolute;
  right: 1vw;
  bottom: 2.5vw;
  width: 7.864583333333333vw;
  height: 2.1875vw;
  border-radius: 1.5625vw;
}

.delete_popup_voters_innerDiv {
  height: 100%;
  width: 100%;
  position: relative;
}

.loadingscreenI2connect_votersdata {
  height: 100vh;
  width: 100%;
  background-color: black;
  position: fixed;
  opacity: 0.89;
  z-index: 1040;
  top: 0%;
  left: 0%;
  right: 0%;
}

@keyframes logoanimation {
  0% {
    left: 0%;
    background-color: #04a559;
  }

  50% {
    left: 100%;
    background-color: rgb(250, 110, 63);
  }

  100% {
    left: 0%;
    background-color: #04a559;
  }
}

.constituency_polygon_img {
  position: absolute;
  right: 1vw;
  top: 1vw;
  height: 0.5291666666666666vw;
  width: 1vw;
  transition: 0.3s linear;
  z-index: 50;
}

.titleDiv_Edit_votersdata {
  width: 12.4vw;
  height: 2.8645833333333335vw;
  display: flex;
  align-items: center;
  border: 0.052083333333333336vw solid #ccd0d6;
  color: #263659;
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  position: relative;
  padding-left: 5px;
}

.csv_download_text {
  color: #ffffff;
  position: absolute;
  left: 10.9vw;
  top: 15.7vw;
  font-size: 0.9275vw;
  font-family: "Montserrat";
  font-weight: 501;
}

.pdsmessage_div {
  height: fit-content;
  width: fit-content;
  padding: 0.8vw;
  background-color: white;
  position: absolute;
  z-index: 1040;
  text-align: center;

  right: 0%;
  animation: pdferrormsg 3s linear;
}

.pdsmessage_div span {
  color: red;
  font-size: 0.7vw;
  font-family: "Montserrat";
  font-weight: 501;
}

.parent_Loading_holder {
  height: 12vw;
  width: 12vw;
  margin-left: 0vw !important;
  position: relative;
}

.loading_logo {
  height: 40%;
  width: 50%;
}

.Loading_ring {
  height: 10vw;
  width: 10vw;
  position: absolute;
  /* left: 0%; */
  pointer-events: none;
  animation: loadingring 2s linear infinite;
}

@keyframes loadingring {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .titleDiv_votersdata {
    height: 5vw;
    width: 15vw;
  }

  .titleDiv_votersdata span {
    font-size: 1.2vw;
  }

  .top_heading_in_voters_data {
    height: 5vw;
    width: 15vw;
  }

  .pdfcenter_Logo {
    width: 1.2vw;
    height: 1.9vw;
  }

  .top_heading_in_voters_data span {
    font-size: 1.2vw;
  }

  .voters_data_view_delete_maindiv {
    width: 9vw;
    top: 4vw;
  }

  .titleDiv_Edit_votersdata {
    height: 5vw !important;
    width: 15vw;
  }

  .titleDiv_Edit_votersdata span {
    font-size: 1.3vw;
  }

  .polygon__votersdata {
    height: 0.8vw;
    width: 1.1vw;
    margin-left: 0.5vw;
  }

  .votersdata_text_ems {
    font-size: 2vw;
  }

  .votersdata_text_maindiv {
    height: 5vw;
  }

  .search_voter_id {
    height: 3vw;
    margin-top: 1vw;
    margin-left: 18%;
  }

  .delete_btn_voters_data {
    font-size: 1.3vw;
  }

  .add_new_voter_btn {
    height: 3vw;
    width: 10vw;
    top: 1vw;
    left: 51%;
  }

  .refreshlogo {
    height: 3vw;
    width: 4vw;
    top: 1vw;
    left: 64%;
  }

  .filter {
    height: 3vw;
    width: 10vw;
    top: 1vw;
    left: 70%;
  }

  .excelllogo {
    height: 3vw;
    width: 4vw;
    top: 1vw;
    left: 83.5%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .voters_data_holder_main_div {
    height: 100% !important;
    width: -moz-fit-content;
    width: fit-content;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-left: 0%;
}
.delete_popup_votersdata {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  height: 100vh;
  width: 100%;
  left: 0%;
  top: 0%;
  z-index: 1;
}
.delete_popup_voters_subDiv {
  height: 20.635417vw;
  width: 40.78125vw;
  background-color: #ffffff;
  opacity: 1 !important;
  z-index: 1041;
  position: absolute;
  left: 27vw;
  top: 4vw;
  border-radius: 20px 20px 20px 20px;
  background: #FFF;
}
.delete_popup_voters_data_delete_text_div span {
  font-size: 2.145833vw;
}
.delete_voters_dismiss {
    font-weight: 700;
    font-size: 2.0416666666666667vw;
    font-family: "Montserrat";
    background-color: #B3C8E8;
    border: none;
    position: absolute;
    left: 2.3vw;
    width: 14vw;
    height:4vw;
    bottom: 6.7vw;
    color: #1c477a;
}
.delete_voters_continue {
  font-weight: 700;
  font-size: 2.0416666666666667vw;
  font-family: "Montserrat";
  background-color: #e85b5b;
  color: #ffffff;
  border: none;
  position: absolute;
  right: 1vw;
  height:4vw;
  bottom: 6.8vw !important;
  width: 14vw;
}
.view_voters_data_popup_main_div{
  position: absolute;
  right:15vw;
  top:30vw;
  width: 70vw;
  background-color: #FFFFFF;
  opacity: 1 !important;
    z-index: 1041;
    /* border-radius: 20px 20px 20px 20px; */
    border-radius: 10px;

    background: #FFF;
}
.view_voters_popup_sub_div_bottom{
  display: flex;
  margin-top:1.5vw;
  margin-bottom: 1vw;;
}
.view_voters_voter_id_display{
  font-size: 2.5vw;
  color:#263659;
  font-weight: 600!important;
  width: 20vw;
  margin-left:2vw;

}
.pencil_icon_view_voters_{
  margin-left:23vw;
}
.pencil_icon_mobile_,.delete_icon_mobile_,.cross_img_{
  height:3.5vw !important;
  width: 3.5vw !important;
  margin-left:3vw
}

.popup_newvotrs_underlines_1{
  height:0.2vw;
  background-color: #CCD0D6;
}
.popup_view_voters_main_content_div{
  display: flex;
  flex-direction: row;
  margin-top:2vw;
  margin-bottom:2vw;

}
.view_voters_first_name{
  width: 33vw;
  margin-left:2vw;
  height:7vw;
  height: auto !important;

}
.view_voters_first_name_name{
  font-size: 2.3vw;;
  color: #606B83;
  font-weight: 600;
  margin-top:1vw;
  
}
.view_voters_last_name,.view_voters_gender,.view_voters_mobile{
  height:7vw;
  width: 32vw;
  margin-left:2vw;
  height: auto !important;

}
.popup_view_voters_text{
  font-size: 2vw;
  color:#263659;
  font-weight: 700;
  /* width: 40vw; */
  white-space: wrap !important;
  word-break: break-all;
}
  #canclebt_in_voters_data_dropdowns {
    width: 9vw !important;
   
    border-radius: 0.2vw !important;
    height: 5vw !important;
    bottom: 3%;
    font-size: 2vw;

  }
  .applybtn_in_voters_data_dropdowns {
    border-radius: 0.2vw !important;
    border: none;
    width: 9vw !important;
    height: 5vw !important;
    font-size: 2vw;
   bottom: 3%;
   background: #1C477A;
    border: none;
}
.search_option_votersdata_dropdown::placeholder {
  font-size: 2vw!important;
}
.search_option_votersdata_dropdown {
  border-radius: 0.3vw!important;
  background: #E4F0FF;
  font-size: 1.7vw!important;
}
.dropdown_selected_count {
  font-size: 1.333333333333334vw;
}
.nodata_found_message {
  font-size: 1.433333333333334vw;

}
.dropdown-options_pollingstation {
  position: absolute;
  top: 35vw;
  max-height: 8vw;
  background-color: #ffffff !important;
  /* background-color: red !important; */
  width: 96%;
  margin-left: 2%;
  overflow-y: scroll;
  z-index: 50;
  border-radius: 0.5vw;
  animation: dropdown 0.5s linear;
  z-index: 51;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 0.5vw;
    animation: dropdown 0.5s linear;
}
.dropdown-option {
    height: 5.5vw;
    border: 0.1vw solid #d9d8d8;
    position: relative;
    width: 100%;
    background-color: #ffffff;
}
.dropdown-option span {
  margin-left: 2%;
  font-size: 1.8vw!important;
  width: 20vw!important;
}
.votersdata_checkboxesdropdown {
  height: 2.4vw !important;
  width: 2.4vw !important;
  background: none !important;
}
  .titleDiv_votersdata {
    height: 6vw;
    width: 30vw;
  }
  .mandal_first_name_votersdata{
    font-size: 2vw !important;
    text-align: left;
    width: 20vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1C477A;
    font-weight: 600 !important;
  }
  .constancy_spandiv {
    text-align: left;
    width: 18vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2vw !important;
    color: #1C477A;
    font-weight: 600 !important;
}
  .constituency_polygon_img {
    position: absolute;
    right: 1vw;
    top: 1vw;
    height: 1.6vw;
    width: 2vw;
    transition: 0.3s linear;
    z-index: 50;
}

  .titleDiv_votersdata span {
    font-size: 2vw;
    width: 16vw !important;
  }
.title_div_first_column{
  color: #046A38!important;
  font-weight: 600;
}
  .top_heading_in_voters_data {
    height: 5vw;
    width: 30vw;
  }

  .top_heading_in_voters_data span {
    font-size: 1.7vw;
    font-weight: 700!important;
  }

  .titleDiv_Edit_votersdata {
    height: 5vw;
    width: 25vw;
  }

  .titleDiv_Edit_votersdata span {
    font-size: 1.8vw;
  }

  .polygon__votersdata {
    height: 0.8vw;
    width: 1.1vw;
    margin-left: 0.5vw;
  }

  .votersdata_text_ems {
    font-size: 2vw;
  }

  .votersdata_text_maindiv {
    height: 5vw;
  }

  .search_voter_id {
    height: 4vw;
    margin-top: 1vw;
    margin-left: 18%;
    border-color: #0047B2;
    width: 33% !important;
  }
  .voterid_search_input::placeholder{
     /* font-size: 1.3vw!important; */
  }
  .voterid_search_input{
    width: 30vw;
    font-size: 1.8vw!important;
    font-weight: 600 !important;

  }
  #voterid_search_input::placeholder{
    font-size: 1.8vw!important;

  }
  .add_new_voter_btn {
    height: 4vw;
    width: 11vw;
    top: 1vw;
    left: 50.5% !important;
  }

  .refreshlogo {
    height: 4vw;
    width: 5vw;
    top: 1vw;
    left: 63% !important;
  }

  .filter {
    height: 4vw;
    width: 11vw;
    top: 1vw;
    left: 69.5% !important;
  }

  .excelllogo {
    height: 4vw;
    width: 5vw;
    top: 1vw;
    left: 81%;
  }

  .pdflogo {
    height: 4vw;
    width: 5vw;
    top: 1vw;
    left: 86% !important;
  }

  .settingslogo {
    height: 4vw;
    width: 5vw;
    top: 1vw;
    left: 92% !important;
  }

  .search_button_voterid {
    height: 2vw;
    width: 7vw;
    margin-right: 1vw;
    font-size: 0.9vw;
  }
  #extra_inputs_for_tab {
    width: 12vw;
}
  .top_nav_holder_main {
    height: 92.5vh;
  }

  .votersdata_container {
    height: 85vh;
    position: relative !important;
  }

  .total_pages {
    height: 4.5vw !important;
    /* width: 12vw !important; */
    font-size: 2.2vw !important;
    width: auto !important;
  }

  .horizontal_scroll_votersdata {
    height: 97%;
    margin-top:2vw;
  }

  .parent_horizontal_scroll_votersdata {
    height: 78vh !important;
  }

  .top_nav_holder_main {
    height: 92vh;
    position: relative;
  }

  .prev_button_votersdata {
    width: 6.83333333333335vw !important;
    height: 4.3270833333333333vw !important;
    font-size: 2vw !important;
  }

  .next_button_votersdata {
    font-size: 2vw;;
    width: 6.83333333333335vw;
    height: 4.3270833333333333vw;
    border-radius: 6px!important;

  }
  .prev_button_votersdata,.next_button_votersdata{
    border-radius: 0px!important;
    }

  .current_page_number_votersdata {
    height: 4.4vw !important;
    width: 3vw !important;
    font-size: 2vw !important;
  }
  .top_heading_in_voters_data{
    background-color: #B3C8E8;
  }
  .buttons_voterdata_next_prev_main_div {
    margin-left: 64.7% !important;
  }
  .go_topage_main_div {
    width: 31vw !important;
    position: absolute;
    left: 30%;
    bottom: 0%;
  }
  .go_topage_main_div input {
    border: 0.1vw solid black;
    border-radius: 0.3vw;
    height: 3.5vw;
    font-size: 1vw;
    display: flex;
    align-items: center;
  }
  .go_topage_main_div input::placeholder {
    font-size: 2vw !important;
  }
  .go_topage_main_div button {
    background: #88a7c6;
    border: none;
    height: 3.5vw;
    width: 6vw;
    border-radius: 0.3vw;
    color: #ffffff;
    font-weight: 500;
  }
}

/* YourComponentStyles.css */

.floating-print-button {
  /* position: fixed; */
  /* bottom: 13rem; */
  position: absolute;
  /* right: 2.5rem; */
  right:7.8vw !important;
  /* background-color: #007bff; */
  /* background: #B3C8E8; */
  padding: 12px;
  /* border-radius: 5px; */
  cursor: pointer;
  /* z-index: 999;
  font-size: 3vw;
  color: #0047B2 !important;
  font-weight: 700; */
}


@media (max-width: 767px) {
 .search_option_votersdata_dropdown{
  background-color: red;
  border-radius: 5px;
background: #E4F0FF;
 }
 .search_option_votersdata_dropdown::placeholder{
 color: #457BBC;
font-size: 3.88vw;
font-style: italic;
font-weight: 600;
 }
 .dropdown-option{
  border-radius: 5px;
  background: #FFF;
  border: none;
 }
 .votersdata_checkboxesdropdown{
  width: 4vw !important; 
  height:4vw !important;
  box-shadow: none;
 }
 .mandal_first_name_votersdata{
  color: #1C477A;
font-size: 4vw;
font-weight: 600;
 }
 .constancy_spandiv{
  font-size: 4vw;
  font-weight: 700;
  color: #1C477A;

 }
 .dropdown-option span{
  color: #444;
font-size: 4.4vw;
font-weight: 600;
 }
 .applybtn_in_voters_data_dropdowns{
  background: #1C477A;
  border: none;
 }
 .dropdown_constituency_sub_div {
  margin-top:2vw;

 }
 .voters_data_printer_img{
  height:6.94vw;
  width: 6.94vw;
 }
 .data_not_available_voters{
  color: #606B83;
font-size: 3.888vw;
font-weight: 600;
display: flex;
justify-content: center;
margin-top:40vw;
 }

}

@media (max-width: 767px) {
  .itoconnect_voters_id_child_div_main{
    height:58vh;
  }
  .buttons_voterdata_next_prev_main_div{
    display: none !important;
  }
  .buttons_footer_voters_data{
    display: flex;
    justify-content: center!important;

  }
  .prev_button_votersdata{
   margin-left:0vw!important;
  }
}
@media screen and (max-width: 824) and (min-width: 320p) {
  .itoconnect_voters_id_child_div_main{
    height:57vh;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
.itoconnect_voters_data_mobile_main_div{
  display: none !important;
}
.votersdata_text_ems {
  display: none !important;
}
.votersdata_text_maindiv {
  height: 6vw;
}
.search_voter_id {
  margin-left: 0.3%;
}
.add_new_voter_btn {
  left: 44%;
}
.refreshlogo {
  left: 55.5%;
}
.filter {
  left: 60.5%;
}
.excelllogo {
  left: 80%;
}
.pdflogo {
  left: 75%;
}
.settingslogo {
  left: 80%;
}
.votersdata_container {
  height: 5vh !important;
}
.votersdata_container {
  height: 3vh !important;
}
.voterid_search_input::placeholder{
  margin-bottom: 0px;;
}
.buttons_voterdata_next_prev_main_div_new_design{
  display: none !important;
}
.parent_horizontal_scroll_votersdata {
  height: 70vh!important;
}

.prev_button_votersdata,.next_button_votersdata{
  border-radius: 6px !important;
}


}


/* //Start Desktop*/



.itoconnect_voters_data_mobile_main_div{
  display: none !important;
}

.prev_button_votersdata,.next_button_votersdata{
  border-radius: 0px!important;
  }
  
  .voterid_search_input::placeholder{
    margin-bottom: 10px!important;
  }

@media (max-width:767px){
  .itoconnect_voters_data_mobile_main_div{
    display: block !important;
  }
  .buttons_voterdata_next_prev_main_div_new_design{
    display: flex;
    margin-top:2.8vw;
    margin-bottom:32vw;

    justify-content: center;
  }
 
  .buttons_voterdata_next_prev_main_div{
    display: none;
  }

}


@media screen and (max-width: 1024px) and (min-width: 768px) {
.voterid_search_input{
  margin-bottom: 0px !important;
}
.buttons_footer_voters_data{
  display: none;
}
.parent_horizontal_scroll_votersdata {
  height: 74vh !important;
}
.votersdata_container {
  height: 5vh !important;
}
/* startactionfield */
.titleDiv_Edit_votersdata {
  display: none !important;
}
#extra_inputs_for_tab{
  display: none!important;
}
/* Endactionfield */
.input-range {
  height: 1rem;
  position: relative;
  width: 90% !important;
  margin-left:1vw;
  margin-top: 2vw !important;;

}
#ps_Text_div{
  height: 100% !important;
  padding: 1vw !important;
  /* margin-top: 1vw !important;; */
  /* margin-bottom: 1vw !important;; */
 }

#ps_text_checkbox{
  margin-top: 2vw !important;
}
.voters_data_filter_dropdown {
  height: 20.927083333333332vw;
  width: 40%;
  background-color: #ffffff;
  position: absolute;
  right: 0%;
  z-index: 50;
  top:6vw!important;
  height: 52vw !important;
  /* max-height:100%!important; */

  /* bottom: -17vw !important; */
  border-radius: 0.20833333333333334vw;
  box-shadow: 0vw 0.20833333333333334vw 0.5208333333333334vw;
  animation: mandaldrop 0.2s linear;
}
.dropdown_constituency_sub_div, .mandal_dropdown-header, .dropdown_village_subdiv, .pollingstation_dropdown-header {
  /* width: 61.111111111111114vw !important; */
  /* width: 79vw !important; */
  /* height: 8.61111111111111vw !important; */
  height: 5vw !important;
  margin-top:1vw!important;
}
}