.usermanagement_main_div {
  height: 100vh;
  width: 100%;
  margin-top: 4vw;
  position: relative !important;
}
.user_management_dashboard-main_div {
  height:3.84vw;
  width: 95%;
  margin-left: 2% !important;
  border-bottom: 0.10416666666666667vw solid black;
}
#user_managemnt_title{
  color: #263659;
  font-family: Montserrat;
  font-size:1.45833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.user_management_left_buttons {
  position: absolute;
  right:9.5vw;
  margin-top: -2.9vw;
  gap:10px;
  display: flex;

}

.user_management_assingn_voter{
  height:2.18vw;
  width: 8.5vw;
  border-radius: 4px;
background: #1C477A;
color: #FFF;
font-family: Montserrat;
font-size: 0.9375vw;
font-style: normal;
font-weight: 500;
line-height: normal;
border:none;
}
.user_management_add_user{
  height:2.18vw;
  width: 8.5vw;
  border-radius: 4px;
  background: #1C477A;
  color: #FFF;
  color: #FFF;
font-family: Montserrat;
font-size: 0.9375vw;
font-style: normal;
font-weight: 500;
line-height: normal;
border:none;
}
.parent_horizontal_scroll_user_managementdata{
  margin-left: 1%;
  width: 83vw;
}
.horizontal_scroll_user_managementdata {
  margin-left: 1vw;
  height: 70vh;
  margin-top: 0.7vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  margin: 4px, 4px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
#usermanagement_title{
  margin-left:13%;
}
.user_data_text_div{
  margin-left:13%;
  position: relative;
  /* top:1vw !important; */
}
.data_main_div_usermanagement {
  background-color: #e8ecf1;
}
.data_main_div_usermanagement span{
  width: 13.1vw;
}
.heading_div_userManagement_data {
  height: 2.8645833333333335vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  background-color: #e8ecf1;
  display: flex;
  align-items: center !important;
  font-size: 0.7291666666666666vw !important;
  font-family: "Montserrat" !important;
  font-weight: 501 !important;
  color: #4d7093 !important;
  width: 13.2vw;
}
.heading_div_userManagement_data_holder{
  display: flex;
  align-items: center;
  font-size: 0.7291vw;
  color: #263659;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 13.2vw;
  height: 2.65625vw;
  border: 1px solid #ccd0d6;
  background: #fff !important;
}
.heading_div_userManagement_edit{
  margin-left: 13%;
}
.heading_div_userManagement_view{
  margin-left:0.7vw;
}
.heading_div_userManagement_activebutton{
    margin-left: 0.8vw;
    height: 1.8vw;
    width: 4.5vw;
    border-radius: 4px;
    background: #168f2f;
    color: #FFF;
    line-height: normal;
    border: none;
    font-weight: 800;
}
.heading_div_userManagement_dltbutton{
  margin-left: 0.8vw;
    height: 1.8vw;
    width: 4.5vw;
    border-radius: 4px;
    background: #e51515;
    color: #FFF;
    line-height: normal;
    border: none;
    font-weight: 800;
}
.user_data_text_div {
  overflow: hidden;
}
.user_management_underlines_holder_main {
  margin-top: 1vw;
}
.user_management_underline_one {
  background-color: #ccd0d6;
  width: 95%;
  height: 0.1vw;
  margin-top: 0.1vw;
  margin-left:1.8vw;
  
}
.buttons_usermanagement_next_prev_main_div {
  margin-left: 70% !important;
  margin-top: 1vw;
}
.total_pages_usermanagement {
  background-color: #88a7c6;
  width: fit-content;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-weight: 800;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  padding: 0.5vw;
  position: absolute;
  left: 2%;
}
.prev_button_usermanagement {
  background-color: #88a7c6;
  width: 2.7083333333333335vw;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  text-align: center;
  font-family: "Montserrat";
  outline: #88a7c6 !important;
  margin-right: 1%;
  cursor: pointer;
  font-weight: 800;
  margin-left: 16.5vw;
}

.next_button_usermanagement {
  background-color: #88a7c6;
  width: 2.7083333333333335vw;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-family: "Montserrat";
  text-align: center;
  outline: #88a7c6 !important;
  margin-left: 1%;
  cursor: pointer;
  font-weight: 800;
}
.current_page_number_usermanagement {
  background-color: #88a7c6;
  width: fit-content;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-weight: 800;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vw;
}

#item_userman_email{
margin: 0;
display: flex;
}

#item_userman_email span {
  width: 9vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.user_mangementvoters_data_view_delete_maindiv {
  /* height: 100%; */
height:4.5vw;
  display: flex !important;
  flex-direction: column;
  border-radius: 0.10416666666666667vw;
  position: absolute;
  z-index: 1;
  top: 4vw;
  left: 94vw;
  width: 4.9vw;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);
}
.usermanagement_edit{
  margin-left: 11%;
  /* position: relative;
  top: 1vw !important;
  left:1vw; */
}
.hrline_view_delete {
  border: 1px solid #ccd0d6;
  margin: 0;
  width: 100%;
}
#view{
  padding:0.5vw;
  text-align: center;
  
}

.delete_btn_voters_data {
  /* height: 1.5vw;
  display: flex;
  justify-content: center;
  color: #eb7070;
  padding: 0.1vw;
  cursor: pointer;
  font-size: 0.729167vw;
  padding: 0.3vw;
  width: 100%; */
}

.user_active{
  color: #EB7070;
font-family: Montserrat;
font-size: 0.729vw;
font-style: normal;
font-weight: 500;
line-height: normal;
}




@media screen and (max-width:767px){
  .user_management_dashboard-main_div {
    margin-top:20vw;
    color: #263659;
   font-family: Montserrat;
  height:14vw;
  }
#user_managemnt_title{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 5vw !important;
  color: #263659;
  line-height: 6.1111vw;
  margin-left:6vw;
}
  .user_management_left_buttons {
    flex-direction: column;
  }
  #we{
    width: 33vw;
    height:10vw;
    position: relative;
    top:-9vw;
   left:2vw;

  }
  .user_management_assingn_voter{
     width: 33vw !important;
     height:10.277vw;
    border-radius: 4px;
  background: #1C477A;
  color: #FFF;
  font-family: Montserrat;
  font-size: 3.88vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border:none;

  }
  #ad{
    position: relative;
    top:-7.5vw;
    background-color: red;
    width: 35vw;
    height:10.277vw;
    margin-left:-30vw;
  }
  .user_management_add_user{
    border-radius: 4px;
    background: #1C477A;
    color: #FFF;
  font-family: Montserrat;
  font-size: 3.88vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border:none;
  width: 35vw !important;
  height:10.277vw;
  }
  .parent_horizontal_scroll_user_managementdata{
    height: 68vh!important;
    width: 88%;
    margin-left: 6.9%;
    position: relative;
    top:14vw;
  }
  .horizontal_scroll_user_managementdata {
    width: 96.1%;
    height: 98%;
    left: 1%;
    position: absolute;
    margin-top: 2vw;
    border: 0.052083333333333336vw solid #ccd0d6;
    margin: 4px, 4px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .data_main_div_usermanagement {
    background-color: #e8ecf1;
  }
  .data_main_div_usermanagement span{
    width: 46vw;
  }
  .heading_div_userManagement_data {   
    font-size: 3.3333333333333335vw !important;
    line-height: 4.166666666666667vw !important;
    font-weight: 500 !important;
    width: 45.833333333333336vw!important;
    height: 10.277777777777779vw;
  }
  .heading_div_userManagement_data_holder {
    height: 2.65625vw;
    border: 1px solid #ccd0d6;
    background: #fff !important;
  }
  .heading_div_userManagement_data_holder{
    font-size: 3.3333333333333335vw !important;
    line-height: 4.166666666666667vw !important;
    font-weight: 500 !important;
    width: 45.833333333333336vw!important;
    height: 10.277777777777779vw;
  }
  .user_management_underlines_holder {
    margin-top: 18%;
    margin-left: 0vw;
    margin-bottom: 3.0555555555555554vw;
    display: flex;
    flex-direction: column;
    gap: 0.7vw;
  }
  .user_management_underline_one {
    background-color: #ccd0d6;
    width: 85%;
    height: 0.2vw;
    margin-top: 0.1vw;
    margin-left: 8vw;
  }
  .buttons_usermanagement_next_prev_main_div{
    margin-left:40vw !important; 
  }
  #usermanagement.total_pages_usermanagement {
    left: 8vw !important;
    width: 30vw !important;
    height:10vw;
color: #FFF;
font-family: Montserrat;
font-size: 3.3333333333333335vw;
font-style: normal;
font-weight: 500;
line-height: normal;

  }
  .prev_button_usermanagement,
  .next_button_usermanagement {
    width: 13.333333333333334vw;
    height: 9.722222222222221vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.3333333333333335vw;
    line-height: 4.166666666666667vw;
  }
  .current_page_number_usermanagement {
    width: 6.944444444444445vw;
    height: 9.722222222222221vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 3.3333333333333335vw;
    line-height: 4.166666666666667vw;
  }
  .heading_div_userManagement_activebutton {
    height: 5.8vw;
    width: 15.5vw;
  }
  .heading_div_userManagement_dltbutton {
    height: 5.8vw;
    width: 15.5vw;
}
}


@media screen and (max-width: 1024px) and (min-width: 768px){
  .heading_div_userManagement_activebutton {
    height: 5.8vw;
    width: 15.5vw;
  }
  #user_managemnt_title { 
    font-size: 2vw;
  }
  .usermanagement_main_div {
    height: 92vh;
  }
  .user_management_left_buttons {
    margin-top: -3.84vw;
  }
  .user_management_assingn_voter{
    height:2.5vw;
    margin-top: 0.65vw;
  }
  .user_management_add_user{
    height:2.5vw;
    margin-top: 0.65vw;
  }
  .data_main_div_usermanagement span{
    width: 13.09vw;
  }
  .heading_div_userManagement_data {
    font-size: 1.2vw !important;
  }
  .user_data_text_div{
    font-size: 1.2vw !important;
  }
  .heading_div_userManagement_data_holder span{
    font-size: 1.2vw !important;
  }
  .heading_div_userManagement_data_holder{
    height: 5vw !important;;
  }
  .horizontal_scroll_user_managementdata {
    height:76vh;
  }

}