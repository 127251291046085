.popup_sign_out_main_div {
  // position: absolute;
  right: 8%;
  border: 0.1vw solid #46607e;
  height: 4.166666666666667vw;
  min-width: 11.6145833333vw;
  /* width: 11.614583333333334vw; */
  top: 0%;
  cursor: pointer;

  .hidden-text{
    display: block;
  }
  .username {
    display: flex;
    width: 11.614583333333334vw;
    align-items: center;
    justify-content: space-evenly;
  }

  .username_container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  .popupcontainer {
    position: relative;
  }

  #popupmaindiv {
    position: absolute;
  }
 

  .my-dropdown-menu {
    column-count: 2;
    column-width: 50%;
    column-gap: 1rem;
  }

  #dropdown-basic-button+div.dropdown-menu.show {
    position: absolute;
    top: 13px !important;
    background-color: #383b3c;
    padding-bottom: 0px;
    right: -5px;
    padding-right: 0px;
    border-radius: 0px;
    border: none;
    width: 35.83333vw;
    overflow-y: unset;
  }

  #dropdown-basic-button+div.dropdown-menu.show::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: #383b3c;
    position: absolute;
    top: -6px;
    right: 2.5vw;
    z-index: -1;
  }

  #dropdown-basic-button,
  .userprofiledropdown {
    background-color: #383b3c !important;
    border: none;
    display: flex;
    align-items: center;
    margin-top: 0px;
    color: #FFFFFF;
  }
.heading_line{
  background: #1C477A;
border-radius: 4px 4px 0px 0px;
height: 0.8vw;
width: 100%;
}
  .contsiner_div_popup {
    width: 35.8333333333vw;
    padding: 1.5vw;
    background-color: #fff;
    height: fit-content;
    padding-right: 10px;
  }

  .signout_no {
    background: #7c8081;
    border: none;
  }

  .signout_yes {
    background: #1c477a;
    border: none;
  }
 

  .admin_btn {
    color: #ffffff;
    font-size: 1vw;
    font-weight: 800 !important;
    height: 100% !important;
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #92aece;
    height: 10vw;
    width: 10vw;
  }

  .profileImage {
    // width:10.2083333333vw;
    // height: 10.2083333333vw;
    // border:1px solid #92AECE;
  }

  .profile img {
    width: 6.7708333333vw;
  }

  /* new */
  .admin_logout_block {
    width: 11.614583333333334vw;
    /* right: 8%; */
    border: 0.1vw solid #46607e;
    height: 100%;
    top: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer !important;
    /* left: 9%; */
    /* top: 1.5vw; */
  }

  /* new */
  .pop_up_main {
    display: none;
    position: absolute;
    top: 1.916666vw;
    right: -11.6vw;
    width: 35.833vw;
    height: 19.8958vw;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(148, 148, 148, 0.25);
    border-radius: 4px;
  }

  .pop_up_sub::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: #1c477a;
    position: absolute;
    top: -8px;
    right: 2.5vw;
    z-index: -1;
  }

  .pop_up_sub {
    height: 0.5729166vw;
    background: #1c477a;
    border-radius: 4px 4px 0px 0px;
  }

  .ems_name {
    font-style: normal;
    font-weight: 400;
    font-size: 1.041666666vw;
    margin-bottom: 0px;
    color: #263659;
  }

  .hr_tag {
    border: 1px solid #d2d5db;
    margin: 0px;
  }

  .tools,
  .user_set {
    font-style: normal;
    font-weight: 600;
    font-size: 0.9375vw;
    line-height: 1.14583333vw;
    color: #e62c3b;
    text-decoration: auto;
  }
  .hidden-text{
    display: block;
  }

  .user_settings {
    font-style: normal;
    font-weight: 400;
    list-style: none;
    font-size: 0.9375vw;
    /* font-size: 18px; */
    line-height: 1.14583333vw;
    /* position:absolute; */
    /* left:12.80416666vw; */

    top: 4.4vw;
  }

  .size {
    font-style: normal;
    font-weight: 600;
    font-size: 0.83333vw;
    line-height: 1.041666vw;
    /* identical to box height */
    color: #263659;
    margin-top: 10px;
  }

  .hr_tag2 {
    // border:1px solid #D2D5DB;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .emsheading {
    display: flex;
    align-items: center;
    height: 2.2916666667vw;
    justify-content: space-between;
    background-color: red;
    margin-top: 0%;
  }

  .namedisplaymobileversion {
    display: none;
  }

  .cancel_button {
    margin-left: 2vw;
    background: #ffffff;
    border: 1px solid #bacadd;
    border-radius: 4px;

    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.14583333vw;
    /* identical to box height */
    color: #1c477a;
    /* padding:8px; */
    width: 4.32291vw;
    height: 2.1875vw;
  }

  .vec {
    height: 1vw;
    width: 0.833vw;
    position: relative;
    left: -0.7vw;
    top: -0.15vw;
  }

  .sign_out {
    background: #1c477a;
    border: 1px solid #bacadd;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    line-height: 1.14583333vw;
    /* identical to box height */
    color: #ffffff;
    width: 7vw;
    height: 2.1875vw;
    /* padding:8px; */
  }

  .choicebuttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .popup-arrow {
    display: none;
  }

  .logout_lock_image {
    position: absolute;
    height: 1.3671875vw;
    width: 1.0416666666666667vw;
    right: -30%;
    top: 1.4vw;
    cursor: pointer;
  }

  .profile_logo {
    width: 1.0416666666666667vw;
  }

  .toolsusersettingslayout {
    display: flex;
  }

  #dropdown-basic-button::before {
    background-repeat: no-repeat;
    content: "";
    background-image: url("../../Assets/profilelogo.png");
    display: inline-block;
    width: 1.5vw;
    height: 1vw;
    margin-right: 0.78125vw;
    background-size: 1.0416666666666667vw 1.0416666666666667vw;
    background-position: center center;
    position: relative;
  }

  #popstyle {
    font-size: 1vw;
  }

  #popupcontainerprofile {
    padding-left: 0px !important;
  }

  @media screen and (max-width: 767px) {
    .hidden-text{
      display: none !important;
    }
  
    div.a {
      width: 14px;
      height: 14px;
      margin-left: 1.8vw;
      margin-top: 7.1vw;
      background-color: #1c477a;
      transform: rotate(45deg);
      background-color: red;
    }

    .img_insertion {
      width: 37.77777777777778vw;
      height: 37.77777777777778vw;
      top: 39.189583vw;
    }

    .hr_tag2 {
      top: 54.625vw;
    }

    .cancel_button {
      width: 23.055555555555557vw !important;
      height: 10.666667vw !important;
      font-size: 5vw !important;
      left: 5.77vw;
      top: 93vw;
    }

    .sign_out {
      width: 37.5vw !important;
      height: 11.222222vw !important;
      font-size: 5vw !important;
      left: 48.90625vw;
      top: 91.95vw;
    }

    .size {
      font-size: 3.888888888888889vw;
      left: 23.5vw;
      top: 80.777vw;
    }

    .vec {
      height: 5vw;
      width: 3.833vw;
      left: -2.7vw;
      top: -0.4vw;
    }

    .popupcontainer {
      padding-left: 0px;
      height: 5vw;
      margin-right: 5vw;
    }

    .admin_btn,
    .svg-inline--fa {
      display: none;
    }

    .profile_logo {
      width: 5vw;
    }

    .username {
      width: auto;
    }

    .user_settings {
      font-size: 4.166666666666667vw !important;
      line-height: 4.722222222222222vw !important;
      top: 15vw;
      left: 48vw;
    }

    .tools,
    .user_set {
      font-size: 3.166667vw !important;
      line-height: 4.722222222222222vw !important;
    }

    .pop_up_main {
      width: 91.66666666666667vw;
      height: 109.055556vw;
      right: -12.5vw;
      top: 7.916666vw;
    }

    .ems_name {
      font-size: 5vw;
      line-height: 6.111111111111111vw;
      padding-left: 4vw;
      padding-top: 2vw;
    }

    .list_1 {
      font-size: 3.888888888888889vw;
      line-height: 4.722222222222222vw;
      padding: 0;
      margin-left: 5vw;
      /* top: 1vw; */
    }

    span#popstyle {
      font-size: 3.166667vw !important;
    }

    .hr_tag {
      border: 1px solid #d2d5db;
    }

    hr {
      position: initial;
      opacity: 1.25;
    }

    .image_size_popup {
      font-size: 3.166667vw !important;
    }
  }

  @media (max-width: 373px) {
    .list_1 {
      font-size: 3.888888888888889vw;
      line-height: 4.722222222222222vw;
      padding: 0;
      margin-left: 5vw;
      /* top: 1vw; */
    }
  }


  // new code

  * {
    font-family: "Montserrat";
    padding: 0;
    box-sizing: border-box;
  }

  .popupMainDiv_profile {
    height: 23vw;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0vw 0.20833333333333334vw 0.5208333333333334vw rgba(148, 148, 148, 0.25);
  }

  .ems_text_heading_popup {
    height: 3vw;
    width: 96%;
    border-bottom: #d2d5db 0.1vw solid;
  }

  .ems_heading_text {
    font-size: 1.0416666666666667vw;
  }

  .profile_popup_center_div {
    height: 70%;
    width: 96%;
    border-bottom: #d2d5db 0.1vw solid;
  }

  .tools_text_popup {
    font-size: 0.9375vw;
    color: #e62c3b;
    font-weight: 600;
  }

  .user_settings_main_div_popup {
    margin-left: 2vw;
    margin-top: 1.5vw;
  }

  .tools_popup_main_div {
    // margin-left: 1vw;
    margin-top: 1.5vw;
  }

  .userSettings_text_span {
    font-size: 0.9375vw;
    color: #e62c3b;
    font-weight: 600;
  }

  .tools_and_usersettings_text {
    font-size: 0.9375vw;
  }

  .profile_pic_upload_div {
    width: 10.208333333333334vw;
    height: 10.208333333333334vw;
    border: 0.1vw solid #92aece;
    border-radius: 0.20833333333333334vw;
    margin-top: 1.5vw;
    margin-left: 1.5vw;
  }

  .image_size_text_max {
    font-size: 0.8333333333333334vw;
    margin-left: 1.5vw;
  }

  .profile_upload_center_pic {
    height: 55%;
    width: 55%;
  }

  .signout_and_cancel_main_div {
    margin-top: 0.8vw;
    height: fit-content;
    width: 92%;
  }
  

  .cancel_button_popup {
    border: #bacadd 0.1vw solid;
    height: 2.1875vw;
    width: 4.322916666666667vw;
    background-color: #ffffff;
    font-size: 0.9375vw;
    border-radius: 0.20833333333333334vw;
  }

  .sign_out_popup_profile {
    width: 7.03125vw;
    height: 2.2916666666666665vw;
    margin-bottom: 1vw;
    font-size: 0.9375vw;
    background-color: #1c477a;
    border: none;
    color: #ffffff;
    border-radius: 0.20833333333333334vw;
  }

  .firstname_div_in_popup {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .popupMainDiv_profile {
      height: 118.05555555555556vw;
      box-shadow: 0vw 1.1111111111111112vw 2.7777777777777777vw rgba(148, 148, 148, 0.25);
    }

    .ems_text_heading_popup {
      height: 11.666666666666666vw;
    }

    .ems_heading_text {
      font-size: 5vw;
    }

    .tools_text_popup {
      font-size: 3.2vw;
    }

    .user_settings_main_div_popup {
      margin-left: 9vw;
    }

    .userSettings_text_span {
      font-size: 3.2vw;
    }

    .tools_and_usersettings_text {
      font-size: 3.2vw;
      font-weight: 400;
    }

    .profile_pic_upload_div {
      height: 37.77777777777778vw;
      width: 37.77777777777778vw;
      margin-top: 9vw;
      border-radius: 1vw;
    }

    .image_size_text_max {
      font-size: 3.3333333333333335vw;
    }

    .cancel_button_popup {
      margin-left: 2vw;
    }

    .signout_and_cancel_main_div {
      margin-top: 3vw;
    }

    .cancel_button_popup {
      border: #bacadd 0.1vw solid;
      height: 11.666666666666666vw;
      width: 23.055555555555557vw;
      background-color: #ffffff;
      font-size: 5vw;
      border-radius: 1.1111111111111112vw;
    }

    .sign_out_popup_profile {
      width: 37.5vw;
      height: 12.222222222222221vw;
      font-size: 5vw;
      background-color: #1c477a;
      border: none;
      color: #ffffff;
      border-radius: 1.1111111111111112vw;
    }

    .firstname_div_in_popup {
      display: block;
      font-size: 5vw;
      height: fit-content;
      width: 40vw;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    #dropdown-basic-button+div.dropdown-menu.show {
      width: 90vw;
      margin-left: -5vw;
      background-color: #383b3c;
      margin-top: 5vw;
    }

    #dropdown-basic-button+div.dropdown-menu.show::after {
      right: 11.5vw !important;
    }

    #dropdown-basic-button::before {
      height: 5vw;
      width: 5vw;
      background-size: 90% 90%;
      margin-top: 0px;
      margin-right: 0px;
    }
  }
}


@media screen and (max-width: 767px) {
  .mobile_view_pop_logout .modal-content {
    width: inherit; 
    margin: 0 auto;
  }
  .hidden-text{
    display: none !important;
  }
  .container {
    width: 91.6666666667vw;
  }

  .emsheading {
    height: fit-content;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-toggle[aria-expanded="true"]::after {
    display: none !important;
  }

  .namedisplaymobileversion {
    display: block;
  }

  .calendarlink {
    height: fit-content;
  }

  #dropdown-basic-button {
    padding: 0px;
    height: fit-content;
  }

  // .dropdown-toggle[aria-expanded="true"]::before {
  //   display: none !important;
  // }
  .dropdown-toggle[title] {
    display: none !important;
  }

  .popup_sign_out_main_div {
    border: none !important;
    width: fit-content;
  }

  .popup_sign_out_main_div {
    border: none !important;
    width: fit-content;
  }

  .popupMainDiv_profile {
    height: 118.05555555555556vw;
    box-shadow: 0vw 1.1111111111111112vw 2.7777777777777777vw rgba(148, 148, 148, 0.25);
  }

  .ems_text_heading_popup {
    height: 11.666666666666666vw;
  }

  .ems_heading_text {
    font-size: 5vw;
  }

  .tools_text_popup {
    font-size: 3.2vw;
  }

  .user_settings_main_div_popup {
    margin-left: 9vw;
  }

  .userSettings_text_span {
    font-size: 3.2vw;
  }

  .tools_and_usersettings_text {
    font-size: 3.2vw;
    font-weight: 400;
    color: #212529 !important;
  }

  .profile_pic_upload_div {
    height: 37.77777777777778vw;
    width: 37.77777777777778vw;
    margin-top: 9vw;
    border-radius: 1vw;
  }

  .image_size_text_max {
    font-size: 3.3333333333333335vw;
  }

  .cancel_button_popup {
    margin-left: 2vw;
  }

  .signout_and_cancel_main_div {
    margin-top: 3vw;
  }

  .cancel_button_popup {
    border: #BACADD 0.1vw solid;
    height: 11.666666666666666vw;
    width: 23.055555555555557vw;
    background-color: #FFFFFF;
    font-size: 5vw;
    border-radius: 1.1111111111111112vw;
  }

  .sign_out_popup_profile {
    width: 37.5vw;
    height: 12.222222222222221vw;
    font-size: 5vw;
    background-color: #1C477A;
    border: none;
    color: #FFFFFF;
    border-radius: 1.1111111111111112vw;
  }

  .firstname_div_in_popup {
    display: block;
    font-size: 5vw;
    height: fit-content;
    width: 40vw;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #dropdown-basic-button+div.dropdown-menu.show {
    width: 90vw;
    margin-left: -5vw;
    background-color: #383b3c;
    margin-top: 5vw;
  }

  #dropdown-basic-button+div.dropdown-menu.show::after {
    right: 11.5vw !important;
  }

  #dropdown-basic-button::before {
    height: 5vw;
    width: 5vw;
    background-size: 90% 90%;
    margin-top: 1vw;
  }
  

//   .modal-content {
//     width: 76vw!important;
// }

// .modal-content {
//   position: relative;
//   display: flex;
//   flex-direction: flex-end;
// }


}

@media (max-width:400px) {
  #dropdown-basic-button::before {
    margin-top: 2vw;
  }
}
.cropper-wrap-box {
  background-color: black;
}

.profile_image_display {
  width: 10.208333333333334vw;
  height: 10.208333333333334vw;
  margin-left: 1.5vw;
  margin-top: 1.5vw;
  border-radius: 0.2vw;
  border: 0.052083333333333vw solid #92AECE;
}

.image_cropper_main_div {
  background-color: rgba(13, 13, 13, 0.5);
  height: 100vh;
  width: 100%;
  top: 0%;
  position: fixed;
  z-index: 1440;
  left: 0%;
}

.imageDialogu_div_crop {
  height: 39.041666666666668vw;
  width: 40.25vw;
  background-color: white;
  border-radius: 0.5vw;
  position: relative;
}

.imageCropper_center_sub_div {
  width: 90%;
  height: 25vw;
  display: flex;
  align-items: center;
}

.crop_heading_text_main {
  height: 3vw;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  border-bottom: 0.1vw solid black;
  font-size: 1.145833333333333vw;
  font-weight: 500;
  font-family: 'Montserrat';
}

#image_cropper_id .cropper-container {
  height: 10vw !important;
}

.cropper-point.point-se {
  height: 5px !important;
  width: 5px !important;
}

.crop_button_image_profile {
  background-color: #92AECE;
  color: white;
  height: 3vw;
  width: 16vw;
  margin-top: 2vw;
  font-size: 1.5vw;
  font-weight: 500;
  border: none;
  border-radius: 0.5vw;
}

@media screen and (max-width: 767px) {

  .imageDialogu_div_crop {
      height: 60vh;
      width: 90%;
  }

  .imageCropper_center_sub_div {
      width: 90%;
      height: 60%;
      display: flex;
      align-items: center;
      background-color: gray;
  }

  .crop_heading_text_main {
      height: 7vh;
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      border-bottom: 0.1vw solid black;
      font-size: 4.145833333333333vw;
      font-weight: 500;
      font-family: 'Montserrat';
  }

  .profile_image_display {
      height: 45vw;
      width: 45vw;
      margin-top: 8vw;
      border-radius: 1vw;
  }

  .cropper-point.point-se {
      height: 5px !important;
      width: 5px !important;
  }

  .crop_button_image_profile {
      background-color: #92AECE;
      color: white;
      height: 11vw;
      width: 40vw;
      margin-top: 1vh;
      font-size: 5vw;
      font-weight: 500;
      border: none;
      border-radius: 1vw;
  }
}




 