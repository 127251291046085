
.ems-step-progress {
    
    .step-nav-button-wrap, .step-nav-content-wrap {
        display: none;
        
    }
    .step-nav-main-wrap {
        padding: 0;
        
    }
    .step-nav-progress-wrap {

    }
    ._2Jtxm._2ZUAI::before, ._2Jtxm._35Ago::before, ._2Jtxm._1CcaK::before {
        background: #1C477A;
    }
    .step-nav-step-wrap {
      
        
        &::before {
            // background-color: #000;
        }
        ._2Jtxm._35Ago ._2kL0S  {
            color: #1C477A !important;
        }
        span {
            height: 1.5em;
            width: 1.5em;
            font-size: 0.703125vw;
            background-color: #1C477A !important;
            color: #1C477A;
            // color: #ffff;
            // content-visibility: hidden;
        }
        svg {
            width: 0.5em !important;
            
        }
    }
    .step-nav-label-wrap {
      
    }

    hr {
        margin-top:2.5625vw;
    }
}

  @media (max-width: 600px) {
    
    .ems-step-progress{
        hr {
            margin-top: 3.6458333333333335vw;
        }
    }
}
