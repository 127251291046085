// .drop_down_list input:focus {
//   /* Apply your custom styling here */
//   /* For example, you can adjust the width and other properties */
//   max-width: 80% !important; /* Adjust the width as needed */
//   /* Add any other necessary styles */
// }
.drop_down_list .react-multi-select-input:focus {
  width: 100%; /* Adjust the width as needed */
}
.select_role__multi-value {
  display: none !important;
}

.select_role_field {
  display: flex;
  justify-content: space-between;
  width: 87%;
  padding-left: 10px;
}
.select_role_field__label,
.select_role_field__more {
  display: inline-flex !important;
  align-items: end;
}
.ems-dropdown {
  width: 100%;
  height: 100%;

  /* Generaldropdown.css */

  /* Style the control container */
  .ems-dropdown .react-multi-select-container {
    border: 1px solid #ccc;
    transition: box-shadow 0.2s;
  }

  .ems-dropdown .react-multi-select-container:hover {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3);
  }
  .ems-dropdown .react-multi-select-container:focus-visible {
    outline: red;
    background-color: red;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3);
  }
  /* Style the selected options */
  .ems-dropdown .react-multi-select-option--is-selected {
    // background-color: #007bff;
    // color: white;
  }

  /* Style the selected option labels */
  .ems-dropdown .react-multi-select-label {
    color: white;
  }

  /* Style the remove button on selected options */
  .ems-dropdown .react-multi-select-option__remove {
    background-color: transparent;
    color: white;
  }

  .ems-dropdown .react-multi-select-option__remove:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  > div:first-child {
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      height: 100%;
      background: #f3f7f9;
      vertical-align: super;
      border-radius: 0.2vw;
      span {
        font-size: 0.9375vw !important;
      }
    }

    > div:nth-child(2) {
      width: 100%;
      border: 1px solid #c2c2c2;
      height: auto;
      border-radius: 0.5vw !important;
      height: 13.5vw !important;
      overflow: scroll;
      .css-1jllj6i-control {
        min-width: auto !important;
      }

      div:has(> input) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        min-width: auto;
        // background-color: #fff;
        border-bottom: 0.1vw solid #d9d8d8;
        font-weight: normal !important;
        font-size: 1vw;
        // background-color: red;
        // height:3vw;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .mandal_town_dropdown {
  }
  .ems-dropdown {
    // background-color: red;
    > div:first-child {
      button {
        border-radius: 1.6666666666666667vw;
        span {
          font-size: 3.888888888888889vw !important;
          svg {
            width: 7vw;
            height: 7vw;
          }
        }
      }

      > div:nth-child(2) {
        width: 100%;
        border: 1px solid #c2c2c2;
        height: auto;
        height: 60vw !important;
        div:has(> input) {
          font-size: 3.888888888888889vw;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
          // height:50vw;
        }
      }
    }
  }
}
.ems-dropdown > div:first-child > div:nth-child(2) > span:first-child {
  max-width: 100px !important;
  // background-color: red !important;
}
