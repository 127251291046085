  /* Profile.css */
  .ems_title_dashbaord{
    display: none;
  }
  .profile-container {
    /* display: flex; */
    /* flex-direction: column; */
  }
  .pc_tab{
    display: flex;
    justify-content: space-evenly;

  }
  .card {
    border-radius: 10px !important;
    margin-bottom: 9rem!important;
  }
  
  .backarrow_img_profileimg {
    height: 1.8vw !important;
    width: 1.5vw !important;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .itoconnect_profile_module_android {
    color: #444;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 1.3vw;
    width: 40vw !important;
  }
  
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .itoconnect_profile_module_android_input-field {
    height:4.5vw !important;
    border-radius: 5px !important;
    background: #F3F3F3 !important;
    border: none!important;
    color: #8B93A5;
  font-family: "Open Sans";
  font-size: 1.2vw!important;
  font-weight: 600;
  padding-left: 1.4vw;
  margin-top:1vw !important;
  border: none !important;
  outline: none !important;
  
  }
  .itoconnect_profile_module_android_input-field{
    /* background-color: red !important; */
  }
 
  .pc_tab_role{
    margin-left:5vw !important
  }
  .button-container {
    display: flex;
    /* gap: 10px; */
    /* margin-top: 20px; */
    justify-content: center;
  }
  
  .save-button,
  .delete-button {
    /* margin-top:4vw; */
    /* padding: 10px; */
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #f44336;
    width: 20vw;
    height:4vw !important;
    border-radius: 6px !important;
  border: 1px solid #C0C0C0;
  background: #FFF;
  color: #D90000;
  font-family: "Open Sans";
  font-size: 1.3vw;
  font-weight: 600;
  }
  .delete-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .delete-button span {
    margin-right: 10px; 
  }
  

  .log_out_icons{
     font-size:2.3vw;
     /* margin-top: 2px; */
   }
   .modal-body-title_profile{
    font-size: 1vw;
    font-weight: 600;
    color:#263659 !important;

   }
   .logout_from-application{
     color:#FFFFFF;
     color:#263659 !important;
     opacity: 1;
     visibility: visible;
     z-index: 1;
     background-color: #f0f0f0 !important;
     border: none !important;
     outline: none !important;
     height: 3vw;;
     width:4vw;
     border-radius: 50% !important;
     font-size: 1vw;
     font-weight: 600;
     
   }





@media (max-width:767px){
.profile-container {
}
.pc_tab{
  display: flex; 
  flex-direction: column;
}
.pc_tab_role{
  margin-left:0vw !important;
}
.card {
  border-radius: 10px !important;
  margin-bottom: 9rem!important;
}
.backarrow_img_profileimg {
  height: 5.33vw !important;
  width: 5.33vw !important;
}
label {
  display: block;
  margin-bottom: 5px;
}
.itoconnect_profile_module_android {
  color: #444;
  font-family: "Open Sans";
  font-size: 4.444vw;
  font-weight: 700;
}
input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.itoconnect_profile_module_android_input-field {
  width: 79.888vw!important;
  /* max-width: 80vw !important; */
  height: 12.22vw !important;
  border-radius: 5px !important;
  background: #F3F3F3 !important;
  border: none!important;
  color: #8B93A5;
font-family: "Open Sans";
font-size: 4.1666vw !important;
font-weight: 600;
padding-left: 3vw;
margin-top:3vw !important;
}
.button-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}
.save-button,
.delete-button {
  padding: 10px !important;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.delete-button {
  background-color: #f44336;
  width: 65vw;
  height:12vw !important;
  border-radius: 6px !important;
border: 1px solid #C0C0C0;
background: #FFF;
color: #D90000;
font-family: "Open Sans";
font-size: 4.1666vw;
font-weight: 600;
margin: 0;
padding: 0;
}
.delete-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.delete-button span {
  margin-right: 10px; 
}
.log_out_icons{
 font-size:6.6vw;
  margin-top: 2px;
}
.logout_from-application{
  color:#FFFFFF;
  color:#263659 !important;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  background-color: #f0f0f0 !important;
  border: none !important;
  outline: none !important;
  height: 12vw;;
  width:16vw;
  border-radius: 50% !important;
  font-size: 4.5vw;
  font-weight: 600;
}
.logout_from-application:active {
  /* background-color: #D5E5F2!important;
  color:#263659 !important;
  border: none !important;
  outline: none !important; */
}
.modal-body-title_profile {
  font-size: 4vw;
  font-weight: 600;
  color: #263659 !important;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
  
  .profile-container {
    /* display: flex; */
    /* flex-direction: column; */
  }
  .pc_tab{
    display: flex;
    justify-content: space-evenly;

  }
  .card {
    border-radius: 10px !important;
    margin-bottom: 9rem!important;
  }
  
  .backarrow_img_profileimg {
    height: 3vw !important;
    width: 3vw !important;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .itoconnect_profile_module_android {
    color: #444;
    font-family: "Open Sans";
    /* font-size: 4.444vw; */
    font-weight: 700;
    font-size: 2.2vw;
    width: 40vw !important;
  }
  
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .itoconnect_profile_module_android_input-field {
    height:7vw !important;
    border-radius: 5px !important;
    background: #F3F3F3 !important;
    border: none!important;
    color: #8B93A5;
  font-family: "Open Sans";
  font-size: 2vw !important;
  font-weight: 600;
  padding-left: 3vw;
  margin-top:3vw !important;
  
  }
  .itoconnect_profile_module_android_input-field{
  }
 
  .pc_tab_role{
    margin-left:4vw !important
  }
  .button-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
  }
  
  .save-button,
  .delete-button {
    margin-top:4vw;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #f44336;
    width: 40vw;
    border-radius: 6px !important;
  border: 1px solid #C0C0C0;
  background: #FFF;
  color: #D90000;
  font-family: "Open Sans";
  font-size: 2.2666vw;
  height:7vw !important;
  font-weight: 600;
  }
  .delete-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .delete-button span {
    margin-right: 10px; 
  }
  .log_out_icons{
   font-size:4vw;
    /* margin-top: 2px; */
  }
  .modal-body-title_profile {
    font-size: 1.9vw;
    font-weight: 600;
    color: #263659 !important;
  }
  .logout_from-application{
    color:#FFFFFF;
    color:#263659 !important;
    opacity: 1;
    visibility: visible;
    z-index: 1;
    background-color: #f0f0f0 !important;
    border: none !important;
    outline: none !important;
    height: 6vw;;
    width:7vw;
    border-radius: 50% !important;
    font-size: 1.9vw;
    font-weight: 600;
  }
  }