.loadingscreenI2connect_votersdata {
  height: 100vh;
  width: 100%;
  background-color: black;
  position: fixed;
  opacity: 0.85;
  z-index: 1040;
  top: 0% !important;
  left: 0%;
  right: 0%;
  
}
.parent_Loading_holder{
  height: 12vw;
  width: 12vw;
  margin-left: 0vw !important;
  position: relative;
}
.loading_logo{
  height: 40%;
  width: 50%;
}
.Loading_ring{
  height: 10vw;
  width: 10vw;
  position: absolute;
  /* left: 0%; */
  pointer-events: none;
  animation: loadingring 2s linear infinite;
}
@keyframes loadingring{
  from{
    transform:rotateZ(0deg) ;
  }
  to{
    transform:rotateZ(360deg) ;
  }
}
.progress-bar-container {
  width: 10vw;
  background-color: #645a5a;
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.7s ease-in-out;
  animation: loaderanimi 0.7s infinite;
}

@keyframes loaderanimi{
  from{
    border-right: 0.2vw solid #4caf50;
  }
  to{
    border-right: 0.2vw solid #FFFFFF;
  }
}