 .birthday_data_main_div {
  animation: votersdata 1.5s linear;
}

.pdflogo-birthday {
  position: absolute;
  left: 80%;
  width: 2.8125vw;
  height: 2.2916666666666665vw;
  top: 0.8891666666666666vw;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
}

.excelllogo-birthday {
  position: absolute;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  width: 2.8125vw;
  height: 2.3016666666666665vw;
  top: 0.9vw;
  left: 75.5%;
}

.excel_img {
  width: 100%;
  height: 100%;
}

.pdfImg_birthday {
  height: 100%;
  width: 100%;
  background-color: #c64632;
  border-radius: 0.2vw;
}

.titleDiv {
  width: 12.4vw;
  height: 2.8645833333333335vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  overflow: hidden;
  display: inline-block;
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  margin: 0;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
}

.titleDiv span {
  color: #263659;
  margin-left: 13%;
}

.sidenav_holder_div_birthday_data {
  width: fit-content;
  height: 100%;
  display: flex;
}

.birthday_data_text_main_div {
  height: 3.8541666666666665vw;
  width: 94.5%;
  position: relative;
  border-bottom: 0.2vw solid #1e1e1e;
  margin-left: 1.2890625vw;
  margin-bottom: 0.625vw;
}

.top_heading_in_birthday_data {
  width: 12.4vw;
  height: 2.8645833333333335vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  background-color: #e8ecf1;
  display: flex;
  align-items: center;
  font-size: 0.7291666666666666vw !important;
  font-family: "Montserrat" !important;
  font-weight: 501 !important;
  color: #4d7093 !important;
}

.top_heading_in_birthday_data span {
  margin-left: 13%;
}

.modal-fullscreen .modal-content {
  height: 47%;
  position: absolute;
  border: 0;
  border-radius: 30px 30px 0px 0px;
  bottom: 0% !important;
  width: 100%;
  overflow: scroll;
}
.call_image_button {
  height: 2vw;
  margin-left: 13%;
  border-radius: 0.3vw;
}

.birthday_view_main_div {
  display: flex;
}

.birthday_view_main_div h6 {
  color: gray;
}

.birthday_view_main_div p {
  color: black;
  font-weight: 500;
}

.modal-header {
  padding: 20px;
  border-radius: 0;
}

.left_right_btns_birthday {
  font-size: 1.0625rem;
  font-weight: 500;
  width: 60px !important;
  height: 55px;
  border: 0.052083333333333336vw solid #ccd0d6;
  font-family: "Montserrat";
  margin-top: 0.625vw;
  background-color: #e8ecf1;
  display: flex;
  align-items: center;
}
.leftarrow_btn_birthday,
.rightarrow_btn_birthday {
  background: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
}

.view-eye-icon {
  font-size: 1.0625rem;
  font-weight: 500;
  width: 60px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.052083333333333336vw solid #ccd0d6;
  color: #263659;
  overflow: hidden;
}

.birthday_data_text_ems {
  font-size: 1.4583333333333333vw;
  font-family: "Montserrat";
  font-weight: 400;
  position: absolute;
  top: 1.1979166666666667vw;
  color: #263659;
}

.birthday_dropdow_filter {
  height: 2.65625vw;
  background-color: #f3f7f9;
  border-radius: 0.3vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  cursor: pointer;
  position: relative;
  width: 100% !important;
}

.endfooterimgbirthday {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 3.166667vw;
  color: #263659;
}

.filter_birthday {
  position: absolute;
  right: 0.2%;
  width: 15%;
  height: 2.2916666666666665vw;
  top: 0.7291666666666666vw;
  cursor: pointer;
}

.birthday_dropdown_box_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  color: #8b93a5;
  font-size: 1.0416666666666667vw;
  margin-left: 0.78125vw;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.birthday_dropdown_arrow {
  height: 0.6291666666666666vw;
  width: 0.7291666666666666vw;
  position: absolute;
  left: 90%;
}

.parent_horizontal_scroll_birthday_data {
  height: 82vh;
  width: 100%;
  position: relative;
}

.horizontal_scroll_birthday_data {
  width: 94.5%;
  height: inherit;
  overflow-y: none;
  overflow-x: scroll;
  left: 1.2890625vw;
  position: absolute;
  border: 0.052083333333333336vw solid #ccd0d6;
}

.top_headings_in_birthday_data_main_div {
  width: fit-content;
}

.birthday_call_status_nav_bar {
  width: 8.78vw;
  height: 2.8645833333333335vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  background-color: #e8ecf1;
  display: flex;
  align-items: center;
  font-size: 0.7291666666666666vw;
  font-family: "Montserrat";
  font-weight: 501;
  color: #4d7093;
}

.birthday_call_status_nav_bar span {
  margin-left: 13%;
}

.call_status_div {
  width: 8.78vw;
}

.birthday_data_holder_main_div {
  width: fit-content;
  overflow-y: scroll;
  margin-left: 0%;
}
.birthday_data_holder_main_div::-webkit-scrollbar {
  width: 0.2vw;
}
.birthday_data_holder_main_div::-webkit-scrollbar-thumb {
  background-color: #959595;
  border-radius: 1vw;
}

.birthday_data_underlines_holder {
  margin-left: 1.2890625vw;
  margin-top: 1vw;
}

.birthday_data_underline_one {
  background-color: #ccd0d6;
  width: 96%;
  height: 0.1vw;
  margin-top: 0.1vw;
}

.buttons_birthday_data_next_prev_main_div {
  margin-left: 88% !important;
  margin-top: 1vw;
}

.total_pages_birthday {
  background-color: #88a7c6;
  width: fit-content;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-weight: 800;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  padding: 0.5vw;
  position: absolute;
  left: 1.2890625vw;
}

.prev_button_birthday_data {
  background-color: #88a7c6;
  width: 2.7083333333333335vw;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  text-align: center;
  font-family: "Montserrat";
  outline: #88a7c6 !important;
  margin-right: 1%;
  cursor: pointer;
  font-weight: 800;
}

.current_page_number_birthday_data {
  background-color: #88a7c6;
  width: fit-content;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-weight: 800;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  padding: 0.5vw;
}

.next_button_birthday_data {
  background-color: #88a7c6;
  width: 2.7083333333333335vw;
  height: 1.9270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw;
  color: white;
  font-size: 0.7291666666666666vw;
  font-family: "Montserrat";
  text-align: center;
  outline: #88a7c6 !important;
  margin-left: 1%;
  cursor: pointer;
  font-weight: 800;
}
.nodata_available {
  position: absolute;
  color: red;
  left: 35vw;
  top: 30%;
  font-size: 1.5vw;
  font-weight: 500;
}
.titleDiv_Edit {
  width: 12.4vw;
  height: 2.8645833333333335vw;
  display: flex;
  align-items: center;
  border: 0.052083333333333336vw solid #ccd0d6;
  color: #263659;
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  position: relative;
}
.loadingscreenI2connect {
  height: 100vh;
  width: 100%;
  background-color: black;
  position: fixed;
  opacity: 0.5;
  z-index: 1040;
  left: 0%;
  right: 0%;
}
.Itoconnectlogo_loading_div {
  position: fixed;
  height: 30vw;
  width: 30vw;
  left: 38%;
  top: 10vw;
  z-index: 1041;
}
.itoconnect_loading_sub_div {
  height: 100%;
  width: 100%;
  position: relative;
}
.loadingLogo_pdf {
  height: 5vw;
  width: 6vw;
  position: absolute;
  left: 12vw;
  top: 10vw;
  z-index: 1080;
}
.loading_div {
  height: 0.5vw;
  position: absolute;
  left: 7vw;
  top: 17vw;
  background-color: #ffffff;
  border-radius: 1vw;
  animation: logoanimation 0.9s linear infinite;
}
@keyframes logoanimation {
  from {
    width: 0vw;
  }

  to {
    width: 10vw;
  }
}
.birthday_end_footer {
  height: 2.8645833333333335vw;
  width: 100%;
  margin-top: 0.6770833333333334vw;
  border-top: 0.1vw solid;
}

@media (max-width: 767px) {
  ::-webkit-scrollbar-track {
    background: rgb(237, 236, 236);
  }
  .mandaltown_data,
  .constituency_d {
    height: 30vw !important;
    margin-top: 6vw;
    border-radius: 1vw;
  }
  .titleDiv,
  .top_heading_in_birthday_data {
    font-size: 3.3333333333333335vw !important;
    line-height: 4.166666666666667vw !important;
    font-weight: 500 !important;
    width: 45.833333333333336vw !important;
    height: 10.277777777777779vw;
  }

  .endfooterimgbirthday {
    height: 4.166666666666667vw;
    font-size: 3vw;
    line-height: 4.166666666666667vw;
    width: 100% !important;
    margin-left: 12.166666666666666vw;
    margin-top: 4.166666666666667vw;
  }

  .parent_horizontal_scroll_birthday_data {
    height: 76vh;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .filter_birthday {
    width: 45vw;
    left: 50%;
    top: 17%;
  }

  .birthday_dropdow_filter {
    height: 9vw;
  }

  .birthday_dropdown_box_text {
    font-size: 4vw;
    margin-left: 10px;
    font-weight: 900;
  }

  .birthday_data_text_ems {
    margin-top: 2vw;
    font-size: 5vw;
    line-height: 6.111111111111111vw;
    margin-left: 0%;
  }

  .birthday_dropdown_arrow {
    height: 2vw;
    width: 2.7291666666666666vw;
    position: absolute;
    left: 85%;
  }
  ::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 10px;
  }
  .total_pages_birthday {
    left: 6%;
  }
  .buttons_birthday_data_next_prev_main_div {
    padding-right: 25px;
    margin-left: 56% !important;
    gap: 10px;
  }
  .birthday_data_text_main_div {
    height: 12.854167vw;
    width: 88.3333333398vw !important;
    left: 26px;
  }
  .titleDiv,
  .top_heading_in_birthday_data {
    font-size: 1.0625rem;
    font-weight: 500;
    width: 173px !important;
    height: 55px;
  }
  .delete_btn_birthday_data {
    height: 8.5vw;
  }
  .birthday_data_underlines_holder {
    margin-top: 84vw;
    position: relative;
    height: 1vh;
  }
  .birthday_data_holder_main_div {
    height: 105vw;
  }

  .sidenav_holder_div_birthday_data {
    width: 100% !important;
    position: absolute;
    margin-left: 0%;
    z-index: 2;
    padding: 0%;
  }
  .total_pages_birthday {
    width: 9.722222222222221vw;
    height: 9.722222222222221vw;
    font-size: 0.75rem;
    justify-content: center;
  }
  .prev_button_birthday_data,
  .next_button_birthday_data {
    width: 13.333333333333334vw;
    height: 9.722222222222221vw;
    font-size: 0.75rem;
  }
  .current_page_number_birthday_data {
    width: 6.944444444444445vw;
    height: 9.722222222222221vw;
    font-size: 0.75rem;
    justify-content: center;
  }
  #title_div_first_column {
    background-color: #dce8f5;
  }
  .dropdown-toggle {
    height: 7.65625vw;
    font-size: 1rem;
  }
  .fields_in_filter_div span {
    font-size: 0.97rem;
    border-bottom: 1px solid lightgrey;
    height: 3vw;
  }
  .checkboxesdropdown {
    height: 1.7vw !important;
    width: 1.7vw !important;
  }
  .horizontal_scroll_birthday_data {
    width: 86.38888888888889vw;
    left: 7%;
    border: 1px solid #ccd0d6;
    border-radius: 3px;
  }
  .dropdown_text_newVoters {
    font-size: 3vw;
  }
  .dropdown-menu {
    width: 96%;
    max-height: 20vw;
  }
  .top_headings_in_birthday_data_main_div {
    margin-top: -3px !important;
  }
  .titleDiv_Edit {
    width: 173px !important;
    height: 55px !important;
    font-size: 1.0625rem !important;
    display: flex;
    align-items: center;
    border: 0.052083333333333336vw solid #ccd0d6;
    color: #263659;

    font-weight: 500;
    position: relative;
    padding-left: 5px;
  }
}

@media (max-width: 460px) {
  .images_for_responsive {
    position: absolute;
    width: 100%;
    bottom: 75px;
  }
  .birthday_data_text_main_div {
    left: 12px;
  }
  .pdflogo-birthday {
    left: 46%;
    width: 9vw;
    height: 9vw;
    top: 2.8vw;
  }
  .excelllogo-birthday {
    left: 32% !important;
    width: 9vw !important;
    height: 9vw !important;
    top: 2.8vw !important;
  }
}

@media (max-width: 370px) {
  .birthday_data_underlines_holder {
    margin-top: 87vw;
  }
}




@media screen and (max-width: 1024px) and (min-width: 768px) {
  .horizontal_scroll_birthday_data {
    height: 79vh !important;
  }
  .excelllogo-birthday {
    width: 2.8125vw;
    height: 2.3016666666666665vw;
    top: 0.9vw;
    left: 75.5%;
  }
  .parent_horizontal_scroll_birthday_data {
  }
  .horizontal_scroll_birthday_data {
  
  }

  .birthday_data_holder_main_div {
    height: 76.322916666666664vh;
    width: fit-content;
    overflow-y: scroll;
    margin-left: 0%;
  }
  .birthday_data_main_div {
    margin-top: 5vw;
    height: 91vh;
    animation: votersdata 1.5s linear;
  }
  .birthday_data_underlines_holder {
    margin-top: 0vh;
  }

  .birthday_data_underline_one {
    background-color: #ccd0d6;
    width: 96%;
    height: 0.1vw;
    margin-top: 0.1vw;
  }
}



@media (max-width: 767px) {
  ::-webkit-scrollbar-track {
    background: rgb(237, 236, 236);
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 10px;
  }

  .birthday_data_text_main_div {
    width: 88.3333333398vw !important;
    left: 26px;
  }
  .excelllogo-birthday {
    width: 2.8125vw;
    height: 2.3016666666666665vw;
    top: 0.9vw;
    left: 75.5%;
  }
  .titleDiv,
  .top_heading_in_birthday_data {
    font-size: 1.0625rem;
    font-weight: 500;
    width: 173px !important;
    height: 55px;
  }
  .birthday_data_underlines_holder {
    margin-top: 5vw;
    margin-top: -3vw;
    position: relative;
    height: 1vh !important;
  }
  .birthday_data_underline_one {
    border: 1px solid #ccd0d6;
    width: 96%;
    height: 0.1vw;
    margin-top: 0.1vw;
  }
  .birthday_data_holder_main_div {
    height: 95%;
  }

  .sidenav_holder_div_birthday_data {
    width: 100% !important;
    position: absolute;
    margin-left: 0%;
    z-index: 2;
    padding: 0%;
  }
  .parent_horizontal_scroll_birthday_data {
    height: 74vh;
  }
  .buttons_birthday_data_next_prev_main_div {
    padding-right: 25px;
    margin-left: 56% !important;
    gap: 10px;
    width: auto;
    display: none !important;
  }
  .total_pages_birthday {
    width: 9.722222222222221vw;
    height: 9.722222222222221vw;
    font-size: 0.75rem;
    justify-content: center;
  }
  .prev_button_birthday_data,
  .next_button_birthday_data {
    width: 13.333333333333334vw;
    height: 9.722222222222221vw;
    font-size: 0.75rem;
  }
  .current_page_number_birthday_data {
    width: 6.944444444444445vw;
    height: 9.722222222222221vw;
    font-size: 0.75rem;
    justify-content: center;
  }
  #title_div_first_column {
    background-color: #dce8f5;
  }
  .dropdown-toggle {
    height: 7.65625vw;
    font-size: 1rem;
  }

  .checkboxesdropdown {
    height: 1.7vw !important;
    width: 1.7vw !important;
  }
  .birthday_call_status_nav_bar {
    font-size: 1.0625rem;
    font-weight: 500;
    width: 173px !important;
    height: 55px;
  }
  .horizontal_scroll_birthday_data {
    width: 86.38888888888889vw;
    left: 5%;
    border: 1px solid #ccd0d6;
    border-radius: 3px;
    margin-top: 8px;
    height: 90%;
  }
  .titleDiv_Edit {
    width: 173px !important;
    height: 55px !important;
    font-size: 1.0625rem !important;
    display: flex;
    align-items: center;
    border: 0.052083333333333336vw solid #ccd0d6;
    color: #263659;

    font-weight: 500;
    position: relative;
    padding-left: 5px;
  }
  .nodata_available {
    position: absolute;
    color: red;
    left: 28vw;
    top: 30%;
    font-size: 3vw;
    font-weight: 500;
  }
}

@media (max-width: 460px) {
  .images_for_responsive {
    position: absolute;
    width: 100%;
    bottom: 75px;
  }
  .birthday_data_text_main_div {
    left: 12px;
  }

  .titleDiv,
  .top_heading_in_birthday_data {
    font-size: 1.0625rem;
    font-weight: 500;
    width: 137px !important;
    height: 57px;
  }
  .view-eye-icon {
    height: 57px;
  }
  .call_status_div {
    width: 137px !important;
  }
  .birthday_call_status_nav_bar {
    width: 137px !important;
    height: 57px;
  }
}
@media (min-width: 520px) and (max-width: 767px) {
  .images_for_responsive {
    position: absolute;
    width: 100%;
    bottom: 75px;
  }
}




@media (max-width: 767) {
  .birthday_data_text_main_div {
    border: none !important;
  }
  .birthday_data_text_ems {
    display: none;
    text-align: center;
    color: #0047b2;
    font-size: 5.55vw;
    font-weight: 700;
    margin-left: 33vw;
    position: relative;
  }
  .filter_birthday {
    margin-top: 10vw !important;
  }
  .birthday_dropdow_filter {
    border-radius: 3px;
    border: 2px solid #528bb8;
    background: #fff;
  }
  .birthday_dropdown_box_text {
    color: #528bb8;
    font-size: 5vw;
    font-weight: 600;
  }

  #mandaltown_data_ {
    border-radius: 5px 5px 0px 0px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  .itoconnect_birthdays_data_mobile_main_div {
    margin-left: 6.5vw;
    width: 86.5vw;
    height: 167vw;
    border-radius: 2px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .birthdays_title_name {
    width: 86.4vw !important;
    width: 100%;
    height: 11.66vw;
    background: #b3c8e8 !important;
    margin-left: -0.1vw !important;
    margin-top: -0.1vw !important;
  }
  .birthdays_title_name span {
    color: #0047b2;
    font-size: 5vw;
    font-weight: 600;
    padding: 10px;
    position: relative;
    top: 2vw;
  }
  .itoconnect_birthdays_id_child_div_main {
    height: 152.16vw;
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-width: auto;
    scrollbar-width: inherit !important;
  }

  #title_div_birthdays_data_first_column {
    background: none !important;
    border: none !important;
    margin-left: 4.16vw;
    margin-top: 5vw;
    width: 35vw;
    display: flex;
  }
  .main_div_voters_display_data {
    display: flex;
    flex-direction: row;
  }
  #title_div_birthdays_data_first_column span {
    color: #046a38;
    font-size: 5vw;
    font-weight: 600;
    text-align: left;
    width: 40vw !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .birthdays_data_first_name_age_caste_main {
    display: flex;
    justify-content: space-between;
  }
  .birthdays_data_child_div_first_name_age_caste {
    margin-top: 2vw;
  }
  .birthdays_data_first_name_age_caste_main span {
    color: #263659;
    font-size: 3.888vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 4.16vw;
  }
  .birthdays_display_first_name_ {
    text-align: left;
  }
  .birthdays_display_last_name_ {
    text-align: left;
  }
  .birthdays_display_gender_ {
    text-align: left;
  }
  .call_icon_Data {
    width: 15%;
    margin-top: -3vw;
  }
  .call_image_button {
    height: 6.11vw !important;
    width: 6.11vw !important;
    border-radius: 0.3vw;
  }
  .parent_horizontal_scroll_birthday_data {
    display: none;
  }
  .buttons_birthday_data_next_prev_main_div {
    background-color: red;
  }

  .modal-fullscreen .modal-content {
    height: 38%;
    position: absolute;
    position: fixed;
  left:0vw;
    border: 0;
    width: 100%;
    width:100vw;
    overflow: scroll;
    border-radius: 20px 20px 0px 0px;
    background: #fff;
  }
  .modal-fullscreen .modal-header {
    border-radius: 0;
    border-bottom: 1px solid #ccd0d6 !important;
    height: 12.22vw;
  }
  .modal-title {
    color: #263659;
    font-size: 4.44vw;
    font-weight: 600;
  }
  .btn-close {
    font-size: 4.5vw;
  }
  .birthday_view_main_div h6 {
    color: #606b83;
    font-size: 3.888vw;
    font-weight: 600;
  }
  .birthday_view_main_div p {
    color: #263659;
    font-size: 3.888vw;
    font-weight: 700;
    width: 37vw; 
            overflow-wrap: break-word; 
            word-wrap: break-word; 
            word-break: break-word; 
  }
  .birthday_data_underlines_holder {
    display: none;
  }
  .birthdays_data_next_prev_main_div {
    padding-right: 25px;
    margin-left: 56% !important;
    margin-left: 35% !important;
    gap: 10px;
    width: auto;
    margin-top: 4vw;
    margin-bottom: 31vw;
  }
  .total_pages_birthday {
    width: 9.722222222222221vw;
    height: 9.722222222222221vw;
    font-size: 0.75rem;
    justify-content: center;
    display: none;
  }
  .prev_button_birthday_data,
  .next_button_birthday_data {
    width: 6vw;
    height: 8vw;
    font-size: 0.75rem;
    border-radius: 0px !important;
  }
  .current_page_number_birthday_data {
    width: 6vw;
    height: 8vw;
    font-size: 0.75rem;
    justify-content: center;
  }
  #checkbox_birthdays {
    margin-top: 2.5vw !important;
  }
  #mandal_town_data {
    width:46vw;
  }
  .mandal_town_data_{
    height: 9vw;
    width: 100%;
    border-bottom: 0.1vw solid #d9d8d8;
    text-align: left;
    font-family: "Montserrat";
    font-weight: 500;
    position: relative;
    border-bottom: 0.1vw solid #d9d8d8 !important;
    overflow-wrap: break-word !important; 
    word-wrap: break-word !important; 
    word-break: break-word !important; 
  }
  .mandal_town_data_ span{
    width:10px!important;  
    overflow-wrap: break-word !important; 
    word-wrap: break-word !important; 
    word-break: break-word !important; 
    font-size: 3vw!important;

  }
  #mandal_town_span_texttitle {
    position: relative;
    top: 2.5vw;
  }

}

@media (max-width: 767px) {
  .parent_horizontal_scroll_birthday_data {
    display: none !important;
  }
  .buttons_birthday_data_next_prev_main_div {
    display: none !important;
  }
}

@media (min-width: 768) and (max-width: 1024){
  .itoconnect_birthdays_data_mobile_main_div {
    display: none !important;
  }
  .birthdays_data_next_prev_main_div {
    display: none !important;
  }
  .parent_horizontal_scroll_birthday_data {
    display: block;
  }
  
  .birthday_data_text_ems {
    display: none;
  }
  .birthday_dropdow_filter {
    height: 5vw;
    position: absolute;
    right: 0% !important;
    width: 30vw !important;
    top: -2.5vw;
    cursor: pointer;
  }
  #mandaltown_data_{
    width: 30vw;
    position: absolute;
    right: 0vw;
    height:18vw !important;
    top: 5.8vw;
    word-break: break-all;
  }
  .mandal_town_data_ span{
    font-size: 2vw;
  }
  .mandal_town_data_ {
    height: 6vw;
    width: 100%;
    border-bottom: 0.1vw solid #d9d8d8;
    text-align: left;
    font-family: "Montserrat";
    font-weight: 500;
    position: relative;
    border-bottom: 0.1vw solid #d9d8d8 !important;
}
  .mandal_town_span_texttitle {
    font-size: 1.5vw;
  }
  #checkbox_birthdays {
    height: 2vw;
    width: 2vw;
    margin-top: 1vw;
  }
  .birthday_dropdown_arrow{
    height: 1.2vw;
    width: 1.2vw;
  }
  .birthday_dropdown_box_text {
    font-size: 2vw !important;
  }
  .top_heading_in_birthday_data {
    background-color: #b3c8e8;
    height: 6vw;
    width: 21vw !important;
    font-size: 2.2vw !important;
  }

  .titleDiv {
    height: 6vw;
    width: 21vw !important;
    font-size: 2.3vw;

  }

  .voter_id_birthdays{
    color: #046a38!important; 
    font-weight: 600;
  }
  .horizontal_scroll_birthday_data {
    height: 74vh !important;
}
.birthday_data_underlines_holder 
{
  display: none;
}
  .parent_horizontal_scroll_birthday_data {
    height: 77vh;
}
.total_pages_birthday{
  width: 5vw!important;
  height:4vw!important;
}
.total_pages_birthday span{
  font-size: 2vw!important;
  margin-left:1vw!important;
}
.buttons_birthday_data_next_prev_main_div {
  margin-left: 76% !important;
}
.prev_button_birthday_data,.next_button_birthday_data{
  width: 7vw!important;
  height:4vw!important;
  font-size: 2vw;
  border-radius: 0px!important;
}
.current_page_number_birthday_data{
  width: 5vw!important;
  height:4vw!important;
}
.current_page_number_birthday_data span{
  font-size: 2vw!important;
  margin-left:1.5vw!important;
}
.call_image_button {
  height: 4vw;
}
.fade_mobile_view_pop_modal_show{
  display: none!important;
}
.modal-fullscreen .modal-content {
  height: 35%;
  position: absolute;
  border: 0;
  border-radius: 30px 30px 0px 0px;
  bottom: 0% !important;
  width: 100%;
  overflow: scroll;
}
.birthday_view_main_div h6{
  color: #606B83;
  font-weight: 500;
  font-size: 2.8vw;
}
.birthday_view_main_div p{
  color:#263659;
  font-weight: 700;
  font-size: 3vw;
}
#first-name_div_{
  height:11vw;
}
#lasst-name_div_{
  height:11vw;

}
#person-designation_div_{
  height:11vw;
  margin-top:1vw;
}
#call_stauts_view_page{
  height:6vw;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
  border-bottom: 1px solid #ccd0d6 !important;
  height: 10.22vw;
}
.btn-close{
  font-size:2.5vw;
}
#logout_birthdays{
  position: relative;
  top: -1.5vw!important;
}
.filter_birthday {
  width: 0vw;
  top: 17%;
}

 .titleDiv_span_title_{
  width: 16vw !important;
  text-align: left !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;

}

}



.btnSaveChanges{
  /* position: relative;
  bottom: 6rem;
  border-radius: 10px !important;
  background-color: skyblue;
  padding: 10px; */
}

/* .submit {
  padding: 10px 30px;
  justify-content: center;
  left: 22.15vw;
  background: #1c477a;
  opacity: 0.5;
  border-radius: 4px;
  margin-top: 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375vw;
  line-height: 1.145vw;
  color: #ffffff;
  border: none;
} */


/* .modal-dialogP {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  top: 35% !important;
} */

@media screen and (max-width:767px) {
  /* .modal-dialogP {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none;
    top: 15% !important;
  } */
  
}