.topnav_main_div{
    height:4.166666666666667vw ;
    width: 86.65%;
    background-color: #fff;
    // position: fixed;
    z-index: 100;
    border: 1px solid gray;
    // left:13.35%;
    // left:16vw;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // padding-left: 1.2890625vw;

.nav_top_togglebtn{
    height:0.78125vw;
    width: 1.1458333333333333vw;
    cursor: pointer;
}

.calendarlink,.notificationlink{
    margin-right:1.25vw;
}

.right_align_icons{
    display: flex;
    justify-content: center;
    min-width: 18vw;
    align-items: center;
    position: relative;
    right: 3.3854166666666665%;
}

.signout_profile_popup{
    position: absolute;
    background-color: red;
}
.respnav_top_togglebtn{
    visibility: hidden;
}
.profileimg_logo{
    display: none;
}
.signout_logo_lock{
    /* display: none; */
    margin-left:1.8229166667vw;
    height: 1.3671875vw;
    width: 1.0416666666666667vw;
    background: url('../../Assets/locknav.png');
    background-size: 1.0416666666666667vw 1.3671875vw ;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    --bs-btn-hover-bg: none;
}
.signout_no{
    background: #7c8081;
  }
  .signout_yes{
    background: #1c477a;
  }
.modal-content {
    margin-top: 20vw;
}
.calender_logo{
    height: 1.0416666666666667vw;
    width:1.0416666666666667vw;
    /* position: absolute; */
    top: 1.5vw;
    right: 23%;
}
.notifaction_logo{
    height: 1.3416666666666668vw;
    width: 1.333333333333334vw;
    right: 26%;
    margin-top: 0.25vw;
    /* position: absolute; */
    /* margin-right:1.1458333333vw; */
}

@media screen and (max-width:767px) { 
   
    .notificationlink{
        margin-right:5vw;
    }
    .nav_top_togglebtn{
        visibility:visible;
        height: 3.3333333333333335vw;
        /* position: absolute; */
        width: 5vw;
        left: 7.777777777777778vw;
        top:8.88888888888889vw;
    }
    .notifaction_logo{
        display: block;
        /* position: absolute; */
        height: 5.630556vw;
        width: 5.630556vw;
        left: 59.888889vw;
        top: 7.777777777777778vw;
    }
    .profileimg_logo{
        display: block;
        /* position: absolute; */
        height: 4.444444444444445vw;
        width: 4.444444444444445vw;
        left: 78.61111111111111vw;
        top: 7.777777777777778vw;
    }
    .signout_logo_lock{
        /* display: none; */
        padding-left:0px;
        padding-right:0px;
        margin-left:0px;
        height: 5vw;
        width:4.702777777777778vw;
        background-size: 3.702777777777778vw 4.861111111111111vw ;
    }
    .modal-content {
        margin-top: 30vh;
    }
        .modal.show .modal-dialog {
            display: grid;
            place-items: center;
        }
    .calender_logo{
        display: block;
        /* position: absolute; */
        height: 4.630555555555556vw;
        width:4.630555555555556vw;
        left: 68.88888888888889vw;
        top: 7.777777777777778vw;
    }
    .calendarlink{
        margin-right: 5vw;
        height: 5vw;
    }
    .right_align_icons{
        min-width:27.7777777778vw;
        right: 0 !important;
    }
}
}
@media screen and (max-width:767px) {
    .Admin_dashboard_text{
        display: none;
        border: none !important;
    }
    .topnav_main_div{
        // display: none;
        height:19.166666666666668vw ;
        margin: 0% !important;
        z-index: 100;
        width: 100% !important;
        left: 0% !important;
        padding:0px  7.8611111111vw;
        border: 1px solid #D3D3D3 !important;
        // background: #EEE;
        box-shadow: 0px 1px 0px 0px #C7C7C7, 0px -2px 0px 0px #C7C7C7;
    }
.topnav_main_div{
    border: none !important;
    box-shadow: none !important;
    padding:none !important;
    // height: 1vw;
}

    .mobile_view_pop .modal-content {
        width: inherit; 
        margin: 0 auto;
        border: 1px solid #FFF;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);

      }
      .mobile_view_pop{
        color: #263659;
        font-size:4vw;
        font-weight: 600;

      }
      .modal-dialog {
        position: relative;
        width: auto;
        margin: var(--bs-modal-margin);
        pointer-events: none;
        top: 45% !important;
    }
      .modal_footer_signout{
        padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 2);
      }
      .signout_no{
        border: 1px solid #D8D8D8;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .4);
        color: #263659;
        font-size:4vw;
        font-weight: 600;

      }
      .signout_yes{
        border: 1px solid #D8D8D8;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .4);
        color: #263659;
        font-size:4vw;
        font-weight: 600;

      }
  
 }
 @media (min-width: 768px) and (max-width: 1024px) {
    .topnav_main_div{
    //  left:16vw!important;
    }

    .topnav_main_div{
        // margin: 0% !important;
        // z-index: 100;
        // width: 100% !important;
        // left: 0% !important;
        // padding:0px  7.8611111111vw;
    }
   .nav_top_togglebtn{
    margin: 0% !important;
    z-index: 100;
   }
    .nav_top_togglebtn{
        margin-left:-6vw;
    }
  }

  /* Add this style to center the modal vertically and horizontally */
.mobile_view_pop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Optionally, add a maximum width for the modal */
  

  /* Add this style to create an overlay */
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    justify-content: center;
    align-items: center;
  }
  
  /* Style for the modal content box */
  .modal-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
  }
  
  /* Optionally, add styles for close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  
  Button{
    border-radius: 10px !important;
  }