.import-upload-component {
    .list-group-item.active {
        color: #000;
        background-color: #fff;
        border-color: var(--bs-list-group-border-color);
    }

    .list-group-item {
        .success-icon {
            display: none;
        }
    }

    .list-group-item.active {
        .success-icon {
            display: block;
        }

        .arrow-icon {
            display: none;
        }
    }

    .form-select {
        border: 1px solid rgba(0, 0, 0, 0.17);
        border-radius: 0;
        color: #000 !important;
        font-weight: 500 !important;
        font-size: 0.9375vw;
        border-radius: 4px;
        background-color: #F6FAFF;

        option {
            font-size: 0.9375vw !important;

        }

    }



    .form-check-input:checked {
        background-color: #77A13B;
        border-color: #CBCBCB;
    }

    .card {
        border-radius: 2px;
        // background-color: #F6FAFF;
    }

    .pdf-icon {
        color: #1C477A;
    }

    .pdf-upload-main {
        margin-bottom: 2.6041666666666665vw;
    }
}