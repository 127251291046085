.Areagraph_main {
    height: 100% !important ;
    width: 100% !important ;
    position: relative;
    display: flex;
    align-items: center;
    justify-content:center;
    /* z-index:1; */
  }
.reddy_color{
  height: 0.7291666666666666vw;
  width: 0.7291666666666666vw;
  background-color:#3396EC;
  border: none;
}
.dx-export-menu-button{
  display:none
}
.dxc-chart{
  height: 10.5vw !important;
  
}
.dxc-legend{
  display: none;
}
.dxc-item{
  display: none;
}
text{
  font-size: 0.7638888888888888vw !important;
  font-weight: 600 !important;
  font-family: 'Montserrat';
}
.castedetails_main_div{
  right: 1vw;
  height:fit-content;
  width: fit-content;
  top: 20vw;
  position: absolute;
 
}
.castecolo_div{
  height:0.7291666666666666vw ;
  width:0.7291666666666666vw;
  border-radius: 100%
}
.castecolors_caste_div{
  margin-left: 0.5vw;
  display: flex;
  font-size: 3.888vw !important;

  font-family: 'Montserrat';
  font-weight: 1000;
  color:#444;
  width: 3vw;

}
.castecolors_caste_div_main{
  margin-top: 0.5vw;
}
.underline_admin_caste_chart_div{
  width: 1.6666666666666667vw;
  height: 0.1vw;
  background-color: #ACACAC;
}
.caste_chart_main_div{
  position: absolute;
  left:30.0416666666666667vw;
  top: 1.0416666666666667vw;
  width: 30vw;
}
.doughnut_cast_chart_text{
  font-size: 0.9375vw;
  font-family: 'Montserrat';
  font-weight: 1000;
}
.charts_viewport_text{
  position: absolute;
  bottom: 1.0416666666666667vw;
  left: 1.0416666666666667vw;
  font-size: 0.7291666666666666vw;
  font-family: 'Montserrat';
  font-weight: 1000;
  color: #174A79;
}
.dougnut_graph_center_text{
  position: absolute;
  left:33.5%;
  top:44.7%;
  font-size: 0.9375vw;
  font-family:'Montserrat';
  font-weight: 800;
  text-align: center;
  height: fit-content;
  width: 27.9%;
}
.Areagraph_main text {
  font-size: 0.8vw !important;
  margin-left: 0%;
  font-family: 'Montserrat' !important;
  font-weight: 800 !important;
}
.hover_text_graphs{
  font-size: 2.8vw;
  font-family: "Montserrat";
  font-weight: 800;
}

@media screen and (max-width:767px) {
  .pie_chart_totalvoters{
    width: 40vw !important;
    height: 40vw !important;;
  }
  .Areagraph_main{
    /* z-index:1 */
  }
/* anything worn adjust here */
   .dx-visibility-change-handler{
    
    position: absolute;
    /* top:20vw; */
    top:17vw !important;
    /* width: 100vw !important; */
    width: 81vw;

  }
  .castedetails_main_div{
    /* right: 1vw;
    right:-4vw;
    height:fit-content;
    width: 81vw;
    top: 60vw; */

    right: -5vw;
    height: -moz-fit-content;
    height: fit-content;
    width: 81vw;
    top: 52vw;
    height: 23vw;
    /* background-color: red; */
    position: absolute;
    margin:0vw;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    /* background-color: red; */
    overflow: hidden;
    /* width: 5vw;;
    background-color: red; */
  
  }
  .partydetails_main_div{
    /* right: 0vw;
    height:fit-content;
    width: 80vw; */
    right: -2vw;
    height: fit-content;
    height: 21vw;
    top: 62vw;
    /* top:51vw; */
    width: 80vw;
    position: absolute;
    margin:0vw;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    /* background-color: red !important; */

  }
  .castecolors_caste_div {
    margin-left: 2.5vw;
    display: flex;
    font-size: 3.888vw !important;
    font-family: 'Montserrat';
    font-weight: 1000;
    color: #444;
    width: 15vw !important;
    width: 32vw !important;
  
}

.castedetails_main_div_caste{
  /* height:20vw; */
  height:25vw !important;
  overflow-y: scroll;
  /* background-color: green !important; */
}
  .underline_admin_caste_chart_div {
    width: 87vw !important;
    margin-left: -1.8vw !important;
    margin-top:2vw; 
}
  .dxc-chart{
    height: 42.5vw !important;
    width: 100% !important;
    
  }
  .doughnut_cast_chart_text{
    font-size: 5vw !important;
    margin-top: .0555555555555554vw;
    color: #444444;
    color: #444;
font-style: normal;
font-weight: 700;
line-height: normal;
/* width: 20px; */
/* margin-left:-1vw; */
  }
  
  .castecolo_div{
    height: 5vw !important;;
    width:5vw !important;
    border-radius: 0%!important;


  }
  /* .castecolo_div{
    height:0.7291666666666666vw ;
    width:0.7291666666666666vw;
    border-radius: 100%
  } */
  #pie{
    /* position: absolute;
    top: 20vw;
    left: -3vw;
    width: 100vw !important;
    height: auto !important; */
  }
  .voters_Areagraph_viewreport {
    position: fixed;
    background-color: #ffffff;
    width: 88%;
    left: 6%;
    height:63vw;
    height: 116vw !important;
    height:99vw !important;
    top: 35vw;
    opacity: 1 !important;
    /* background-color: red; */
    /* position: relative;
    left:4vw */
}
  .Areagraph_main text {
    font-size: 2vw !important;
    margin-left: 0% !important ;
    font-family: 'Montserrat' !important;
    font-weight: 800!important;
    
  }
  .dougnut_graph_center_text{
    font-size:4vw;
    margin-left:-12vw;
    margin-top:13vw;
    width: 60.9%;
    /* background-color: red; */

  }

  .voters_bargraph_viewreport {
    position: fixed;
    background-color: #ffffff;
    width: 87%;
    left: 7%;
    /* height:90vw; */
    min-height:100vw;

    /* height:76vw; */
    top: 35vw;
    opacity: 1 !important;
}
.voters_bargraph_viewreport .dx-visibility-change-handler {
  width: 96% !important;
  height: 60%;
  height:53%;
  margin-top: -8vw;
}
.important_people_name_text {
  margin-left: 3.979167vw;
}
.admin_dashboard_text_info_detail_border_div {
  padding-left: 2vw;
}
  .hover_text_graphs{
    font-size: 2vw !important;
    font-family: "Montserrat";
    font-weight: 800;
    
  }
}
@media screen and (max-width:312px) {
  .dougnut_graph_center_text{
    margin-left: 3%;
  }
}

@media  (min-width:768px) and (max-width: 1024px) {

.pie_chart_totalvoters{
  width: 10vw !important;
  /* height: 10vw !important; */
  /* height:22vw !important; */
  margin-left:-11vw !important;
  /* background-color: red !important; */

}
.caste_chart_main_div {
  position: absolute;
  left: 1.958333vw;
  top: 1.0416666666666667vw;
  width: 4vw;
}
.caste_chart_main_div span{
   font-size: 2vw !important;
}
.underline_admin_caste_chart_div {
  width: 6.666667vw;
  height: 0.1vw;
  background-color: #ACACAC;
  display: none !important;
}

.dougnut_graph_center_text {
  position: absolute;
  left: 15.5%;
  top: 83.7%;
  font-size: 1.4375vw;
  font-family: 'Montserrat';
  font-weight: 800;
  text-align: center;
  height: -moz-fit-content;
  height: fit-content;
  width:50.9%;
  /* background-color: red; */
}
.castedetails_main_div {
  right: -2vw;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: 4vw;
  position: absolute;
  height:14vw;
  overflow: hidden;
  
}
.voters_Areagraph_viewreport .castedetails_main_div {
  /* margin-top: 10vw; */
  /* overflow-y: scroll !important;
  height: 30vw;
  width: 8vw;
  background: red; */
}
.castecolors_caste_div {
  margin-left: 0.5vw;
  display: flex;
  font-family: 'Montserrat';
  font-weight: 1000;
  color: #444;
  width: 11vw !important;
  font-size: 1.5vw !important;
}
.castecolo_div {
  height: 1.729167vw;
  width: 1.7291666666666666vw;
  border-radius: 100%;
}
.partydetails_main_div{
  position: absolute;
  top: 4vw;
  right:-1vw;
}
.voters_Areagraph_viewreport {
  position: fixed;
  background-color: #ffffff;
  width: 70%;
  left: 15%;
  height: 35vw;
  top: 20vw;
  opacity: 1 !important;
}


}
@media  (min-width:1140px) and (max-height: 958px){
.castecolors_caste_div {
  margin-left: 0.5vw;
  display: flex;
  font-size: 0.888vw !important;
  font-family: 'Montserrat';
  font-weight: 1000;
  color: #444;
  width: 3vw;
}
.castedetails_main_div {
  right: 2vw;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: 3vw;
  position: absolute;
}
.caste_chart_main_div {
  position: absolute;
  left: 2.041666666666668vw;
  top: 1.0416666666666667vw;
  width: 2vw;
}
#admin_dashboard_bargraph_main_div_charts{
  /* background-color: red; */
}
#admin_dashboard_bargraph_main_div_charts__{
  /* background-color: red !important; */

}
}
@media  (min-width:1366px) and (max-height: 1024px){
  .castecolors_caste_div {
    /* margin-left: 2vw !important; */
    display: flex;
    font-size: 1vw !important;
    font-family: 'Montserrat';
    font-weight: 1000;
    color: #444;
    /* width: 3vw; */
}
.castecolo_div{
  /* margin-left:3vw!important; */
}
.castedetails_main_div {
  right: 2vw;
  top: 2vw;
  position: absolute;
}
}