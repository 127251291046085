.view-data-component {
    .import-footer-main{
        background-color: white;
    }
    .toast-body{
        padding-left: 1.0416666667vw !important;
    padding-right: 1.0416666667vw !important;
    align-items: center;
    font-size: 0.9375vw;
    font-weight: 500;
    height: 2.2vw;
    display: flex;
    justify-content: space-between;
    width: 100%;
    }
    .resetbutton_viewdata {
        border-radius: 4px;
        border: 1px solid #1C477A;
        background: #1C477A;
        color: white;
        padding: 4px 7px 4px 7px;
        margin-bottom: 8px;
    }

    .active-button {
        background-color: #198754;
    }
    .table-main {
        width: 84.5vw;
        transition: width 0.5s ease;
        margin-bottom: 6vw;
      }
    .table-main-full {
        width: 100vw;
        transition: width 0.5s ease;
        }
      
    .tablemain_div {
        overflow: scroll;
        height: calc(100vh - 295px);
        border: 1px solid darkgray;
        margin-bottom: 5px;
        width: 100% !important;
        
    }

    ::-webkit-scrollbar-track {
        background: rgb(237, 236, 236);
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: darkgray;
        border-radius: 10px;
    }

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            

            :last-child {
                border-right: 0;
            }

            input {
                font-size: 0.8rem !important;
                font-weight: 400;
                padding: 0;
                margin: 0;
                border: 0;
                margin-left: -7px;
                width: 100%;
            }

            .resizer {
                display: inline-block;
                // background: blue;
                width: 10px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                /* prevents from scrolling while dragging on touch devices */
                touch-action: none;

/*                 &.isResizing {
                    //   background: red;
                } */
            }

            &.error-cell {
                border: 1px solid #ee6036 !important;
            }
        }
    }

    .pagination {
        // padding: 0.5rem;
        display: flex;
        gap: 13vw;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;

        .pagination_buttons {
            border: 2px solid darkgray;
            border-radius: 4px;
        }

        .select_pages {
            width: 10vw;
            height: 33px;
            border: 1px solid darkgray;
        }
    }

  
}
@media (max-width: 708px) {
    .pagination{
        display: grid !important;
        grid-template-columns: auto auto;
        .pagination_element{
            padding: 0px;
        }
    }

}
@media (max-width:1281px){
    .table-main {
        margin-bottom: 7vw;
    }
}