.wardvillage_issues_filters_main_div {
  width: fit-content;
  margin-left: 1.1%;
  margin-top: 0.5%;
}

.constituency_wardvillageissues {
  width: 19.9vw;
  height: 2.65vw;
  margin-left: 0.8vw;
  background-color: #f3f7f9;
  position: relative;
  display: flex;
  align-items: center;
  border: 0.1vw solid #d8d8d8;
  border-radius: 0.3vw;
}
.nodata_filters_data {
  text-align: center;
  color: gray;
  font-weight: 800;
  font-family: "Montserrat";
}
.ward_village_dropdown_arrow {
  height: 0.6291666666666667vw;
  width: 0.7291666666666666vw;
  /* position: absolute; */
  /* left:90%; */
  /* background-color: red !important; */
}

.wardvillages_dropdowns_data_scroller_div {
  height: 65%;
  overflow-y: scroll;
  width: 100%;
  margin-top: 0.7%;
}

.dropdown_wardvillages_data_div {
  background-color: #ffffff;
  box-shadow: 0.1vw 0.1vw 0.5vw black;
  border-radius: 0.5vw;
  z-index: 50;
  animation: mandaldisplay 0.4s linear;
  border: none;
  width: 20vw;
  height: 10vw;
  margin-left: 0.5vw;
  position: absolute;
  z-index: 50;
  margin-top: 0.5vw;
  animation: dropdown 0.7s linear;
}

@keyframes dropdown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.lablesof-wardvillage_dropdown_data {
  width: 100%;
  height: 3vw;
  border: 0.1vw solid #ccd0d6;
  align-items: center;
  padding: 0;
  margin: 0;
  text-align: left;
  background-color: #ffffff;
}

.ward_village_filter_contstiency_text {
  color: #8b93a5;
  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 57%;
  overflow: hidden;
  margin-left: 0.78vw;
  width: 10vw;
  text-align: left;
}

.ward_village_filter_constiency_checkbox {
  margin-left: 1vw;
}

.ward_village_filter_constiency_api_data {
  margin-left: 2vw;
  font-size: 1vw;
  width: 70%;
  overflow: hidden;
  height: 50%;
}

.searchbar_wardvillagesissue_filters {
  width: 96%;
  height: 2.6vw;
  margin-left: 2%;
  margin-top: 2%;
  border-radius: 0.4vw;
  border: 0.1vw solid #d8d8d8;
  font-size: 1vw;
}

@media screen and (max-width: 767px) {
  .constituency_wardvillageissues {
    border-radius: 1vw !important;
  }
  .searchbar_wardvillagesissue_filters {
    font-size: 4vw;
    font-weight: 500;
    font-family: "Montserrat";
    border: none !important;
    outline: none !important;
  }
  .nodata_filters_data {
    text-align: center;
    color: gray;
    font-weight: 800;
    font-size: 4vw !important;
    font-family: "Montserrat";
  }
}

.todo_sroll_wardvillages {
  height: 33vw;
  overflow-y: scroll;
  margin-top: 0.5vw;
  width: 98%;
  margin-left: 1%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .searchbar_wardvillagesissue_filters::placeholder {
    color: #457bbc !important;
    font-size: italic !important;
  }
  .nodata_filters_data {
    text-align: center;
    color: gray;
    font-weight: 800;
    font-family: "Montserrat";
    font-size: 2vw !important;
  }
  .ward_village_filter_constiency_api_data {
    color: #444;
    font-weight: 600;
    font-size: 2vw !important;
    width: 70%;
    height: auto;
  }
  .ward_village_filter_contstiency_text_more {
    margin-left: 3vw !important;
  }
  .ward_village_filter_contstiency_text {
    width: 50% !important;
  }
  .searchbar_wardvillagesissue_filters {
    font-size: 2vw;
    font-weight: 500;
    font-family: "Montserrat";
    border: none !important;
    outline: none !important;
    font-style: normal !important;
  }
  .wardvillages_dropdowns_data_scroller_div {
    height: 55%;
    /* height: auto !important; */
    overflow-y: scroll;
    width: 100%;
    margin-top: 0.7%;
    /* background:red !important; */
}

  .searchbar_wardvillagesissue_filters::placeholder{
    /* font-size: 5vw !important; */
  }
  
}
