.import-batch-component {
    .table-main {
        height: calc(100vh - 247px);
        overflow: auto;
    }
    .total_pages{
        position: relative;
    }
   .table {
        width: 100%;
        // margin-bottom: 11%;


        th {
            background: #E8ECF1;
            color: #4D7093 !important;
        }

        th,
        td,
        tr {
            border: 1px solid lightgray;
            padding: 0.5208333333333334vw;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 0.9375vw;
            line-height: 1.1458333333333333vw;
            color: #263659;
            vertical-align: middle;

        }
        td,
        tr{
            padding: 0.8854166666666666vw;
        }


    }

    #cancel_button {
        border: 1px solid #1C477A !important;
        color: #1C477A !important;
        border-radius: 4px !important;

    }

    #prv_button {

        background: #C4D1E0;
        border-radius: 4px;
    }

    #next_button {
        border-radius: 4px !important;

    }

    .map_div {
        color: #528BB8;
    }

    .unmapped_div {
        color: #263659;
    }

    .json_data {
        color: #263659;
    }

}

@media (max-width:430px) {
    .mapping-field-component {
        .table {
            margin-right: 0.2604166666666667vw;
        }
    }
}
@media (max-width:1200px){
    .import-batch-component{
        overflow-x: scroll;
        width: 100%;
        .table {
            width: 1000px;
            // margin-bottom: 11%;
        }
    }
    ::-webkit-scrollbar-track {
        background: rgb(237, 236, 236);
    }

    ::-webkit-scrollbar {
        width: 0.2604166666666667vw;
        height: 0.2604166666666667vw;
    }

    ::-webkit-scrollbar-thumb {
        background: darkgray;
        border-radius: 0.5208333333333334vw;
    }
}