.signupcontainer {
  background: linear-gradient(
    153deg,
    rgba(217, 217, 217, 0) 0%,
    #93c7f7 80.21%
  );

  .arrrow_div {
    // width: 3vw;
  }
  .leftarrow_img_signupimg {
    height: 2vw;
    width: 1vw;
    margin-left: 3vw;
    margin-top: 3vw;
  }
  .signup_emslogo {
    height: 22.2916666667vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signup_emslogo img {
    height: 6.21875vw;
  }

  .signup__main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signup__sub {
    width: 30.57291666666667vw;
    border-radius: 12px;
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8854166666666667vw;
    margin-bottom: 1.041666666666667vw;
  }

  .signup__sub h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 2.25vw;
    line-height: 1.510416666666667vw;
    color: #0047b2;
    text-align: center;
    margin: 0;
  }

  .hrline1 {
    width: 9.7vw;
    height: 0;
    border: 1px solid #a5a5a5;
  }

  .hrline2 {
    width: 9.7vw;
    height: 0;
    border: 1px solid #a5a5a5;
  }

  .signup__sub p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    color: #263659;
    margin-left: 1.5625vw;
    margin-bottom: 3px;
    display: none !important;
  }
  #districtError,
  .usertypeerrormessage {
    margin-left: 0px !important;
  }
  .signup__sub_inputBlock {
    width: 27.447916666666668vw;
    height: 3.65625vw !important;
    margin-left: 1.5625vw;
    border-radius: 0.4vw;
    margin-top: 3vw !important;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .signup__sub_inputBlock::placeholder {
    font-size: 0.9375vw !important;
    font-weight: 500 !important;
    margin-left: 0.8vw !important;
  }
  .signup__sub_inputBlock input {
    width: 27.447916666666668vw;
    height: 4vw !important;
    border: none;
    border-radius: 0.4vw !important;
    font-size: 0.9375vw;
    font-family: "Montserrat";
    font-style: "Medium";
    margin-left: 0.8vw !important;
    
  }

  .signup__sub_inputBlock input:focus-visible {
    outline: none;
  }

  ::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.8333333333333333vw;
    color: #8b93a5;
  }

  .signin__text {
    height: 7.078125vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    color: #056b38;
    text-decoration: none;
    font-size: 2.041666666666667vw;
    margin-left: 5vw;
  }
  .signin__text span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 1.341666666666667vw;
    line-height: 2.25vw;
    color: #8b93a5;
    margin-bottom: 0;
  }

  .signin__text p a {
    color: #1c477a;
    text-decoration: none;
    margin-top: 2vw;
  }

  .copyright {
    height: 4.47916667vw;
    background: #1b2837;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.7291666666666667vw;
    line-height: 0.8854166666666667vw;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px !important;
  }


  .extra__text {
    margin-left: 0 !important;
  }
  .signup_phonenumberBlock_itoconnect-phoneblock_ {
    margin-top: 2vw;
  }
  .signup_DistrictBlock {
  }

  .signup_DistrictBlock {
    margin-left: 2.3vw !important;
  }

  .signup_DistrictBlock,
  .signup_usertypeBlock {
    width: 13.17708333333333vw !important;
    height: 3.65625vw !important;
    margin-left: 1.5625vw;
    border-radius: 0.4vw;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2.5vw;
    background: none !important;
  }
  #user_type_signup_bloack {
    width: 15vw;
  }
  .sigup_select_state {
    width: 15vw;
  }
  .signup_stateBlock {
    width: 13.17708333333333vw !important;
    margin-left: 16.5625vw;
    background-color: none !important;
    border-radius: 0.4vw;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -3.5vw;
  }
  .signup_stateBlock {
    margin-top: -3.9vw !important;
  }
  .signup_ConstituencyBlock {
    width: 13.17708333333333vw !important;
    height: 3.65625vw !important;
    margin-left: 16.5625vw;
    background-color: none !important;
    border-radius: 0.4vw;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -3.63vw;
  }
  .signup_DistrictBlock {
    margin-left: 2vw;
  }
  .signup_usertypeBlock {
    margin-left: 2.3vw;
  }

  .signup_stateBlock_Input,
  .signup_DistrictBlock_input,
  .signup_ConstituencyBlock_input,
  .signup_usertypeBlock_input {
    background-color: #f3f7f9;
    width: 90%;
    border: none;
    font-size: 0.9375vw;
    font-size: 0.9375vw;
    color: black;
    font-family: "Montserrat";
    font-style: "Medium";
    padding-left: 0.9375vw;
  }

  .signupbutton__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .select_role__control {
    height: 3.65vw;
  }
  #errormessage_firstName_ {
    color: red !important;
    margin-left: .74vw !important;
    font-weight: 700 !important;
    font-size: 1vw;
    margin-top: -0.5vw;
  }
  #errormessage_phonenumber {
    color: red !important;
    font-weight: 700 !important;
    font-size: 1vw;
    position: relative;
    left:2.3vw !important;

  }
  #itoconnect-phoneblock_main_{
    width: 28vw;;
    border: none;
    outline: none;
  }
  .signup_phonenumberBlock_input {
    outline: none;
    border: none;
  }
  #errormessage_district,
  #errormessage_constituency {
    color: red !important;
    margin-left: 0vw !important;
    font-weight: 700 !important;
    font-size: 1vw;
  }
  #errormessage_state {
    color: red !important;
    margin-left: 0vw !important;
    font-weight: 700 !important;
    font-size: 1vw;
  }
  .signup__button {
    background: #056b38;
    border: none;
    border-radius: 6px;
    width: 17.76041666666667vw;
    height: 5vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 501;
    font-size: 2vw;
    line-height: 1.25vw;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 4.354166666666667vw;
    margin-bottom: 0.4166666666666667vw;
  }

  #role,
  #state,
  #dist,
  #constituency {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.8333333333333333vw;
    line-height: 1.145833333333333vw;
    color: #8b93a5;
    border: none;
    margin: 0;
    width: 100%;
    background-size: 0.7291666666666667vw;
    --bs-btn-active-bg: none !important;
    --bs-btn-hover-bg: none !important;
  }
  .dropdown-menu.show {
    display: block;
    padding: 0;
    border-radius: 7px;
    background: #ffffff;
    border: 1px solid #d7c9c9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
  .dropdown-toggle::after {
    display: inline-block;
    position: absolute;
    left: 11vw;
    top: 1.1vw;
    font-size: 1.5vw;
    vertical-align: 0.1em !important;
    color: #263659;
  }
  .dropdown-item {
    border: 1px solid #d9d8d8 !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375vw;
    color: #263659;
  }
  .dropdown-menu {
    overflow-y: scroll !important;
  }
  .dropdown-menu::-webkit-scrollbar-track {
    background-color: transparent;
    opacity: 0;
    transition: opacity 0.2s ease;
    border-radius: 7px;
  }

  .dropdown-menu::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  .signup_DistrictBlock_input,
  .signup_ConstituencyBlock_input,
  .signup_stateBlock_Input,
  .signup_usertypeBlock_input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background: transparent;
    background-color: #f3f7f9 !important;
    background-position: fixed;
    border: 0px;
    outline: 0px;
  }

  .signup_phonenumberBlock {
    width: 13.17708333333333vw !important;
    height: 2.65625vw !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 1.5vw;
  }
  .signup_phonenumberBlock_input::placeholder {
    font-size: 0.9375vw !important;
    font-weight: 500 !important;
  }
  #itoconnect-phoneblock_main {
    margin-left: 1.5vw;
  }
  .signup_phonenumberBlock_input {
    background-color: #f3f7f9;
    border: none;
    padding-left: 0.8vw !important;
    font-size: 0.9375vw;
    color: #8b93a5;
    font-family: "Montserrat";
    font-style: "Medium";
    border-radius: 0.4vw !important;
  }
  .signup_phonenumberBlock_input {
    width: 27.5vw !important;
    height: 3.65vw;
    margin-top: 2.5vw !important;
    margin-left: 2.3vw !important;
  }
  .signup_phonenumberBlockCountrycode {
    background-color: rgb(184, 184, 184);
    width: 3vw;
    border: none;
    font-size: 0.9375vw;
    font-size: 0.9375vw;
    color: white;
    font-family: "Montserrat";
    font-style: "Medium";
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0.4vw;
    border-bottom-left-radius: 0.4vw;
  }
  .usertypeerrormessage {
    color: red !important;
    font-size: 0.8375vw !important;
    margin-top: 5px;
    font-weight: 500 !important;
  }

  .signup__sub p.errormessage {
    font-weight: 500;
    color: red;
    font-size: 0.8375vw;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .signup__sub_inputBlock input::placeholder,
  .signup_DistrictBlock_input::placeholder,
  .signup_stateBlock_Input::placeholder,
  .signup_ConstituencyBlock_input::placeholder,
  .signup_phonenumberBlockCountrycode::placeholder,
  .signup_usertypeBlock_input::placeholder,
  .signup_phonenumberBlock_input::placeholder,
  .react-select-role-container::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    z-index: 1;
    font-size: 1vw !important;
    color: #8b93a5;
    font-weight: 600;
    padding-left: 1vw;
  }
  .select_role__placeholder {
    font-size: 1vw !important;
    font-weight: 600;
  }

  .select_role__option {
    font-size: 1vw !important;
  }
  .dropdown-item {
    padding: 1.5vw;
  }
  .dropdown-toggle::after {
    font-size: 6.5vw;
    left: 63vw;
    top: 3vw;
  }
  .dropdown-menu.show {
    max-height: 20vw !important;
  }

  .select_role__menu {
    max-width: 13.2vw !important;
    background-color: #ffffff !important;
  }
  

  @media (max-width: 1250px) {
    .signup__sub {
      width: 30.57291666666667vw;
    }
  }

  @media (max-width: 767px) {
    .signupcontainer {
      background: linear-gradient(
        153deg,
        rgba(217, 217, 217, 0) 0%,
        #93c7f7 80.21%
      ) !important;
    }

  
    .select_role__control {
      width: 35.011vw !important;
      height: 11.111111111111vw !important;
      font-size: 3.888888888888889vw !important;
      border-radius: 5px !important;
      border: 1px solid #0047b2;
      background: #fff;
    }
    .select_role__placeholder {
      font-size: 2.888888888888889vw !important;
      font-weight: 600;
    }

    .select_role__option {
      font-size: 3.888888888888889vw !important;
    }
    div[class$="-control"]:after {
      right: 3.9375vw;
    }
    .dropdown-item {
      font-size: 3.888888888888889vw !important;
      padding: 1.5vw;
    }
    .dropdown-toggle::after {
      font-size: 6.5vw;
      left: 63vw;
      top: 3vw;
    }
    .dropdown-menu.show {
      max-height: 20vw !important;
    }
    .signup_emslogo {
      height: 21.66666666666667vw;
    }

    .signup_emslogo img {
      height: 22.38888888888889vw !important;
    }

    .signup__sub {
      width: 86.11111111111vw;
      background: none;
      box-shadow: none;
      margin-top: 17vw;
      margin-top: 8vw !important;
    }
    .signup__main {
      min-height: 100vh !important;
    }
    .grid-container {
      display: grid;
      grid-template-columns: auto;
    }
    .sigup_select_state {
      position: relative;
    }
    .signup_stateBlock {
      position: absolute;
      left: 40vw;
    }
 
    .signup_ConstituencyBlock {
      position: relative;
      left: 40vw;
      bottom: 16vw;
    }
    .signup_stateBlock_dropdown {
      height: 11vw;
    }
    .signup_stateBlock {
      width: 35vw !important;
      margin-top: -11.23vw !important;
      height: 11.11vw !important;
    }

    .signup_stateBlock,
    .signup_ConstituencyBlock,
    .signup_usertypeBlock,
    .signup_DistrictBlock {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 3.888888888888889vw !important;
      line-height: 4.722222222222222vw !important;
      color: #8b93a5;
    }

    .signupcontainer,
    .signup_usertypeBlock,
    .signup_stateBlock,
    .signup_DistrictBlock,
    .signup_ConstituencyBlock {
      background: none;
    }
    .sigup_select_ditrict {
      margin-left: 3vw !important;
    }
    .signup_DistrictBlock {
    }

    .signup_phonenumberBlock_input {
      border: none;
      outline: none;
    }
    #itoconnect-phoneblock_main_ {
      border: none;
      outline: none;
      height: 15vw;
      // background-color: red;
    }
    #errormessage_phonenumber {
      color: red;
      font-size: 2.811111111111111vw;
      position: relative;
      width: 100%;
      bottom: 2vw;;
      font-weight: 700;      
    }
    #phoneblock_main_{
      background-color: red !important;
      height:14vw !important;
    }
    #itoconnect-phoneblock {
      width: 74.72vw !important;
      margin-left: 3.3vw;
      margin-top: 7.33vw !important;
    }

    .signup_stateBlock,
    .signup_usertypeBlock {
      height: 11.111111111111vw !important;
      margin-left: 5.533333333333333vw;
    }
    .signup_stateBlock {
    }
    .label_state {
      padding: 0px !important;
      margin: 0px !important;
    }
    .signup_ConstituencyBlock,
    .signup_DistrictBlock {
      margin-left: 5.533333333333333vw;
      margin-top: 12.333vw;
    }

    .signup__sub_inputBlock {
      width: 74.722222222222vw !important;
      height: 13.111111111111vw;
      margin-left: 4.833333333333333vw;
      margin-top: 6.333vw;
      background: none;
    }
    .signup__sub p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 4.444444444444444vw;
      line-height: 9.555555555555556vw;
      color: #263659;
      margin-left: 5.833333333333333vw;
      color: red;
      display: none;
    }

    .extra__text {
      margin-left: 5.833333333333333vw !important;
    }

    .signup__sub_inputBlock input,
    .signup_DistrictBlock_input,
    .signup_stateBlock_Input,
    .signup_ConstituencyBlock_input,
    .signup_phonenumberBlockCountrycode,
    .signup_usertypeBlock_input,
    .signup_phonenumberBlock_input {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      z-index: 1;
      font-size: 3.888888888888889vw !important;
      color: #8b93a5;
      border-radius: 5px !important;
      border: 1px solid #0047b2;
      background: #fff;
      height: 11.11vw !important;
      width: 74vw !important;
      padding: 0;
    }
    #itoconnect-phoneblock_main {
      margin-top: 10vw !important;
    }
    .signupcontainer .signup__sub_inputBlock {
    }
    .signup__sub_inputBlock {
      height: 11.11vw !important;
    }
   
    #singup_block_firstname {
      margin-top: 10vw !important;
      word-break: break-all;
    }
    #singup_block_lastname {
      margin-top: 8vw !important;
    }
    #signup__sub_inputBloc__ {
      margin-top: 9vw !important;
      margin-top: 10.5vw !important;

      height: 9vw !important;
    }
   
    .signup_phonenumberBlock_input {
      margin-top: 0vw !important;
    }
    .signup__sub_input {
      height: 11.11vw !important;
    }
    .signup_phonenumberBlock_input {
      width: 100%;
      height: 11.11vw;
    }
    div[class$="-control"]:after {
      right: "0.9375vw";
    }

    #user_type_signup_bloack {
      margin-top: 6vw !important;
    }

    .signup__sub_inputBlock input::placeholder,
    .signup_DistrictBlock_input::placeholder,
    .signup_stateBlock_Input::placeholder,
    .signup_ConstituencyBlock_input::placeholder,
    .signup_phonenumberBlockCountrycode::placeholder,
    .signup_usertypeBlock_input::placeholder,
    .signup_phonenumberBlock_input::placeholder,
    .react-select-role-container::placeholder {
      font-family: "Montserrat";
      font-style: normal;
      z-index: 1;
      font-size: 3.5vw !important;
      color: #8b93a5;
      font-weight: 600;
      padding-left: 2vw;
    }

    .signup_phonenumberBlockCountrycode {
      width: 10vw;
      display: none;
    }

    .signup__button {
      width: 43.88888888vw !important;
      height: 12.5vw;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 5.1111vw;
      line-height: 4.722222222222222vw;
      color: #fff;
      border-radius: 6px !important;
      background: #056b38;
      margin-top: 11vw;
    }

    .bottom__text {
      font-size: 3.611111111111111vw !important;
      line-height: 4.444444444444444vw !important;
      margin-top: 4vw !important;
      margin-bottom: 6vw !important;
    }
    .signup__sub h3 {
      font-family: "Montserrat";
      color: #0047b2;
      text-align: center;
      font-size: 5.944vw;
      font-weight: 700;
      line-height: normal;
    }

    .hrline1,
    .hrline2 {
      width: 25.83333333333333vw;
    }
    .signup__sub_inputBlock_ {
      width: 74.722222222222vw !important;
      height: 13.111111111111vw;
      margin-left: 3.333333333333333vw !important;
      margin-top: 8vw !important;
      background: none;
      
    }
    .signupbutton__main {
      margin-bottom: 29.166vw !important;
      height: 0vw;
      margin-top: 3vw !important;
    }
    .signin__text {
      height: 19.44444444444444vw;
      padding-bottom: 10vw !important;
    }

    .signup__sub_inputBlock {
      height:12vw !important;
    }
    #errormessage_firstName {
      color: red;
      font-size: 2.811111111111111vw;
      margin-top: -4vw !important;
      width: 90%;
      font-weight: 700 !important;
      margin-left: 2vw;
    }
    #errormessage_firstName_ {
      color: red;
      font-size: 2.811111111111111vw;
      width: 90%;
      font-weight: 700 !important;
      margin-left: 1vw !important;
      position: relative;
      bottom: 2vw;
    }

   
    #errormessage_state {
      color: red;
      font-size: 2.811111111111111vw;
      position: relative;
      width: 21vw !important;
      font-weight: 700;
      margin-left: 0vw !important;
      height: 1vw;
    }
    #errormessage_district {
      color: red;
      font-size: 2.811111111111111vw;
   
      position: relative;
      width: 41vw !important;
      font-weight: 700;
      margin-left: 1vw !important;
    }
    #errormessage_constituency {
      color: red;
      font-size: 2.811111111111111vw;
      position: relative;
      width: 41vw !important;
      font-weight: 700;
      margin-left: 0vw !important;
    }
    .signin__text span b {
      color: #056b38 !important;
      font-family: "Montserrat";
      font-size: 4.44vw;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    .signin__text p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 3.611111111111111vw;
      line-height: 4.444444444444444vw;
      text-align: center;
      width: 50vw;
      color: #8b93a5 !important;
    }
    .sing_text_title {
      margin-top: 7.166vw;
      font-size: 3.5vw !important;
      color: #666;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    .sign_text_loginlink b {
      color: #056b38 !important;
      font-family: "Montserrat";
      font-size: 4.44vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      justify-content: center;
      margin-top: 1vw;
    }
    .sign_text_loginlink a {
      text-decoration: none;
    }
    .css-1pkxkvx-control svg {
      color: #aeaeae;
     
    }
    .usertypeerrormessage {
      color: red !important;
      font-size: 2.811111111111111vw !important;
      font-weight: 500 !important;
      margin-top: 5px;
      line-height: 5vw !important;
    }

    .signup__sub p.errormessage {
      color: red;
      font-size: 2.811111111111111vw;
      margin-top: 5px;
      line-height: 5vw;
      width: 90%;
    }
    #districtError,
    .usertypeerrormessage {
      margin-left: 5.8333333333vw !important;
    }

    .copyright {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 3.611111111111111vw !important;
      line-height: 4.444444444444444vw !important;
      text-align: center;
      height: 18.44444444444444vw !important;
      color: #8b93a5 !important;
      margin-top: 0px !important;
    }

    #role,
    #state,
    #dist,
    #constituency {
      background-position: fixed !important;
    }
    #role,
    #state,
    #dist,
    #constituency {
      font-size: 3.9375vw !important;
      line-height: 5vw;
      font-family: "Montserrat";
      font-weight: 501 !important;
      color: #8b93a5 !important;
      opacity: 1;
      background-size: 4vw;
    }

    option {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      z-index: 1;
      font-size: 2.888888888888889vw !important;
      line-height: 4.722222222222222vw !important;
      color: #8b93a5;
      background-color: #ffffff;
    }
    .arrrow_div {
      margin-left: 6.944444vw;
      position: relative;
      top: 15.555vw;
      width: 2.777777vw !important;
      height: 5vw !important;
    }
    .leftarrow_img_signupimg {
       position: absolute;
      top: 5vw !important;
      left: 2vw !important;
      width: 2.777777vw !important;
      height: 5vw !important;
    }
    .select_role__menu {
      max-width: 35vw !important;
      background-color: #ffffff !important;
    }

    .signup_stateBlock_dropdown .select_role__option {
      background-color: #ffffff !important;
    }
    .signup_usertypeBlock_dropdown .select_role__option--is-selected,
    .signup_stateBlock_dropdown .select_role__option--is-selected {
      background-color: #ffffff !important;
    }
    .signup_usertypeBlock .react_select_role__menu,
    .signup_usertypeBlock .react_select_role__option,
    .signup_usertypeBlock .react_select_role__option--is-focused,
    .signup_usertypeBlock .react_select_role__option--is-selected,
    .signup_usertypeBlock .react_select_role__option:hover {
      background-color: red !important;
    }
    .signup_usertypeBlock .react_select_role__option--is-selected {
      background-color: red !important;
      color: black;
    }

   
  
  }

  ::placeholder {
    font-family: "Montserrat";
    color: #8b93a5;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  
    .signupcontainer {
      background: linear-gradient(
        153deg,
        rgba(217, 217, 217, 0) 0%,
        #93c7f7 80.21%
      ) !important;
    }

    .css-1xc3v61-indicatorContainer {
      padding: 0;
    }
    .select_role__control {
      width: 35.011vw !important;
      height: 11.111111111111vw !important;
      font-size: 3.888888888888889vw !important;
      border-radius: 5px;
      border: 1px solid #0047b2;

      background: #fff;
    }
    #singup_block_firstname {
      margin-top: 7vw !important;
    }
    .singup_last_name_input {
      margin-top: 8vw !important;
    }
    .singup_email_input {
      margin-top: 8vw !important;
    }
    .signup_phonenumberBlock_input {
      margin-top: 12vw !important;
      margin-left: 6vw !important;
    }
    .select_role__placeholder {
      font-size: 2.888888888888889vw !important;
    }
    .select_role__option {
      font-size: 3.888888888888889vw !important;
    }
    div[class$="-control"]:after {
      right: 3.9375vw;
    }
    .dropdown-item {
      font-size: 3.888888888888889vw !important;
      padding: 1.5vw;
    }
    .dropdown-toggle::after {
      font-size: 6.5vw;
      left: 63vw;
      top: 3vw;
    }
    .dropdown-menu.show {
      max-height: 20vw !important;
    }
    .signup_emslogo {
      height: 21.66666666666667vw;
    }

    .signup_emslogo img {
      height: 22.38888888888889vw !important;
    }

    .signup__sub {
      width: 86.11111111111vw;
      background: none;
      box-shadow: none;
      margin-top: 17.44vw !important;
    }

    .grid-container {
      display: grid;
      grid-template-columns: auto;
    }
    .sigup_select_state {
      position: relative;
    }
    .signup_stateBlock {
      position: absolute;
      left: 40vw;
    }

    .signup_ConstituencyBlock {
      position: relative;
      left: 40vw;
      bottom: 20.5vw;
    }
    .signup_stateBlock {
      width: 35vw !important;
      margin-top: -11vw !important;
      height: 11.11vw !important;
    }
  
    .signup_stateBlock,
    .signup_ConstituencyBlock,
    .signup_usertypeBlock,
    .signup_DistrictBlock {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 3.888888888888889vw !important;
      line-height: 4.722222222222222vw !important;
      color: #8b93a5;
    }

    .signupcontainer,
    .signup_usertypeBlock,
    .signup_stateBlock,
    .signup_DistrictBlock,
    .signup_ConstituencyBlock {
      background: none;
    }
    .sigup_select_ditrict {
      margin-left: 3vw !important;
    }
    .signup_DistrictBlock {
      background-color: red !important;
    }

    #itoconnect-phoneblock {
      width: 74.72vw !important;
      margin-left: 3.3vw;
      margin-top: 5.33vw !important;
    }

    .signup_stateBlock,
    .signup_usertypeBlock {
      height: 11.111111111111vw !important;
      margin-left: 5.533333333333333vw;
      margin-top: 6.5vw;
    }
    .signup_stateBlock {
    }
    .label_state {
      padding: 0px !important;
      margin: 0px !important;
    }
    .signup_ConstituencyBlock,
    .signup_DistrictBlock {
      margin-left: 5.533333333333333vw;
      margin-top: 12.333vw;
    }
    .signup_ConstituencyBlock {
      margin-top: 16.6vw;
    }

    .signup__sub_inputBlock {
      width: 74.722222222222vw !important;
      height: 13.111111111111vw;
      margin-left: 4.833333333333333vw;
      margin-top: 6.333vw;
      background: none;
    }

    .signup__sub p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 4.444444444444444vw;
      line-height: 9.555555555555556vw;
      color: #263659;
      margin-left: 5.833333333333333vw;
      color: red;
      display: none;
    }

    .extra__text {
      margin-left: 5.833333333333333vw !important;
    }

    .signup__sub_inputBlock input,
    .signup_DistrictBlock_input,
    .signup_stateBlock_Input,
    .signup_ConstituencyBlock_input,
    .signup_phonenumberBlockCountrycode,
    .signup_usertypeBlock_input,
    .signup_phonenumberBlock_input {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      z-index: 1;
      font-size: 3.888888888888889vw !important;
      color: #8b93a5;
      border-radius: 5px;
      border: 1px solid #0047b2;
      background: #fff;
      height: 11.11vw !important;
      width: 74vw !important;
      padding: 0;
      outline: none;
    }

    .signup__sub_inputBlock input::placeholder,
    .signup_DistrictBlock_input::placeholder,
    .signup_stateBlock_Input::placeholder,
    .signup_ConstituencyBlock_input::placeholder,
    .signup_phonenumberBlockCountrycode::placeholder,
    .signup_usertypeBlock_input::placeholder,
    .signup_phonenumberBlock_input::placeholder,
    .react-select-role-container::placeholder {
      padding-left: 2vw !important;
      font-weight: 600 !important;
    }

    .signup__sub_inputBlock {
      height: 12.11vw !important;
    }
    #signup__sub_inputBloc__ {
      // margin-top: 6vw !important;
      margin-top: 11vw!important;

    }
    .singup_last_name_input {
      // margin-top: 8vw !important;
      margin-top: 18vw !important;

    }
    .signup__sub_input {
      height: 11.11vw !important;
    }
    .signup_phonenumberBlock_input {
      width: 100%;
      height: 11.11vw;
    }
    div[class$="-control"]:after {
      right: "0.9375vw";
    }

    .signup__sub_inputBlock input::placeholder,
    .signup_DistrictBlock_input::placeholder,
    .signup_stateBlock_Input::placeholder,
    .signup_ConstituencyBlock_input::placeholder,
    .signup_phonenumberBlockCountrycode::placeholder,
    .signup_usertypeBlock_input::placeholder,
    .signup_phonenumberBlock_input::placeholder,
    .react-select-role-container::placeholder {
      font-family: "Montserrat";
      font-style: normal;
      z-index: 1;
      font-size: 3.5vw !important;
      color: #8b93a5;
    }

    .signup_phonenumberBlockCountrycode {
      width: 10vw;
      display: none;
    }
  
    .signup__button {
      width: 43.88888888vw !important;
      height: 12.5vw;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 5.1111vw;
      line-height: 4.722222222222222vw;
      color: #fff;
      border-radius: 6px !important;
      background: #056b38;
      margin-top: 11vw;
    }

    .bottom__text {
      font-size: 3.611111111111111vw !important;
      line-height: 4.444444444444444vw !important;
      margin-top: 4vw !important;
      margin-bottom: 6vw !important;
    }

    .signup__sub h3 {
      font-family: "Montserrat";
      color: #0047b2;
      text-align: center;
      font-size: 5.944vw;
      font-weight: 700;
      line-height: normal;
    }

    .hrline1,
    .hrline2 {
      width: 25.83333333333333vw;
    }

    .signupbutton__main {
      margin-bottom: 29.166vw !important;
      height: 0vw;
      margin-top: 3vw !important;
    }
    .signin__text {
      height: 19.44444444444444vw;
      padding-bottom: 10vw !important;
    }
  
    #errormessage_firstName {
      color: red;
      font-size: 2.811111111111111vw;
      margin-top: -2vw !important;
      width: 90%;
      font-weight: 700 !important;
      margin-left: 2vw;
    }
    #errormessage_firstName_ {
      color: red;
      font-size: 2.811111111111111vw;
      width: 90%;
      font-weight: 700 !important;
      margin-left: 1vw !important;
    }
   
    #errormessage_phonenumber {
      color: red;
      font-size: 2.811111111111111vw;
      position: relative;
      font-weight: 700;
      left:6vw!important;
      word-break: break-word !important;
    }
    #itoconnect-phoneblock_main_{
      width: 75vw;;
    }
   
    #errormessage_state {
      color: red;
      font-size: 2.811111111111111vw;
      position: relative;
      width: 21vw !important;
      font-weight: 700;
      margin-left: 0vw !important;
      height: 1vw;
    }
    #errormessage_district {
      color: red;
      font-size: 2.811111111111111vw;
      position: relative;
      width: 41vw !important;
      font-weight: 700;
      margin-left: .5vw !important;
    }
    #errormessage_constituency {
      color: red;
      font-size: 2.811111111111111vw;
      position: relative;
      width: 41vw !important;
      font-weight: 700;
      margin-left: 0vw !important;
    }
    .signin__text span b {
      color: #056b38 !important;
      font-family: "Montserrat";
      font-size: 4.44vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .signin__text p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 3.611111111111111vw;
      line-height: 4.444444444444444vw;
      text-align: center;
      width: 50vw;
      color: #8b93a5 !important;
    }
    .sing_text_title {
      margin-top: 6.166vw;
      font-size: 3vw !important;
      color: #666;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .sign_text_loginlink b {
      color: #056b38 !important;
      font-family: "Montserrat";
      font-size: 4.44vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      justify-content: center;
      margin-top: 1vw;
    }
    .sign_text_loginlink a {
      text-decoration: none;
    }
  
    .css-1pkxkvx-control svg {
      color: #aeaeae;
      width: 5vw;
      height: 5vw;
    }
    .css-vy80k9-control svg{
      margin-right: 0vw!important;
      width: 5vw;
      height: 5vw;
    }
    .usertypeerrormessage {
      color: red !important;
      font-size: 2.811111111111111vw !important;
      font-weight: 500 !important;
      margin-top: 5px;
      line-height: 5vw !important;
    }

    .signup__sub p.errormessage {
      color: red;
      font-size: 2.811111111111111vw;
      margin-top: 5px;
      line-height: 5vw;
      width: 90%;
    }
    #districtError,
    .usertypeerrormessage {
      margin-left: 5.8333333333vw !important;
    }

    .copyright {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 3.611111111111111vw !important;
      line-height: 4.444444444444444vw !important;
      text-align: center;
      height: 18.44444444444444vw !important;
      color: #8b93a5 !important;
      margin-top: 0px !important;
    }

    #role,
    #state,
    #dist,
    #constituency {
      background-position: fixed !important;
    }
    #role,
    #state,
    #dist,
    #constituency {
      font-size: 3.9375vw !important;
      line-height: 5vw;
      font-family: "Montserrat";
      font-weight: 501 !important;
      color: #8b93a5 !important;
      opacity: 1;
      background-size: 4vw;
    }

    option {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      z-index: 1;
      font-size: 2.888888888888889vw !important;
      line-height: 4.722222222222222vw !important;
      color: #8b93a5;
      background-color: #ffffff;
    }
    .arrrow_div {
      position: relative;
      top: 15.555vw;
      width: 2.777777vw !important;
      height: 5vw !important;
    }
    .leftarrow_img_signupimg {
      position: absolute;
      top: 5vw !important;
      width: 2.777777vw !important;
      height: 5vw !important;
      left: 3vw;
    }
    .select_role__placeholder {
      font-size: 3vw !important;
      font-weight: 600 !important;
    }
    .select_role__menu {
      max-width: 35vw !important;
      background-color: #ffffff !important;
    }

    .signup_DistrictBlock .select_role__control,
    .signup_DistrictBlock .select_role__menu,
    .signup_DistrictBlock .select_role__option,
    .signup_ConstituencyBlock .select_role__control,
    .signup_ConstituencyBlock .select_role__menu,
    .signup_ConstituencyBlock .select_role__option {
      font-size: 3.9vw !important;
    }

    .signup_ConstituencyBlock .select_role__single-value,
    .signup_DistrictBlock .select_role__single-value {
      font-size: 3.9vw !important;
    }
  }
}
