.import-summary-component {
    .list-group-item.active {
        color: #000;
        background-color: #fff;
        border-color: var(--bs-list-group-border-color);
    }
    .list-group-item {
        .success-icon {
            display: none;
        }
    }
    .list-group-item.active {
        .success-icon {
            display: block;
        }
    }

    .form-select {
        border: 1px solid rgba(0,0,0,0.17);
        border-radius: 0;
        color: #000 !important;
        font-weight: 500 !important;
        background-color: #F6FAFF;
    }

    .form-check-input:checked {
        background-color: #77A13B;
        border-color: #CBCBCB;
    }

    .card {
        border-radius: 2px;
        background-color: #F6FAFF;
    }

    .pdf-icon {
        color: #1C477A;
    }
} 

@media (min-width:575px) and (max-width:711px){
    .ems-step-progress hr{
        margin-top: 3.3854166666666665vw;
    }

}