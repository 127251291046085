
   .footer_itoconnect{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
  .copyright_footer{
    color: #263659;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  }
  .under_line1{
    width: 100vw;
    border: 1px solid #9A9A9A;
  background: #F0F0F0 !important;
  margin:.7vw !important;
  }
  .bottom-navigation{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: white;
    height:4.3vw !important;
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 6px;
    text-decoration: none !important;
    flex: 1; 
    color: #737373;
  text-align: center;
  font-size: 2vw !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  
  .nav-icon {
    /* font-size: 26px; 
    color:#737373; */
  
  }
  
  .nav-label {
    font-size: 1vw;
    text-align: center; 
    font-weight: 800;
    color: #737373;
  font-weight: 600;
    
  }
  .nav-label.active {
    color: #046A38 !important;
    font-weight: 700;
  }
  
  .nav-item.active .nav-label {
    color: #046A38 !important;
    font-weight: 700 !important;
  }
  .nav-item.active {
    color: #046A38 !important;
    font-weight: 700 !important;
  }
  
  .nav-item.active .nav-icon  {
    color: #046A38; 
    font-weight: 700;
  
  }
  .nav_icon_footer_images{
    width: 2vw;
    height: 2vw;
  } 
  


@media (max-width: 767px) {
  .footer_itoconnect{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
  .copyright_footer{
    color: #263659;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  /* height:14vw !important; */
  }
  .under_line1{
    width: 100vw;
    border: 1px solid #9A9A9A;
  background: #F0F0F0 !important;
   margin:2vw !important;  
  
  }
  .bottom-navigation{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: white;
    height:15vw !important;
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 6px;
    text-decoration: none !important;
    flex: 1; 
    color: #737373;
  text-align: center;
  font-size: 2.7vw !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  
  .nav-icon {
    /* font-size: 26px; 
    color:#737373;
   */
  }
  
  .nav-label {
    font-size: 2.4vw;
    text-align: center; 
    font-weight: 800;
    color: #737373;
  font-weight: 600;
    
  }
  .nav-label.active {
    color: #046A38 !important;
    font-weight: 700;
  }
  
  .nav-item.active .nav-label {
    color: #046A38 !important;
    font-weight: 700 !important;
  }
  .nav-item.active {
    color: #046A38 !important;
    font-weight: 700 !important;
  }
  
  .nav-item.active .nav-icon  {
    color: #046A38; 
    font-weight: 700;
  
  }
  .nav_icon_footer_images{
    width: 8vw !important;
    height: 8vw !important;

  }
  
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .footer_itoconnect{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    /* background-color: red; */
    /* display: none; */
  }
  .copyright_footer{
    color: #263659;
  font-size:2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  /* background-color: red; */
  height:6vw;
  }
  .under_line1{
    width: 100vw;
    border: 1px solid #9A9A9A;
  background: #F0F0F0 !important;
  margin:1vw!important;
  
  }
  .bottom-navigation{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: white;
    /* margin-bottom: 14px; */
    /* background-color: green; */
    /* height:7vw; */
    height:8vw !important;
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 6px;
    text-decoration: none !important;
    flex: 1; 
    color: #737373;
  text-align: center;
  font-size: 2.7vw !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  
  .nav-icon {
    font-size: 26px; 
    color:#737373;
  
  }
  
  .nav-label {
    font-size: 2vw;
    text-align: center; 
    font-weight: 800;
    color: #737373;
  font-weight: 600;
    
  }
  .nav-label.active {
    color: #046A38 !important;
    font-weight: 700;
  
  }
  
  .nav-item.active .nav-label {
    color: #046A38 !important;
    font-weight: 700 !important;
  }
  .nav-item.active {
    color: #046A38 !important;
    font-weight: 700 !important;
  }
  
  .nav-item.active .nav-icon  {
    color: #046A38; 
    font-weight: 700;
  
  }
  .nav_icon_footer_images{
    width: 3.5vw;
    height: 3.5vw;
  }
  
}