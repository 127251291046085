.rules-view {
    margin-top: 4.5vw;
    height: 85vh;

    .title {
        font-size: 1vw;
        // margin-left: 0vw;
        // width: 97%;
        font-weight: 400;
    }

    .title {
        font-size: 28px;
        font-weight: 400;

        hr {
            margin-top:0.26vw;
            border-width: 2px;
            border-color: #000;
            // width: 94.9%;
            width: 95%;
            margin-left:2.17vw;

        }
    }
 
    .table-main {
        overflow: auto;
        height: 70vh;
        overflow-y: scroll;
    }

    .form-error {
        display: block !important;
    }

    .create-button {
        font-size: 14px;
    }

    .page {
        margin: 5px 0;
    }

    .session {
        margin-top: 5px;
    }

    .title-section {
        margin-bottom: 15px;

        .title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #263659;
            margin-bottom: 5px;
        }

        .sub-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #263659;
        }
    }

    .ems-text {
        color: #263659;
        font-size: 18px;
        font-weight: 500;
    }

    .data-section {
        margin-bottom: 10px;
    }

    .import-button {
        background: #1C477A;
        border-radius: 0;
    }

    .label {
        font-size: 18px;
        font-weight: 500;
    }

    .btn-outline-ems {
        --bs-btn-color: #1C477A;
        --bs-btn-border-color: #1C477A;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #1C477A;
        --bs-btn-hover-border-color: #1C477A;
        --bs-btn-focus-shadow-rgb: 13, 110, 253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #1C477A;
        --bs-btn-active-border-color: #1C477A;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #1C477A;
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #1C477A;
        --bs-gradient: none;
        // color: #1C477A;
    }

    .btn-primary-ems {
        --bs-btn-color: #fff;
        --bs-btn-bg: #1C477A;
        --bs-btn-border-color: #1C477A;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #0b5ed7;
        --bs-btn-hover-border-color: #1C477A;
        --bs-btn-focus-shadow-rgb: 49, 132, 253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #1C477A;
        --bs-btn-active-border-color: #1C477A;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #0d6efd;
        --bs-btn-disabled-border-color: #0d6efd;
        color: #fff;
    }

    .navbar-fixed-bottom {
        position: fixed;
        bottom: 0px;
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
    }

    .card-navbar-bottom {
        background-color: #fff;
        border: none;

        .card-body {
            padding: 10px 55px;
        }
    }

    .no-border-radius {
        // border-radius: 1px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .table-bordered>:not(caption)>* {
        height: 2vw !important;
        background-color: red !important;
    }

    .table {
        height: fit-content;
    }

    .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: 'none';
    }

    .btn {
        margin-left: 4vw;
    }

    .btn.disabled {
        // font-size: 1vw;
    }

#reports_download_button{
  position: absolute;
  right:6vw;

}
.reports_title{
    margin-left:1vw;
}

}

@media (max-width: 767px) {
    .rules-view {
        margin-top: 21.5vw;
        height: 81vh;
    }
    .btn.disabled {
        // font-size: 2.5vw;
    }
    .btn {
        margin-left: 4vw;
    }
    .rules-view  {
        font-size: 2.5vw;
    }

    .hstack {
        // font-size: 3.5vw;
        font-weight: 500;
        font-size: 5vw !important;
    }
    .reports_checkbox input[type="checkbox"] {
        // transform: scale(1.5);
        height: 3.8vw;
        width: 3.8vw;
      }
      .hstack input[type="checkbox"]{
        height: 3.8vw;
        width: 3.8vw;
      }
      .rules-view .title hr {
        margin-top: 1vw;
        border-width: 2px;
        border-color: #000;
        width: 100%;
        // display: flex;
        // flex-direction: column;
        margin-left:0vw;
    }
    // .rules-view .title {
    //    margin-left: 0vw; 
    // }

}

@media (min-width:768px) and (max-width:1024px) {
    .rules-view {
        margin-top: 4.5vw;
        height: 91.3vh;
        hr {
            margin-top:0.26vw;
            border-width: 2px;
            border-color: #000;
            width: 92.1% !important;
            margin-left:3.4vw !important;
        }

    }
    .reports_checkbox input[type="checkbox"] {
        // transform: scale(1.5);
        height: 1.5vw !important;
        width: 1.5vw !important;
      }
      .hstack input[type="checkbox"]{
        height: 1.5vw !important;
        width: 1.5vw !important;
      }
      .reports_title{
        margin-left:1.6vw!important;
    }
}