.birthday_data_main_div {
  animation: votersdata 1.5s linear;
}
.birthday_data_text_main_div {
  height: 3.8541666666666665vw;
  width: 94.5%;
  position: relative;
  border-bottom: 0.2vw solid #1e1e1e;
  margin-left: 1.2890625vw;
  margin-bottom: 0.625vw;
}
.birthday_data_text_ems {
  font-size: 1.4583333333333333vw;
  font-family: "Montserrat";
  font-weight: 700;
  position: absolute;
  top: 1.1979166666666667vw;
  left: 1.3vw !important;
  color: #0047b2;
}
.filter_birthday {
  position: absolute;
  right: 0.2%;
  width: 15%;
  height: 2.2916666666666665vw;
  top: 0.7291666666666666vw;
  cursor: pointer;
}
.birthday_dropdow_filter {
  height: 2.65625vw;
  background-color: #f3f7f9;
  border-radius: 0.3vw;
  border: 0.052083333333333336vw solid #d8d8d8;
  cursor: pointer;
  position: relative;
  width: 100% !important;
}

.birthday_dropdown_box_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  color: #8b93a5;
  font-size: 1.0416666666666667vw;
  margin-left: 0.78125vw;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.birthday_dropdown_arrow {
  height: 0.6291666666666666vw;
  width: 0.7291666666666666vw;
  position: absolute;
  left: 90%;
}
#mandal_town_data {
  /* width:19vw; */
}
.mandal_town_data_ {
  border-bottom: 0.1vw solid #d9d8d8;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 500;
  position: relative;
  border-bottom: 0.1vw solid #d9d8d8 !important;
  height: 2vw !important;
}
.mandal_town_data_ span {
  font-size: 1vw !important;
  font-weight: 500;
  width: 5vw !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
  font-family: "Montserrat";
  color: #263659;
  font-weight: 600;
}

#checkbox_birthdays {
  position: absolute;
  left: 12.3vw;
  width: 1vw !important;
  top: 0.5vw !important;
}
.itoconnect_birthdays_data_mobile_main_div {
  margin-top: 0vw !important;
  display: none !important;
}
.birthdays_data_next_prev_main_div {
  display: none !important;
}
.parent_horizontal_scroll_birthday_data {
  height: 71vh;
  width: 100%;
  position: relative;
  margin-top: 3.5vw;
}

.horizontal_scroll_birthday_data {
  width: 94.5%;
  height: inherit;
  overflow-y: none;
  overflow-x: scroll;
  left: 1.2890625vw;
  position: absolute;
  border: 0.052083333333333336vw solid #ccd0d6;
}

.top_heading_in_birthday_data {
  width: 13.48vw;
  height: 2.8645833333333335vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  background-color: #e8ecf1;
  display: flex;
  align-items: center;
  font-size: 1vw !important;
  font-family: "Montserrat" !important;
  color: #4d7093 !important;
  font-weight: 600;
}

.top_heading_in_birthday_data span {
  margin-left: 13%;
}

.titleDiv {
  width: 13.48vw;
  height: 2.8645833333333335vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  overflow: hidden;
  display: inline-block;
  font-size: 1vw;
  font-weight: 600;
  margin: 0;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
}

.titleDiv span {
  font-family: "Montserrat";
  color: #263659;
  margin-left: 13%;
  font-weight: 600;
}
.titleDiv_span_title_ {
  width: 10vw !important;
  text-align: left !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.voter_id_birthdays {
  font-family: "Montserrat";
  color: #046a38 !important;
  font-weight: 600;
}
.call_image_button {
  height: 2vw;
  margin-left: 13%;
  border-radius: 0.3vw;
}
.birthday_data_underlines_holder {
  margin-left: 1.2890625vw;
  margin-top: 1vw;
}

.birthday_data_underline_one {
  background-color: #ccd0d6;
  width: 95.8%;
  height: 0.1vw;
  margin-top: 0.1vw;
}

.buttons_birthday_data_next_prev_main_div {
  margin-left: 85.7% !important;
  margin-top: 1vw;
}

.total_pages_birthday {
  background-color: #88a7c6;
  width: fit-content;
  height: 2.4270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw !important;
  color: white;
  font-size: 1.291666666666666vw;
  font-weight: 800;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  padding: 0.5vw;
  position: absolute;
  left: 1.2890625vw;
}

.prev_button_birthday_data {
  background-color: #88a7c6;
  width: 4.083333333333335vw;
  height: 2.4270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw !important;
  color: white;
  font-size: 1.291666666666666vw;
  text-align: center;
  font-family: "Montserrat";
  outline: #88a7c6 !important;
  margin-right: 1%;
  cursor: pointer;
  font-weight: 800;
}

.current_page_number_birthday_data {
  background-color: #88a7c6;
  width: fit-content;
  height: 2.4270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw !important;
  color: white;
  font-size: 1.291666666666666vw;
  font-weight: 800;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  padding: 0.5vw;
}

.next_button_birthday_data {
  background-color: #88a7c6;
  width: 4.083333333333335vw;
  height: 2.4270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw !important;
  color: white;
  font-size: 1.291666666666666vw;
  font-family: "Montserrat";
  text-align: center;
  outline: #88a7c6 !important;
  margin-left: 1%;
  cursor: pointer;
  font-weight: 800;
}

.sidenav_holder_div_birthday_data {
  width: fit-content;
  height: 100%;
  display: flex;
}

.top_headings_in_birthday_data_main_div {
  width: fit-content;
}

.birthday_call_status_nav_bar span {
  margin-left: 13%;
}

.call_status_div {
  width: 8.78vw;
}

.birthday_data_holder_main_div {
  width: fit-content;
  overflow-y: scroll;
  margin-left: 0%;
}
.birthday_data_holder_main_div::-webkit-scrollbar {
  width: 0.2vw;
}
.birthday_data_holder_main_div::-webkit-scrollbar-thumb {
  background-color: #959595;
  border-radius: 1vw;
}

.nodata_available {
  position: absolute;
  color: red;
  left: 35vw;
  top: 30%;
  font-size: 1.5vw;
  font-weight: 500;
}
#nodata_available_{
  color: #1e1e1e;
  font-weight: 600;
}

.loadingscreenI2connect {
  height: 100vh;
  width: 100%;
  background-color: black;
  position: fixed;
  opacity: 0.5;
  z-index: 1040;
  left: 0%;
  right: 0%;
}
.Itoconnectlogo_loading_div {
  position: fixed;
  height: 30vw;
  width: 30vw;
  left: 38%;
  top: 10vw;
  z-index: 1041;
}
.itoconnect_loading_sub_div {
  height: 100%;
  width: 100%;
  position: relative;
}
.loadingLogo_pdf {
  height: 5vw;
  width: 6vw;
  position: absolute;
  left: 12vw;
  top: 10vw;
  z-index: 1080;
}
.loading_div {
  height: 0.5vw;
  position: absolute;
  left: 7vw;
  top: 17vw;
  background-color: #ffffff;
  border-radius: 1vw;
  animation: logoanimation 0.9s linear infinite;
}
@keyframes logoanimation {
  from {
    width: 0vw;
  }

  to {
    width: 10vw;
  }
}

.modal-fullscreen .modal-content {
  height: 34%;
  /* position: relative; */
  /* position: absolute; */
  border: 0;
  border-radius: 30px 30px 30px 30px;
  bottom: 0% !important;
  /* top: 20% !important; */
  width: 40%;
  left:20%;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
  border-bottom: 1px solid #ccd0d6 !important;
  height: 3.5vw !important;
}

.btn-close {
  font-size: 1vw;
}
.birthday_view_main_div {
  display: flex;
}

#first-name_div_ {
  height: 5.5vw;
}
#lasst-name_div_ {
  height: 5.5vw;
}
#person-designation_div_ {
  height: 5.5vw;
  margin-top: 1vw;
}
#call_stauts_view_page {
  height: 6vw;
  margin-top: 1.3vw;
}
.modal-title {
  font-size: 1.3vw !important;
  font-weight: 600;
  color: #263659 !important;
}

.birthday_view_main_div h6 {
  color: #606b83;
  font-weight: 600;
  font-size: 1.2vw !important;
}

.birthday_view_main_div p {
  color: #263659;
  font-weight: 700;
  font-size: 1.2vw !important;
  width: 17vw !important;
  text-align: left !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 767px) {
  .buttons_birthday_data_next_prev_main_div {
    display: none !important;
  }
  .itoconnect_birthdays_data_mobile_main_div {
    margin-top: 10vw !important;
    display: block !important;
  }
  .birthdays_data_next_prev_main_div {
    display: block !important;
  }
  .birthday_data_underlines_holder {
    display: none;
  }
  .parent_horizontal_scroll_birthday_data {
    display: none;
  }
}

@media (max-width: 767px) {
  .birthday_data_text_ems {
    text-align: center;
    color: #0047b2;
    font-size: 5.55vw;
    font-weight: 700;
    margin-top: 3vw;
    font-size: 5vw;
    margin-left: 6%;
    display: none;
  }

  .parent_horizontal_scroll_birthday_data {
    height: 76vh;
  }
  .filter_birthday {
    width: 45vw;
    left: 50%;
    margin-top: 3vw !important;
  }
  .birthday_dropdow_filter {
    height: 9vw;
  }

  .birthday_dropdown_box_text {
    font-size: 4vw;
    margin-left: 10px;
    font-weight: 900;
  }
  .birthday_dropdown_arrow {
    height: 2vw;
    width: 2.7291666666666666vw;
    position: absolute;
    left: 85%;
  }
  .birthday_dropdow_filter {
    border-radius: 3px;
    border: 2px solid #528bb8;
    background: #fff;
  }
  .birthday_dropdown_box_text {
    color: #528bb8;
    font-size: 5vw;
    font-weight: 600;
  }
  .birthday_data_text_main_div {
    width: 78.3333333398vw !important;
    left: 26px;
    height: 0vw !important;
    border: none !important;
  }

  #mandaltown_data_ {
    /* border-radius: 5px 5px 0px 0px; */
    margin-top: 7vw !important;
    background: #fff;
    /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1); */
  }

  .mandal_town_data_ {
    /* height: 10vw !important; */
    height: auto !important;
    width: 100%;
    border-bottom: 0.1vw solid #d9d8d8;
    text-align: left;
    font-family: "Montserrat";
    font-weight: 500;
    position: relative;
    border-bottom: 0.1vw solid #d9d8d8 !important;
    border-radius: 5px 5px 0px 0px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding-top: 2vw;;
    padding-bottom: 2vw;
    
  }
  .mandal_town_span_texttitle_ {
    display: inline-block;
    white-space: pre-line !important; 
  }
  .mandal_town_data_ span {
    font-size: 3.4vw !important;
    width:30vw !important;
     padding-left: 2vw;   
  }

  #checkbox_birthdays {
    position: absolute;
    left: 38vw;
    width: 3vw !important;
    height: 3.1vw !important;
    margin-top: 2.5vw;
    /* top: 1.3vw !important; */
    /* padding-top: 2vw !important;
    padding-bottom: 2vw !important; */
  }

  .birthdays_title_name {
    width: 86.4vw !important;
    width: 100%;
    height: 11.66vw;
    background: #b3c8e8 !important;
    margin-left: -0.1vw !important;
    margin-top: -0.1vw !important;
  }
  .birthdays_title_name span {
    color: #0047b2;
    font-size: 4vw;
    font-weight: 600;
    padding: 10px;
    position: relative;
    top: 3vw;
  }
  .itoconnect_birthdays_data_mobile_main_div {
    margin-left: 6.5vw;
    width: 86.5vw;
    height: 167vw;
    border-radius: 2px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    /* margin-top: 15vw !important; */
  }

  .itoconnect_birthdays_id_child_div_main {
    height: 152.16vw;
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-width: auto;
    scrollbar-width: inherit !important;
  }
  .main_div_voters_display_data {
    display: flex;
    flex-direction: row;
  }
  #title_div_birthdays_data_first_column {
    background: none !important;
    border: none !important;
    margin-left: 4.16vw !important;
    margin-top: 5vw;
    width: 59vw;
    display: flex;
  }

  #title_div_birthdays_data_first_column span {
    color: #046a38;
    font-size: 5vw;
    font-weight: 600;
    text-align: left;
    /* width: 40vw!important; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* background-color: red; */
  }

  .birthdays_data_first_name_age_caste_main {
    display: flex;
    justify-content: space-between;
  }
  .birthdays_data_child_div_first_name_age_caste {
    margin-top: 2vw;
  }
  .birthdays_data_first_name_age_caste_main span {
    color: #263659;
    font-size: 3.888vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 4.16vw;
  }
  .call_icon_Data {
    width: 15%;
    margin-top: -3vw;
  }
  .call_image_button {
    height: 6.11vw !important;
    width: 6.11vw !important;
    border-radius: 0.3vw;
  }

  .birthdays_data_next_prev_main_div {
    display: flex !important;
    padding-right: 25px;
    /* margin-left: 56% !important; */
    margin-left: 38% !important;
    /* gap: 10px; */
    width: auto;
    margin-top: 4vw;
    margin-bottom: 31vw;
  }
  .total_pages_birthday {
    width: 9.722222222222221vw;
    height: 9.722222222222221vw;
    font-size: 0.75rem;
    justify-content: center;
    display: none;
  }
  .prev_button_birthday_data,
  .next_button_birthday_data {
    width: 6vw;
    height: 8vw;
    font-size: 0.75rem;
    border-radius: 0px !important;
    background: #B3C8E8;
    color: #0047B2;
    font-weight: 700 !important;
  }
  .current_page_number_birthday_data {
    width: 6vw;
    height: 8vw;
    font-size: 0.75rem;
    justify-content: center;
    background: #B3C8E8;
    color: #0047B2;
    font-weight: 700 !important;
  }

  .modal-fullscreen .modal-content {
    height: 38%;
    /* height: 30% !important; */
    position: absolute;
    position: fixed !important;
    left:0vw !important;
    border: 0 !important;
    width: 100%;
    width:100vw;
    overflow: scroll;
    border-radius: 20px 20px 0 0;
    background: #fff;
  }
 

  .modal-fullscreen .modal-header {
    border-radius: 0;
    border-bottom: 1px solid #ccd0d6 !important;
    height: 12.22vw;
  }
  .modal-fullscreen .modal-header {
    border-radius: 0;
    border-bottom: 1px solid #ccd0d6 !important;
    height: 12.22vw;
  }
  .modal-title {
    color: #263659;
    font-size: 4.44vw !important;
    font-weight: 600;
  }
  .btn-close {
    font-size: 3.5vw !important;
    outline: none;
    border: none;
    
  }
  #first-name_div_ {
    height: 11.5vw;
    height: auto !important;
    /* background-color: red; */
}
#lasst-name_div_ {
    height: 11.5vw;
    height: auto !important;
}
#call_stauts_view_page {
    height: 6vw;
    margin-top: 3.3vw;
}
  #person-designation_div_ {
    height: 11.5vw;
    /* margin-top: 9vw; */
}
  .birthday_view_main_div h6 {
    color: #606b83;
    font-size: 3.888vw !important ;
    font-weight: 600;
  }
  .birthday_view_main_div p {
    color: #263659;
    font-size: 3.888vw !important;
    font-weight: 700;
    width: 43vw !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    text-align: left !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: wrap !important;
    height: auto !important;
    overflow: scroll !important;
  }

  .nodata_available {
    position: absolute;
    color: red;
    left: 28vw;
    top: 30%;
    font-size: 3vw !important;
    font-weight: 500;
  }
  #nodata_available_{
    color: #1e1e1e;
    font-weight: 600;
  }
  .calender_container .day-of-month {
    /* width: 35px !important;
    height: 35px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    color: #fff; */
    /* display: none !important; */
  }
  .send_sms_button_main_div{
    /* position:fixed;
     bottom: 7rem;
     right: 24px; */
      /* z-index: 9999 */
      position: absolute;
      top:16.2vw;
      right:7vw;
  }
  .btnSaveChanges{
    /* position: relative; */
    /* bottom: 6rem; */
    border-radius: .8vw !important;
    background-color: skyblue;
    /* background-color:#ADD8E6; */
    padding: 3.2vw;
    border: none;
    outline: none;
    color: #000;
    /* color:#fff; */
    font-weight: 600;
    font-size: 3.55vw;
    /* display: none !important; */
  }
  .birthday_data_text_ems_ {
    text-align: center;
    color: #0047b2;
    font-size: 5.55vw;
    font-weight: 700;
    font-size: 4vw;
    margin-left: 6.8%;
    position: relative;
    top: 8vw;

  }
  .modal-dialogP {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none;
    top: 15% !important;
  }
  .ecampaign_modal-header{
    background: #b3c8e8 !important;

  }
  .modal-title{
    /* background: #b3c8e8 !important;
    width: auto; */
    color: #263659;
    font-size: 4.4vw;
    font-weight: 600;
    color: #046a38 !important;
  }
  .mes_close_{
    border: none !important;
    outline: none !important;
    position: relative;
    left: 7vw;
    font-size: 4vw !important;
    margin:0 !important;
    background: none !important;
  }
  .close{
    /* background-color: red; */
    border: none !important;
    outline: none !important;
    position: relative;
    left: 7vw;
    font-size: 2.6vw !important;
    margin:0 !important;
    background: none !important;
  }
  .btn-close:focus {
    border: none !important;
    outline: none !important;
  }
  .modal-title_title_{
    color:#000;
    font-size: 3.888vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .message_preview_title_description{
    color: #263659;
    font-size: 3.888vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center !important;
  }
  .submit_messages {
    /* padding: 10px 30px; */
    height: 8vw;
    width: 26vw;
    justify-content: center;
    left: 22.15vw;
    /* opacity: 0.5; */
    border-radius: 0.3vw !important;
    margin-top: 2vw;
    font-family: "Montserrat";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 0.9375vw;
    /* line-height: 1.145vw; */
    color: #FFF;
    border: none;
    /* color: #000; */
    font-weight: 600;
    font-size: 3.55vw;
    background-color: #1C477A !important;
  }
}


@media (min-width: 768px) and (max-width: 1024px){
  .birthday_data_main_div {
    margin-top: 3vw;
    height: 91vh;
    animation: votersdata 1.5s linear;
}
 .birthday_data_text_ems {
    color: #0047b2;
    font-weight: 700;
    font-size: 2.5vw !important;
    /* background-color: red; */
    display: none;
  }
  .birthday_data_text_main_div {
    height: 5.5vw !important;
    width: 94.5% !important;
    position: relative;
    border-bottom: 0.2vw solid #1e1e1e;
}
.filter_birthday {
  position: absolute;
  right: 0.2%;
  width: 28%;
}
.birthday_dropdow_filter {
  height: 3.8625vw ;
}
.birthday_dropdown_box_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  color: #8b93a5;
  font-size: 2.041667vw !important;
  text-align: left;
}
.birthday_dropdown_arrow {
  height: 1.129167vw;
  width: 1.1291666666666666vw;
}
.mandaltown_data {
  position: absolute;
  width: 100%;
  top: 4vw;
  height: 20vw!important;
}
.mandal_town_data_ {
  height: 7vw!important;;
  height: auto !important;
  padding-top: 1vw;;
    padding-bottom: 1vw;
}

.mandal_town_span_texttitle_ {
  display: inline-block !important;
  white-space: pre-line !important; 
  width: 5vw!important;

}
#mandal_town_span_texttitle_ {
  display: inline-block !important;
  white-space: pre-line !important; 
}
.mandal_town_data_ span {
  font-size: 2.2vw !important;
  width:20vw !important;
  padding-left: 2vw;
}

#checkbox_birthdays{
position: absolute;
    left: 23vw !important;
    width: 1.9vw !important;
    height: 1.9vw !important;
    margin-top: 1.4vw !important; 
}
.parent_horizontal_scroll_birthday_data {
  height: 78vh;
  min-height: 57vw!important;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}
.horizontal_scroll_birthday_data
{  
margin-top: 2vw;
}
.top_heading_in_birthday_data {
  width: 31.4vw !important;
  height: 4.4645833333333335vw !important;
  border: 0.052083333333333336vw solid #ccd0d6 !important;
  background-color: #e8ecf1 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 1.7vw !important;
  font-family: "Montserrat" !important;
  color: #4d7093 !important;
  font-weight: 600 !important;
  background-color: #B3C8E8 !important;
}
.top_heading_in_birthday_data span {
  font-size: 1.8vw !important;
  color: #4d7093 !important;
  font-weight: 600 !important;

}
.titleDiv {
  width: 31.4vw !important;
  height: 4.4645833333333335vw !important;
  border: 0.052083333333333336vw solid #ccd0d6 !important;
  overflow: hidden;
  display: inline-block;
  font-size: 1.7vw !important;
  font-weight: 600 !important;
  margin: 0;
  text-decoration: none !important;
  /* text-overflow: ellipsis !important;
  white-space: nowrap !important; */
  display: flex !important;
  align-items: center !important;
  position: relative !important;
}
.titleDiv  span{
  width: 24vw;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-align: left !important;
  /* background-color: red; */
}
.call_image_button {
  height: 3vw !important;
  margin-left: 13% !important;
  border-radius: 0.3vw;
}
.total_pages_birthday {
  background-color: #88a7c6;
  width: 3.927083vw;
  height: 3.327083vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw !important;
  width: 12vw !important;
  height: 4.5vw !important;
}
.total_pages_birthday span{
  font-size: 2.2vw!important;
  
}
.buttons_birthday_data_next_prev_main_div {
  margin-left: 80.5% !important;
  margin-top: 1vw;
}
.prev_button_birthday_data {
  background-color: #88a7c6;
  width: 6.83333333333335vw;
  height: 4.3270833333333333vw;
  border: 0.052083333333333336vw solid #ccd0d6;
  border-radius: 0.10416666666666667vw !important;
  color: white;
  font-size: 2vw;;
  text-align: center;
}
.current_page_number_birthday_data{
  font-size: 2vw;;
  width: 2.7083333333333335vw;
  height: 4.3270833333333333vw;

}
.current_page_number_birthday_data span{
 margin-left:.4vw;
}
.next_button_birthday_data{
  font-size: 2vw;;
  width: 6.83333333333335vw;
  height: 4.3270833333333333vw;
}
.modal-fullscreen .modal-content {
  height: 24%;
  /* position: absolute; */
  position: relative;
  border: 0;
  /* border-radius: 30px 30px 30px 30px; */
  border-radius: 10px;

  bottom: 0% !important;
  top: 20% !important;
  width: 50%;
  left: 20%;
  /* right: 30%; */
  /* overflow: scroll; */

  /* height: 34%; */
  /* position: relative; */
  /* border: 0; */
  /* border-radius: 30px 30px 30px 30px; */
  /* bottom: 0% !important; */
  /* top: 20% !important; */
  /* width: 40%;
  left:20%; */
  
}

.modal-title {
  font-size: 1.9vw !important;
  font-weight: 600;
  color: #263659 !important;
}
.btn-close {
  font-size: 1.5vw;
  outline: none !important;
  border: none !important;
}
.mes_close_{
  border: none !important;
  outline: none !important;
  position: relative;
  left: 9vw;
  font-size: 3vw !important;
  margin:0 !important;
  background: none !important;
}
.birthday_view_main_div h6 {
  color: #606b83;
  font-weight: 600;
  font-size: 1.8vw !important;
}
.birthday_view_main_div p {
  color: #263659;
  font-weight: 700;
  font-size: 1.8vw !important;
  width: 18vw !important;
  text-align: left !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-wrap: break-word;
  word-break: break-word;
  width: 22vw !important;

  white-space: wrap !important;

}
#person-designation_div_ {
  height: 5.5vw;
  margin-top: 5vw;
}
#nodata_available_{
  color: #1e1e1e;
  font-weight: 600;
}
.calender_container .day-of-month {
  width: 35px !important;
  height: 35px;
  /* background: #528bb8; */
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  color: #fff;
  display: none !important;
}
.parent_horizontal_scroll_birthday_data {
  height: 71vh;
  width: 100%;
  position: relative;
  margin-top: 0vw;
}
.send_sms_button_main_div{
  /* position:fixed;
   bottom: 7rem;
   right: 4.2vw;
   bottom: 8.5rem; */
   position: absolute;
   top:-1vw;
   right:4.4vw;
    /* z-index: 9999 */
}
.btnSaveChanges{
  position: relative;
  /* bottom: 6rem; */
  border-radius: .8vw !important;
  background-color: skyblue;
  padding: 10px;
  border: none;
  outline: none;
  color: #000;
  font-weight: 600;
  font-size: 1.7vw;

}
.birthday_data_text_ems_ {
  text-align: center;
  color: #0047b2;
  font-size: 2.4vw;
  font-weight: 700;
  margin-left: 2%;
  position: relative;
  /* top: 5vw; */

}
.modal-dialogP {
  position: relative;
  width: auto;
  
  margin: var(--bs-modal-margin);
  pointer-events: none;
  max-width: 80% !important;
  max-height: 40vw !important;
  top: 15% !important;
  left:5% !important;
}
.ecampaign_modal-header{
  background: #b3c8e8 !important;

}
.modal-title{
  /* background: #b3c8e8 !important;
  width: auto; */
  font-size: 3vw !important;
  font-weight: 600;
  color: #046a38 !important;
}
.close,.btn-close{
  /* background-color: red; */
  border: none;
  outline: none;
  position: relative;
  left: 8vw;
  font-size: 2vw;
}
.close,.btn-close:focus{
  border: none;
  outline: none;
}
.modal-title_title_{
  color:#000;
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.message_preview_title_description{
  color: #263659;
  font-size: 2.2vw;
  font-style: normal;
  font-weight:500;
  line-height: normal;
  display: flex;
  justify-content: center !important;
}
.submit_messages {
  height: 5vw;
  width: 18vw !important;
  justify-content: center;
  left: 22.15vw;
  border-radius: 0.3vw !important;
  margin-top: 2vw;
  font-family: "Montserrat";
  font-style: normal;
  color: #FFF;
  border: none;
  font-weight: 600;
  font-size: 2.55vw;
  background-color: #1C477A !important;
}
}