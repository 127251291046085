.mapping-field-component {
   .text-danger{
    font-size: 0.973vw;
   }
    .table-main {
        height: calc( 100vh - 365px);
        overflow: auto;
    }
    .error_messages_main{
        margin-bottom: 4.25em !important;
    }
    .table {
        width: 100%;
        margin: 0;
        // margin-bottom: 11%;

        th {
            background: #E8ECF1;
            color: #263659;
        }

        th,
        td,
        tr {
            border: 1px solid lightgray;
            font-size: 0.9375vw;
            line-height: 1.1458333333333333vw;
            padding: 0.5208333333333334vw;
        }
    }

    #cancel_button {
        border: 1px solid #1C477A !important;
        color: #1C477A !important;
        border-radius: 4px !important;
    
    }
    
    #prv_button {
    
        background: #C4D1E0;
        border-radius: 4px;
    }
    
    #next_button {
        border-radius: 4px !important;
    
    }
    .map_div {
        color: #528BB8;
    }

    .unmapped_div {
        color: #263659;
    }

    .json_data {
        color: #263659;
    }
    
}
@media (max-width:430px){
    .mapping-field-component{
        .table{
            margin-right: 0.2604166666666667vw;
        }
    } 
}

